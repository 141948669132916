import { Services_Texts } from "../../../../../interfaces/forms/areas/appbuilder/applications/services/services";
import { Services_Texts as Response } from "../../../../../interfaces/responses/areas/appbuilder/applications/services/services";

import { IBaseResponse } from "../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../_APIConstants";
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import {IGetForm} from "../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Services_TextsService {
    Add_Services_Texts = async (data: Services_Texts): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.APPLICATIONS.SERVICES.ADD, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Services_Texts = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.APPLICATIONS.SERVICES.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Services_Texts = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.SERVICES.DETAILS}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    }; 

    Update_Services_Texts = async (data: Services_Texts): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.SERVICES.UPDATE}`, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Services_Texts = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.SERVICES.DELETE}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Publish_Services_Texts = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.SERVICES.PUBLISH}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Services_TextsService();

import { Button, Form, Input, notification, Select, Switch } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Translations from '../../../../../../../common/components/Content/Translations/Translations';
import { Translations as TRLNS } from '../../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../../services/areas/content/apps/apps';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { useEffectOnce } from 'react-use';
import Placement from '../../../../../../../common/components/Content/Placement/Placement';
import Tags from '../../../../../../../common/components/Content/Tags/Tags';
import Providers from '../../../../../../../common/components/Content/Providers/Providers';

import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';
import Share from '../../../../../../../common/components/Content/Share/Share';

const EditApp = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [placement, setPlacement] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const [provider, setProvider] = useState('');
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;
  const [share, setShare] = useState(false);

  useEffect(() => {
    setPlacement(data.data.placement);
    setTranslations(data.data.translations);
    setTags(data.data.tags);
    setProvider(data.data.provider?.name);
    setShare(data.data.share ?? false);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      _id: _id,
      ...data.data,
      name: values.name,
      placement: placement,
      share: share,
      deeplink: values.deeplink,
      packageid: values.packgeid,
      tags: tags,

      translations: translations,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_App(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  useEffectOnce(() => {
    form.setFieldsValue(data);
  });

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('app-name')} />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">Deeplink</label>
                <Form.Item className="col-md-7" name="deeplink">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="deeplink://" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">Package ID</label>
                <Form.Item className="col-md-7" name="packageid">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="com.package.app" />
                </Form.Item>
              </div>
            </div>

            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />
            <Tags data={tags} setTags={setTags} />
            <Placement data={placement} setPlacement={setPlacement} />
            <Share data={share} setShare={setShare} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-app')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditApp;

import React from 'react'

type BackButtonProps = {
  text : string,
  url : string
}

export const BackButton = ({ text, url } : BackButtonProps) => {
  return (
    <>
      <a href={url}>{ text }</a>
      <div className='mb-4' style={{
        border: "1px solid rgb(207, 207, 207)"
      }} />
    </>
  )
}

import { Route, Switch, useRouteMatch } from 'react-router';
import ShoppingCartList from './list'
import AddShoppingCart from './add'
import ShoppingCartDetails from './details'

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><AddShoppingCart /></Route>
      <Route path={`${path}/:id`}><ShoppingCartDetails /></Route>
      <Route path={`${path}`}><ShoppingCartList /></Route>
    </Switch>
  )
}

export default Index

import { Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import ButtonPng from '../../../../assets/images/appbuilder/Ribbon.png';
import Translations from '../Translations/Translations';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [titleColor, settitleColor] = useState('#ffffff');
  const [showtitleColorPicker, setShowtitleColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#999999');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const [translations, setTranslations] = useState([] as any);
  const [translationsLocal, setTranslationsLocal] = useState([] as any);
  const [showRibbon, setShowRibbon] = useState(false);
  const [bold, setBold] = useState(false);
  const [alignType, setAlignType] = useState("Left");

  useEffect(() => {
    if (data.ribbon != undefined) {
      setBackgroundColor(data.ribbon.background_color);
      settitleColor(data.ribbon.title_color);
      setTranslations(data.ribbon.title);
      setShowRibbon(data.ribbon.show_ribbon);
      setAlignType(data.ribbon.align);
      setBold(data.ribbon.title_bold);
      form.setFieldsValue(data.ribbon);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var translations_ = [] as any;
    translationsLocal.forEach((element: any) => {
      translations_.push({
        language: element.language,
        title: element.title,
      });
    });
    var ribbon = {
      show_ribbon: showRibbon,
      background_color: backgroundColor,
      border_radius: values.border_radius ?? 0,
      title_color: titleColor,
      title_bold: bold,
      title: translations_,
      align: alignType,
    };

    let dataOut = {
      ...data,
      ribbon: ribbon,
    };
    onSubmit(dataOut, type, props.index);
  };

  const setAlignTypeLocal = (value) => {
    setAlignType(value);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-component')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row" style={{ padding: 15 }}>
                    <div className="col card-bg-light p-4 mb-2 mr-4" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('ribbon')}</h5>
                      <p>{t('settings-that-define-the-object-such-as-background-color-borders-etc')}</p>
                      <div className="row mb-2">
                        <label className="col-md-4 col-form-label">{t('enable-ribbon')}</label>

                        <div className="col">
                          <Form.Item className="form-group pb-0" name="show_ribbon">
                            <Switch className="ch-switch" checked={showRibbon} defaultChecked={showRibbon} onChange={() => setShowRibbon(!showRibbon)} />
                          </Form.Item>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-md-4 col-form-label">{t('ribbon-position')}</label>
                        <div className="col">
                          <Form.Item name={'align'} className="form-group pb-0">
                            <Select onChange={setAlignTypeLocal} className="default-select" placeholder={t('select-position')}>
                              <Select.Option value={"Left"} key={'Left'}>
                                {t('left')}
                              </Select.Option>
                              <Select.Option value={"Right"} key={'Right'}>
                                {t('right')}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('background')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showBackgroundColorPicker}
                              color={backgroundColor}
                              onChange={(color) => {
                                setBackgroundColor(color);
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              setVisible={setShowBackgroundColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-md-4 col-form-label">{t('border')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <Form.Item name="border_radius" className="form-group pb-0 col">
                            <Input type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            RADIUS
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col" style={{ paddingRight: 0 }}></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('title')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowtitleColorPicker(!showtitleColorPicker);
                              }}
                              style={{
                                backgroundColor: titleColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showtitleColorPicker}
                              color={titleColor}
                              onChange={(color) => {
                                settitleColor(color);
                                setShowtitleColorPicker(!showtitleColorPicker);
                              }}
                              setVisible={setShowtitleColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ paddingLeft: 0 }}></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('title-bold')}</label>

                        <div className="col">
                          <Form.Item className="form-group pb-0" name="title_bold">
                            <Switch className="ch-switch" checked={bold} defaultChecked={bold} onChange={() => setBold(!bold)} />
                          </Form.Item>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row p-3">
                        <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <Translations setTranslations={setTranslationsLocal} data={translations}></Translations>
                        </div>
                      </div>
                    </div>
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={ButtonPng} alt={''}></img>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                      {t('update-ribbon')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

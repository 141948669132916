import { IOTPSubmitValues, ISignInFormValues } from '../../interfaces/forms/auth/AuthInterfaces';
import { IMakePrimaryAuthMode } from '../../interfaces/forms/auth/IMFAAuthInterfaces';
import { IGetAllAuthModesResponse } from '../../interfaces/responses/auth/IGetAllAuthResponse';
import { IUserLoginResponse } from '../../interfaces/responses/auth/ILoginResponse';
import { IAddTOTPAuthResponse } from '../../interfaces/responses/auth/IMFAAuthResponses';
import { User } from '../../interfaces/responses/users/user';
import { OTPVerifySuccessResponse } from '../../pages/auth/login';
import { default as axios, default as axiosInstance } from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class AuthService {
  Login = ({ email, password }: ISignInFormValues): Promise<User| OTPVerifySuccessResponse> => {
    const data = { email, password };
    return axios
      .post(API_ENDPOINTS.V1.Auth.LOGIN_ENDPOINT, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  OTPVerify = (data: IOTPSubmitValues) => {
    return axios
      .post(API_ENDPOINTS.V1.Auth.OTP_VERIFY, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  //mfa

  addTOTPAuthMode = async (user_id: string): Promise<IAddTOTPAuthResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.User.MFA.ADD_TOTP, { user_id })
      .then((res) => res.data)
      .catch((err) => err);
  };
  addEmailAuthMode = async (user_id: string): Promise<IAddTOTPAuthResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.User.MFA.ADD_EMAIL, { user_id })
      .then((res) => res.data)
      .catch((err) => err);
  };
  makePrimaryAuthMode = async (data: IMakePrimaryAuthMode): Promise<IAddTOTPAuthResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.User.MFA.MAKE_PRIMARY, data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  verifyTOTPAuthMode = async (data: { verification_otp: string }): Promise<IAddTOTPAuthResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.User.MFA.VERIFY_TOTP, data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  verifyEmailAuthMode = async (data: { verification_otp: string }): Promise<IAddTOTPAuthResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.User.MFA.VERIFY_EMAIL, data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  getAllAuthModes = async (): Promise<IGetAllAuthModesResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.User.MFA.ALL_ACTIVE_MODES)
      .then((res) => res.data)
      .catch((err) => err);
  };
}
export default new AuthService();

import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const Survey = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div
      className="container-fluid remove-hor-padding"
      style={{
        height: 180,
      }}
    >
      <div style={{ gridAutoColumns: '100%', display: 'grid', marginTop: props.application.theme?.rails_top_margin ?? 5, marginBottom: props.application.theme?.rails_bottom_margin ?? 5, marginLeft: 5, marginRight:5 }}>
        <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, marginTop: 10 }}>
          <Skeleton.Node active={false} style={{ width: 200, height: 20, backgroundColor: '#999', marginBottom: 10 }}>
            <div className="col-sm remove-hor-padding text-left"></div>
          </Skeleton.Node>
          <Skeleton.Node active={false} style={{ width: '100%', height: 50, backgroundColor: '#999', marginBottom: 10 }}>
            <div className="col-sm remove-hor-padding text-left"></div>
          </Skeleton.Node>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Node active={false} style={{ width: 75, height: 75, backgroundColor: '#999', borderRadius: 100, marginRight: 5 }}>
              <div className="col-sm remove-hor-padding text-left"></div>
            </Skeleton.Node>
            <Skeleton.Node active={false} style={{ width: 75, height: 75, backgroundColor: '#999', borderRadius: 100, marginRight: 5 }}>
              <div className="col-sm remove-hor-padding text-left"></div>
            </Skeleton.Node>
            <Skeleton.Node active={false} style={{ width: 75, height: 75, backgroundColor: '#999', borderRadius: 100, marginRight: 5 }}>
              <div className="col-sm remove-hor-padding text-left"></div>
            </Skeleton.Node>
            <Skeleton.Node active={false} style={{ width: 75, height: 75, backgroundColor: '#999', borderRadius: 100, marginRight: 5 }}>
              <div className="col-sm remove-hor-padding text-left"></div>
            </Skeleton.Node>
            <Skeleton.Node active={false} style={{ width: 75, height: 75, backgroundColor: '#999', borderRadius: 100, marginRight: 5 }}>
              <div className="col-sm remove-hor-padding text-left"></div>
            </Skeleton.Node>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;

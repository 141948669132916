import { Route, Switch, useRouteMatch } from 'react-router';
import BulkEdit from './edit';
import ExcelImporter from './importer/excel';
import Automation from './automations';
import AutomationAdd from './automations/add';
import AutomationDetails from './automations/details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/bulk-edit`}>
        <BulkEdit />
      </Route>
      <Route path={`${path}/importer-excel`}>
        <ExcelImporter />
      </Route>
      <Route path={`${path}/automations/add`}>
        <AutomationAdd />
      </Route>
      <Route path={`${path}/automations/details/:_id`}>
        <AutomationDetails />
      </Route>
      <Route path={`${path}/automations`}>
        <Automation />
      </Route>
    </Switch>
  );
};

export default Index;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import Irdeto from './irdeto'
import Token from './token'
import BuyDRM from './buydrm'

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/buydrm`}><BuyDRM /></Route>
      <Route path={`${path}/irdeto`}><Irdeto /></Route>
      <Route path={`${path}/token-settings`}><Token /></Route>
    </Switch>
  )
}

export default Index

import { createContext, useReducer } from "react";
import { AuthActions } from "./action-types";
import AuthReducer, { AuthState, initialAuthState } from "./reducer";

interface AuthContextType {
  dispatch: React.Dispatch<AuthActions>;
  state: AuthState;
}

export const AuthContext = createContext<AuthContextType>({
  state: {
    isAuthenticated: false,
    is_userinfo_loading: false,
    user: null
  },
  dispatch: () => null,
});
type Props = {
  children: React.ReactNode;
};
const AuthContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(AuthReducer, initialAuthState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import { Form, Input, Modal } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../pages/dashboard';
import Translations from '../Translations/Translations';
import LessonStreams from '../LessonStreams/LessonStreams';
import { ContentContext } from '../../../../contexts/contentContext';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';

export type IAddSongModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  reset: boolean;
  dataIn?: [];
};
const LessonModel = (props: IAddSongModalProps) => {
  const { visible, onSubmit, onCancel, dataIn } = props;
  const [data, setData] = useState(dataIn) as any;
  const params = useParams<IMSNameParams>();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [lesson, setLesson] = useState([] as any);
  const [translations, setTranslations] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    var translations_ = [] as any;
    translations.map((translation: any) => {
      var new_ = {
        name: translation.name,
        description: translation.description,
      };
      translations_.push(new_);
    });

    onSubmit({
      update: data.name != undefined ? true : false,
      name: values.name,
      number: values.number,
      translations: translations_,
      duration: values.duraction ?? 0,
      images: {
        widescreen: data.name != undefined && lesson.url == undefined ? lesson : lesson.url,
      },
      streams: {
        courses: {
          url: values.streams.courses.url,
        },
      },
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [props.reset]);

  useEffect(() => {
    if (data.translations != undefined) {
      setLesson(data.images.widescreen);
      setTranslations(data.translations);

      form.setFieldsValue(data);
    }
  }, [data]);

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary mb-5">
              {data != undefined ? t('edit') : t('add')} +{t('lesson')}
            </h4>
            <Form form={form} initialValues={data} onFinish={handleSubmit} onFinishFailed={(errorInfo) => {}}>
              <label className="col-form-label">{t('lesson-name')}</label>
              <Form.Item
                className="form-group pb-0"
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-lesson-name'),
                  },
                ]}
              >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-lesson-name')} />
              </Form.Item>

              <label className="col-form-label">{t('lesson-number')}</label>
              <Form.Item
                className="form-group pb-0"
                name="number"
                rules={[
                  {
                    required: true,
                    message: t('number-is-required'),
                  },
                ]}
              >
                <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="0" />
              </Form.Item>

              <label className="col-form-label">{t('lesson-length-seconds')}</label>
              <Form.Item
                className="form-group pb-0"
                name="duration"
                rules={[
                  {
                    required: true,
                    message: t('duration-is-required'),
                  },
                ]}
              >
                <Input min={0} type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="0" />
              </Form.Item>

              <Translations data={translations} service_name={params.service_name} clientName={params.clientName} setTranslations={setTranslations} />

              <LessonStreams />

              <ImageUploader
                onSubmit={(values: any) => {
                  setLesson(values);
                }}
                initialData={{
                  client: clientName,
                  deployment: deployment,
                  service: undefined,
                  uploadName: t('lesson-image'),
                  uploadType: 'widescreen',
                  uploadedImage: data.translations != undefined && lesson ? lesson : null,
                  path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
                }}
              />

              <div className="col-md-12 p-0 mt-3">
                {data.translations == undefined && (
                  <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('add-lesson')}
                  </button>
                )}
                {data.translations != undefined && (
                  <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-lesson')}
                  </button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LessonModel;

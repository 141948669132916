import axiosInstance from "../../../utils/axios";
import { API_ENDPOINTS } from "../../_APIConstants";
import { TaxRate } from "../../../interfaces/forms/areas/billing/taxRate"
import { AxiosResponse } from "axios";

const { BILLING : BILLING_ENDPONTS } = API_ENDPOINTS
const { TAXES : ENDPOINTS } = BILLING_ENDPONTS

type Params = { instance: string, cms: string, crm: string }

type PaginationParams = {
  page : number,
  limit : number
}

type ResponseFormat<T> = {
  docs : T
}

type PaginatedResults<T> = {
  hasNextPage : boolean
  hasPrevPage : boolean
  limit : number
  nextPage : number | null
  page : number
  pagingCounter : number
  prevPage : null | number
  totalDocs : number,
  totalPages : number
} & ResponseFormat<T>

export const list = ({ params } : { params : Params & PaginationParams}) : Promise<AxiosResponse<PaginatedResults<TaxRate[]>>> => {
  return axiosInstance.get<PaginatedResults<TaxRate[]>>(ENDPOINTS.LIST, { params })
}

export type AddParams = { params : Params } & { data : TaxRate }

export const add = ({ data, params } : AddParams ) : Promise<AxiosResponse<TaxRate>> => {
  return axiosInstance.post<TaxRate>(ENDPOINTS.ADD, data, { params })
}

export type PatchParams = { params : Params } & { data : Partial<TaxRate>, id : string }

export const patch = ({ data, params, id } : PatchParams ) : Promise<AxiosResponse<TaxRate>> => {
  return axiosInstance.patch<TaxRate>(`${ENDPOINTS.PATCH}/${id}`, data, { params })
}

export type PutParams = { params : Params } & { data : TaxRate, id : string }

export const put = ({ data, params, id } : PutParams ) : Promise<AxiosResponse<TaxRate>> => {
  return axiosInstance.put<TaxRate>(`${ENDPOINTS.PUT}/${id}`, data, { params })
}

type DeleteParams = { params : Params, id : string }

export const del = ({ params, id } : DeleteParams) : Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params })
}

type GetParams = { params : Params, id : string }

export const get = ({ params, id } : GetParams) : Promise<AxiosResponse<TaxRate>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params })
}

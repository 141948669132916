import { Skeleton, Image } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd } from '@fortawesome/pro-regular-svg-icons';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const KaiAd = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid remove-hor-padding" style={{ height: 120 }}>
      <div style={{ gridAutoColumns: '100%', display: 'grid', marginTop: props.application.theme?.rails_top_margin ?? 5, marginBottom: props.application.theme?.rails_bottom_margin ?? 5, marginLeft: 5,marginRight:5, }}>
        <div style={{ backgroundColor: '#999', borderRadius: 5, marginTop: 10 }}>
          <Skeleton.Node active={false} style={{ width: '100%', height: 100, backgroundColor: '#999' }}>
            <div className="col-sm d-flex flex-wrap align-items-center remove-hor-padding">
              <FontAwesomeIcon icon={faAd} size={'3x'} color="#bfbfbf" />
            </div>
          </Skeleton.Node>
        </div>
      </div>
    </div>
  );
};

export default KaiAd;

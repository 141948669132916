import { useParams, useRouteMatch } from "react-router-dom";
import { IMSNameParams } from "../../pages/dashboard/index";
interface MwareRouteMatch {
  path:string;
  url:string;
}
const useMwareRouteMatch = () : MwareRouteMatch => {
  let {path: routePath} = useRouteMatch();
  const params = useParams<IMSNameParams>();

  let { clientName, service, service_name } = params
  let returnPath ='/client';
  let returnUrl = '/client';
  if(routePath.includes('clientName')){
    returnPath += '/:clientName';
    returnUrl += `/${clientName}`
  }
  if(routePath.includes('service')){
    returnPath += '/:service';
    returnUrl += `/${service}`
  }
  if(routePath.includes('service_name')){
    returnPath += '/:service_name';
    returnUrl += `/${service_name}`
  }



  return {url: returnUrl, path: returnPath};
};

export default useMwareRouteMatch;

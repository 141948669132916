import React, { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { add as addProduct } from '../../../../services/areas/billing/productService';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { FormFields, Form } from './shared/form';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { Product } from '../../../../interfaces/forms/areas/billing/products/product';
import { notification } from 'antd';
import { BillingContext } from '../../../../contexts/billingContext';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { BackButton } from '../../../../common/components/BackButton';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';

const AddProduct: React.FC = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<{ clientName: string; deployment: string }>();
  const { service, deployment } = React.useContext(BillingContext);
  const [form] = useForm<Product>();
  const history = useHistory();
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const redirectUrl = url.split('/').slice(0, -1).join('/');

  const { isLoading, mutate } = useMutation(
    (values: Product) => {
      return addProduct({ params: { cms: deployment?.name || '', instance: clientName, crm: service?.name || '', path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).PRODUCTS.PRODUCTS }, data: values });
    },
    {
      onSuccess: () => {
        notification.success({ message: t('subscription-added')});
        history.push(redirectUrl);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong') });
      },
      
    }
  );

  // https://ant.design/components/form#formitem
  // "You cannot set value for each form control via value or defaultValue prop, you should set default value with initialValues of Form."
  const initialValues = {
    settings: {
      devices: {
        mediaplayers: {
          androidtv: true,
          firetv: true,
          roku: true,
          appletv: true,
        },
        phones: {
          android: true,
          ios: true,
          kaios: true,
          windows: true,
        },
        smart_tvs: {
          tizen: true,
          vidaa: true,
          webos: true,
        },
        stb: {
          android: true,
          linux: true,
        },
        tablets: {
          android: true,
          ios: true,
          windows: true,
        },
        web: {
          osx: true,
          windows: true,
        },
      },
    },
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-subscription-list')} url={redirectUrl} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('add-a-subscription')} subtitle={t('add-a-new-subscription')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form, initialValues }}
            disabled={isLoading}
            onFinish={(values: Product) => {
              
              mutate(values);
            }}
            onFinishFailed={(errorInfo) => {
              setModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
          >
            <FormFields />
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddProduct;

import { Route, Switch, useRouteMatch } from 'react-router';
import List from './list'

const Index = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path}><List /></Route>
    </Switch>
  )
}

export default Index

import { Button, Input, Space } from 'antd';
import { Spin} from "../../spinner";
import { ForwardedRef, forwardRef, useState } from 'react';
import { FilterProps } from 'react-table';
import { IReactBCTableColumn } from '../Interfaces/IReactBCTableColumn.interface';
import { useTranslation } from 'react-i18next';

const ReactBCNumberFilter = forwardRef(<T extends {}>({ column }: FilterProps<T>, filterRef: ForwardedRef<HTMLDivElement>) => {
  const { setFilter, Header, customData } = column;
  const {t} = useTranslation();
  let passedProps = customData.parentColumn as IReactBCTableColumn<T>;
  if (!passedProps.filterOption) passedProps.filterOption = 'eq';
  const [selectedKeys, setSelectedKeys] = useState<number>();
  return (
    <div ref={filterRef} style={{ backgroundColor: '#ffffff', border: '1px solid #1ac884', padding: 8 }}>
      <div>
        <Input defaultValue={0} type={'number'} value={selectedKeys} style={{ minWidth: '200px' }} placeholder={`Search ${Header?.toString()}`} onChange={(event) => setSelectedKeys(parseInt(event.target.value))} />
      </div>
      <Space style={{ marginTop: '10px' }}>
        <Button
          type="primary"
          disabled={!selectedKeys}
          onClick={() => setFilter({ filterValue: selectedKeys, filterOption: passedProps.filterOption, filterType: 'number' })}
          size="small"
        >
          {t('search')}
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys(undefined);
            setFilter(undefined);
          }}
          size="small"
        >
          {t('reset')}
        </Button>
      </Space>
    </div>
  );
});

export default ReactBCNumberFilter;

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const Streams = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('streams')}</h4>
      <div className="row">
        <div className="col-md-12 card-bg-light p-5">
          <div className="row">
            <label className="col-md-3 col-form-label">Android/FireTV/AndroidTV</label>
            <Form.Item
              className="col-md-10"
              name={['streams', 'cams', 'urls', 'standard_url']}
              extra={t('we-recommend-to-use-dash-hls-or-udp-with-drm-or-token-protection')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
            <label className="col-md-3 col-form-label">iOS/tvOS/KaiOS</label>
            <Form.Item
              className="col-md-10"
              name={['streams', 'cams', 'urls', 'ios_tvos']}
              extra={t('we-recommend-to-use-hls-also-with-with-drm-or-token-protection')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
            <label className="col-md-3 col-form-label">Tizen/WebOS/Vidaa/WebTV</label>
            <Form.Item
              className="col-md-10"
              name={['streams', 'cams', 'urls', 'tizen_webos']}
              extra={t('we-recommend-to-use-dash-with-drm-or-token-protection')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
        </div>
        <div className="col">
        <h4 className="text-primary mt-5 mb-3">Deeplink</h4>
        <p>Deeplinks are hyperlinks that point directly to specific content within an app.</p>
        <div className="col-md-12 card-bg-light p-5">
          <div className="row">
            <label className="col-md-3 col-form-label">Deeplink</label>
            <Form.Item className="col-md-10" name={['streams', 'cams', 'deeplink']} >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="dplnk://stream.com" />
            </Form.Item>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};
export default Streams;

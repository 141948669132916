import { Epg_Settings } from "../../../../../interfaces/forms/areas/content/epg/epg_settings";
import { IGetForm } from "../../../../../interfaces/forms/common/ICMSExtraQuerytParams";
import { Epg_Settings as Response } from "../../../../../interfaces/responses/areas/content/epg/epg_settings";
import { IBaseResponse } from "../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../_APIConstants";

class Epg_Settings_Service {
  Add_Epg_Settings = async (data: Epg_Settings): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.EPG.SETTINGS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Epg_Settings = async (
    data: IGetForm
  ): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.EPG.SETTINGS.DETAILS}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Epg_Settings = async (data: Epg_Settings): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.EPG.SETTINGS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new Epg_Settings_Service();

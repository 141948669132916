import { ISMSGateway, ISMSSendResponse } from '../../../definitions';
import smpp from 'smpp';
import { TextEncoder } from 'util';

const smppUrl = 'smpp://103.177.125.106:9988';

////////////////////////////////////////////////////////////////
// Read the documentation for smpp-node and the SMPP spec before touching this code!
// https://smpp.org/SMPP_v5.pdf
// https://github.com/farhadi/node-smpp
////////////////////////////////////////////////////////////////

export const revesms: ISMSGateway = {
  name: 'Reve SMS',
  sms_gateway_id: 'revesms',
  fields: {
    smppId: { label: 'SMPP ID', type: 'string' },
    smppPassword: { label: 'SMPP Password', type: 'string' },
    senderId: { label: 'Sender ID', type: 'string' },
  },
  url: 'https://www.revesms.com/',
  helpText: 'Set up messaging with Reve SMS.',
  infotext: 'Set up messaging with Reve SMS.',
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/sms-gateways/revesms.png',
  send: async ({ smppId, smppPassword, senderId }, { phone, text }) => {
    // FIXME This implementation is unfinished due to issues

    if (!smppId || !smppPassword || !senderId) {
      throw new Error('SMPP ID, Password or Sender ID is missing.');
    }

    const sanitizedSender = senderId.startsWith('+')
      ? senderId
      : `+${senderId}`;

    phone = senderId;

    const sanitizedPhone = phone.startsWith('+') ? phone : `+${phone}`;

    let session: any;

    const smppPromise = new Promise<void>((resolve, reject) => {
      session = smpp.connect(
        {
          url: smppUrl,
          auto_enquire_link_period: 10000,
          debug: true,
        },
        function () {
          session.bind_transceiver(
            {
              system_id: smppId,
              password: smppPassword,
              system_type: '', // Don't provide system detail - ???
              interface_version: 0, // 0x00-0x33 maps to ESME 3.3
              addr_ton: 0, // ???
              addr_npi: 0, // ???
              address_range: '', // ???
            },
            function (pdu) {
              if (pdu.command_status === 0) {
                // NULL values are 0, '', empty buffers, etc.
                // Check the spec if needed

                let isAscii = true;

                for (let i = 0; i < text.length; i++) {
                  if (text.charCodeAt(i) > 127) {
                    isAscii = false;
                  }
                }

                const bufferData = Buffer.from(
                  text,
                  isAscii ? 'ascii' : 'ucs2'
                );

                session.submit_sm(
                  {
                    service_type: '', // Default MC Settings
                    source_addr_ton: 1, // International
                    source_addr_npi: 1, // ISDN (E163/E164)
                    source_addr: sanitizedSender, // This field seems to be required by REVE SMS
                    dest_addr_ton: 1, // International
                    dest_addr_npi: 1, // ISDN (E163/E164)
                    destination_addr: sanitizedPhone,
                    esm_class: 0, // Default MC Mode (Store and Forward)
                    protocol_id: 0, // Implicit mode - FIXME This might not work correctly?
                    priority_flag: 0, // Non-priority
                    schedule_delivery_time: '', // Deliver immediately
                    validity_period: '', // Default validity period
                    registered_delivery: 0, // No MC Delivery Receipt requested
                    replace_if_present_flag: 0, // Don't replace
                    data_coding: isAscii ? 1 : 8, // 1 - ASCII set, 8 - UCS2 / ISO 10646 set
                    sm_default_msg_id: 0, // Unused
                    sm_length: bufferData.length,
                    short_message: bufferData, // Text to send, will be converted
                  },
                  function (pdu) {
                    if (pdu.command_status === 0) {
                      console.log(pdu.message_id);
                      resolve();
                    } else {
                      console.log('Reject');
                      reject(new Error('Failed to submit short message'));
                    }
                  }
                );
              } else {
                console.log('Reject');
                reject(new Error('Failed to bind transceiver'));
              }
            }
          );
        }
      );

      session.on('error', function (e) {
        // Callback to catch emitted errors to prevent exit due unhandled errors
        console.log('Error');
        reject(new Error('Error: ' + e.code));
      });
    });

    let result: ISMSSendResponse;

    // Await response from SMPP
    try {
      await smppPromise;
      result = {
        isSend: true,
      };
    } catch (e) {
     

      result = {
        isSend: false,
      };
    }

    // Close the SMPP session
    // Otherwise we're going to leak the session
    try {
      await new Promise<void>((resolve) => {
        session.close();
        resolve();
      });
    } catch (e) {
      console.log('Close crash');
     
    }

    return result;
  },
};

export default revesms;

import type { IEmailGateway } from './definitions';

import sendgrid from './gateways/sendgrid/src';
import { mailgun } from './gateways/mailgun/src';

export const emailGateways: IEmailGateway[] = [
    sendgrid, 
    mailgun,
    // smtp, // NOT IMPLEMENTED, DO NOT USE
];

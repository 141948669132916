import { ImagesProps } from './image.interface';

export const splitEvery = (array: ImagesProps[], length: number) =>
  array.reduce((result: ImagesProps[][], item, index) => {
    if (index % length === 0) {
      result.push([]);
    }
    result[Math.floor(index / length)].push(item);
    return result;
  }, []);

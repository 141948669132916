import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { NestedContent } from '../../../../../common/components/Content/Bundles/NestedContent';
import { IMSNameParams } from '../../../../dashboard';
import { Form, Select } from 'antd';
import { useEffectOnce } from 'react-use';
import cmsService from '../../../../../services/areas/content/courses/bundles';
import { useContext, useState } from 'react';
import { ContentContext } from '../../../../../contexts/contentContext';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';

const Bundles = () => {
  let params = useParams<IMSNameParams>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [bundle, setBundle] = useState([] as any);
  const [loading, setLoading] = useState(true);
  let params_ = useParams<IMSNameParams>();
  const history = useHistory();

  const getBundle = async () => {
    let response = await cmsService.Details_Course_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      var data = response.data as any;
      
      setBundle(data.categories);
      setLoading(false);
    }
  };

  const selectCategory = (index) => {
    history.push(redirectUrl + '/' + bundle[index].translations[0].description + '/' + index);
  };

  useEffectOnce(() => {
    getBundle();
  });
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-4">
          <Link to={`${redirectUrl}`}>{t('back-to-level-bundles')}</Link>
        </div>
        <div className="col-md-6">
          <h2 className="page-title">
            {t('your-quote')}{params.package} / {params.category}{t('quote-content')}{' '}
          </h2>
          <p className="h6 font-weight-normal line-height-26">
            {t('drag-your-content-from-the-left-side-to-your-category-you-can-also-drag-and-drop-to-reorder')} <br /> {t('your-category-content-the-order-its-presented-here-is-the-same-as-in-your-apps')}
          </p>
        </div>
        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <div className="col-md-6">
            {!loading && (
              <Form.Item style={{ padding: 0 }} className="col-md-12" name="type">
                <Select onChange={selectCategory} className="default-select" placeholder={t('jump-to-other-category')}>
                  {bundle.map((category, index) => {
                    return (
                      <Select.Option value={index} key={index}>
                        {category.translations[0].description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <NestedContent type={'courses'} category={params.category ?? ''}></NestedContent>
        </div>
      </div>
    </div>
  );
};

export default Bundles;

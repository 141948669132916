import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import TvGuide from './epg_tvguide'
import Urls from './epg_urls'
import Caching from './epg_caching'
import Settings from './epg_settings'
import Import from './epg_import'
import Publish from './epg_publish'
import Linking from './epg_linking'


const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/epg_tvguide`}><TvGuide /></Route>
      <Route path={`${path}/epg_urls`}><Urls /></Route>
      <Route path={`${path}/epg_caching`}><Caching /></Route>
      <Route path={`${path}/epg_linking`}><Linking /></Route>
      <Route path={`${path}/epg_settings`}><Settings /></Route>
      <Route path={`${path}/epg_import`}><Import /></Route>
      <Route path={`${path}/epg_publish`}><Publish /></Route>
    </Switch>
  )
}

export default Index

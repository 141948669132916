import axiosInstance from '../../../../utils/axios';
import { useQuery } from '@tanstack/react-query';

export const useEmailGatewaysSchemas = () =>
  useQuery(
    ['emailSchemas'],
    async () => {
      const response = await axiosInstance.get<any[]>(`/v1/billing/email-gateway-schemas`);
      return response.data;
    },
    {
      retry: true,
    }
  );

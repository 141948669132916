import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../..';
import { PageTitle } from '../../../../../common/components/pageTitle';
import ReactBCTable from '../../../../../common/components/ReactBCTable';
import { PrimaryButton } from '../../../../../common/components/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface JobData {
  deployment: string;
  service: string | null;
  type: string;
  subtype: string;
  schedule: string;
  lastAttemptTimestamp: number;
  state: string;
  status: string;
  timezone: string;
}

export const JobsSchedulersStatus = () => {
  const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const { clientName } = useParams<IMSNameParams>();
  const [reloading, setReloading] = useState(false);
  const {t} = useTranslation();
  const { data, isLoading, refetch } = useQuery(['job-scheduler'], async () => {
    const response = await axiosInstance.get<JobData[]>(`${baseUrl}/api/v1/jobs-schedulers/`, {
      params: {
        instance: clientName,
      },
    });
    return response.data;
  });

  const serviceJobs = !data
    ? []
    : data
        .filter((x) => x.service !== null && x.deployment !== null)
        .reduce<{ deployment: string; service: string; jobs: JobData[] }[]>((groups, job) => {
          const group = groups.find((group) => group.deployment === job.deployment && group.service === job.service);

          if (!group) {
            groups.push({ deployment: job.deployment, service: job.service!, jobs: [job] });
          } else {
            group.jobs.push(job);
          }

          return groups;
        }, []);
  const deploymentJobs = !data
    ? []
    : data
        .filter((x) => x.service === null && x.deployment !== null)
        .reduce<{ deployment: string; jobs: JobData[] }[]>((groups, job) => {
          const group = groups.find((group) => group.deployment === job.deployment);

          if (!group) {
            groups.push({ deployment: job.deployment, jobs: [job] });
          } else {
            group.jobs.push(job);
          }

          return groups;
        }, []);
  const clientJobs = !data ? [] : data.filter((x) => x.service === null && x.deployment === null);

  const reloadSchedulers = async () => {
    if (reloading) {
      return;
    }

    setReloading(true);

    await axiosInstance.post(
      `${baseUrl}/api/v1/jobs-schedulers-init/`,
      {},
      {
        params: {
          instance: clientName,
        },
      }
    );

    refetch();
    setReloading(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('jobs-and-schedulers')} subtitle={t('view-the-state-of-jobs-and-schedulers-here')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <div>
            <PrimaryButton disabled={reloading} onClick={reloadSchedulers}>
              {reloading ? 'Reloading...' : t('reload-schedulers')}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p style={{ marginLeft: '20px', marginTop: '20px', fontWeight: 'bold', fontSize: '1.5em' }}>{t('jobs-and-schedulers-for-each-service')} </p>
          <ReactBCTable
            isLoading={isLoading}
            totalRecords={serviceJobs.length}
            pageSize={50}
            tableId={'scheduler_deployments'}
            columns={[
              { title: t('deployment'), dataKey: 'deployment' },
              { title: t('service'), dataKey: 'service' },
              { title: t('job-count'), dataKey: '', cell: (row) => <span>{row.jobs.length}</span> },
            ]}
            data={serviceJobs}
            onChange={() => {}}
            hasChild={true}
            childComponent={(row, index) => (
              <div className="row">
                <div className="col-md-12">
                  <ReactBCTable
                    isLoading={isLoading}
                    totalRecords={row.jobs.length}
                    pageSize={50}
                    tableId={`scheduler_deployments_services`}
                    columns={[
                      { title: t('type'), dataKey: 'type' },
                      { title: t('subtype'), dataKey: 'subtype' },
                      { title: t('schedule'), dataKey: 'schedule' },
                      { title: t('last-attempt'), dataKey: '', cell: (row) => <span>{new Date(row.lastAttemptTimestamp * 1000).toString()}</span> },
                      { title: t('state'), dataKey: 'state' },
                      { title: t('status'), dataKey: 'status' },
                      { title: t('timezone'), dataKey: 'timezone' },
                    ]}
                    data={row.jobs}
                    onChange={() => {}}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div style={{ marginLeft: '20px', marginTop: '20px', fontWeight: 'bold', fontSize: '1.5em' }}>
            <p> {t('jobs-and-schedulers-for-each-deployment')} </p>
          </div>
          <ReactBCTable
            isLoading={isLoading}
            totalRecords={deploymentJobs.length}
            pageSize={50}
            tableId={'scheduler_deployments'}
            columns={[
              { title: t('deployment'), dataKey: 'deployment' },
              { title: t('job-count'), dataKey: '', cell: (row) => <span>{row.jobs.length}</span> },
            ]}
            data={deploymentJobs}
            onChange={() => {}}
            hasChild={true}
            childComponent={(row, index) => (
              <div className="row">
                <div className="col-md-12">
                  <ReactBCTable
                    isLoading={isLoading}
                    totalRecords={row.jobs.length}
                    pageSize={50}
                    tableId={`scheduler_deployments_services`}
                    columns={[
                      { title: t('type'), dataKey: 'type' },
                      { title: t('subtype'), dataKey: 'subtype' },
                      { title: t('schedule'), dataKey: 'schedule' },
                      { title: t('last-attempt'), dataKey: '', cell: (row) => <span>{new Date(row.lastAttemptTimestamp * 1000).toString()}</span> },
                      { title: t('state'), dataKey: 'state' },
                      { title: t('status'), dataKey: 'status' },
                      { title: t('timezone'), dataKey: 'timezone' },
                    ]}
                    data={row.jobs}
                    onChange={() => {}}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div style={{ marginLeft: '20px', marginTop: '20px', fontWeight: 'bold', fontSize: '1.5em' }}>
            <p> {t('jobs-and-schedulers-at-client-level')} </p>
          </div>
          <ReactBCTable
            isLoading={isLoading}
            totalRecords={clientJobs.length}
            pageSize={50}
            tableId={'scheduler_client'}
            columns={[
              { title: t('type'), dataKey: 'type' },
              { title: t('subtype'), dataKey: 'subtype' },
              { title: t('schedule'), dataKey: 'schedule' },
              { title: t('last-attempt'), dataKey: '', cell: (row) => <span>{new Date(row.lastAttemptTimestamp * 1000).toString()}</span> },
              { title: t('state'), dataKey: 'state' },
              { title: t('status'), dataKey: 'status' },
              { title: t('timezone'), dataKey: 'timezone' },
            ]}
            data={clientJobs}
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

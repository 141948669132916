import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Table from '../../../../../common/components/Marketing/Table';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';

const Albums = () => {
  const { clientName, deployment, service } = useParams<IMSNameParams>();
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title pt-4">{t('affiliates')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('affiliates-or-influencers-are-a-great-way-to-spread-the-word-about-your-services-and-get-new-customers-to-signup')}</p>
        </div>
        <div className="col-md-6 text-right"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('add-affiliate')}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table service={service} clientName={clientName} deployment={deployment} type={'affiliates'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Albums;

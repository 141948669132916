import React, { useContext } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { add as addService } from '../../../../services/areas/billing/serviceService';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Form, FormFields } from './shared/form';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { Service } from '../../../../interfaces/forms/areas/billing/services/service';
import { notification } from 'antd';
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';

const AddService: React.FC = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const [form] = useForm<Service>();
  const history = useHistory();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { deployment, service } = React.useContext(BillingContext);
  const {t} = useTranslation();

  const { mutate, isLoading } = useMutation(
    (values: Service) => {
      return addService({ params: { instance: clientName, crm: service?.name || '', cms: deployment?.name || '' }, data: values });
    },
    {
      
      onSuccess: (data) => {
        notification.success({ message: t('service-added') });
        history.push(redirectUrl);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-saving-the-service') });
      },
    }
  );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-service-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('add-service')} subtitle={t('add-a-new-service')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            disabled={isLoading}
            onFinish={(values: Service) => {
              mutate(values);
            }}
          >
            <FormFields />
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddService;

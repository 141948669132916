import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router';
import List from './list'
import MyGatewaysList from './myGatewaysList';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/my-gateways`}><MyGatewaysList /></Route>
      <Route path={`${path}`}><List /></Route>
    </Switch>
  )
}

export default Index

import { Placeholder } from '../types/placeholder';

export const orderPlaceholders: Placeholder[] = [
  {
    placeholder: 'product',
    description: 'Current customer product',
  },

  {
    placeholder: 'expiration',
    description: 'Expiration date of the current product',
  },
  {
    placeholder: 'portal_link',
    description: 'Link to the portal associated with the active product',
  },
  {
    placeholder: 'website_portal',
    description: 'Link to the website where the customer portal iframe is placed',
  },
  {
    placeholder: 'website_cart',
    description: 'Link to the website where the shopping cart iframe is placed',
  },
];
import { IBaseResponse } from "../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../utils/axios";
import { API_ENDPOINTS } from "../../../_APIConstants";

class Dashboards_Service {
  Generate = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.REPORTING.DASHBOARDS.GENERATE, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Dashboards_Service();

import React from 'react';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { useEmailGatewaysSchemas } from './emailGatewaySchemas';
import * as EmailGatewayService from '../../../../services/areas/billing/emailGatewayService';
import { Card, Form, Modal, notification, Typography } from 'antd';
import { useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { BillingContext } from '../../../../contexts/billingContext';
import { IMSNameParams } from '../../../dashboard';
import { PrimaryButton, PrimaryButtonSM } from '../../../../common/components/form';
import GatewayForm from './shared/form';
import { IEmailGateway } from '@mwaretv/communication';
import { useTranslation } from 'react-i18next';

const List = () => {
  const { data: emailGateways } = useEmailGatewaysSchemas();
  const { t } = useTranslation();
  return (
    <>
      {emailGateways && (
        <ContainerWrapper>
          <div className="row">
            <div className="col-md-6 mb-2">
              <PageTitle title={t('email-gateways')} subtitle={t('email-gateways-are-used-to-send-emails-to-your-customer-your-configured-gateways-are-saved-under-my-email-gateways')} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <GatewayGrid gateways={emailGateways} />
            </div>
          </div>
        </ContainerWrapper>
      )}
    </>
  );
};

type GatewayCardProps = {
  gateway: IEmailGateway;
};

const getProviderLogo = (logo: string) => {
  if (logo.includes('localhost')) {
    return new URL(new URL(logo).pathname, process.env.REACT_APP_BACKEND_API_BASE_URL).href;
  } else {
    return logo;
  }
};

const GatewayCard = ({ gateway }: GatewayCardProps) => {
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <img height={100} src={getProviderLogo(gateway.logo)} alt={gateway.name} />
        <div className="card-bg-light pl-5 pt-3 mt-2">
          <p className="mt-1">{gateway.helpText}</p>
        </div>
        <div className="text-right">
          <PrimaryButton onClick={() => setSettingsOpen(true)}>{t('setup-gateway')}</PrimaryButton>
        </div>
      </Card>
      <GatewaySettingsModal {...{ gateway }} open={settingsOpen} onCancel={() => setSettingsOpen(false)} />
    </>
  );
};

type GatewaySettingsModalProps = {
  open: boolean;
  gateway: IEmailGateway;
  onCancel: () => void;
};

const GatewaySettingsModal = ({ open, gateway, onCancel }: GatewaySettingsModalProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(BillingContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { isLoading: mutationIsLoading, mutate } = useMutation(
    async (values: any) => {
      const { data } = await EmailGatewayService.add({ data: values, params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' } });
      return data;
    },
    {
      onSuccess: () => {
        notification.success({ message: t('email-gateway-saved') });
        form.resetFields();
        onCancel();
      },
      onError: () => notification.error({ message: t('email-gateway-could-not-be-saved-please-try-again-later')}),
    }
  );

  return (
    <Modal
      {...{ open, onCancel }}
      width={1000}
      okText={t('save')}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ disabled: mutationIsLoading }}
    >
      <div className="container">
        <div className="row">
          <div className="col col-lg-6">
            <ModalGatewayInfo {...{ gateway }} />
          </div>
          <div className="col col-lg-6">
            <Typography.Title level={5}>{t('gateway-settings')}</Typography.Title>
            <Form
              {...{ form }}
              initialValues={{ email_gateway_id: gateway.email_gateway_id }}
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <GatewayForm {...{ gateway }} />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

type ModalGatewayInfoProps = {
  gateway: IEmailGateway;
};

const ModalGatewayInfo = ({ gateway }: ModalGatewayInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <img height={75} src={new URL(new URL(gateway.logo).pathname, process.env.REACT_APP_BACKEND_API_BASE_URL).href} alt={gateway.name} />
      <Typography.Title level={5} className="mt-2">
        {t('setup-information')}
      </Typography.Title>
      {(gateway?.infotext as string)?.split('\n').map((text) => (
        <p className="mt-3">{text}</p>
      ))}
      {gateway.url && (
        <>
          <Typography.Title level={5}>{t('more-info')}</Typography.Title>
          <a target="_blank" rel="noreferrer" href={gateway?.url || ''}>
            {t('visit-website')}
          </a>
        </>
      )}
    </>
  );
};

type GatewayGridProps = {
  gateways: IEmailGateway[];
};

const GatewayGrid = ({ gateways }: GatewayGridProps) => {
  return (
    <div className="row">
      {gateways.map((gateway, i) => (
        <div className="col-12 col-md-6 mb-4" key={i}>
          <GatewayCard {...{ gateway }} />
        </div>
      ))}
    </div>
  );
};

export default List;

import { Form, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/series/series';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import SubContent from '../../../../../../../common/components/Content/Content/SubContent';
import { Seasons } from '../../../../../../../interfaces/forms/areas/content/series/seasons';
import { useEffectOnce } from 'react-use';

const AddNewMusicAlbum = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [episodes, setEpisodes] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();

  const handleSubmit = async (values: Seasons) => {
    setIsAdding(true);

    values = {
      _id: _id,
      ...data.data, //added
      episodes: values, //added
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };

    let response = await cmsService.Update_Season(values as any);
    if (response.status_code === StatusCodes.OK) {
      //setEpisodes(response.data.episodes);
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };

  useEffectOnce(() => {
    if (data.data.episodes != undefined) {
      setEpisodes(data.data.episodes);
      form.setFieldsValue(data.data);
    }
  });

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <SubContent onUpdate={handleSubmit} setData={setEpisodes} type={'Season Episodes'} data={episodes} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;

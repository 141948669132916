import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Input, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Tags = (params: { setTags: any; data?: any }) => {
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [tags_, setTags_] = useState([] as any);
  const { t } = useTranslation();

  useEffect(() => {
    if (params.data != undefined) {
      setTags_(params.data);
    }
  }, [params.data]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, []);

  const handleClose = (removedTag: string) => {
    const newTags = tags_.filter((tag: any) => tag !== removedTag);
    setTags_(newTags);
    params.setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      var act = [...tags_, { value: inputValue }];
      setTags_(act);
      params.setTags(act);
    }
    setInputVisible(false);
    setInputValue('');
  };

  return (
    <div className="col-md-9 mb-5">
      <div className="row">
        <div className="pl-3">
          {inputVisible && <Input ref={inputRef} type="text" className="form-control bg-white font-size-14 text-default p-3" style={{ width: 150 }} value={inputValue} onChange={handleInputChange} onBlur={handleInputConfirm} onPressEnter={handleInputConfirm} />}
          {!inputVisible && (
            <Tag onClick={showInput} className="site-tag-plus">
              <PlusOutlined width={150} /> {t('new-value')}
            </Tag>
          )}
        </div>
        <div className="col">
          {tags_ != undefined && (
            <>
              {tags_.map((tag: any, index: any) => {
                return (
                  <Tag
                    key={index}
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleClose(tag);
                    }}
                  >
                    {tag.value}
                  </Tag>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Tags;

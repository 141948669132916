import Timeline from '../../../../../../../common/components/Content/Epg/Timeline';

const Epg = (data: any) => {
  return (
    <div className="container-fluid ">
      <div className="card mt-4 pb-4">
        <div className="card-body card-ch-padding">
          <Timeline type={"Radios"} />
        </div>
      </div>
    </div>
  );
};

export default Epg;

import { Button, Form, Input } from 'antd';

import validator from 'validator';
import { ISignInFormValues } from '../../../interfaces/forms/auth/AuthInterfaces';
import { LoginSuccessObj } from '../../../interfaces/responses/auth/ILoginResponse';
import { useTranslation } from 'react-i18next';

type ITOTPFormProps = {
  onSubmit: (values: any) => void;
  LoginSuccessDataRef?: LoginSuccessObj;
  GoBackToLogin: () => void;
  isVerifying: boolean;
};

const TOTPForm = (props: ITOTPFormProps) => {
  const { onSubmit, GoBackToLogin, LoginSuccessDataRef } = props;
  const [otpForm] = Form.useForm();
  const { t } = useTranslation();
  const numbersValidator = async (rule: any, value: any) => {
    if (value && !validator.isNumeric(value)) {
      return Promise.reject('Invalid One-Time Password!');
    }
    return Promise.resolve();
  };
  const onFinish = async (values: ISignInFormValues) => {
    onSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {};
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={otpForm}>
      <h3 className="text-muted my-2">{t('please-enter-the-authentication-code-to-verify-your-account')}</h3>
      <p>{t('a-authentication-code-is-showing-in-the-authenticator-app-in-which-you-have-added-your-account')}</p>
      <div className="row">
        <Form.Item className="form-group col-md-12" name="otp" rules={[{ required: true, message: t('authentication-code-is-required') }, { len: 6, message: t('authentication-code-should-be-6-digits-long') }, { validator: numbersValidator }]}>
          <Input className="" placeholder={t('authentication-code')} />
        </Form.Item>
        <Form.Item className="col-md-12 mt-3 align-self-start">
          <div className="btn-group-vertical">
            <Button loading={props.isVerifying} disabled={props.isVerifying} htmlType="submit" className="btn btn-secondary rounded-pill height-48">
              {t('submit')}
            </Button>
            {/* <Button type="text" className="btn btn-link text-secondary text-decoration-none text-left p-0 pt-2">
              Choose Another Method
            </Button> */}
            <Button type="text" className="btn btn-link text-secondary text-decoration-none text-left p-0 pt-2" onClick={() => GoBackToLogin()}>
              {t('go-back')}
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default TOTPForm;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Bundles from './bundles';
import Add from './series/add/series';
import Details from './series/details';
import SeasonsAdd from './seasons/add';
import Seasons from './seasons/index';
import SeasonDetails from './seasons/details';
import BundleContent from './bundles/content';
import ListContent from './lists/content';
import Lists from './lists';
import Series from './series/index';
import Search from './series/search';
import Providers from './providers';
import Upload from './seasons/upload';

const Index = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route path={`${path}/add`}>
        <Add />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <Details />
      </Route>
      <Route path={`${path}/upload`}>
        <Upload />
      </Route>
      <Route path={`${path}/seasons/add/:_id`}>
        <SeasonsAdd />
      </Route>
      <Route path={`${path}/seasons/add`}>
        <SeasonsAdd />
      </Route>
      <Route path={`${path}/seasons/details/:_id`}>
        <SeasonDetails />
      </Route>

      <Route path={`${path}/seasons`}>
        <Seasons />
      </Route>

      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}>
        <BundleContent />
      </Route>

      <Route path={`${path}/search`}>
        <Search />
      </Route>
      <Route path={`${path}/bundles`}>
        <Bundles />
      </Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}>
        <ListContent />
      </Route>
      <Route path={`${path}/lists`}>
        <Lists />
      </Route>
      <Route path={`${path}/providers`}><Providers /></Route>
      <Route path={`${path}`}>
        <Series />
      </Route>
    </Switch>
  );
};

export default Index;

import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { useForm } from 'antd/lib/form/Form';
import { TextArea } from '../../form';

type ModalProps = {
  visible: boolean;
  onSubmit: (row, reason, type, rejectoraccept) => void;
  onCancel: () => void;
  initialData: {
    type: string;
    content: [];
  };
};

const PlayerModal = (props: ModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const { t } = useTranslation();

  useEffectOnce(() => {
    form.setFieldsValue(initialData.content);
  });

  const onSubmitLocal = (values) => {
    onSubmit(initialData.content, values.provider_reject_reason, initialData.type, 'rejected');
  };

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={760} footer={false} closable={true} onCancel={onCancel}>
      <Form form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
        <div className="p-4 mt-4" style={{ border: '1px solid #ebebeb' }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h4>{t('reject-admission')}</h4>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('reject-reason')}</label>
                <Form.Item className="col-md-9" name="provider_reject_reason">
                  <TextArea rows={5} className="form-control bg-white font-size-14 text-default p-3rem" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="text-right">
            <Button htmlType="submit" className="mt-4 ant-btn btn btn-secondary rounded-pill width-120 height-48">
              {t('reject')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PlayerModal;

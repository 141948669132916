import React, { useContext, useState } from 'react';
import {
  Form as AntdForm,
  Button as AntdButton,
  Input as AntdInput,
  Select as AntdSelect,
  Switch as AntdSwitch,
  Table as AntdTable,
  Tooltip as AntdTooltip,
  Modal as AntdModal,
  Upload as AntdUpload,
  Image as AntdImage,
  DatePicker as AntdDatePicker,
  notification,
  FormItemProps,
  InputProps,
  ButtonProps,
  FormProps,
  SelectProps,
  SwitchProps,
  TableProps,
  TooltipProps,
  ModalProps,
  UploadProps,
  UploadFile,
  ImageProps,
  DatePickerProps,
  FormInstance,
} from 'antd';
import { useParams } from 'react-router';
import { IMSNameParams } from '../../pages/dashboard';
import { DrawerContext } from '../../context_api/DrawerContext';
import { useQuery } from '@tanstack/react-query';
import commonCMSService from '../../services/areas/content/common';
import { TextAreaProps } from 'antd/lib/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faInboxOut, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'antd/es/form/Form';
import { ChromePicker } from 'react-color';
import { NamePath } from 'antd/lib/form/interface';
import commonService from '../../services/common';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { StatusCodes } from 'http-status-codes';
import { BillingContext } from '../../contexts/billingContext';
import { STORAGE_ENDPOINTS } from '../../services/storage_paths';
import Card from './card';
import classNames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Spin } from './spinner'; 
import { ICountry } from '../../interfaces/responses/common/ICountriesResponse';
import { ILanguage } from '../../interfaces/responses/common/ILanguagesResponse';
import { getImageSizes } from './ImageUploader/Helper';
import { useTranslation } from 'react-i18next';
import TimezoneSelect, { type ITimezoneOption, type ITimezone } from "react-timezone-select"
import { CommonContext } from '../../contexts/commonContext';

const { TextArea: AntdTextArea } = AntdInput;

export const Form: React.FC<FormProps & { children?: React.ReactNode }> = (props) => {
  return <AntdForm {...props}>{props.children}</AntdForm>;
};

export const CardForm: React.FC<FormProps & { children?: React.ReactNode; buttonBgWhite?: boolean }> = (props) => {
  return (
    <Form {...props}>
      <Card>{props.children}</Card>
      <div className={classNames('text-right pb-4 pl-4 pr-5', { 'bg-white': true })}>
        <PrimaryButton htmlType="submit" loading={props.disabled}>
          Save
        </PrimaryButton>
      </div>
    </Form>
  );
};

export const Image: React.FC<ImageProps> = (props) => {
  return <AntdImage {...props} />;
};

export const FormHeading: React.FC<{ children?: React.ReactNode; subtitle?: string }> = ({ children, subtitle }) => {
  return (
    <div className="pt-3">
      <h4 className="text-primary mt-4 mb-3">{children}</h4>
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
};

export const FormHeadingWithButton: React.FC<{
  title: string;
  buttonText: string;
  subtitle?: string;
  onClick: () => void;
}> = ({ title, buttonText, onClick, subtitle }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <FormHeading {...{ subtitle }}>{title}</FormHeading>
      </div>
      <div className='mb-5'>
        <PrimaryButton  {...{ onClick }}>
          {buttonText}
        </PrimaryButton>
      </div>
    </>
  );
};

export const FormHeadingWithAddNewForm: React.FC<{
  title: string;
  subtitle?: string;
  buttonText: string;
  children?: React.ReactNode;
  onOk: (form: FormInstance<any>) => void;
}> = ({ title, buttonText, onOk, children, subtitle }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = useForm();
const { t } = useTranslation();

  return (
    <>
      <FormHeadingWithButton
        {...{ title, buttonText, subtitle }}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Modal {...{ open }} onCancel={() => setOpen(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
        <ModalFormWrapper>
          <CardForm
            {...{ form }}
            layout="vertical"
            onFinish={() => {
              form.validateFields().then(() => {
                onOk(form);
                setOpen(false);
                form.resetFields();
              });
            }}
          >
            {children}
          </CardForm>
        </ModalFormWrapper>
      </Modal>
    </>
  );
};

export const FormSection: React.FC<{
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  backgroundWhite?: boolean;
  iconLeft?: IconProp;
}> = ({ title, children, subtitle, backgroundWhite, iconLeft }) => {
  return (
    <section>
      <FormHeading>
        {iconLeft && <FontAwesomeIcon icon={iconLeft} className="mr-3" />}
        <span>{title}</span>
      </FormHeading>
      {subtitle && (
        <div className="col-md-6 pb-2 pt-2" style={{ paddingLeft: 0 }}>
          <p>{subtitle}</p>
        </div>
      )}
      <div
        className={classNames('pl-5', 'pt-5', 'pr-5', 'pb-5', {
          'card-bg-light': !backgroundWhite,
          'card-bg-white': backgroundWhite,
        })}
      >
        {children}
      </div>
    </section>
  );
};

export const Button: React.FC<ButtonProps> = (props) => {
  return <AntdButton {...props}>{props.children}</AntdButton>;
};

export const PrimaryButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
      {props.children}
    </Button>
  );
};

export const PrimaryButtonSM: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} className="mt-4 ant-btn btn-secondary rounded-pill">
      {props.children}
    </Button>
  );
};

export const Item: React.FC<FormItemProps> = (props) => {
  return <AntdForm.Item labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} labelAlign="left" style={{ marginBottom: 10, paddingBottom: 0 }} {...props} />;
};

export const TimezoneItem: React.FC<FormItemProps> = (props) => {
  return <AntdForm.Item labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} {...props} labelAlign="left" style={{ marginBottom: 10, paddingBottom: 0 }} />;
};

export const LabellessItem: React.FC<FormItemProps> = (props) => {
  return <AntdForm.Item {...props} />;
};

export const Input: React.FC<InputProps> = (props) => {
  return <AntdInput {...props} className="form-control bg-white font-size-14 text-default" />;
};

export const InputNumber: React.FC<InputProps> = (props) => {
  return <AntdInput type='number' {...props} className="form-control bg-white font-size-14 text-default" />;
};

export const Select: React.FC<SelectProps> = (props) => {
  return <AntdSelect {...props} />;
};

export const Switch: React.FC<SwitchProps> = (props) => {
  return <AntdSwitch {...props} />;
};

export const Table: React.FC<TableProps<any>> = (props) => {
  return <AntdTable {...props} />;
};

export const CurrencySelector = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { state: drawerState } = useContext(DrawerContext);

  const {
    data: currencies,
    isLoading,
    isError,
  } = useQuery(
    ['currencies'],
    async () => {
      const result = await commonCMSService.getCMSCurrencies({
        query_params: { cms: drawerState.parent_service_name, instance: clientName },
      });

      return result.data.currencies;
    },
    { retry: 3, staleTime: Infinity }
  );

  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => {
        return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
      }}
      options={isLoading || isError ? [] : currencies.map((currency) => ({ value: currency.iso3, label: currency.iso3 }))}
    />
  );
};

export const Spinner = () => {
  return <Spin />;
};

export const TextArea: React.FC<TextAreaProps> = (props) => {
  return <AntdTextArea {...props} className="form-control bg-white font-size-14 text-default" />;
};

export const Tooltip: React.FC<TooltipProps> = (props) => {
  return <AntdTooltip {...props} />;
};

export const Modal: React.FC<ModalProps> = (props) => {
  return <AntdModal {...props} />;
};

export const EditButtonWithFormModal: React.FC<{
  onOk: (form: FormInstance) => void;
  children?: React.ReactNode;
  heading: string;
}> = ({ children, onOk, heading }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = useForm();
const { t } = useTranslation();
  return (
    <>
      <span className="justify-content-end">
        <Tooltip placement="top" title={`Edit`}>
          <Button
            className="mx-2 cursor-pointer"
            onClick={() => {
              setOpen(true);
            }}
            type="text"
          >
            <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
          </Button>
        </Tooltip>
      </span>
      <Modal {...{ open }} onCancel={() => setOpen(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
        <div className="mt-4" style={{ border: '1px solid #ebebeb' }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <CardForm
                {...{ form }}
                layout="vertical"
                onFinish={(values) => {
                  onOk(form);
                  setOpen(false);
                }}
              >
                <FormHeading>{heading}</FormHeading>
                {children}
              </CardForm>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const ColorPickerModal: React.FC<{
  currentColor: string;
  onOk: (color: string) => void;
}> = ({ currentColor = '#fff', onOk }) => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [color, setColor] = useState<string>(currentColor);

  React.useEffect(() => {
    setColor(currentColor);
  }, [currentColor]);

  return (
    <>
      <Button
        type="text"
        onClick={() => setIsOpen(true)}
        className="form-control height-48"
        style={{
          display: 'block',
          backgroundColor: currentColor,
          borderRadius: '5px',
          border: '#e9edf4 1px solid',
          width: '48px',
          visibility: 'visible',
        }}
      />
      <Modal
        width={273}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => {
          onOk(color);
          setIsOpen(false);
        }}
      >
        <ChromePicker
          className="picker-box mt-4"
          color={color}
          onChange={(c) => {
            setColor(c.hex);
          }}
        />
      </Modal>
    </>
  );
};

export const CountrySelect: React.FC<{
  country?: string;
  name: NamePath;
  filter?: string[];
}> = ({ country, name, filter }) => {
  const { data, isLoading, isError } = useQuery(
    ['all_countries'],
    async () => {
      const result = await commonService.FetchCountries();

      return result.data;
    },
    { retry: 3, staleTime: Infinity }
  );
  const {t} = useTranslation();

  return (
    <Item label="Country" name={name} initialValue={country} rules={[{ required: true, message: t('country-is-required')}]}>
      <Select
        showSearch
        loading={isLoading}
        optionFilterProp="children"
        filterOption={(input, option) => {
          return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
        }}
        options={isLoading || isError ? [] : data.countries.filter((x) => (filter ? !filter.includes(x.name) : true)).map((country) => ({ value: country.name, label: country.name }))}
      />
    </Item>
  );
};

export const MultipleCountrySelect: React.FC<{ name: NamePath; extra: string }> = ({ name, extra }) => {
  const { data, isLoading, isError } = useQuery(
    ['all_countries'],
    async () => {
      const result = await commonService.FetchCountries();

      return result.data;
    },
    { retry: 3, staleTime: Infinity }
  );
  const {t} = useTranslation();

  return (
    <Item label={t('countries')} {...{ name, extra }}>
      <Select
        showSearch
        loading={isLoading}
        mode="multiple"
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) => {
          return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
        }}
        options={isLoading || isError ? [] : data.countries.map((country) => ({ value: country._id, label: country.name }))}
      />
    </Item>
  );
};

type TimezoneProps = {
  name: string[];
  extra: string;
};

export const Timezone: React.FC<TimezoneProps> = ({ name, extra }) => {
  const [open, setOpen] = React.useState(false);
  const [timeZoneName, setTimeZoneName] = React.useState('');
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )

  const form = AntdForm.useFormInstance();

  const handleChange = React.useCallback(
    (newTimeZone: any) => {
      setSelectedTimezone(newTimeZone);
      setTimeZoneName(newTimeZone.altName);
      if (newTimeZone as ITimezoneOption) {
        form.setFieldValue(name, (newTimeZone as ITimezoneOption).value);
      } else {
        form.setFieldValue(name, newTimeZone);
      }
      setOpen(false);
    },
    [form, name]
  );
  const {t} = useTranslation();

  return (
    <TimezoneItem initialValue={selectedTimezone} label={t('timezone')} {...{ name, extra }} rules={[{ required: true, message: t('timezone-is-required') }]}>

      <TimezoneSelect value={selectedTimezone} onChange={handleChange}/>
    </TimezoneItem>
  );
};

type StorageProps = {
  name: string[];
  extra: string;
};

export const Storage: React.FC<StorageProps> = ({ name, extra }) => {
  const {t} = useTranslation();

  return (
    <Item label={t('storage')} {...{ name, extra }} rules={[{ required: true, message: t('storage-is-required') }]}>

    
    </Item>
  );
};


export const MultipleCurrencySelector: React.FC<{ name: NamePath; extra: string }> = ({ name, extra }) => {
  const { currencies } = useContext(CommonContext);
  const {t} = useTranslation();
  return (
    <Item label={t('currencies')} {...{ name, extra }}>
      <Select
        showSearch
        loading={false}
        mode="multiple"
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) => {
          return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
        }}
        options={!currencies ? [] : currencies.map((currency) => ({ value: currency._id, label: currency.isoCurrency }))}
      />
    </Item>
  );
};

export const MultipleLanguagesSelector: React.FC<{ name: NamePath; extra: string; languages?: ILanguage[] }> = ({ name, extra, languages }) => {
  const {t} = useTranslation();
  return (
    <Item label={t('languages')} {...{ name, extra }} rules={[{ required: true, message: t('language-is-required') }]}>
      <Select
        showSearch
        loading={!languages}
        mode="multiple"
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) => {
          return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
        }}
        options={(languages ?? []).map((language) => ({ value: language._id, label: language.name }))}
      />
    </Item>
  );
};

export const CountryCurrencySelect: React.FC<{ name: NamePath; extra: string; countries?: ICountry[]; label?: string; useIso3?: boolean }> = ({ name, extra, countries, label = 'Countries', useIso3 }) => {
  const {t} = useTranslation();
  return (
    <Item label={label} {...{ name, extra }} rules={[{ required: true, message: t('country-is-required') }]}>
      <Select
        showSearch
        loading={!countries}
        mode="multiple"
        allowClear
        optionLabelProp="label"
        optionFilterProp="children"
        filterOption={(input, option) => {
          return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
        }}
      >
        {(countries ?? []).map((country: ICountry) => (
          <AntdSelect.Option value={useIso3 ? country.iso3 : country._id} label={`${country.emoji} ${country.name}`}>
            {`${country.emoji} ${country.name}, ${country.currency}`}
          </AntdSelect.Option>
        ))}
      </Select>
    </Item>
  );
};

export const Upload: React.FC<UploadProps> = (props) => {
  return <AntdUpload {...{ ...props }}>{props.children}</AntdUpload>;
};

export const ImageUploader: React.FC<{
  itemName: NamePath;
  acceptedFileTypes?: string;
}> = ({ itemName, acceptedFileTypes }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(BillingContext);
  const {t} = useTranslation();
  const fileTypes = acceptedFileTypes || '.png, .jpg, .jpeg';

  const image = AntdForm.useWatch(itemName);

  const getItemValue = ({ file }: { file: UploadFile }) => {
    return file.status === 'done' ? file.response.url : image;
  };

  return (
    <div>
      <div className="col-md-12 card-bg-light">
        <div className="row">
          <div className="col-md-6 row ml-2 align-items-center">
            {image ? (
              <Image src={image} style={{ height: 200, width: 200 }} />
            ) : (
              <div style={{ width: 200, border: '3px dashed #999', height: 200 }}>
                <div style={{ fontSize: 11, textAlign: 'center', marginTop: 5 }}>
                  <span>{fileTypes}</span>
                </div>
              </div>
            )}
          </div>
          <LabellessItem name={itemName} className="col-md-6 mb-0 mt-4" rules={[]} getValueFromEvent={getItemValue}>
            <AntdUpload.Dragger
              multiple={false}
              maxCount={1}
              accept={fileTypes}
              customRequest={async (options: UploadRequestOption<{ name: string; url: string }>) => {
                const response = await commonService.UploadImage({
                  image: options.file as File,
                  query_params: {
                    name: options.filename ?? '',
                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).SERVICES.BILLING.IMAGES,
                    client: clientName,
                    deployment: deployment?.name,
                    service: service?.name,
                  },
                });

                if (response && response.status_code === StatusCodes.OK && options.onSuccess) {
                  notification.success({ message: t('file-uploaded-as-response-data-name', { responseDataName: response.data.name }) });
                  return options.onSuccess({ name: response.data.name, url: response.data.url || '' });
                }

                if (response.errors !== undefined && options.onError) {
                  const message = response.errors[0] as any;
                  notification.error({ message: t('an-error-occured-while-uploading-your-file') });

                  options.onError({ name: t('error'), message: t('an-error-occured-message', { message: message }) });
                }
              }}
            >
              <div className="media d-inline-flex align-items-center">
                <FontAwesomeIcon size="2x" className="mr-3" icon={faInboxOut} color="#1ac884" />
                <div className="media-body text-left my-3">
                  <h6 className="mt-0 text-primary">{t('upload-your-file')}</h6>
                  <p className="mb-0 h6 icon-gray font-weight-normal">
                    {t('drag-files-here-or')} <span className="text-secondary"> {t('browse')}</span>
                  </p>
                </div>
              </div>
            </AntdUpload.Dragger>
          </LabellessItem>
        </div>
      </div>
    </div>
  );
};

export const MultipleImageUpload: React.FC<{
  itemName: NamePath;
  images: { name: string; url: string }[];
}> = ({ itemName, images }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(BillingContext);
  const {t} = useTranslation();
  const [fileList, setFileList] = useState<UploadProps['fileList']>(
    images.map((image, index) => ({
      uid: `image-upload-${image.name}-${index}`,
      name: image.name,
      response: image,
      thumbUrl: image.url,
      status: 'done',
    }))
  );

  const getItemValue = ({ fileList }: { fileList: UploadFile[] }) => {
    return fileList
      .filter((x) => x.status === 'done' && x.response)
      .map((file: UploadFile<{ name: string; url: string }>) => {
        return {
          name: file.response?.name,
          url: file.response?.url,
        };
      });
  };

  return (
    <div className="col-md-12 card-bg-light">
      <div className="row">
        <div className="col-md-6 row ml-2" style={{ alignItems: 'center' }}>
          <table>
            <tbody>
              <tr>
                <td>
                  <div style={{ width: 200, height: 50, border: '3px dashed #999' }}>
                    <div
                      style={{
                        fontSize: 11,
                        textAlign: 'center',
                        marginTop: 5,
                      }}
                    >
                      *.png, *.jpg, *.jpeg
                    </div>
                  </div>
                </td>
                <td style={{ paddingLeft: 25 }}>
                  <div className="col-form-label mt-2">
                    {t('upload-multiple-images')} <br />
                    <p>{t('aspect-ratio-free-png-jpg-jpeg')}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <AntdForm.Item className="col-md-6 mb-0 mt-4" name={itemName} getValueFromEvent={getItemValue}>
          <AntdUpload.Dragger
            listType="picture"
            className="default-upload"
            multiple={true}
            showUploadList={{
              showPreviewIcon: true,
            }}
            accept=".png, .jpg, .jpeg"
            customRequest={async (options: UploadRequestOption<{ name: string; url: string }>) => {
              
              await getImageSizes(options.file).then(async (result: any) => {
                const response = await commonService.UploadImage({
                  image: options.file as File,
                  query_params: {
                    name: (options.file as File).name ?? '', //test this
                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).SERVICES.BILLING.IMAGES,
                    client: clientName,
                    deployment: deployment?.name,
                    service: service?.name,
                    width: Number(result.width),
                    height: Number(result.height),
                  },
                });

                if (response && response.status_code === StatusCodes.OK && options.onSuccess) {
                  notification.success({ message: t('file-uploaded-as-response-data-name', { responseDataName: response.data.name }) });
                  return options.onSuccess({ name: response.data.name, url: response.data.url || '' });
                }

                if (response.errors !== undefined && options.onError) {
                  const message = response.errors[0] as any;
                  notification.error({ message: t('an-error-occured-while-uploading-your-file') });

                  options.onError({ name: t('error'), message: t('an-error-occured-message', { message: message }) });
                }
              });
            }}
            onChange={({ fileList }) => {
              setFileList(fileList);
            }}
            {...{ fileList }}
          >
            <div className="media d-inline-flex align-items-center">
              <FontAwesomeIcon size="2x" className="mr-3" icon={faInboxOut} color="#1ac884" />
              <div className="media-body text-left my-3">
                <h6 className="mt-0 text-primary">{t('upload-your-file')}</h6>
                <p className="mb-0 h6 icon-gray font-weight-normal">
                  {t('drag-files-here-or')} <span className="text-secondary"> {t('browse')}</span>
                </p>
              </div>
            </div>
          </AntdUpload.Dragger>
        </AntdForm.Item>
      </div>
    </div>

    // <>
    //   <LabellessItem name={itemName} getValueFromEvent={getItemValue}>
    //     <Upload
    //       listType="picture"
    //       multiple={true}
    //       accept=".png, .jpg, .jpeg"
    //       customRequest={async (options : UploadRequestOption<{ name : string, url: string }>) => {
    //         const response = await commonService.UploadFile({
    //           media_file: options.file as File,
    //           query_params: {
    //               instance: clientName,
    //               datastore: deployment?.name ?? '',
    //           }
    //         })

    //         if (response && response.status_code === StatusCodes.OK && options.onSuccess) {
    //           notification.success({ message: `File uploaded as ${response.data.name}`})
    //           return options.onSuccess({name: response.data.name, url: response.data.url || ''})
    //         }

    //         if (response.errors !== undefined && options.onError) {
    //           const message = response.errors[0] as any;
    //           notification.error({ message: 'An error occured while uploading your file'})

    //           options.onError({name: 'Error', message: `An error occured: ${message}`});
    //         }
    //       }}
    //       onChange={({ fileList }) => {
    //         setFileList(fileList)
    //       }}
    //       {...{ fileList }}
    //     >
    //       <PrimaryButton icon={<UploadOutlined />}>Upload</PrimaryButton>
    //     </Upload>
    //   </LabellessItem>
    // </>
  );
};

export const ExpandButton: React.FC<{
  expanded: boolean;
  onExpand: (record: any, e: any) => void;
  record: any;
}> = ({ expanded, onExpand, record }) => {
  return (
    <Button
      type="text"
      style={{ backgroundColor: 'none' }}
      onClick={(e) => {
        onExpand(record, e);
      }}
    >
      {expanded ? <FontAwesomeIcon color="#999" size={'2x'} icon={faCaretDown} /> : <FontAwesomeIcon color="#999" size={'2x'} icon={faCaretRight} />}
    </Button>
  );
};

export const ModalFormWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="mt-4" style={{ border: '1px solid #ebebeb' }}>
      <div className="row justify-content-center">
        <div className="col-md-12">{children}</div>
      </div>
    </div>
  );
};

export const DatePicker = (props: DatePickerProps) => {
  return <AntdDatePicker {...props} className="form-control bg-white font-size-14 text-default" />;
};

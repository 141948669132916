import { ISMSGateway } from '../../../definitions';
import axios from 'axios';

// Interface made from the cURL response
interface DaguSMSLoginResponse {
  success: boolean;
  message: string;
  data: {
    success: boolean;
    user: {
      id: number;
      username: string;
      email: string;
      phone: string;
      businessType: string;
      address: string;
      fname: string;
      lname: string;
      isAdmin: boolean;
      isActive: boolean;
      createdAt: string;
      organization: {
        id: number;
        username: string;
        email: string;
        password: string; // Who needs data sanitization anyway?
        phone: string;
        business: string;
        address: string;
        fname: string;
        lname: string;
        isAdmin: boolean;
        isActive: boolean;
        isPhoneVerified: boolean;
        createdAt: string;
      };
    };
    accessToken: string;
  };
}

// Interface made from the cURL response
interface DaguSMSSendMessageResponse {
  message: string;
  success: boolean;
}

// Replace with HTTPS endpoint as soon as possible
const sandboxUrl = 'https://api.dagusms.com';
const productionUrl = 'https://api.dagusms.com';

// const auth = async (baseUrl: string, phone: string, password: string) => {
//   return await axios.post<DaguSMSLoginResponse>(
//     `${baseUrl}/auth/login`,
//     {
//       phone,
//       password,
//     },
//     {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     }
//   );
// };

const sendMessage = async (
  baseUrl: string, 
  token: string,
  recipient: string,
  message: string,
  senderID: string
) => {
  return await axios.post<DaguSMSSendMessageResponse>(
    `${baseUrl}/by-phone`,
    {
      phone: recipient,
      message: message,
      senderID: senderID,
      flash: true
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const dagusms: ISMSGateway = {
  name: 'DaguSMS',
  sms_gateway_id: 'dagusms',
  fields: {
    username: { label: 'Username', type: 'string' },
    password: { label: 'Password', type: 'string' },
    senderID: { label: 'Sender ID', type: 'string' },
    token: { label: 'Token', type: 'string' },
  },
  url: 'https://dagusms.com/',
  helpText: 'Set up messaging with DaguSMS',
  infotext: [
    'Set up messaging with DaguSMS.',
    'Sender ID must be an approved ID in the DaguSMS dashboard.'
  ].join('\n'),
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/sms-gateways/dagusms.png',
  send: async ({ username, password, senderID, token }, { phone, text }) => {
    if (!(username && password && senderID)) {
      throw new Error('Username, password or senderID were not provided.');
    }

    const baseUrl = productionUrl;
    const sanitizedPhone = phone.startsWith('+') ? phone : `+${phone}`;

    try {
      // const tokenResponse = await auth(baseUrl, username, password);

      // const token = tokenResponse.data.data.accessToken;

      const sendResponse = await sendMessage(
        baseUrl,
        token,
        sanitizedPhone,
        text,
        senderID
      );

      console.log(sendResponse.data);

      return {
        isSend: !!sendResponse.data.success,
      };
    } catch (e) {
      console.log("Couldn't send message to " + sanitizedPhone);
      if (e.response) {
        console.log(e.response?.data);
        console.log('Status: ' + e.response?.status);
      } else {
       
      }
      return {
        isSend: false,
      };
    }
  },
};

import { faArrowsUpDown, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input, Select, Table, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useTranslation } from 'react-i18next';

const Menus = (params: { setData: any; data?: any; viaPageBuilder?: boolean; menus?: any }) => {
  const [dataLocal, setDataLocal] = useState([] as any);
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const [loading, setLoading] = useState(true);
  let [form] = useForm();

  const handleAddSubmit = async () => {
    setLoading(true);
    var test = dataLocal.find((d: any) => d.name == selectedMenu);
    if (test == undefined) {
      let previous_state: any[] = params.data;
      let length = params.data.length;
      dataLocal.key = length;
      previous_state.push({ name: selectedMenu });
      let index = _.findIndex(previous_state, { key: dataLocal.key });
      if (dataLocal.add_to_top) {
        previous_state = previous_state.filter((value, i) => {
          if (i !== index) value.add_to_top = false;
          return value;
        });
      }
      setDataLocal(previous_state);
      params.setData(previous_state);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleDelete = (row: any) => {
    let previous_state: any[] = dataLocal;
    previous_state = previous_state.filter((m) => m.name !== row?.name);
    setDataLocal(previous_state);
    params.setData(previous_state);
  };

  useEffect(() => {
    if (params.data != undefined) {
      if (params.data.length > 0) {
        if (params.data.find((d) => d.name == 'Loader') != undefined) {
          setDataLocal(params.data);
        } else {
          if (params.viaPageBuilder == undefined) {
            params.data.unshift({ name: 'Loader' });
          }
          setDataLocal(params.data);
        }
      } else {
        if (params.viaPageBuilder == undefined) {
          params.setData([
            {
              name: 'Loader',
            },
            {
              name: 'Home',
            },
            {
              name: 'Audio Player',
            },
            {
              name: 'Video Player',
            },
          ]);
          setDataLocal([
            {
              name: 'Loader',
            },
            {
              name: 'Home',
            },
            {
              name: 'Audio Player',
            },
            {
              name: 'Video Player',
            },
          ]);
        }
      }
    }
    setLoading(false);
  }, [params.data]);

  //const that = this;
  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const data = [...dataLocal];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setDataLocal(data);
      params.setData(data);
    },
    lineClassname: 'global-drag-line',
    handleSelector: '.drag-handle',
  };

  const setSelectedMenus = (value: any) => {
    setSelectedMenu(value.value);
  };
  const handleAddAll = () => {
    params.setData([{ name: 'Loader' }, { name: 'Home' }, { name: 'Channels' }, { name: 'TV Guide' }, { name: 'Radio' }, { name: 'Recordings' }, { name: 'Cams' }, { name: 'Movies' }, { name: 'Shorts' }, { name: 'Series' }, { name: 'Music' }, { name: 'Podcasts' }, { name: 'Courses' }, { name: 'Search' }, { name: 'Games' }]);
  };
  const customMenu = Form.useWatch('custom', form);
  const addCustomMenu = () => {
    setLoading(true);

    let previous_state: any[] = params.data;
    let length = params.data.length;
    dataLocal.key = length;
    previous_state.push({ name: customMenu });
    let index = _.findIndex(previous_state, { key: dataLocal.key });
    if (dataLocal.add_to_top) {
      previous_state = previous_state.filter((value, i) => {
        if (i !== index) value.add_to_top = false;
        return value;
      });
    }
    setDataLocal(previous_state);
    params.setData(previous_state);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-5">
            <div className="row-md-12" style={{ paddingLeft: -15 }}>
              <div className="row mt-4">
                <div className="col-md-5">
                  {params.viaPageBuilder == undefined && (
                    <Select onChange={setSelectedMenus} style={{ width: '100%' }} labelInValue defaultValue={t('select-a-menu-to-add')}>
                      <Select.Option value={'Channels'} key={1}>
                        {t('channels')}
                      </Select.Option>
                      <Select.Option value={'TV Guide'} key={2}>
                        {t('tv-guide')}
                      </Select.Option>
                      <Select.Option value={'Radio'} key={3}>
                        {t('radio')}
                      </Select.Option>
                      <Select.Option value={'Recordings'} key={4}>
                        {t('recordings')}
                      </Select.Option>
                      <Select.Option value={'Cams'} key={5}>
                        {t('cams')}
                      </Select.Option>
                      <Select.Option value={'Movies'} key={6}>
                        {t('movies')}
                      </Select.Option>
                      <Select.Option value={'Shorts'} key={7}>
                        {t('shorts')}
                      </Select.Option>
                      <Select.Option value={'Series'} key={8}>
                        {t('series')}
                      </Select.Option>
                      <Select.Option value={'Music'} key={9}>
                        {t('music')}
                      </Select.Option>
                      <Select.Option value={'Podcasts'} key={10}>
                        {t('podcasts')}
                      </Select.Option>
                      <Select.Option value={'Courses'} key={11}>
                        {t('courses')}
                      </Select.Option>
                      <Select.Option value={'Games'} key={12}>
                        {t('games')}
                      </Select.Option>
                      <Select.Option value={'Education'} key={13}>
                        {t('education')}
                      </Select.Option>
                      <Select.Option value={'Search'} key={14}>
                        {t('search')}
                      </Select.Option>
                      <Select.Option value={'Favorites'} key={15}>
                        {t('favorites')}
                      </Select.Option>
                      <Select.Option value={'Watchlist'} key={16}>
                        {t('watchlist')}
                      </Select.Option>
                      <Select.Option value={'Rentals'} key={17}>
                        {t('rentals')}
                      </Select.Option>
                      <Select.Option value={'Downloads'} key={18}>
                        {t('downloads')}
                      </Select.Option>
                      <Select.Option value={'Purchases'} key={19}>
                        {t('purchases')}
                      </Select.Option>
                      <Select.Option value={'Wallet'} key={20}>
                        {t('wallet')}
                      </Select.Option>
                    </Select>
                  )}
                  {params.viaPageBuilder != undefined && (
                    <Select onChange={setSelectedMenus} className={'col-md-12'} labelInValue defaultValue={t('select-a-menu-to-add')}>
                      {params.menus.find((m) => m.name == 'Channels') != undefined && (
                        <Select.Option value={'Channels'} key={1}>
                          {t('channels')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'TV Guide') != undefined && (
                        <Select.Option value={'TV Guide'} key={2}>
                          {t('tv-guide')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Radio') != undefined && (
                        <Select.Option value={'Radio'} key={2}>
                          {t('radio')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Recordings') != undefined && (
                        <Select.Option value={'Recordings'} key={3}>
                          {t('recordings')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Cams') != undefined && (
                        <Select.Option value={'Cams'} key={4}>
                          {t('cams')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Movies') != undefined && (
                        <Select.Option value={'Movies'} key={5}>
                          {t('movies')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Shorts') != undefined && (
                        <Select.Option value={'Shorts'} key={6}>
                          {t('shorts')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Series') != undefined && (
                        <Select.Option value={'Series'} key={7}>
                          {t('series')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Music') != undefined && (
                        <Select.Option value={'Music'} key={8}>
                          {t('music')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Podcasts') != undefined && (
                        <Select.Option value={'Podcasts'} key={9}>
                          {t('podcasts')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Courses') != undefined && (
                        <Select.Option value="Courses" key={10}>
                          {t('courses')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Search') != undefined && (
                        <Select.Option value={'Search'} key={11}>
                          {t('search')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Favorites') != undefined && (
                        <Select.Option value={'Favorites'} key={12}>
                          {t('favorites')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Watchlist') != undefined && (
                        <Select.Option value={'Watchlist'} key={13}>
                          {t('watchlist')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Rentals') != undefined && (
                        <Select.Option value={'Rentals'} key={14}>
                          {t('rentals')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Purchases') != undefined && (
                        <Select.Option value={'Purchases'} key={15}>
                          {t('purchases')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Downloads') != undefined && (
                        <Select.Option value={'Downloads'} key={16}>
                          {t('downloads')}
                        </Select.Option>
                      )}
                      {params.menus.find((m) => m.name == 'Wallet') != undefined && (
                        <Select.Option value={'Wallet'} key={17}>
                          {t('wallet')}
                        </Select.Option>
                      )}
                    </Select>
                  )}
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <Button onClick={handleAddSubmit} className="ant-btn btn btn-secondary rounded-pill width-240 height-48 mr-2">
                      {t('add-menu')}
                    </Button>
                    <Button onClick={handleAddAll} className="ant-btn btn btn-secondary rounded-pill width-240 height-48">
                      {t('add-all')}
                    </Button>
                  </div>
                </div>
              </div>

              <Form className="row mt-4" form={form} onFinish={(values: any) => {}} onFinishFailed={(errorInfo) => {}}>
                <div className="col-md-5">
                  <Form.Item
                    name="custom"
                    extra={t('')}
                    className="form-group mb-0"
                    rules={[
                      {
                        required: true,
                        message: t('name-required'),
                      },
                    ]}
                  >
                    <Input className="form-control bg-white font-size-14 text-default" placeholder={t('your-custom-menu-here')} />
                  </Form.Item>
                </div>
                <div className="col-md-7" style={{ marginLeft: -15 }}>
                  <Button htmlType={'button'} onClick={() => addCustomMenu()} className="ant-btn btn btn-secondary rounded-pill width-240 height-48 mr-2">
                    {t('add-custom-menu')}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <ReactDragListView {...dragProps}>
            <Table
              loading={loading}
              rowKey={(dataLocal) => dataLocal?.name}
              columns={[
                {
                  title: t('drag-and-drop'),
                  key: 'operate',
                  width: 100,
                  render: (text, record, index) => (
                    <div className="text-center">
                      <FontAwesomeIcon className="action-icon drag-handle" color={'#999999'} size={'xl'} icon={faArrowsUpDown} />
                    </div>
                  ),
                },
                {
                  title: t('name'),
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: t('actions'),
                  dataIndex: 'action',
                  width: 100,
                  render: (tags: any, row: any, index_: any) => (
                    <span className="justify-content-end">
                      {row?.name != 'Home' && row?.name != 'Loader' && row?.name != 'Player' && (
                        <Tooltip placement="top" title={`Delete`}>
                          <Button onClick={() => handleDelete(row)} className="mx-2 cursor-pointer" type="text">
                            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                          </Button>
                        </Tooltip>
                      )}
                    </span>
                  ),
                },
              ]}
              pagination={{
                position: ['bottomLeft'],
                pageSize: 20,
                hideOnSinglePage: true,
                showTotal: (total, range) => {
                  return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total })
                },
              }}
              dataSource={dataLocal}
              key={JSON.stringify(dataLocal)}
            />
          </ReactDragListView>
        </div>
      </div>
    </div>
  );
};
export default Menus;

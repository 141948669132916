import { Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

type AddModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading: boolean;
};
const AddModal = (props: AddModalProps) => {
  const { visible, onSubmit, onCancel, isLoading } = props;
  const handleSubmit = (e: any) => {
    onSubmit(e);
  };
  const { t } = useTranslation();

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">{t('add-provider')}</h4>
            <Form onFinish={handleSubmit} onFinishFailed={(errorInfo) => {}}>
              <label className="col-form-label pb-2 d-block text-default">{t('name')}</label>
              <Form.Item
                className="form-group pb-0"
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-a-name'),
                  },
                ]}
              >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('provider-name')} />
              </Form.Item>

              <div className="col-md-12 p-0">
                <Button loading={isLoading} disabled={isLoading} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-provider')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;

import { useTranslation } from 'react-i18next';
import { Input, Item } from '../../../../../common/components/form';
import { Card } from 'antd';
import { CommunicationReporting } from '@mwaretv/database/build/backend/models/reporting/communications/communication';

export const CommunicationsDetails = ({ data }: { data: CommunicationReporting }) => {
  const {t} = useTranslation();
    return (
      <>
        <Card title={t('general')}>
          <Item className="col-md-8" label={t('timestamp')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.timestamp ? new Date(data.timestamp * 1000).toString() : undefined} disabled />
          </Item>
          <Item className="col-md-8" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.source} disabled />
          </Item>
          <Item className="col-md-8" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.type} disabled />
          </Item>
          <Item className="col-md-8" label={t('template-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.template_name} disabled />
          </Item>
          {/* <Item className="col-md-8" label={t('template-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.template_id} disabled />
          </Item> */}
          <Item className="col-md-8" label={t('product-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.subscription?.name} disabled />
          </Item>
          {/* <Item className="col-md-8" label={t('product-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.product_id} disabled />
          </Item>
          <Item className="col-md-8" label={t('email')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.email} disabled />
          </Item>
          <Item className="col-md-8" label={t('mobile')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.mobile} disabled />
          </Item> */}
        </Card>
      </>
    );
  };
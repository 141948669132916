import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Coupons from './coupons';
import CouponsAdd from './coupons/add';
import CouponsDetails from './coupons/details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/details/:_id`}>
        <CouponsDetails />
      </Route>
      <Route path={`${path}/add`}>
        <CouponsAdd />
      </Route>
      <Route path={`${path}/`}>
        <Coupons />
      </Route>
    </Switch>
  );
};

export default Index;

import { Alert, Form, notification, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import Card from '../../../../common/components/card';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { CardForm, FormHeading } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { Customer } from '../../../../interfaces/forms/areas/customers/customers/customer';
import { useCustomer, usePatchMutation } from '../../../../services/areas/customers/customerService';
import { TransactionsFields, ProductSelectionFields, CustomerDataFields, TicketFields, ShoppingCartFields, CommunicationsFields, CustomerAccountFieldsLocked } from './shared/form';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { CustomersContext } from '../../../../contexts/customersContext';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import DevicesTable from './shared/devices';
import ProfileTable from './shared/profiles';
import FavoriteTable from './shared/favorites';
import RecordingTable from './shared/recordings';
import WatchlistTable from './shared/watchlist';
import { useQuery } from '@tanstack/react-query';
import * as TaxService from '../../../../services/areas/billing/taxService';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CommonContext } from '../../../../contexts/commonContext';
import { Link } from 'react-router-dom';
import PayPerViewTable from './shared/payperview';

const Details = () => {
  const { url } = useRouteMatch();
  const { clientName, service: serviceName, id } = useParams<{ clientName: string; service: string; id: string }>();
  const { deployment } = React.useContext(CustomersContext);
  const { isLoading, isError, data, refetch } = useCustomer({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName }, id });
  const { t } = useTranslation();

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-customer-list')} url={url.split('/').slice(0, -1).join('/') + '/list'} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('customer-details')} subtitle={t('manage-an-existing-customer-you-can-update-subscription-personal-information-tickets-and-view-logs')} />
        </div>
        <div className="col-md-6 mb-2 text-right" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              verticalAlign: 'center',
              justifyContent: 'center',
              width: 120,
              height: 50,
              color: '#fff',
              borderRadius: 5,
              padding: 10,
              fontSize: 18,
              fontWeight: 'bold',
              backgroundColor: '#16a76e',
              marginRight: 10,
            }}
          >
            {data?.data.productStatus == 'trial' ? t('trial') : t('regular')}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              verticalAlign: 'center',
              justifyContent: 'center',
              width: 120,
              height: 50,
              color: '#fff',
              borderRadius: 5,
              padding: 10,
              fontSize: 18,
              fontWeight: 'bold',
              backgroundColor: data?.data.subscriptionStatus == 'active' ? '#16a76e' : data?.data.subscriptionStatus == 'expired' ? '#c05453' : '#16a76e',
              marginRight: 10,
            }}
          >
            {data?.data.subscriptionStatus == 'active' ? t('active') : data?.data.subscriptionStatus == 'expired' ? t('chart-design-customer-subscription-status-expired') : t('chart-design-customer-subscription-status-pending')}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              verticalAlign: 'center',
              justifyContent: 'center',
              width: 120,
              height: 50,
              color: '#fff',
              borderRadius: 5,
              padding: 10,
              fontSize: 18,
              fontWeight: 'bold',
              marginRight: 10,
              backgroundColor: data?.data.status == 'active' ? '#16a76e' : '#c05453',
            }}
          >
            {data?.data.status == 'active' ? t('enabled') : t('disabled')}
          </div>
          {data?.data.wallet_amount != undefined && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                verticalAlign: 'center',
                justifyContent: 'center',
                height: 50,
                color: '#fff',
                borderRadius: 5,
                padding: 10,
                paddingLeft:20,
                paddingRight:20,
                fontSize: 18,
                fontWeight: 'bold',
                backgroundColor: '#999999',
              }}
            >
              {data?.data.wallet_amount} {t('credits')}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isLoading && <Spin />}
          {isError && <span>{t('something-went-wrong-while-fetching-your-ticket')}</span>}
          {!isLoading && !isError && <DetailTabs customer={data.data} />}
        </div>
      </div>
    </ContainerWrapper>
  );
};

type DetailTabsProps = {
  customer: Customer;
};

const DetailTabs = ({ customer }: DetailTabsProps) => {
  const { clientName, service: serviceName, id } = useParams<{ clientName: string; service: string; id: string }>();
  const { deployment } = React.useContext(CustomersContext);
  const path = STORAGE_ENDPOINTS(clientName, deployment?.name, '').CUSTOMERS.CUSTOMERS;
  const { t } = useTranslation();
  const [subscriptionsForm] = Form.useForm();
  const accountForm = subscriptionsForm;
  const customerDataForm = subscriptionsForm;
  const shoppingCartForm = subscriptionsForm;

  const { isLoading, mutate } = usePatchMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, path: path }, id },
    {
      onSuccess: () => notification.success({ message: t('customer-updated') }),
      onError: () => notification.error({ message: t('something-went-wrong-updating-the-customer-please-try-again-later') }),
    }
  );

  const initialValues: Customer = {
    ...customer,
    password: customer.password, // undefined as any,
    password_app: customer.password_app, // undefined as any,
    subscriptions: {
      base: {
        ...customer.subscriptions?.base,
        activation_date: customer.subscriptions?.base?.activation_date ? (dayjs.unix(customer.subscriptions.base.activation_date) as any) : undefined,
        expiration_date: customer.subscriptions?.base?.expiration_date ? (dayjs.unix(customer.subscriptions.base.expiration_date) as any) : undefined,
      },
      extras: (customer.subscriptions?.extras || []).map((x) => ({
        ...x,
        activation_date: x.activation_date ? (dayjs.unix(x.activation_date) as any) : undefined,
      })),
    },
  };

  const { countries } = useContext(CommonContext);

  const { data: tax_rates } = useQuery(['taxes'], async () => {
    return (
      await TaxService.list({
        params: {
          cms: deployment?.name ?? '',
          crm: serviceName ?? '',
          instance: clientName,
          page: 1,
          limit: 9999,
        },
      })
    ).data.docs;
  });

  const onFinish = (values) => {
    if (!countries) {
      return;
    }

    // TODO Rewrite this
    if (values.mobile === '') {
      values.mobile = undefined;
    }
    if (values.phone === '') {
      values.phone = undefined;
    }
    if (values.email === '') {
      values.email = undefined;
    }

    const country = countries.find((x) => x.iso3 === customerDataForm.getFieldValue('billing_country'));

    if (!country || !tax_rates) {
      return;
    }

    if (values?.subscriptions?.base?.plan && values?.subscriptions?.base?.plan?._id === undefined) {
      values.subscriptions.base.plan = undefined;
    }

    if (values?.subscriptions?.base?.product && values?.subscriptions?.base?.product?._id === undefined) {
      values.subscriptions.base.product = undefined;
    }

    if (values?.subscriptions?.base?.activation_date) {
      values.subscriptions.base.activation_date = dayjs(values.subscriptions.base.activation_date).unix();
    }
    if (values?.subscriptions?.base?.expiration_date) {
      values.subscriptions.base.expiration_date = dayjs(values.subscriptions.base.expiration_date).unix();
    }

    if (values?.subscriptions?.extra) {
      values.subscriptions.extra.forEach((extra) => {
        if (extra.activation_date) {
          extra.activation_date = dayjs(extra.activation_date).unix();
        }
        if (extra.expiration_date) {
          extra.expiration_date = dayjs(extra.expiration_date).unix();
        }
      });
    }

    const billing_country = country.iso3;
    const billing_currency = country.currency;
    const billing_taxrate = tax_rates != undefined && tax_rates[0] != undefined && tax_rates[0].rates != undefined && tax_rates[0].rates.find((r) => r.country == country.iso3) != undefined ? tax_rates[0].rates.find((r) => r.country == country.iso3)!.percentage : 0;
    mutate({
      ...values,
      billing_country,
      billing_currency,
      billing_taxrate,
    });
  };

  return (
    <Tabs
      className="default-tab"
      items={[
        {
          key: 'customer-info',
          label: t('customer-info'),
          children: (
            <CardForm {...{ initialValues }} form={customerDataForm} disabled={isLoading} onFinish={onFinish}>
              <CustomerDataFields isInAddMode={false} />
            </CardForm>
          ),
        },
        {
          key: 'shopping-cart',
          label: t('shopping-cart'),
          children: (
            <CardForm {...{ initialValues }} form={shoppingCartForm} disabled={isLoading} onFinish={onFinish}>
              <ShoppingCartFields />
            </CardForm>
          ),
        },
        {
          key: 'customer-subscriptions',
          label: t('subscriptions'),
          children: (
            <CardForm {...{ initialValues }} form={subscriptionsForm} disabled={isLoading} onFinish={onFinish}>
              <ProductSelectionFields />
            </CardForm>
          ),
        },
        {
          key: 'customer-account',
          label: t('account'),
          children: (
            <CardForm {...{ initialValues }} form={accountForm} disabled={isLoading} onFinish={onFinish}>
              <CustomerAccountFieldsLocked />
            </CardForm>
          ),
        },
        {
          key: 'customer-tickets',
          label: t('tickets'),
          children: (
            <Card>
              <FormHeading subtitle={t('here-you-can-find-all-tickets-associated-with-this-customer')}>{t('tickets')}</FormHeading>
              <TicketFields />
            </Card>
          ),
        },
        {
          key: 'customer-devices',
          label: t('devices'),
          children: (
            <Card>
              <FormHeading subtitle={t('current-devices-the-customer-uses')}>{t('devices')}</FormHeading>
              <DevicesTable initialData={initialValues} />
            </Card>
          ),
        },
        {
          key: 'customer-profiles',
          label: t('profiles'),
          children: (
            <Card>
              <FormHeading subtitle={t('the-profiles-the-customer-uses-on-his-applications')}>{t('profiles')}</FormHeading>
              <ProfileTable initialData={initialValues} />
            </Card>
          ),
        },
        {
          key: 'customer-favorites',
          label: t('favorites'),
          children: (
            <Card>
              <FormHeading subtitle={t('the-favorites-the-customer-has-set-on-his-profiles')}>{t('favorites')}</FormHeading>
              <FavoriteTable initialData={initialValues} />
            </Card>
          ),
        },
        {
          key: 'customer-watchlist',
          label: t('watchlist'),
          children: (
            <Card>
              <FormHeading subtitle={t('the-watchlist-the-customer-has-accumulated')}>{t('watchlist')}</FormHeading>
              <WatchlistTable initialData={initialValues} />
            </Card>
          ),
        },
        {
          key: 'customer-payperview',
          label: t('payperview'),
          children: (
            <Card>
              <FormHeading subtitle={t('payperview-list-of-the-content-the-customer-has-purchased-or-rented')}>{t('payperview')}</FormHeading>
              <PayPerViewTable initialData={initialValues} />
            </Card>
          ),
        },
        {
          key: 'customer-recordings',
          label: t('recordings'),
          children: (
            <Card>
              <FormHeading subtitle={t('the-recordings-the-customer-has-set')}>{t('recordings')}</FormHeading>
              <RecordingTable initialData={initialValues} />
            </Card>
          ),
        },
        {
          key: 'customer-transactions',
          label: t('transactions'),
          children: (
            <Card>
              <FormHeading subtitle={t('view-all-transactions-associated-with-this-customer')}>{t('transactions')}</FormHeading>
              <TransactionsFields />
            </Card>
          ),
        },
        {
          key: 'customer-communications',
          label: t('communications'),
          children: (
            <Card>
              <FormHeading subtitle={t('view-all-communications-associated-with-this-customer')}>{t('communications')}</FormHeading>
              <CommunicationsFields />
            </Card>
          ),
        },
      ]}
    />
  );
};

export default Details;

import { ISMSGateway } from '../../../definitions';
import axios, { AxiosError } from 'axios';
import querystring from 'querystring';
import type { AfricasTalkingResponse } from './africasTalkingResponse.interface';

export const africastalking: ISMSGateway = {
  name: "Africa's talking",
  sms_gateway_id: 'africastalking',
  fields: {
    apiKey: { label: 'Api Key', type: 'string' },
    username: { label: 'Username', type: 'string' },
    environment: { label: 'Environment (live / sandbox)', type: 'string' },
  },
  url: 'https://www.africastalking.com',
  helpText: "Set up messaging with Africa's talking",
  infotext: "Set up messaging with Africa's talking.",
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/sms-gateways/africastalking1.png',
  send: async ({ apiKey, username, environment }, { phone, text }) => {
    if (!apiKey || !username) {
      throw new Error('Apikey  or Username was not provided.');
    }

    const isLive = environment === 'live';

    if (!isLive) {
      username = 'sandbox';
    }

    const baseUrl =
      environment === 'live'
        ? 'https://api.africastalking.com'
        : 'https://api.sandbox.africastalking.com';
    const africaTalkingUrl = `${baseUrl}/version1/messaging`;

    const res = await axios.post(
      africaTalkingUrl,
      querystring.stringify({ username: username, to: phone, message: text }),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          apiKey: apiKey,
        },
      }
    );

    const validateSms = res.data as AfricasTalkingResponse;
    if (validateSms.SMSMessageData.Recipients[0].statusCode === 101) {
      return { isSend: true };
    } else {
      console.log(JSON.stringify(res.data));
      return { isSend: false };
    }
  },
};

export default africastalking;

import {Tabs } from 'antd';
import { useContext, useState } from 'react';
import { useEpg, Epg, Layout } from 'planby';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import cmsChannels from '../../../../../services/areas/content/channels/bundles';
import { StatusCodes } from 'http-status-codes';
import { useEffectOnce } from 'react-use';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../../dashboard';
import moment from 'moment';
import axios from 'axios';
import { ContentContext } from '../../../../../contexts/contentContext';
import { useTranslation } from 'react-i18next';

const TVGuide_ = () => {
  let params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { service } = useContext(ContentContext);
  const { t } = useTranslation();
  const theme = {
    primary: {
      600: '#fff',
      900: '#eaeaea',
    },
    grey: { 300: '#999' },
    white: '#fff',
    green: {
      300: '#2C7A7B',
    },
    loader: {
      teal: '#5DDADB',
      purple: '#3437A2',
      pink: '#F78EB6',
      bg: '#171923db',
    },
    scrollbar: {
      border: '#333333',
      thumb: {
        bg: '#e1e1e1',
      },
    },
    gradient: {
      blue: {
        300: '#f5f5f5',
        600: '#f2f2f2',
        900: '#e5e5e5',
      },
    },
    text: {
      grey: {
        300: '#222',
        500: '#444',
      },
    },
    timeline: {
      divider: {
        bg: '#718096',
      },
    },
  };

  const [channels, setEpgChannels] = useState([] as any);
  const [epg, setEpgData] = useState([] as any);
  const [loaded, setLoaded] = useState(false);
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>('1');
  const { url } = useRouteMatch();
  const { getEpgProps, getLayoutProps, onScrollToNow, onScrollLeft, onScrollRight } = useEpg({
    epg,
    channels,
    startDate: '2022-10-11T00:00:00',
    endDate: '2022-10-11T23:59:59',
    theme: theme,
  });

  const [tableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 1000,
    query_params: {
      instance: clientName,
      cms: params.deployment ?? '',
    },
  });

  const getChannelBundles = async (list: ICMS_Lists) => {
    setLoaded(false);
    let response = await cmsChannels.List_Channel_Bundless(list);
    if (response && response.status_code === StatusCodes.OK) {
      var bundles = response.data.data;
      if (bundles != undefined) {
        var epgBundles = [] as any;
        getEpgBundledEpgData(bundles, 0, epgBundles);
      }
    }
  };

  const getEpgBundledEpgData = async (bundles: any, index: any, epgBundles: any) => {
    var url = 'https://appcloud.akamaized.net/' + clientName + '/' + service?.name ?? '' + '/content/epg/' + bundles[index]._id + '_' + moment().add('day', 2).format('DD') + '-' + (moment().add('day', 2).month() + 1) + '-' + moment().add('day', 2).year() + '_package.json';
    await axios.get(url).then((response: any) => {
      var resp = response.data;
      epgBundles.push(resp);
      if (bundles.length - 1 > index) {
        getEpgBundledEpgData(bundles, index + 1, epgBundles);
      } else {
        mergeEpgBundlesToTvGuideData(epgBundles);
      }
    });
  };

  const mergeEpgBundlesToTvGuideData = async (epgBundles: any) => {
    

    var channels_ = [] as any;
    var epg_ = [] as any;

    epgBundles.forEach((epgData: any) => {
      epgData.channels.forEach((data: any) => {
        var channel = {
          logo: data.m,
          uuid: data.i,
        };
        channels_.push(channel);
        var programs = data.p;
        programs.forEach((program: any, index: any) => {
          var prog = {
            channelUuid: data.i,
            description: program.d,
            id: data.i + index,
            image: program.m,
            since: moment.unix(program.s).format('YYYY-DD-MMTHH:mm:ss'),
            till: moment.unix(program.e).format('YYYY-DD-MMTHH:mm:ss'),
            title: program.n,
          };
          epg_.push(prog);
        });
      });
    });
    setEpgChannels(channels_);
    setEpgData(epg_);
  };

  useEffectOnce(() => {
    getChannelBundles(tableSearchState);
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('guide')}</h2>
          <p className="h6 font-weight-normal line-height-26">
            {t('see-exactly-how-your-guide-data-is-shown-in-the-apps')}
            <br />
            <br />
            {t('if-your-data-is-not-correct-or-is-missing-content-then-follow-these-steps')}
            <br />
            <br />
            {t('1-import-your-mapped-sources-use-the-import-guide-button')}
            <br />
            {t('2-after-the-above-its-time-to-publish-your-imports-to-the-app-cloud-use-the-publish-guide-button-for-this')}
          </p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4 justify-content-md-end ">
        <div className="d-flex justify-content-center justify-content-md-end align-items-start mr-3">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('import-guide')}
          </Link>
        </div>
        <div className="d-flex justify-content-center justify-content-md-end align-items-start">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('publish-guide')}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="col-12 mt-4 bg-white">
              <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={'1'}>
                <TabPane tab={t('channels')} key={'1'}>
                  <div className="p-4">
                    <Epg {...getEpgProps()}>
                      <Layout {...getLayoutProps()} />
                    </Epg>
                  </div>
                </TabPane>
                <TabPane tab={t('radio-stations')} key={'2'}>
                  <div className="p-4">
                    <Epg {...getEpgProps()}>
                      <Layout {...getLayoutProps()} />
                    </Epg>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVGuide_;

import { Placeholder } from '../types/placeholder';

export const inappPlaceholders: Placeholder[] = [
  {
    placeholder: 'first_name',
    description: 'Firstname of the customer',
  },
  {
    placeholder: 'last_name',
    description: 'Lastname of the customer',
  },
  {
    placeholder: 'product',
    description: 'Current customer product',
  },
  {
    placeholder: 'expiration',
    description: 'Expiration date of the current product',
  },
  {
    placeholder: 'renewal_link',
    description:
      'Link for the customer to renew the subscription on the active product',
  },
  {
    placeholder: 'renewal_amount',
    description: 'Price to renew the active product',
  },
];

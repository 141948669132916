import { Image, Typography } from 'antd';
import { saveAs } from 'file-saver';
import { ImagesProps } from './image.interface';
import { PrimaryButton } from '../../../../../../common/components/form';
import { useTranslation } from 'react-i18next';

const ImageComponent = ({ title, image, fileName }: ImagesProps) => {
  const {t} = useTranslation();
  return (
    <>
      <Typography.Text style={{ paddingTop: '20px', fontWeight: 'bold' }}> {title}</Typography.Text>
      <Image width="100%" src={image} style={{ objectFit: 'cover', padding: '10px' }} />
      <PrimaryButton onClick={() => saveAs(image, fileName)}>{t('download')}</PrimaryButton>
    </>
  );
};

export default ImageComponent;

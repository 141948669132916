import { Skeleton, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Rectangle_Rails } from '../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/rectangle/rectangle';
import moment from 'moment';
import axios from 'axios';

export type LayoutProps = {
  data: Rectangle_Rails;
  application: Application;
};

const PlayerGrids = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  return (
    <div className="container remove-hor-padding" style={{ height: '100%', flex: 1, overflow: 'scroll', marginBottom: 5 }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          gap: 5,
          marginLeft:5,
          marginRight:5
        }}
      >
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
        <Skeleton.Image active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }} />
      </div>
    </div>
  );
};

export default PlayerGrids;

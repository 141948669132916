import { Form, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index: any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  application: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, application } = props;
  const [form] = useForm();
  const { t } = useTranslation();

  const [button, setButton] = useState('');
  const [keyboard, setKeyboard] = useState('');
  const [input, setInput] = useState('');
  const [dropdown, setDropdown] = useState('');
  const [switch_, setSwitch_] = useState('');
  const [modal, setModal] = useState('');

  const [buttons, setButtons] = useState([] as any);
  const [keyboards, setKeyboards] = useState([] as any);
  const [inputs, setInputs] = useState([] as any);
  const [dropdowns, setDropdowns] = useState([] as any);
  const [switches, setSwitches] = useState([] as any);
  const [modals, setModals] = useState([] as any);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    if (application.components != undefined) {
      if (application.components?.buttons != undefined) {
        setButtons(application.components.buttons);
      }
      if (application.components?.keyboards != undefined) {
        setKeyboards(application.components.keyboards);
      }
      if (application.components?.inputs != undefined) {
        setInputs(application.components.inputs);
      }
      if (application.components?.dropdowns != undefined) {
        setDropdowns(application.components.dropdowns);
      }
      if (application.components?.switches != undefined) {
        setSwitches(application.components.switches);
      }
      if (application.components?.modals != undefined) {
        setModals(application.components.modals);
      }
    }
    if (data.components != undefined) {
      form.setFieldsValue(data.components);

      setButton(data.components.button?._id);
      setDropdown(data.components.dropdown?._id);
      setKeyboard(data.components.keyboard?._id);
      setModal(data.components.modal?._id);
      setSwitch_(data.components.switch_?._id);
      setInput(data.components.input?._id);
    }
    setIsLoading(false);
  }, [data]);

  const onSubmitLocal = (values: any) => {
    var components = {
      button: {
        _id: button,
      },
      input: {
        _id: input,
      },
      dropdown: {
        _id: dropdown,
      },
      modal: {
        _id: modal,
      },
      keyboard: {
        _id: keyboard,
      },
      switch: {
        _id: switch_,
      },
    };
    let dataOut = {
      ...data,
      components: components,
    };
    onSubmit(dataOut, type, props.index);
  };
  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('component-setup')}</h4>
            <p>{t('select-the-component-s-you-want-to-use-with-this-page-element')}</p>
            <div className="row mt-5">
              <div className="col-12">
                {!isLoading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="row">
                      <div className="col card-bg-light p-5 mb-2 mr-4">
                        {(type.indexOf('Grid') > -1 ||
                          type.indexOf('Rails') > -1 ||
                          type.indexOf('Drawer') > -1 ||
                          type == 'Video Player' ||
                          type == 'Audio Player' ||
                          type == 'Services' ||
                          type == 'Registration' ||
                          type == 'Profiles' ||
                          type == 'Onboarding' ||
                          type == 'Login' ||
                          type == 'Languages' ||
                          type == 'Connect' ||
                          type == 'Disclaimer' ||
                          type.indexOf('Menu') > -1 ||
                          type.indexOf('Details') > -1 ||
                          type == 'Single' ||
                          type == 'Slider' ||
                          type == 'Menu' ||
                          type == 'Search Bar' ||
                          type == 'Episode List' ||
                          type == 'Guide Horizontal' ||
                          type == 'Filter Bar') && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('button')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['button', '_id']}>
                                <Select onChange={setButton} className="default-select" placeholder={t('select-a-button-component')}>
                                  {buttons.map((button) => {
                                    return (
                                      <Select.Option value={button._id} key={button._id}>
                                        {button.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {(type == 'Connect Code' || type == 'Search Bar' || type == 'Video Player' || type == 'Services' || type == 'Registration' || type == 'Profiles' || type == 'Login' || type == 'Filter Bar') && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('input')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['input', '_id']}>
                                <Select onChange={setInput} className="default-select" placeholder={t('select-an-input-component')}>
                                  {inputs.map((input) => {
                                    return (
                                      <Select.Option value={input._id} key={input._id}>
                                        {input.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {(type == 'Search Bar' || type == 'Profiles' || type == 'Connect Code' || type == 'Registration' || type == 'Services' || type == 'Login' || type == 'Get Started' || type == 'Forgot' || type == 'Video Player'  || type == 'Filter Bar') && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('keyboard')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['keyboard', '_id']}>
                                <Select onChange={setKeyboard} className="default-select" placeholder={t('select-a-keyboard-component')}>
                                  {keyboards.map((keyboard) => {
                                    return (
                                      <Select.Option value={keyboard._id} key={keyboard._id}>
                                        {keyboard.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {type == 'Profiles' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('switch')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['switch', '_id']}>
                                <Select onChange={setSwitch_} className="default-select" placeholder={t('select-a-switch-component')}>
                                  {switches.map((switch_) => {
                                    return (
                                      <Select.Option value={switch_._id} key={switch_._id}>
                                        {switch_.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {(type == 'Menu' || type == 'Profiles' || type == 'Episode List') && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('dropdown')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['dropdown', '_id']}>
                                <Select onChange={setDropdown} className="default-select" placeholder={t('select-a-dropdown-component')}>
                                  {dropdowns.map((dropdown) => {
                                    return (
                                      <Select.Option value={dropdown._id} key={dropdown._id}>
                                        {dropdown.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {(type.indexOf('Details') > -1 || type == 'Single' || type == 'Slider' || type == 'Guide Horizontal' || type == 'Languages' || type == 'Login' || type == 'Services' || type == 'Video Player' || type == 'Profiles') && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('modal')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['modal', '_id']}>
                                <Select onChange={setModal} className="default-select" placeholder={t('select-a-modal-component')}>
                                  {modals.map((modal) => {
                                    return (
                                      <Select.Option value={modal._id} key={modal._id}>
                                        {modal.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('update-components')}
                      </button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import { Form, notification, Steps as AntdSteps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { PrimaryButton } from '../../../../../common/components/form';
import CompanyFields from '../components/tabs/company';
import ScopeFields from '../components/tabs/scope';
import ServicesFields from '../components/tabs/services';
import SettingsFields from '../components/tabs/settings';
import { useEffectOnce } from 'react-use';
import { Debtor } from '../../../../../interfaces/responses/clients/debtor';
import { StatusCodes } from 'http-status-codes';
import wefact from '../../../../../services/client/wefact';
import clientService from '../../../../../services/client';
import { CustomNotification } from '../../../../../common/components/Notification';
import dayjs from 'dayjs';
import { WeFactProduct } from '../../../../../interfaces/responses/clients/weFactProduct';
import { Product } from '../../../../../interfaces/responses/clients/client';

enum Steps {
  Company,
  Scope,
  Services,
  Settings
}

const AddClient: React.FC = () => {
  const {t} = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<Steps>(Steps.Company);
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [debtors, setDebtors] = useState<Debtor[] | undefined>(undefined);
  const [weFactProducts, setWeFactProducts] = useState<WeFactProduct[] | undefined>(undefined);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const history = useHistory();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');

  useEffectOnce(() => {
    const listDebtors = async () => {
      setIsLoading(true);
      const response = await wefact.listWeFactDebtors();

      if (response?.status_code !== StatusCodes.OK) {
        setIsLoading(false);
        return;
      } 

      const resp = response.data as any;
      setDebtors(resp);
      setIsLoading(false);
    };
    listDebtors().catch(error => { 
      console.log(error);
    });
  });

  useEffectOnce(() => {
    const listWeFactProducts = async () => {
      setIsLoading(true);
      const response = await wefact.listWeFactProducts();

      if (response?.status_code !== StatusCodes.OK) {
        setIsLoading(false);
        return;
      } 

      const resp = response.data as any;
      setWeFactProducts(resp);
      setIsLoading(false);
    };
    listWeFactProducts().catch(error => { 
      console.log(error);
    });
  });

  const onFinish = async (values: any) => {
    const isClientNameUnique = await onCheckClientName(values.name);
    if (isClientNameUnique) {
      var clientValues = {
        ...values,
        weFactProducts: selectedProducts,
        date: dayjs().unix(),
        logo: '',
        customers: [{}],
        subscriptions: [{}],
      };
      const response = await clientService.addClient(clientValues);
      if (response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('success'),
          description: t('client-added-successfully'),
        });
        history.push(redirectUrl);
      }
      else {
        CustomNotification({
          error: response.errors,
        });
      }
    }
  };

  const onCheckClientName = async (name: string): Promise<boolean> => {
    const response = await clientService.checkClient({ _id: name });

    if (response.status_code === StatusCodes.OK) {
      var dirs = response.data;
      
      var test = dirs.dirs.find((n) => n.name == name);
      if (test == undefined) {
        return true;
      } else {
        CustomNotification({
          error: t('this-clientname-already-exists-on-our-systems-please-choose-another-one'),
        });
        return false;
      }
    }
    else {
      return false;
    }
  }

  const getButtonText = (currentStep: Steps) => {
    switch (currentStep) {
      case Steps.Settings:
        return t('save');
      default:
        return t('next');
    }
  };

  const handleClick = async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    if (currentStep === Steps.Settings) {
      form.submit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <div className="card mt-4 pb-4">
            <div className="card-body card-ch-padding">
              <Form {...{ form }} onFinish={onFinish}>
                <AntdSteps
                  current={currentStep}
                  type="navigation"
                  items={[
                    { title: t('company'), disabled: currentStep !== Steps.Company },
                    { title: t('scope'), disabled: currentStep !== Steps.Scope },
                    { title: t('services'), disabled: currentStep !== Steps.Services },
                    { title: t('settings'), disabled: currentStep !== Steps.Settings },
                  ]}
                />
                {debtors && weFactProducts &&
                  <>
                    <CompanyFields isInAddClientMode={true} debtors={debtors} show={currentStep === Steps.Company} />
                    <ScopeFields show={currentStep === Steps.Scope} />
                    <ServicesFields weFactProducts={weFactProducts} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} show={currentStep === Steps.Services} />
                    <SettingsFields show={currentStep === Steps.Settings} />
                  </>
                }
              </Form>
            </div>
          </div>
          <div className="text-right">
            {currentStep !== Steps.Settings &&
              <PrimaryButton loading={isLoading} onClick={handleClick}>
                {getButtonText(currentStep)}
              </PrimaryButton>
            }
            {currentStep === Steps.Settings &&
              <PrimaryButton htmlType="submit" onClick={handleClick}>
                {t('add-client')}
              </PrimaryButton>
            }
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddClient;

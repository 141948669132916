import { Form, notification } from 'antd'
import React, { useContext } from 'react'
import { ContainerWrapper } from '../../../../common/components/containerWrapper'
import { CardForm } from '../../../../common/components/form'
import { PageTitle } from '../../../../common/components/pageTitle'
import { TicketFields } from './shared/form'
import { useAddMutation } from '../../../../services/areas/customers/ticketService'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { BackButton } from '../../../../common/components/BackButton'
import { CustomersContext } from '../../../../contexts/customersContext'
import { useTranslation } from 'react-i18next'

const Add = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/')
  const history = useHistory()
  const {t} = useTranslation();
  const { deployment } = useContext(CustomersContext);
  const { clientName, service: serviceName } = useParams<{clientName : string, service : string}>()
  const [form] = Form.useForm()
  const { isLoading, mutate } = useAddMutation({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName } }, {
    onSuccess: () => {
      notification.success({ message: t('ticket-saved') })
      history.push(redirectUrl)
    },
    onError: () => {
      notification.error({ message: t('something-went-wrong-while-saving-the-ticket-please-try-again-later') })
    }
  })

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-ticket-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('add-ticket')} subtitle={t('manually-add-a-new-ticket')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CardForm {...{ form }} onFinish={(values) => mutate(values)} disabled={isLoading}>
            <TicketFields />
          </CardForm>
        </div>
      </div>
    </ContainerWrapper>
  )
}

export default Add

import { Skeleton, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import axios from 'axios';
import moment from 'moment';
import { getCorrectImage } from '../rails/helpers/helper';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const DetailsVideoStandard = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid text-left" style={{ padding: 0 }}>
      <div
        className="col"
        style={{
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          border: 'none',
          borderRadius: 5,
          backgroundColor:'rgba(0, 0, 0, 0.2)'
        }}
      >
        <div style={{ marginLeft: 40 }}>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Image active={false} style={{ width: 300, height: 75, backgroundColor: '#999' }} />
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Button active={false} size={'large'} style={{ width: 300, height: 15, backgroundColor: '#999' }} />
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Button active={false} size={'large'} style={{ width: 500, height: 60, backgroundColor: '#999' }} />
          </div>
          <div className="row" style={{ marginTop: 20 }}>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'square'} block={true} style={{ width: 150, backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'square'} block={true} style={{ width: 150, backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999' }} />
            </div>
          </div>
          <div className="row" style={{ marginTop: 40 }}>
            <Skeleton.Button active={false} size={'large'} shape={'square'} block={true} style={{ width: 150, backgroundColor: '#999', height: 20, marginBottom: 10 }} />
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', height: 75, width: 75, marginRight: 5 }} />
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', height: 75, width: 75, marginRight: 5 }} />
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', height: 75, width: 75, marginRight: 5 }} />
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', height: 75, width: 75, marginRight: 5 }} />
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', height: 75, width: 75, marginRight: 5 }} />
              <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', height: 75, width: 75, marginRight: 5 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsVideoStandard;

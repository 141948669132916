import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import DetailsChartDesign from '../chartdesigns/details';
import CopyChartDesign from '../chartdesigns/copy';

const DashboardIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/details/:_id`}>
        <DetailsChartDesign />
      </Route>
      <Route path={`${path}/copy/:_id`}>
        <CopyChartDesign />
      </Route>
      <Route path={`${path}`}>
        <Dashboard showActionButtons={true} />
      </Route>
    </Switch>

  );
};

export default DashboardIndex;

import { Placeholder } from '../types/placeholder';

export const renewPlaceholders: Placeholder[] = [
  {
    placeholder: 'renewal_link',
    description:
      'Link for the customer to renew the subscription on the active product',
  },
  {
    placeholder: 'renewal_amount',
    description: 'Price to renew the active product',
  },
];

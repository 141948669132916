export const handleOnClick = ({ keyPath }) => {
  sessionStorage.setItem('expanded-menu', JSON.stringify(keyPath));
};

export const getExpandedMenu = () => {
  const savedMenuState = sessionStorage.getItem('expanded-menu');
  if (savedMenuState) {
    return JSON.parse(savedMenuState);
  }
  return [''];
};

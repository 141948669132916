import { Series_Bundles } from '../../../../../interfaces/forms/areas/content/series/bundles';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Series_Bundles as Response } from '../../../../../interfaces/responses/areas/content/series/bundles';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Series_Bundles_Service {
  Add_Series_Bundles = async (data: Series_Bundles): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.PACKAGES.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Series_Bundless = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.PACKAGES.LIST, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Series_Bundles = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SERIES.PACKAGES.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Series_Bundles = async (data: Series_Bundles): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SERIES.PACKAGES.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Series_Bundles = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SERIES.PACKAGES.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Publish_Series_Bundles = async (data: Series_Bundles): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SERIES.PACKAGES.PUBLISH}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Series_Bundles_Service();

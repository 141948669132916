import { Tabs } from 'antd';
import { useState } from 'react';
import ContentImages from './components/contentImages';
import { useTranslation } from 'react-i18next';

enum EnumTabKeys {
  CONTENT_IMAGES = 'content-images',
  APP_IMAGES = 'app-images',
  CONTENT_EXCELS = 'content-excels',
  EPG_DATA = 'epg-data',
}

export const ExamplesAndStuff = () => {
  const [activeKey, setActiveKey] = useState<string>(EnumTabKeys.CONTENT_IMAGES);
  const { TabPane } = Tabs;
  const {t} = useTranslation();

  
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <h2 className="page-title mt-4">{t('examples-and-stuff')}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mb-2">
          <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={EnumTabKeys.CONTENT_IMAGES}>
            <TabPane tab={t('content-and-app-images')} key={EnumTabKeys.CONTENT_IMAGES}>
              {activeKey === EnumTabKeys.CONTENT_IMAGES && <ContentImages />}
            </TabPane>
            {/* <TabPane tab="App Images" key={EnumTabKeys.APP_IMAGES}>
              {activeKey === EnumTabKeys.APP_IMAGES && <AppImages />}
            </TabPane> */}
            {/* <TabPane tab="Content Excels" key={EnumTabKeys.CONTENT_EXCELS}>
              {activeKey === EnumTabKeys.CONTENT_EXCELS && <ContentExcels />}
            </TabPane>
            <TabPane tab="Epg Data" key={EnumTabKeys.EPG_DATA}>
              {activeKey === EnumTabKeys.EPG_DATA && <EpgData />}
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

import { Template } from '../types/template';

export const orderRenewTemplate: Template = {
  name: 'Renew Order',
  description:
    'This template is used when a customer is prompted to renew his or her order',
  defaultEmailSubject: 'Order Renew',
  defaultEmailContent:
    'Dear {{ first_name }}, please renew your current subscription!',
  defaultSmsContent:
    'Dear {{ first_name }}, please renew your current subscription!',
};

import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, Tooltip } from 'antd';
import { Spin } from '../../spinner';
import { useContext, useEffect, useState } from 'react';
import TranslationModel from './TranslationModal';
import { IFetchedLanguage } from '../../../../interfaces/responses/areas/content/settings/languages/IFetchedLanguagesResponse';
import { useEffectOnce } from 'react-use';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { useTranslation } from 'react-i18next';

const Translations = (params: { service_name: any; clientName: any; setTranslations: any; data?: any }) => {
  const [languageToUpdate, setLanguageToUpdate] = useState<IFetchedLanguage>();
  const [showLanguageUpdateModal, setShowLanguageUpdateModal] = useState<boolean>(false);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [languagesLocal, setLanguagesLocal] = useState<IFetchedLanguage[]>();
  const { deployment } = useContext(AppBuilderContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const { t } = useTranslation();

  const LanguagesColumns = [
    {
      title: t('language'),
      dataIndex: 'name',
    },
    {
      title: t('description'),
      dataIndex: 'description',
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      width: 100,
      render: (tags: any, row: IFetchedLanguage) => (
        <span className="justify-content-end">
          <Tooltip placement="top" title={`Edit`}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={(event) => {
                setShowLanguageUpdateModal(!showLanguageUpdateModal);
                setLanguageToUpdate(row);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  const getCMSLanguages = async () => {
    setIsLoadingLanguages(true);
    var languagesIn = languagesDeployment as any;
    let languages = languagesIn.map((language: any, index: any) => {
      language.name = language.name;
      language.description = language.description == undefined ? t('edit-to-add-your-translated-description') : language.description;
      language.key = language._id;
      return language;
    });
    params.setTranslations(languages);
    setLanguagesLocal(languages);
    setIsLoadingLanguages(false);
  };

  useEffect(() => {
    if (params.data != undefined) {
      setLanguagesLocal(params.data);
    }
  }, [params.data]);

  useEffectOnce(() => {
    getCMSLanguages();
  });

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3"></h4>
      <div className="row">
        <div className="col-md-12">
          {showLanguageUpdateModal && languageToUpdate && (
            <TranslationModel
              initialData={languageToUpdate}
              onCancel={() => {
                setShowLanguageUpdateModal(!showLanguageUpdateModal);
              }}
              onSubmit={(values: IFetchedLanguage) => {
                params.setTranslations((pre: any) =>
                  pre.map((language: any) => {
                    if (language.key === values._id) {
                      language.description = values.description;
                    }
                    return language;
                  })
                );
                setShowLanguageUpdateModal(!showLanguageUpdateModal);
              }}
              visible={showLanguageUpdateModal}
            />
          )}

          <Table
            rowKey={(languagesLocal) => languagesLocal.name}
            loading={isLoadingLanguages}
            columns={LanguagesColumns}
            dataSource={languagesLocal}
            key={JSON.stringify(languagesLocal)}
            pagination={{
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) => {
                return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Translations;

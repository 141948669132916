import { Form, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Translations from '../Translations/Translations';
import Menus from '../Menus';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  menus: [] | any
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [translations, setTranslations] = useState([] as any);
  const [translationsLocal, setTranslationsLocal] = useState([] as any);
  const [contentType, setContentType] = useState('');
  const [loadedMenus, setLoadedMenus] = useState([] as any);
  const [showTitle, setShowTitle] = useState(true);

  const onSubmitLocal = (values: any) => {
    var translations_ = [] as any;
    translationsLocal.forEach((element: any) => {
      translations_.push({
        language: element.language,
        title: element.title,
      });
    });

    var content1 = {
      type: contentType, //Favorites, Watchlist, Lists
      menu: loadedMenus,

      list: {
        show_title: showTitle,
        title: translations_ ?? [],
      },
    };
    let dataOut = {
      ...data,
      content: content1,
    };

    onSubmit(dataOut, type, props.index);
    //}
  };

  useEffect(() => {
    async function fetchData() {
      if (data.content != undefined) {
        setContentType(data.content.type);
        form.setFieldsValue(data.content);
      }
    }
    fetchData();
  }, [data, form]);

  function setData(values: any) {
    setLoadedMenus(values);
  }

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-content-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('menu-items')}</h5>
                      <Menus setData={setData} data={loadedMenus} viaPageBuilder={true} menus={props.menus}></Menus>
                    </div>
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('rails-title')}</h5>
                      <p> </p>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('menu-title')}</h5>
                          <p> </p>

                          <div className="row pt-2">
                            <label className="col-md-4 col-form-label">{t('show-title')}</label>

                            <div className="col">
                              <Form.Item className="form-group pb-0 " name={['list', 'show_title']}>
                                <Switch className="ch-switch" checked={showTitle} defaultChecked={showTitle} onChange={() => setShowTitle(!showTitle)} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>

                          <div className="row p-3">
                            <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <Translations setTranslations={setTranslationsLocal} data={translations}></Translations>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-content')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import { useContext, useEffect, useState } from 'react';
import { Card } from 'antd';
import { IMSNameParams } from '../../../dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { Item } from '../../../../common/components/form';
import { useTranslation } from 'react-i18next';
import { ErrorReporting } from '@mwaretv/database/build/backend/models/reporting/apps/error';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import APPERROR_SERVICE from '../../../../services/areas/appbuilder/applications/apperrors';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat);

export const AppErrorDetails = () => {
  const { id } = useParams<IMSNameParams & { id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [appError, setAppError] = useState<ErrorReporting | undefined>(undefined);
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData: TvmsApiRequestData = {
          urlParams: {
            id: id
          },
          queryParams: {
            clientName: clientName,
            deploymentName: deployment?.name ?? "",
          }
        }

        const responseData = await APPERROR_SERVICE.Get_AppError(requestData);

        setAppError(responseData?.data);
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-apperror-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mt-3 mb-3">
          <PageTitle title={t('apperror-details')} subtitle={''} />
        </div>
      </div>
      {isError && <Error />}
      {isLoading && <Loading />}
      {!isLoading && appError && <Details {...{ appError }} />}
    </ContainerWrapper>
  );
};

const Details = ({ appError }: { appError: ErrorReporting }) => {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
              <Item className="col-md-12" label={t('timestamp')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.timestamp ? dayjs.unix(appError.timestamp).format('lll') : undefined} </div>
              </Item>
              <Item className="col-md-12" label={t('service')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.service} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-error-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.errorType} </div>
              </Item>
              <Item className="col-md-12" label={t('error')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.error} </div>
              </Item>
              <h4 className="text-primary mt-5 mb-3">{t('content')}</h4>
              <Item className="col-md-12" label={t('chart-design-content-main-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.content?.mainType} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-content-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.content?.contentType} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-content-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.content?.name} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-content-subname')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.content?.subname} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-content-provider')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.content?.provider} </div>
              </Item>
              <h4 className="text-primary mt-5 mb-3">{t('location')}</h4>
              <Item className="col-md-12" label={t('chart-design-location-city')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.location?.city} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-location-country')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.location?.country} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-location-longitude')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.location?.longitude} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-location-latitude')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.location?.latitude} </div>
              </Item>
              <h4 className="text-primary mt-5 mb-3">{t('device')}</h4>
              <Item className="col-md-12" label={t('chart-design-device-model')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.device?.model} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-device-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.device?.type} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-device-formfactor')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.device?.formFactor} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-device-os')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.device?.os} </div>
              </Item>
              <h4 className="text-primary mt-5 mb-3">{t('network')}</h4>
              <Item className="col-md-12" label={t('ip-address')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.network?.ipAddress} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-network-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.network?.type} </div>
              </Item>
              <Item className="col-md-12" label={t('chart-design-network-provider')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{appError.network?.provider} </div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const Loading = () => {
  return (
    <div className="row text-center h-100 w-100">
      <div className="col-12 align-self-center">
        <Spin size="large" spinning={true} />
      </div>
    </div>
  );
};

const Error = () => {
  const { t } = useTranslation();
  return <div style={{ fontWeight: 'bold' }}>{t('something-went-wrong-sad-smiley')}</div>;
};

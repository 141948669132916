import { Form, Input, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import SelectedPng from '../../../../assets/images/appbuilder/Selected.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: {}, name: string) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [borderColor, setBorderColor] = useState('#000000');
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const [textColor, setTextColor] = useState('#ffffff');
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [textType, setTextType] = useState("Normal");
  const [textBold, setTextBold] = useState(false);

  useEffect(() => {
    
    if (data != undefined) {
      setBackgroundColor(data.background_color);
      setBorderColor(data.border_bottom_color);
      setTextBold(data.text_bold);
      setTextColor(data.text_color);
      setTextType(data.text_size);

      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var selected = {
      element: data.element,
      background_color: backgroundColor,
      border_radius: values.border_radius ?? 0,

      text_color: textColor,
      text_size: textType, //Small, Normal, Large, Extra Large
      text_bold: textBold,

      border_bottom_width: values.border_bottom_width ?? 0,
      border_bottom_color: borderColor,
    };

    onSubmit(selected, type);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-selected-items')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row" style={{ padding: 15 }}>
                    <div className="col card-bg-light p-4 mb-2 mr-4" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('selected')}</h5>
                      <p>{t('settings-that-define-the-selected-object-such-as-background-color-borders-etc')}</p>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('background')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showBackgroundColorPicker}
                              color={backgroundColor}
                              onChange={(color) => {
                                setBackgroundColor(color);
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              setVisible={setShowBackgroundColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('border')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <Form.Item className="form-group pb-0 col" name="border_bottom_width">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            WIDTH
                          </div>
                        </div>
                        <div className="col">
                          <div className="col" style={{ paddingRight: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setShowBorderColorPicker(!showBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: borderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={showBorderColorPicker}
                                color={borderColor}
                                onChange={(color) => {
                                  setBorderColor(color);
                                  setShowBorderColorPicker(!showBorderColorPicker);
                                }}
                                setVisible={setShowBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col" style={{ paddingRight: 0 }}></div>
                      </div>
                      {/* <div className='row mb-3'>
                        <label className="col-md-4 col-form-label">Text Type</label>
                        <div className='col'>
                          <Form.Item
                            className="form-group pb-0"
                            name={["text_size"]}>
                            <Select
                              onChange={setTextSizeLocal}
                              className="default-select"
                              placeholder="- Select Type -">
                              <Select.Option value={'Small'} key={'Small'}>Small</Select.Option>
                              <Select.Option value={'Normal'} key={'Normal'}>Normal</Select.Option>
                              <Select.Option value={'Large'} key={'Large'}>Large</Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div> */}

                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('text-color')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowTextColorPicker(!showTextColorPicker);
                              }}
                              style={{
                                backgroundColor: textColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showTextColorPicker}
                              color={textColor}
                              onChange={(color) => {
                                setTextColor(color);
                                setShowTextColorPicker(!showTextColorPicker);
                              }}
                              setVisible={setShowTextColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('text-bold')}</label>
                        <div className="col">
                          <Form.Item className="form-group pb-0 " name="text_bold">
                            <Switch className="ch-switch" checked={textBold} defaultChecked={false} onChange={() => setTextBold(!textBold)} />
                          </Form.Item>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                    </div>
                    <div className="col card-bg-light p-4 mb-2 ml-1" style={{ paddingLeft: 0 }}>
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={SelectedPng} alt={''}></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                      {t('update-selected')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import { Button } from 'antd';
import { useContext } from 'react';
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { TVMSRoles } from '../../../constants/TVMSRoles';
// import SideBar from "../../../navigation/sidebar";
// import Header from "../../../navigation/header";
// import Footer from "../../../footer";
import { AuthContext } from '../../../context_api/AuthContext';
import { isInRoles } from '../../components/VisibleForRole';

const UnauthorizedPage = () => {
  const history = useHistory();

  return (
    <div>
      <h3>You are not authorized to access this page.</h3>
      <Button onClick={() => history.goBack() }>
        <h4>
          <u>Go Back</u>
        </h4>
      </Button>
      <Button onClick={() => history.push('/') }>
        <h4>
          <u>Go to Dashboard</u>
        </h4>
      </Button>
    </div>
  );
};

interface PrivateRouteProps extends RouteProps {
  component: any;
  allowedRoles?: string[];
  allowedClient?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { state: AuthState } = useContext(AuthContext);
  const { component: Component, allowedRoles, ...rest } = props;
  const role = AuthState.user?.role;

  const roleAllowed = allowedRoles === undefined || isInRoles(role, allowedRoles as TVMSRoles[]);
  const clientAllowed = props.allowedClient === undefined || (AuthState.user && AuthState.user?.clients?.some((client) => client.name === props.allowedClient));
  const isAPIRole = isInRoles(role, [TVMSRoles.ServiceAPI, TVMSRoles.DeploymentAPI]);
  const isAllowedAccess = roleAllowed && clientAllowed && !isAPIRole;

  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = AuthState.isAuthenticated;

        if (!isAuthenticated) {
          return <Redirect to={{ pathname: '/login' }} />;
        }
        return isAllowedAccess ? <Component {...props} /> : <UnauthorizedPage />;
      }}
    />
  );
};

export default PrivateRoute;

import Widescreen from '../../../../../../assets/images/examples/widescreen.png';
import Tile from '../../../../../../assets/images/examples/tile.png';
import Square from '../../../../../../assets/images/examples/square.png';
import Poster from '../../../../../../assets/images/examples/poster.png';
import Portrait from '../../../../../../assets/images/examples/portrait.png';
import Logo from '../../../../../../assets/images/examples/logo.png';
import Landscape from '../../../../../../assets/images/examples/landscape.png';
import Brandlogo from '../../../../../../assets/images/examples/brandlogo.png';
import Brandicon from '../../../../../../assets/images/examples/brandicon.png';
import Brandfavicon from '../../../../../../assets/images/examples/brandfavicon.png';
import Background from '../../../../../../assets/images/examples/background.png';
import Adsverticalwide from '../../../../../../assets/images/examples/adsverticalwide.png';
import Adshoritzontal from '../../../../../../assets/images/examples/adshorizontal.png';
import { ImagesProps } from './image.interface';




export const images: ImagesProps[] = [
  {
    title: "Widescreen 640x360",
    image: Widescreen,
    fileName: 'widescreen',
  },
  {
    title: "Tile 384x216",
    image: Tile,
    fileName: 'tile',
  },
  {
    title: "Square 300x300",
    image: Square,
    fileName: 'square',
  },
  {
    title:"Poster 340x500",
    image: Poster,
    fileName: 'poster',
  },
  {
    title: "Portrait 1080x1920",
    image: Portrait,
    fileName: 'portrait',
  },
  {
    title: "Logo 300x100",
    image: Logo,
    fileName: 'logo',
  },
  {
    title: "Landscape 1920x1080",
    image: Landscape,
    fileName: 'landscape',
  },
  {
    title: "Brandlogo 200x100",
    image: Brandlogo,
    fileName: 'brandlogo',
  },
  {
    title: "Brandicon 200x200",
    image: Brandicon,
    fileName: 'brandicon',
  },
  {
    title: "Brandfavicon 50x50",
    image: Brandfavicon,
    fileName: 'brandfavicon',
  },

  {
    title: "Background 1920x1080",
    image: Background,
    fileName: 'background',
  },
  {
    title: "Adsverticalwide 160x600",
    image: Adsverticalwide,
    fileName: 'adsverticalwide',
  },
  {
    title: "Adshoritzontal 729x90",
    image: Adshoritzontal,
    fileName: 'adshoritzontal',
  },
];

import { Skeleton, Image } from 'antd';
import img1 from '../../../../../../assets/numbers/1.png';
import img2 from '../../../../../../assets/numbers/2.png';
import img3 from '../../../../../../assets/numbers/3.png';
import img4 from '../../../../../../assets/numbers/4.png';
import img5 from '../../../../../../assets/numbers/5.png';
import img6 from '../../../../../../assets/numbers/6.png';
import img7 from '../../../../../../assets/numbers/7.png';
import img8 from '../../../../../../assets/numbers/8.png';
import img9 from '../../../../../../assets/numbers/9.png';
import img10 from '../../../../../../assets/numbers/10.png';

export const GetRailsElements = (props: { props; color; isActive; height; width; type; form; isRound? }) => {
  return (
    <>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={0}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={1}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={2}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={3}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={4}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={5}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={6}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={7}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={8}></ElementRails>
      <ElementRails props={props.props} color={props.color} isActive={props.isActive} height={props.height} width={props.width} isRound={props.isRound} type={props.type} form={props.form} index={9}></ElementRails>
    </>
  );
};

export const ElementRails = (props: { props; isActive; color; height; width; type; form; index; isRound? }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: props.props.application.theme?.images.background,
          borderRadius: props.isRound ? 100 : props.props.application.theme?.images.radius,
          padding: props.props.application.theme?.images.margin ?? 0,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!props.isRound && <Skeleton.Image active={props.isActive} style={{ width: props.width - (props.props.application.theme?.images.margin * 2 ?? 0), height: props.height - (props.props.application.theme?.images.margin * 2 ?? 0), backgroundColor: props.color, float: 'left', borderRadius: props.props.application.theme?.images.radius }} />}
        {props.isRound && <Skeleton.Image active={props.isActive} style={{ borderRadius: 100, width: props.width - (props.props.application.theme?.images.margin * 2 ?? 0), height: props.height - (props.props.application.theme?.images.margin * 2 ?? 0), backgroundColor: props.color, float: 'left' }} />}
       
        {props.form != 'round' && props.form != 'top10' && props.props.data.texts.placement_text != 'Above' && <div>{getExtraTexts(props.width - (props.props.application.theme?.images.margin * 2 ?? 0), props.type, props.form)}</div>}
        {props.form != 'round' && props.form != 'top10' && props.props.data.texts.placement_text == 'Above' && (
          <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', width: props.width, bottom: props.props.application.theme?.images.margin * 1 ?? 0 }}>
            <div style={{ position: 'absolute', bottom:0 }}>{getExtraTexts(props.width - (props.props.application.theme?.images.margin * 4 ?? 0), props.type, props.form)}</div>
          </div>
        )}
      </div>

      {props.form == 'top10' && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', width: props.width, right: 5, bottom: (props.props.application.theme?.images.margin ?? 0) + 5 + props.width / 2 }}>
          <div style={{ position: 'absolute' }}>{getTop10(props.index + 1)}</div>
        </div>
      )}
    </div>
  );
};
export const ImageRails = (props: { props; height; width; item; image; type; form; index; isRound? }) => {
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: props.props.application.theme?.images.background,
          borderRadius: props.isRound ? 100 : props.props.application.theme?.images.radius,
          padding: props.props.application.theme?.images.margin ?? 0,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!props.isRound && props?.image?.indexOf('http') < 0 && <Image src={'https://cloudtv.akamaized.net/' + props.image} style={{ width: props.width - (props.props.application.theme?.images.margin * 2 ?? 0), height: props.height - (props.props.application.theme?.images.margin * 2 ?? 0), float: 'left', borderRadius: props.props.application.theme?.images.radius }} />}
        {!props.isRound && props?.image?.indexOf('http') > -1 && <Image src={props.image} style={{ width: props.width - (props.props.application.theme?.images.margin * 2 ?? 0), height: props.height - (props.props.application.theme?.images.margin * 2 ?? 0), float: 'left', borderRadius: props.props.application.theme?.images.radius }} />}
       
        {props.isRound && props?.image?.indexOf('http') < 0 && <Image src={'https://cloudtv.akamaized.net/' + props.image} style={{ width: props.width - (props.props.application.theme?.images.margin * 2 ?? 0), height: props.height - (props.props.application.theme?.images.margin * 2 ?? 0), float: 'left', borderRadius:100}} />}
        {props.isRound && props?.image?.indexOf('http') > -1 && <Image src={props.image} style={{ width: props.width - (props.props.application.theme?.images.margin * 2 ?? 0), height: props.height - (props.props.application.theme?.images.margin * 2 ?? 0), float: 'left',borderRadius:100}} />}
       
        {props.form != 'round' && props.form != 'top10' && props.props.data.texts.placement_text != 'Above' && <div>{getExtraTexts(props.width - (props.props.application.theme?.images.margin * 2 ?? 0), props.type, props.form)}</div>}
        {props.form != 'round' && props.form != 'top10' && props.props.data.texts.placement_text == 'Above' && (
          <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', width: props.width, bottom: props.props.application.theme?.images.margin * 1 ?? 0 }}>
            <div style={{ position: 'absolute', bottom:0 }}>{getExtraTexts(props.width - (props.props.application.theme?.images.margin * 4 ?? 0), props.type, props.form)}</div>
          </div>
        )}
      </div>

      {props.form == 'top10' && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', width: props.width, right: 5, bottom: (props.props.application.theme?.images.margin ?? 0) + 5 + props.width / 2 }}>
          <div style={{ position: 'absolute' }}>{getTop10(props.index + 1)}</div>
        </div>
      )}
    </div>
  );
};

export const getExtraTexts = (width, type, form) => {
  switch (type) {
    case 'Channels':
      if (form == 'Wide') {
        return (
          <div className="column text-left" style={{ marginTop: 10 }}>
            <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
            <div style={{ width: width / 2, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
            <div style={{ width: width / 2, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
          </div>
        );
      } else {
        return (
          <div className="column text-left" style={{ marginTop: 10 }}>
            <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
          </div>
        );
      }
    case 'CatchupTV':
      if (form == 'Wide') {
        return (
          <div className="column text-left" style={{ marginTop: 10 }}>
            <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
            <div style={{ width: width / 2, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
            <div style={{ width: width / 2, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
          </div>
        );
      } else {
        return (
          <div className="column text-left" style={{ marginTop: 10 }}>
            <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
          </div>
        );
      }
    case 'Recordings':
      if (form == 'Wide') {
        return (
          <div className="column text-left" style={{ marginTop: 10 }}>
            <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
            <div style={{ width: width / 2, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
          </div>
        );
      } else {
        return (
          <div className="column text-left" style={{ marginTop: 10 }}>
            <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
          </div>
        );
      }
    case 'Movies':
      return (
        <div className="column text-left" style={{ marginTop: 10 }}>
          <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
        </div>
      );
    case 'Series':
      return (
        <div className="column text-left" style={{ marginTop: 10 }}>
          <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
        </div>
      );
    case 'Courses':
      return (
        <div className="column text-left" style={{ marginTop: 10 }}>
          <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 5 }} />
        </div>
      );
    case 'Shorts':
      return (
        <div className="column text-left" style={{ marginTop: 10 }}>
          <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
        </div>
      );
    case 'Music':
      return (
        <div className="column text-left" style={{ marginTop: 10 }}>
          <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
        </div>
      );
    case 'Podcasts':
      return (
        <div className="column text-left" style={{ marginTop: 10 }}>
          <div style={{ width: width, height: 15, backgroundColor: '#bfbfbf', borderRadius: 3 }} />
        </div>
      );
    default:
      break;
  }
};
export const getTitle = (element) => {
  return <div style={{ color: '#ffffff', fontSize: 25, fontWeight: 'bold' }}>{element.content?.list?.title[0].title}</div>;
};

export const getCorrectImage = (data: any, screenName) => {
  if (screenName == 'Channels' || screenName == 'Cams') {
    if (screenName == 'Channels') {
      return getLiveImage(data?.streams.channels.urls.standard_url);
    }
    if (screenName == 'Cams') {
      return getLiveImage(data?.streams.cams.urls.standard_url);
    }
  } else {
    return data?.images?.widescreen;
  }
};

export const getLiveImage = (url) => {
  var splitUrl = url.toString().split('/');
  var image = splitUrl[0] + '//' + splitUrl[2] + '/' + splitUrl[3] + '/preview.jpg';
  return image;
};

const getTop10 = (index) => {
  switch (index) {
    case 1:
      return <Image src={img1} style={{ width: 49, height: 74 }}></Image>;
    case 2:
      return <Image src={img2} style={{ width: 49, height: 74 }}></Image>;
    case 3:
      return <Image src={img3} style={{ width: 49, height: 74 }}></Image>;
    case 4:
      return <Image src={img4} style={{ width: 49, height: 74 }}></Image>;
    case 5:
      return <Image src={img5} style={{ width: 49, height: 74 }}></Image>;
    case 6:
      return <Image src={img6} style={{ width: 49, height: 74 }}></Image>;
    case 7:
      return <Image src={img7} style={{ width: 49, height: 74 }}></Image>;
    case 8:
      return <Image src={img8} style={{ width: 49, height: 74 }}></Image>;
    case 9:
      return <Image src={img9} style={{ width: 49, height: 74 }}></Image>;
    case 10:
      return <Image src={img10} style={{ width: 49, height: 74 }}></Image>;

    default:
      break;
  }
};

import { Tabs } from 'antd';

import { useParams } from 'react-router-dom';
import Table from '../../../../../common/components/Content/Table';
import { IMSNameParams } from '../../../../dashboard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Linking = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>('1');
  const { t } = useTranslation();

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <h2 className="page-title">{t('epg-linking')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('link-you-epg-provider-to-your-tv-channels-and-radio-stations')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={'1'}>
            <TabPane tab={t('channels')} key={'1'}>
              <div className="p-4">
                <Table service_name={service_name} clientName={clientName} type={'epgs_channels'} variant={'normal'}></Table>
              </div>
            </TabPane>
            <TabPane tab={t('radio-stations')} key={'2'}>
              <div className="p-4">
                <Table service_name={service_name} clientName={clientName} type={'epgs_radios'} variant={'normal'}></Table>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Linking;

import { Layout, Menu } from 'antd';
import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { DrawerContext } from '../../../context_api/DrawerContext';
import MWare_Logo from '../../../assets/images/logo.png';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { CustomersContextProvider } from '../../../contexts/customersContext';
import { faSquareUser, faHeadset, faDashboard, faBadgePercent, faUsersViewfinder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Affiliates from './affiliates';
import Coupons from './coupons';
import Dashboard from './dashboard';
import { getExpandedMenu, handleOnClick } from '../../../utils/menu';
import { useEffectOnce, useLocation } from 'react-use';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { hasRole } from '../../../utils/Auth/role.auth';
import { useTranslation } from 'react-i18next';

const Customer = () => {
  const location = useLocation();
  const { state: DrawerState } = React.useContext(DrawerContext);
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  var locSplit = location?.pathname?.split('/');
  const history = useHistory();
  const {t} = useTranslation();
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'customers') {
      history.push(url + '/customers/list');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  return (
    <Layout>
      <Layout.Sider width="250" trigger={null} collapsible collapsed={DrawerState.is_collapsed}>
        <div style={{ justifyContent: 'center', alignContent: 'center', display: 'flex', padding: 40, backgroundColor: '#444',            paddingTop: 55,
            paddingBottom: 55,marginBottom: 17 }}>
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={t('mware')} alt={t('mware')} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={t('mware')} alt={t('mware')} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
            </a>
          )}
        </div>
        <Menu
          mode="inline"
          defaultOpenKeys={getExpandedMenu()}
          onClick={handleOnClick}
          items={[
            isClient
              ? {
                  label: (
                    <div className="height-60 d-flex align-items-center">
                      <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />
                      <span className="ml-3">{t('dashboard')}</span>
                    </div>
                  ),
                  key: 'dashboard',
                  children: [
                    {
                      label: (
                        <Link className="height-60 d-flex align-items-center" to={`${url}`}>
                          <span className="ml-3">{t('dashboard')}</span>
                        </Link>
                      ),
                      key: 'appbuilder-dashboard',
                    },
                  ],
                }
              : {
                  label: <></>,
                  key: 'empty',
                },
            {
              label: (
                <div className="height-60 d-flex align-items-center">
                  <FontAwesomeIcon icon={faUsersViewfinder} size="lg" style={{ width: '20', height: '20' }} />
                  <span className="ml-3">{t('affiliates')}</span>
                </div>
              ),
              key: 'affiliates',
              children: [
                {
                  label: (
                    <Link className="height-60 d-flex align-items-center" to={`${url}/affiliates/list`}>
                      <span className="ml-3">{t('affiliates')}</span>
                    </Link>
                  ),
                  key: 'affiliates_',
                },
              ],
            },
            {
              label: (
                <div className="height-60 d-flex align-items-center">
                  <FontAwesomeIcon icon={faBadgePercent} size="lg" style={{ width: '20', height: '20' }} />
                  <span className="ml-3">{t('coupons')}</span>
                </div>
              ),
              key: 'coupons',
              children: [
                {
                  label: (
                    <Link className="height-60 d-flex align-items-center" to={`${url}/coupons/list`}>
                      <span className="ml-3">{t('coupons')}</span>
                    </Link>
                  ),
                  key: 'coupons_',
                },
              ],
            },
          ]}
        />
      </Layout.Sider>
      <div  style={{ paddingLeft: 0, width:'100%' }}>
        <div>
          <IMSDashboardHeader
            {...{
              isSideBarShow: true,
              path,
            }}
          />
        </div>
        <div>
          {/* {isLoading && <Spin />} */}
          {isError && <span>{t('something-went-wrong')}</span>}
          {!isLoading && !isError && data && (
            <CustomersContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
              <Switch>
                <Route path={`${path}/affiliates`}>
                  <Affiliates />
                </Route>
                <Route path={`${path}/coupons`}>
                  <Coupons />
                </Route>

                <Route path={`${path}/`}>{isClient && <Dashboard />}</Route>
              </Switch>
            </CustomersContextProvider>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Customer;

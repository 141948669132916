import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Translations from './translations';
import Edit from './edit';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit/:_id`}>
        <Edit />
      </Route>
      <Route path={`${path}`}>
        <Translations />
      </Route>
    </Switch>
  );
};

export default Index;

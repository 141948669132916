import React from 'react';
import { ExternalFields, GeneralFields as BaseGeneralFields } from '../../shared/baseProductFields';
import { CardForm, FormSection, InputNumber, Item, Select } from '../../../../../common/components/form';
import { Form } from 'antd';
import { ServiceType } from '@mwaretv/database/build/backend/enums/billing/serviceTypes';
import { useTranslation } from 'react-i18next';

export { CardForm as Form, ExternalFields };

export const FormFields = () => {
  return (
    <>
      <GeneralFields />
      <ExternalFields />
    </>
  );
};

export const GeneralFields = () => {
  const {t} = useTranslation();
  return (
    <>
      <BaseGeneralFields>
        <Item label={t('service-type')} name="type" extra={t('choose-the-type-of-service-service-types-can-be-catchuptv-or-recordings')}>
          <Select
            className="default-select"
            options={Object.values(ServiceType).map((x) => ({
              label: x.charAt(0).toUpperCase() + x.slice(1),
              value: x,
            }))}
          />
        </Item>
      </BaseGeneralFields>
      <TypeSettings />
    </>
  );
};

export const TypeSettings = () => {
  const serviceType = Form.useWatch('type');
  const {t} = useTranslation();

  return (
    <>
      {serviceType === 'CatchupTV' && (
        <FormSection title={t('catchuptv-settings')}>
          <Item label={t('max-days-stored')} name={['type_settings', 'max_days']}>
            <InputNumber />
          </Item>
        </FormSection>
      )}
      {serviceType === "Recordings" && (
        <FormSection title={t('recording-settings')}>
          <Item label={t('storage-hours')} name={['type_settings', 'storage_hours']}>
            <InputNumber />
          </Item>
        </FormSection>
      )}
    </>
  );
};

import { Template } from '../types/template';

export const newCustomerTemplate: Template = {
  name: 'New Customer',
  description: 'This template is used when a new user registers',
  defaultEmailSubject: 'Welcome {{ first_name }}',
  defaultEmailContent:
    'Welcome {{ first_name }}, you can login to your account here {{ link portal }}',
  defaultSmsContent:
    'Welcome {{ first_name }}, you can login to your account here {{ link portal }}',
};

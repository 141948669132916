import { Button, Space, Switch } from "antd";
import React, { ForwardedRef, forwardRef, useState } from "react";
import { FilterProps } from "react-table";
import { IReactBCTableColumn } from "../Interfaces/IReactBCTableColumn.interface";
import { useTranslation } from "react-i18next";

const ReactBCBooleanFilter = forwardRef(<T extends {}>({ column }: FilterProps<T>, filterRef: ForwardedRef<HTMLDivElement>) => {
  const { setFilter, customData } = column;
  const {t} = useTranslation();
  let passedProps = customData.parentColumn as IReactBCTableColumn<T>;
  if (!passedProps.filterOption) passedProps.filterOption = "eq";
  const [isChecked, setChecked] = useState<boolean>();
  return (
    <div ref={filterRef} style={{ backgroundColor: "#ffffff", border: "1px solid #1ac884", padding: 8 }}>
      <div>
        <Switch checked={isChecked} onChange={(checked: boolean) => setChecked(checked)} />
      </div>
      <Space style={{ marginTop: "10px" }}>
        <Button type="primary" onClick={() => setFilter({ filterValue: isChecked, filterType: "boolean" })} size="small">
          {t('search')}
        </Button>
        <Button
          onClick={() => {
            setChecked(false);
            setFilter(undefined);
          }}
          size="small"
        >
          {t('reset')}
        </Button>
      </Space>
    </div>
  );
});

export default ReactBCBooleanFilter;

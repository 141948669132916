import Enumerable from "linq";
import { User } from "../../interfaces/forms/users/user";
import { IUserInfo } from "../../interfaces/responses/users/IUserInfoResponse";
import { Data } from '../../interfaces/responses/users/user';

export const hasRole = (
  user: Data,
  client: string,
  roles: string[]
): boolean => {
  if (user.clients) {
    //let clients = Enumerable.from(user.clients);
    // let searchedClient = clients
    //   .where((x) => x.name.toLowerCase() === client.toLowerCase())
    //   .firstOrDefault();
    // if (searchedClient) {
    //   let all_roles = searchedClient.roles.map((x) => x.name);
    //   return roles.some((role: string) => all_roles.includes(role));
    // }
    return true;
  } else {
    return false;
  }
};

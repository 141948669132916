import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Service } from '../../../../interfaces/forms/areas/billing/services/service';
import { Button, Col, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemo, faTrashCan, faUpload } from '@fortawesome/pro-regular-svg-icons';
import * as ServiceService from '../../../../services/areas/billing/serviceService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { BillingContext } from '../../../../contexts/billingContext';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';

const Services: React.FC = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = React.useContext(BillingContext);
  const history = useHistory();
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const [isLoading_, setIsLoading_] = useState(true);
  const {t} = useTranslation();
  
  const query = useQuery(
    ['services', pagination.page, pagination.limit],
    async () => {
      return (
        await ServiceService.list({
          params: {
            cms: deployment?.name || '',
            crm: service?.name || '',
            instance: clientName,
            page: pagination.page,
            limit: pagination.limit,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  const { isLoading, isError, data } = query;

  const deleteMutation = useMutation(
    (id: string) => {
      return ServiceService.del({ params: { instance: clientName, crm: service?.name || '', cms: deployment?.name || '' }, id });
    },
    {
      
      onSuccess: (data) => {
        notification.success({ message: t('service-deleted') });
        query.refetch();
      },
      onError: () => {
        notification.error({ message: t('delete-failed') });
      },
    }
  );

  const publishService = async (row) => {
    setIsLoading_(true);
    const response = await ServiceService.publishService({
      instance: clientName,
      crm: service?.name || '',
      cms: deployment?.name || '',
      _id: row,
      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).PRODUCTS.PRODUCTS,
    });
    if (response && response.status_code === StatusCodes.OK) {
      setIsLoading_(false);
    }
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('services')} subtitle={t('manage-services-to-offer-your-customers')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong-sad-smiley')}</div>}
          {!isError && data && (
            <ReactBCTable
              isLoading={isLoading && isLoading_}
              totalRecords={data.totalDocs}
              pageSize={data.limit}
              tableId={'billing_services_table'}
              columns={[
                {
                  title: t('name'),
                  dataKey: 'name',
                  width: '100%',
                  cell: (row: Service) => {
                    return <div>{row.name}</div>;
                  },
                },
                {
                  title: t('actions'),
                  dataKey: 'actions',

                  cell: (row: Service) => {
                    return (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        <Button
                          type="text"
                          className="mx-2"
                          disabled={deleteMutation.isLoading}
                          onClick={() => {
                            deleteMutation.mutate(row._id);
                          }}
                        >
                          <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                          <div>{t('delete')}</div>
                        </Button>
                        <Button
                          type="text"
                          className="mx-2"
                          disabled={deleteMutation.isLoading}
                          onClick={() => {
                            publishService(row._id);
                          }}
                        >
                          <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faUpload} />
                          <div>{t('publish')}</div>
                        </Button>
                        <Button
                          type="text"
                          className="mx-2"
                          disabled={deleteMutation.isLoading}
                          onClick={() => {
                            history.push(`${url}/${row._id}`);
                          }}
                        >
                          <FontAwesomeIcon className="action-icon" icon={faMemo} />
                          <div>{t('open-details')}</div>
                        </Button>
                      </Col>
                    );
                  },
                },
              ]}
              data={data.docs || []}
              currentPage={data.page}
              onChange={(page) => {
                setPagination({ ...pagination, page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};

const ActionBar: React.FC = () => {
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <div className="d-flex justify-content-center justify-content-md-end align-items-start">
      <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
        {t('add-service')}
      </Link>
    </div>
  );
};

export default Services;

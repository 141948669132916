"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTimeKeysToFill = exports.Periods = exports.PaymentStatus = exports.CustomerProductStatus = exports.CustomerType = exports.CustomerSubscriptionStatus = exports.CustomerStatus = exports.mainType = exports.contentType = exports.CommunicationType = exports.CommunicationSource = exports.pageType = exports.errorType = exports.actionType = exports.TransactionSubTypes = exports.ProductVariant = exports.ProductType = exports.TransactionSource = exports.TransactionType = exports.FilterAndOr = exports.AvailableChartTypesReporting = exports.AvailableEntitiesReporting = exports.ChartDesignFilterDataTypes = void 0;
var ChartDesignFilterDataTypes;
(function (ChartDesignFilterDataTypes) {
    ChartDesignFilterDataTypes["STRING"] = "string";
    ChartDesignFilterDataTypes["CHOICE"] = "choice";
    ChartDesignFilterDataTypes["NUMBER"] = "number";
    ChartDesignFilterDataTypes["BOOLEAN"] = "boolean";
    ChartDesignFilterDataTypes["ARRAY"] = "array";
    ChartDesignFilterDataTypes["DATETIME"] = "datetime";
})(ChartDesignFilterDataTypes = exports.ChartDesignFilterDataTypes || (exports.ChartDesignFilterDataTypes = {}));
var AvailableEntitiesReporting;
(function (AvailableEntitiesReporting) {
    AvailableEntitiesReporting["TRANSACTION"] = "Transaction";
    AvailableEntitiesReporting["CONTENT_WATCHED"] = "Content_watched";
    AvailableEntitiesReporting["COMMUNICATION"] = "Communication";
    AvailableEntitiesReporting["APP_ACTION"] = "App_action";
    AvailableEntitiesReporting["APP_ERROR"] = "App_error";
    AvailableEntitiesReporting["APP_PAGE"] = "App_page";
    AvailableEntitiesReporting["CUSTOMER"] = "Customer";
    AvailableEntitiesReporting["CDN_TRAFFIC"] = "CDN_traffic";
    AvailableEntitiesReporting["CDN_STORAGE"] = "CDN_storage";
})(AvailableEntitiesReporting = exports.AvailableEntitiesReporting || (exports.AvailableEntitiesReporting = {}));
var AvailableChartTypesReporting;
(function (AvailableChartTypesReporting) {
    AvailableChartTypesReporting["BAR"] = "Bar";
    AvailableChartTypesReporting["COLUMN"] = "Column";
    AvailableChartTypesReporting["LINE"] = "Line";
    AvailableChartTypesReporting["PIE"] = "Pie";
    AvailableChartTypesReporting["PLAIN_VALUE"] = "Plain_value";
    AvailableChartTypesReporting["GAUGE"] = "Gauge";
})(AvailableChartTypesReporting = exports.AvailableChartTypesReporting || (exports.AvailableChartTypesReporting = {}));
var FilterAndOr;
(function (FilterAndOr) {
    FilterAndOr["AND"] = "AND";
    FilterAndOr["OR"] = "OR";
})(FilterAndOr = exports.FilterAndOr || (exports.FilterAndOr = {}));
var TransactionType;
(function (TransactionType) {
    TransactionType["SUBSCRIPTION"] = "Subscription";
    TransactionType["WALLET"] = "Wallet";
    TransactionType["PAYPERVIEW"] = "PayPerView";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
var TransactionSource;
(function (TransactionSource) {
    TransactionSource["TVMS"] = "TVMS";
    TransactionSource["CART"] = "Cart";
    TransactionSource["PORTAL"] = "Portal";
    TransactionSource["FUNCTION"] = "Function";
    TransactionSource["API"] = "Api";
    TransactionSource["MYBUNDLE"] = "MyBundle";
    TransactionSource["APP"] = "App";
})(TransactionSource = exports.TransactionSource || (exports.TransactionSource = {}));
var ProductType;
(function (ProductType) {
    ProductType["BASE"] = "BASE";
    ProductType["EXTRA"] = "EXTRA";
})(ProductType = exports.ProductType || (exports.ProductType = {}));
var ProductVariant;
(function (ProductVariant) {
    ProductVariant["PAID"] = "PAID";
    ProductVariant["FREEMIUM"] = "FREEMIUM";
    ProductVariant["FAST"] = "FAST";
})(ProductVariant = exports.ProductVariant || (exports.ProductVariant = {}));
var TransactionSubTypes;
(function (TransactionSubTypes) {
    TransactionSubTypes["NewCustomer"] = "New Customer";
    TransactionSubTypes["ExpirationDateChange"] = "Expiration Date Change";
    TransactionSubTypes["BaseSubscriptionChange"] = "Base Subscription Change";
    TransactionSubTypes["ExtraSubscriptionAdd"] = "Extra Subscription Add";
    TransactionSubTypes["ExtraSubscriptionRemove"] = "Extra Subscription Remove";
    TransactionSubTypes["SubscriptionRenewal"] = "Subscription Renewal";
    TransactionSubTypes["ConvertFromTrail"] = "Convert From Trial";
    TransactionSubTypes["WalletDeposit"] = "Wallet deposit";
    TransactionSubTypes["WalletWithdraw"] = "Wallet withdraw";
    TransactionSubTypes["PayPerViewRent"] = "PayPerView rent";
    TransactionSubTypes["PayPerViewBuy"] = "PayPerView buy";
})(TransactionSubTypes = exports.TransactionSubTypes || (exports.TransactionSubTypes = {}));
var actionType;
(function (actionType) {
    actionType["SELECT_LANGUAGE"] = "Select Language";
    actionType["SELECT_SERVICE"] = "Select Service";
    actionType["SELECT_PROFILE"] = "Select Profile";
    actionType["ADD_PROFILE"] = "Add Profile";
    actionType["EDIT_PROFILE"] = "Edit Profile";
    actionType["DELETE_PROFILE"] = "Delete Profile";
    actionType["LOGIN"] = "Login";
    actionType["SINGUP"] = "Signup";
    actionType["FORGOT_LOGIN"] = "Forgot Login";
    actionType["ADD_FAVORITE"] = "Add Favorite";
    actionType["DELETE_FAVORITE"] = "Delete Favorite";
    actionType["DELETE_DEVICE"] = "Delete Device";
    actionType["PLAYER_PAUSED"] = "Player Paused";
    actionType["PLAYER_REW"] = "Player Rewind";
    actionType["PLAYER_FWD"] = "Player Forward";
    actionType["PLAYER_CHROMECAST"] = "Chromecast";
    actionType["PLAYER_AIRPLAY"] = "Airplay";
    actionType["PLAYER_PIP"] = "PiP";
    actionType["PLAYER_SCRUBBER"] = "Use Scrubber";
    actionType["PLAYER_SELECT_SUBTITLE"] = "Change Subtitle";
    actionType["PLAYER_SELECT_SCREENSZIE"] = "Change Screensize";
    actionType["PLAYER_SELECT_AUDIO"] = "Change Audio Language";
    actionType["PLAYER_SELECT_PROBLEM_REPORT"] = "Sent Problem Report";
    actionType["PLAYER_RESTART"] = "Player Restart";
})(actionType = exports.actionType || (exports.actionType = {}));
var errorType;
(function (errorType) {
    errorType["PLAYER_ERROR"] = "Player Error";
    errorType["APP_ERROR"] = "App Error";
    errorType["CONTENT_ERROR"] = "Content Error";
})(errorType = exports.errorType || (exports.errorType = {}));
var pageType;
(function (pageType) {
    pageType["LANGUAGE"] = "Language";
    pageType["SERVICES"] = "Services";
    pageType["LOGIN"] = "Login";
    pageType["LOADER"] = "Loader";
    pageType["DISCLAIMER"] = "Disclaimer";
    pageType["FORGOT"] = "Forgot";
    pageType["REGISTER"] = "Register";
    pageType["CONNECT"] = "Connect";
    pageType["PROFILE"] = "Profile";
    pageType["ADD_PROFILE"] = "Add profile";
    pageType["EDIT_PROFILE"] = "Edit profile";
    pageType["HOME"] = "Home";
    pageType["APPINFO"] = "App Info";
    pageType["MESSAGES"] = "Messages";
    pageType["RENEWAL"] = "Renewal";
    pageType["SUBINFO"] = "Subscription Info";
    pageType["UPDATES"] = "App Update";
    pageType["SIGNUP_DETAILS"] = "Signup Details";
    pageType["SIGNUP_FORM"] = "Signup Form";
    pageType["SIGNUP_PRODUCTS"] = "Signup Products";
    pageType["CHANNELS"] = "Channels";
    pageType["RADIO"] = "Radio";
    pageType["CAMS"] = "Cams";
    pageType["TVGUIDE"] = "Tv guide";
    pageType["PROGRAM"] = "Program";
    pageType["RECORDINGS"] = "Recordings";
    pageType["CATCHUPTV"] = "CatchupTV";
    pageType["MOVIES"] = "Movies";
    pageType["SERIES"] = "Series";
    pageType["MUSIC"] = "Music";
    pageType["PODCASTS"] = "Podcasts";
    pageType["EDUCATION"] = "Education";
    pageType["ABOUT"] = "About";
    pageType["SETTINGS"] = "Settings";
    pageType["DEVICES"] = "Devices";
    pageType["SUPPORT"] = "Select Support";
    pageType["CHANNEL_PLAYER"] = "Channel Player";
    pageType["MOVIE_PLAYER"] = "Movie Player";
    pageType["SERIES_PLAYER"] = "Series Player";
    pageType["MUSIC_PLAYER"] = "Music Player";
    pageType["PODCAST_PLAYER"] = "Podcast Player";
    pageType["RADIO_PLAYER"] = "Radio Player";
    pageType["RECORDING_PLAYER"] = "Recording Player";
    pageType["CATCHUPTV_PLAYER"] = "CatchupTV Player";
    pageType["CAMS_PLAYER"] = "Cams Player";
    pageType["MOVIE_DETAILS"] = "Authenticate";
    pageType["SERIES_DETAILS"] = "Series Details";
    pageType["COURSE_DETAILS"] = "Course Details";
    pageType["COURSE_PLAYER"] = "Course Player";
    pageType["COURSES"] = "Courses";
    pageType["MUSIC_DETAILS"] = "Music Details";
    pageType["PODCAST_DETAILS"] = "Podcast Details";
    pageType["SEARCH"] = "Search";
})(pageType = exports.pageType || (exports.pageType = {}));
var CommunicationSource;
(function (CommunicationSource) {
    CommunicationSource["TVMS"] = "TVMS";
    CommunicationSource["CART"] = "Cart";
    CommunicationSource["PORTAL"] = "Portal";
    CommunicationSource["FUNCTION"] = "Function";
    CommunicationSource["API"] = "Api";
})(CommunicationSource = exports.CommunicationSource || (exports.CommunicationSource = {}));
var CommunicationType;
(function (CommunicationType) {
    CommunicationType["EMAIL"] = "Email";
    CommunicationType["SMS"] = "SMS";
    CommunicationType["APP"] = "APP";
})(CommunicationType = exports.CommunicationType || (exports.CommunicationType = {}));
var contentType;
(function (contentType) {
    contentType["CHANNEL"] = "Channel";
    contentType["MOVIE"] = "Movie";
    contentType["RADIO"] = "Radio";
    contentType["CAM"] = "Cam";
    contentType["EPISODE"] = "Episode";
    contentType["POD"] = "Pod";
    contentType["SONG"] = "Song";
    contentType["LESSON"] = "Lesson";
    contentType["CATCHUPTV"] = "CachtupTV";
    contentType["RECORDINGS"] = "Recordings";
})(contentType = exports.contentType || (exports.contentType = {}));
var mainType;
(function (mainType) {
    mainType["CHANNEL"] = "Channels";
    mainType["MOVIE"] = "Movies";
    mainType["RADIO"] = "Radio";
    mainType["CAM"] = "Cams";
    mainType["SERIES"] = "Series";
    mainType["COURSE"] = "Course";
    mainType["PODCAST"] = "Podcast";
    mainType["MUSIC"] = "Music";
    mainType["CATCHUPTV"] = "CatchupTV";
    mainType["RECORDINGS"] = "Recordings";
    mainType["SHORTS"] = "Shorts";
    mainType["GAMES"] = "Games";
})(mainType = exports.mainType || (exports.mainType = {}));
var CustomerStatus;
(function (CustomerStatus) {
    CustomerStatus["ACTIVE"] = "active";
    CustomerStatus["INACTIVE"] = "inactive";
})(CustomerStatus = exports.CustomerStatus || (exports.CustomerStatus = {}));
var CustomerSubscriptionStatus;
(function (CustomerSubscriptionStatus) {
    CustomerSubscriptionStatus["PENDING"] = "pending";
    CustomerSubscriptionStatus["ACTIVE"] = "active";
    CustomerSubscriptionStatus["EXPIRED"] = "expired";
    CustomerSubscriptionStatus["CANCELLED"] = "cancelled";
})(CustomerSubscriptionStatus = exports.CustomerSubscriptionStatus || (exports.CustomerSubscriptionStatus = {}));
var CustomerType;
(function (CustomerType) {
    CustomerType["BUSINESS"] = "business";
    CustomerType["PERSONAL"] = "personal";
})(CustomerType = exports.CustomerType || (exports.CustomerType = {}));
var CustomerProductStatus;
(function (CustomerProductStatus) {
    CustomerProductStatus["TRIAL"] = "trial";
    CustomerProductStatus["REGULAR"] = "regular";
})(CustomerProductStatus = exports.CustomerProductStatus || (exports.CustomerProductStatus = {}));
// export enum formFactor {
//   MOBILE = 'Mobile',
//   TABLET = 'Tablet',
//   TELEVISION = 'Television',
//   COMPUTER = 'Computer',
// }
// export enum deviceModel {
//   FIRETV = 'FireTV AAFM',
//   ANDROID = 'Android 10.0',
//   TIZEN = 'TIzen 4.5',
//   IPAD = 'iPad 14',
//   IPHONE = 'iPhone 13',
//   MEGO = 'Megoo ATV',
//   SONY = 'Sony Android TV',
// }
// export enum deviceType {
//   IPHONE = 'iPhone',
//   IPAD = 'iPad',
//   ANDROID_PHONE = 'Android Phone',
//   FIRE_TV = 'Fire TV',
//   APPLE_TV = 'Apple TV',
//   LG_SMART_TV = 'LG Smart TV',
//   PWA_MOBILE = 'PWA Mobile',
//   WEB_TV_MAC = 'WebTV Mac ',
// }
// export enum NetworkType {
//   WIFI = 'WiFi',
//   ETHERNET = 'Ethernet',
//   MOBILE_5G = 'Mobile 5G',
//   MOBILE_4G = 'Mobile 4G',
//   MOBILE_3G = 'Mobile 3G',
//   MOBILE = 'Mobile',
// }
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["NONE"] = "none";
    PaymentStatus["AWAITING_PAYMENT"] = "awaiting_payment";
    PaymentStatus["PAYMENT_DONE"] = "done";
    PaymentStatus["PAYMENT_FAILED"] = "failed";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var Periods;
(function (Periods) {
    Periods["DAY"] = "day";
    Periods["WEEK"] = "week";
    Periods["MONTH"] = "month";
    Periods["QUARTER"] = "quarter";
    Periods["YEAR"] = "year";
})(Periods = exports.Periods || (exports.Periods = {}));
var DateTimeKeysToFill;
(function (DateTimeKeysToFill) {
    DateTimeKeysToFill["HOURS_IN_DAY"] = "hours_in_day";
    DateTimeKeysToFill["DAYS_IN_WEEK"] = "days_in_week";
    DateTimeKeysToFill["DAYS_IN_MONTH"] = "days_in_month";
})(DateTimeKeysToFill = exports.DateTimeKeysToFill || (exports.DateTimeKeysToFill = {}));

import { faChartTreeMap, faImage, faUsers, faCalendarCheck, faPrescriptionBottle, faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu, notification } from 'antd';
import { memo, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { useContext } from 'react';
import { Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import MWare_Logo_Blue from '../../assets/images/logo-blue.png';
import IMSDashboardHeader from '../../common/components/IMSDashboardHeader';
import LeftSidebar from '../../common/components/LeftSidebar';
import PageNotFound from '../../common/components/PageNotFound';
import PrivateRoute from '../../common/routes/PrivateRoute';
import { DrawerContext } from '../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../context_api/DrawerContext/action-types';
import { IClientInfoResponse } from '../../interfaces/responses/clients/IClientInfoResponse';
import ClientService from '../../services/client';
import AreasLayout from '../areas';
import AppBuilder from '../areas/appbuilder';
import Content from '../areas/content';
// import AkamaiSettings from '../settings/akamai_settings';
// import SendgridSettings from '../settings/sendgrid_settings';
// import SMTPSettings from '../settings/smtp_settings/index.old';
// import TimezoneSettings from '../settings/timezones_setting';
// import UserLogs from '../settings/userlog_settings';
// import WhitelabelSettings from '../settings/whitelabel_settings';
import { IMSHome } from './pages/home';
import NewDeployment from './pages/home/components/dashboard/deployments/add/index';
import NewServiceForm from './pages/home/components/dashboard/services/add/index';
import { ExamplesAndStuff } from './pages/home/examples/examplesAndStuffPage';
import { JobsSchedulersStatus } from './pages/home/jobs';
import { Teamwork } from './pages/home/teamwork';
import { Jira } from './pages/home/jira';
import { CloudOf } from './pages/home/cloudof';
import { hasAccessToClient, NotVisibleForRole, VisibleForRole } from '../../common/components/VisibleForRole';
import { TVMSRoles } from '../../constants/TVMSRoles';
import { AuthContext } from '../../context_api/AuthContext';
import { Users } from '../users';
import { useTranslation } from 'react-i18next';

export type IMSNameParams = {
  clientName: string;
  service?: string;
  deployment: string;
  service_name?: string;
  _id?: string;
  category?: string;
  package?: string;
  index?: string;
};

export const IMSDashboard = () => {
  const { state: AuthState } = useContext(AuthContext);
  const { path, url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const {t} = useTranslation();
  if (!hasAccessToClient(AuthState.user!, clientName)) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <Switch>
      <PrivateRoute path={`${path}/new-deployment`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]} component={memo(() => withLayout(NewDeployment, url))} />
      <PrivateRoute path={`${path}/:deployment/new-service`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]} component={memo(() => withLayout(NewServiceForm, url))} />
      {/* <Route path={`${path}/setting/smtp-setting`} component={memo(() => withLayout(SMTPSettings, url))} />
      <Route path={`${path}/setting/sendgrid-setting`} component={memo(() => withLayout(SendgridSettings, url))} />
      <Route path={`${path}/setting/akamai-settings`} component={memo(() => withLayout(AkamaiSettings, url))} />
      <Route path={`${path}/setting/timezones-setting`} component={memo(() => withLayout(TimezoneSettings, url))} />
      <Route path={`${path}/setting/whitelabel-setting`} component={memo(() => withLayout(WhitelabelSettings, url))} />
      <Route path={`${path}/setting/user-logs`} component={memo(() => withLayout(UserLogs, url))} /> */}
      {/* sub domains */}

      <Route path={`${path}/users`} component={memo(() => withLayout(Users, url))} />

      {/* <PrivateRoute path={`${path}/:service(appbuilder)/:service_name`} component={memo(() => withLayout(AreasLayout))}></PrivateRoute> */}

      <Route path={`${path}/:deployment/content`} component={memo(() => withoutLayout(Content, url))} />
      <Route path={`${path}/:deployment/appbuilder`} component={memo(() => withoutLayout(AppBuilder, url))} />

      <Route path={`${path}/:deployment/:service`} component={memo(() => withoutLayout(AreasLayout, url))} />
      <Route path={`${path}/examples`} component={memo(() => withLayout(ExamplesAndStuff, url))} />
      <PrivateRoute path={`${path}/jobs-schedulers`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]} component={memo(() => withLayout(JobsSchedulersStatus, url))} />
      <PrivateRoute path={`${path}/teamwork`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]} component={memo(() => withLayout(Teamwork, url))} />
      <PrivateRoute path={`${path}/jira`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]} component={memo(() => withLayout(Jira, url))} />
      <PrivateRoute path={`${path}/cloudof`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]} component={memo(() => withLayout(CloudOf, url))} />
      <Route path={`${path}/*`} component={memo(() => withLayout(PageNotFound, url))} />
      <Route path={`${path}`} component={memo(() => withLayout(IMSHome, url))} />
      {/* <PrivateRoute path={`${path}`} component={withSidebar(IMSHome)}></PrivateRoute> */}
    </Switch>
  );
};

const withoutLayout = (Component: any, baseUrl: string) => {
  return (
    <DashBoardWrapper baseUrl={baseUrl} withLayout={false}>
      <Component />
    </DashBoardWrapper>
  );
};

const withLayout = (Component: any, baseUrl: string) => {
  return (
    <DashBoardWrapper baseUrl={baseUrl} withLayout={true}>
      <Component />
    </DashBoardWrapper>
  );
};

const DashBoardWrapper: React.FC<{
  withLayout: boolean;
  children?: React.ReactNode;
  baseUrl: string;
}> = ({ children, withLayout, baseUrl }) => {
  const { _id } = useParams<IMSNameParams>();
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const { path, url } = useRouteMatch();
  const {t} = useTranslation();
  // To do
  const keyUrl = url.split('/').at(-1);
  const [currentKey, setCurrentKey] = useState('tmp_key-0');

  const setKeyByUrl = (keyUrl: string) => {
    if (keyUrl === 'users') {
      setCurrentKey('tmp_key-1');
    } else if (keyUrl === 'examples') {
      setCurrentKey('tmp_key-2');
    } else if (keyUrl === 'jobs-schedulers') {
      setCurrentKey('tmp_key-3');
    } else {
      setCurrentKey('tmp_key-0');
    }
  };

  useEffectOnce(() => {
    let favicon: any = document.getElementById('favicon');
    DrawerDispatch({
      type: DrawerActionTypes.SET_CLIENT_INFO_LOADING,
      is_client_info_loading: true,
    });

    const getClientInfo = async () => {
      if (_id) {
        let ClientInfo: IClientInfoResponse = await ClientService.getClient({
          _id: _id,
        });
        if (ClientInfo.status_code === StatusCodes.OK && ClientInfo.data) {
          DrawerDispatch({
            type: DrawerActionTypes.SET_CLIENT_INFO,
            client_info: ClientInfo.data,
          });
          // let whiteLabel = await common.FetchWhiteLabelSettings(ClientInfo.data.name);
          // if (whiteLabel) {
          //   DrawerDispatch({
          //     type: DrawerActionTypes.UPDATE_WHITE_LABEL_INFO,
          //     ...whiteLabel,
          //   });
          //   if (whiteLabel.fav_icon && favicon) favicon.href = whiteLabel.fav_icon;
          // }
        } else {
          if (ClientInfo.errors)
            notification.error({
              message: JSON.stringify(ClientInfo.errors[0]),
            });
        }
        DrawerDispatch({
          type: DrawerActionTypes.SET_CLIENT_INFO_LOADING,
          is_client_info_loading: false,
        });
      }
    };
    getClientInfo();

    if (keyUrl !== undefined) {
      setKeyByUrl(keyUrl);
    }
  });

  return (
    <>
      {DrawerState.is_client_info_loading && (
        <>
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">{/* <Spin size="large" spinning={true} /> */}</div>
          </div>
        </>
      )}
      {DrawerState.client_info && !DrawerState.is_client_info_loading && (
        <>
          {!withLayout && <>{children}</>}
          {withLayout && (
            <Layout>
              {DrawerState.defaultOpenKey !== '' && DrawerState.is_sidebar_visible && <LeftSidebar />}

              <Layout>
                <div className="row h-100">
                  <NotVisibleForRole roles={[TVMSRoles.Vendor]}>
                    <>
                      {DrawerState.defaultOpenKey === '' && (
                        <div className="col-md-2 bg-white shadow">
                          <div className="m-4 pb-5 pt-4" style={{ borderBottom: '1px solid #ebebeb', display: 'flex', justifyContent: 'center' }}>
                            <Link to={`/`}>
                              <img src={DrawerState.white_labeling.logo || MWare_Logo_Blue} title={"MwareTV"} alt={"MwareTV"} style={DrawerState.white_labeling.logo ? { width: 200, height: 50 } : {}} />
                            </Link>
                          </div>

                          {/* <div className="mt-2 ml-3" style={{ color: '#ebebeb', fontSize: 16 }}>
                        MAIN
                      </div> */}
                          <Menu mode="inline" className="mt-2 ml-2" style={{ height: '100vh', borderRight: 0 }} defaultSelectedKeys={[currentKey]} defaultOpenKeys={[currentKey]}>
                            {/* <Menu.Item className="height-60 d-flex align-items-center" key="deployment">
                          <div className="row">
                            <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                              <FontAwesomeIcon color={'#999'} size={'xl'} icon={faChartTreeMap} style={{ width: '20', height: '20' }} />
                            </div>
                            <div
                              className="col-md-10"
                              style={{
                                fontSize: 14,
                                fontWeight: 100,
                                color: '#999',
                              }}
                            >
                              Deployments
                            </div>
                          </div>
                        </Menu.Item> */}
                            <Menu.Item className="height-60 d-flex align-items-center">
                              <Link to={`${baseUrl}`}>
                                <div className="row">
                                  <div
                                    className="col-md-2"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faChartTreeMap} />
                                  </div>

                                  <div
                                    className="col-md-10"
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 100,
                                      color: '#999',
                                    }}
                                  >
                                    {t('my-deployments')}
                                  </div>
                                </div>
                              </Link>
                            </Menu.Item>

                            {/* <Menu.Item className="height-60 d-flex align-items-center" key="content-destribution">
                          <div className="row">
                            <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                              <FontAwesomeIcon color={'#999'} size={'xl'} icon={faChartNetwork} style={{ width: '20', height: '20' }} />
                            </div>
                            <div
                              className="col-md-10"
                              style={{
                                fontSize: 14,
                                fontWeight: 100,
                                color: '#999',
                              }}
                            >
                              Content Distribution
                            </div>
                          </div>
                        </Menu.Item> */}
                            <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                              <Menu.Item className="height-60 d-flex align-items-center">
                                <Link to={`${baseUrl}/users`}>
                                  <div className="row">
                                    <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                                      <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faUsers} />
                                    </div>
                                    <div
                                      className="col-md-10"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 100,
                                        color: '#999',
                                      }}
                                    >
                                      {t('system-users')}
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>
                            </VisibleForRole>
                            <Menu.Item className="height-60 d-flex align-items-center">
                              <Link to={`${baseUrl}/examples`}>
                                <div className="row">
                                  <div
                                    className="col-md-2"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faImage} />
                                  </div>

                                  <div
                                    className="col-md-10"
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 100,
                                      color: '#999',
                                    }}
                                  >
                                    {t('examples-and-stuff')}
                                  </div>
                                </div>
                              </Link>
                            </Menu.Item>
                            <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                              <Menu.Item className="height-60 d-flex align-items-center">
                                <Link to={`${baseUrl}/jobs-schedulers`}>
                                  <div className="row">
                                    <div
                                      className="col-md-2"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faCalendarCheck} />
                                    </div>

                                    <div
                                      className="col-md-10"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 100,
                                        color: '#999',
                                      }}
                                    >
                                      {t('jobs-and-schedulers')}
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>
                            </VisibleForRole>
                              {/* <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                              <Menu.Item className="height-60 d-flex align-items-center">
                                <Link to={`${baseUrl}/cloudof`}>
                                  <div className="row">
                                    <div
                                      className="col-md-2"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faFileLines} />
                                    </div>

                                    <div
                                      className="col-md-10"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 100,
                                        color: '#999',
                                      }}
                                    >
                                      {t('api')}
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>
                            </VisibleForRole>  */}
                            {/* <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                              <Menu.Item className="height-60 d-flex align-items-center">
                                <Link to={`${baseUrl}/teamwork`}>
                                  <div className="row">
                                    <div
                                      className="col-md-2"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faPrescriptionBottle} />
                                    </div>

                                    <div
                                      className="col-md-10"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 100,
                                        color: '#999',
                                      }}
                                    >
                                      {t('teamwork')}
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>
                            </VisibleForRole> */}
{/*        
                              <Menu.Item className="height-60 d-flex align-items-center">
                                <Link to={`${baseUrl}/jira`}>
                                  <div className="row">
                                    <div
                                      className="col-md-2"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <FontAwesomeIcon color={'#999'} size={'xl'} style={{ width: '20', height: '20' }} icon={faJira} />
                                    </div>

                                    <div
                                      className="col-md-10"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 100,
                                        color: '#999',
                                      }}
                                    >
                                      {t('jira-tickets')}
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item> */}
          
                          </Menu>
                        </div>
                      )}
                    </>
                  </NotVisibleForRole>
                  <NotVisibleForRole roles={[TVMSRoles.Vendor]}>
                    <div className={DrawerState.defaultOpenKey === '' ? 'col-md-10' : 'col-md-12'}>
                      <div>
                        <IMSDashboardHeader
                          {...{
                            isSideBarShow: DrawerState.is_sidebar_visible,
                            path,
                          }}
                        />
                      </div>
                      {children}
                    </div>
                  </NotVisibleForRole>
                  <VisibleForRole roles={[TVMSRoles.Vendor]}>
                    <div className={'col-md-12'}>
                      <div>
                        <IMSDashboardHeader
                          {...{
                            isSideBarShow: DrawerState.is_sidebar_visible,
                            path,
                          }}
                        />
                      </div>
                      {children}
                    </div>
                  </VisibleForRole>
                </div>
              </Layout>
            </Layout>
          )}
        </>
      )}
    </>
  );
};

import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/axios";
import { API_ENDPOINTS } from "../../_APIConstants";
import { EmailTemplate } from '../../../interfaces/forms/areas/billing/emailTemplate'
import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";

const { BILLING : BILLING_ENDPONTS } = API_ENDPOINTS
const { EMAIL_TEMPLATES : ENDPOINTS } = BILLING_ENDPONTS

type Params = { instance: string, cms: string, crm: string };

type PaginationParams = {
  page : number,
  limit : number
}

type ResponseFormat<T> = {
  docs : T
}

type PaginatedResults<T> = {
  hasNextPage : boolean
  hasPrevPage : boolean
  limit : number
  nextPage : number | null
  page : number
  pagingCounter : number
  prevPage : null | number
  totalDocs : number,
  totalPages : number
} & ResponseFormat<T>

export const list = ({ params } : { params : Params & PaginationParams}) : Promise<AxiosResponse<PaginatedResults<EmailTemplate[]>>> => {
  return axiosInstance.get<PaginatedResults<EmailTemplate[]>>(ENDPOINTS.LIST, { params })
}

type GetParams = { params : Params, id : string }

export const get = ({ params, id } : GetParams) : Promise<AxiosResponse<EmailTemplate>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params })
}

export type AddParams = { params : Params } & { data : EmailTemplate }

export const add = ({ data, params } : AddParams ) : Promise<AxiosResponse<EmailTemplate>> => {
  return axiosInstance.post<EmailTemplate>(ENDPOINTS.ADD, data, { params })
}

type DeleteParams = { params : Params, id : string }

export const del = ({ params, id } : DeleteParams) : Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params })
}

export type PutParams = { params : Params } & { data : EmailTemplate, id : string }

export const put = ({ data, params, id } : PutParams ) : Promise<AxiosResponse<EmailTemplate>> => {
  return axiosInstance.put<EmailTemplate>(`${ENDPOINTS.PUT}/${id}`, data, { params })
}

export type PatchParams = { params : Params } & { data : EmailTemplate, id : string }

export const patch = ({ data, params, id } : PatchParams ) : Promise<AxiosResponse<EmailTemplate>> => {
  return axiosInstance.patch<EmailTemplate>(`${ENDPOINTS.PATCH}/${id}`, data, { params })
}

export const useEmailTemplateList = ({ params } : { params : Params & PaginationParams}) => useQuery(['billing', 'settings', 'email-templates', 'list', params], async () => {
  return list({params})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useEmailTemplateGet = ({ params, id } : GetParams) => useQuery(['billing', 'settings', 'email-templates', 'get', params, id], async () => {
  return get({params, id})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useEmailTemplateAdd = ({ params } : { params : Params }, options : Omit<UseMutationOptions<AxiosResponse<EmailTemplate>, unknown, EmailTemplate, unknown>, "mutationFn">) => useMutation(async (values : EmailTemplate) => {
  return add({ params, data: values })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

export const useEmailTemplatePut = ({ params, id } : Omit<PutParams, 'data'>, options : Omit<UseMutationOptions<AxiosResponse<EmailTemplate>, unknown, EmailTemplate, unknown>, "mutationFn">) => useMutation(async (values : EmailTemplate) => {
  return put({ params, data: values, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

export const useEmailTemplateDelete = ({ params } : { params : Params }, options : Omit<UseMutationOptions<AxiosResponse, unknown, string, unknown>, "mutationFn">) => useMutation(async (id) => {
  return del({ params, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

import { Skeleton, Image } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd } from '@fortawesome/pro-regular-svg-icons';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const MessagePromo = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid remove-hor-padding" style={{ height: 120 }}>
      <div className="row" style={{ marginTop: props.application.theme?.rails_top_margin ?? 5, marginBottom: props.application.theme?.rails_bottom_margin ?? 5, marginLeft: 5, marginRight:-10 }}>
        <div>
          <div className="row" style={{marginLeft:0, marginTop: 5, marginBottom:5, marginRight:5 }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 100, backgroundColor: '#999' }} />
          </div>
        </div>
        <div className="col" style={{ marginLeft: 10 }}>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Input active={false} size={'large'} style={{ width: 200, height: 20, backgroundColor: '#999' }} />
          </div>

          <div style={{ gridAutoColumns: '100%', display: 'grid', marginTop: 10, marginLeft: -15 }}>
            <Skeleton.Node active={false} style={{ width: '100%', height: 63, backgroundColor: '#999' }}>
              <div></div>
            </Skeleton.Node>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePromo;

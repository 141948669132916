import { Games, GamesExport, GamesImport } from '../../../../../interfaces/forms/areas/content/games/games';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Games as Response } from '../../../../../interfaces/responses/areas/content/games/games';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Games_Service {
  Add_Games = async (data: Games): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.GAMES.GAMES.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Games = async (data: GamesImport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.GAMES.GAMES.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          addcats: data.query_params.addcats,
          createcats: data.query_params.createcats,
          bundles: data.query_params.bundles,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Games = async (data: GamesExport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.GAMES.GAMES.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Games = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.GAMES.GAMES.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Games = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.GAMES.GAMES.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Games = async (data: Games): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.GAMES.GAMES.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Games = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.GAMES.GAMES.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  // Delete_Games = async (data: IGetForm): Promise<IBaseResponse> => {
  //   return await axiosInstance
  //     .delete(API_ENDPOINTS.V1.CMS.GAMES.GAMES.DELETE_GROUP, {
  //       data,
  //       params: {
  //         instance: data.query_params.instance,
  //         cms: data.query_params.cms,
  //       },
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };

  AutoComplete_List_Games = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.GAMES.GAMES.AUTOCOMPLETE_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Games_Service();

import React from "react";
import { DeploymentService } from "@mwaretv/database/build/backend/models/deployment/deployment";
import { PopulatedDeployment } from '../services/deployments/deployment'


type ContentContextType = {
  deployment : PopulatedDeployment  | undefined
  service : DeploymentService | undefined
}

export const ContentContext = React.createContext<ContentContextType>({
  deployment: undefined,
  service: undefined
});

export const ContentContextProvider = ContentContext.Provider


import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const onSubmitLocal = (values: any) => {
    let dataOut = {
      ...data,
      url: values.url,
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    if (data != undefined) {
      form.setFieldValue('url',data.url);
    }
    setLoading(false);
  }, [data, form]);

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-message-settings')}</h4>
            <p>{t('select-the-message-you-want-to-show')}</p>
            <div className="row mt-5">
              <div className="col-12">
                {!loading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="col">
                      <div className="row" style={{ padding: 15 }}>
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('webview-url')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item
                                className="form-group pb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: t('url-is-required'),
                                  },
                                ]}
                                name={'url'}
                              >
                                <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder={t('url')} />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('update')}
                      </button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import { Button, Form, Input, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import ButtonNew from '../../../../assets/images/appbuilder/ButtonNew.png';
import ElementPng from '../../../../assets/images/appbuilder/ElementNew2.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: [] | any;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [textColor, setTextColor] = useState('#ffffff');
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [borderColor, setBorderColor] = useState('#999999');
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const [textBold, setTextBold] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (data.background_color != undefined) {
      
      setBackgroundColor(data.background_color);
      setBorderColor(data.border_color);
      setTextColor(data.text_color);
      setTextBold(data.text_bold);
      form.setFieldsValue(data);
    }
    setIsLoading(false);
  }, [data]);

  const onSubmitLocal = (values: any) => {
    
    var button = {
      ...data,
      name: values.name,
      background_color: backgroundColor,
      border_radius: values.border_radius ?? 0,
      border_width: values.border_width ?? 0,
      border_color: borderColor,

      text_color: textColor,
      text_bold: textBold,
    };

    onSubmit(button);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{type} {t('button')}</h4>
            <p>{t('buttons-are-used-at-the-authentications-screens-but-also-for-your-rails-and-grids')}</p>
            <div className="row mt-5">
              <div className="col-12">
                {!isLoading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="row" style={{ padding: 15 }}>
                      <div className="col card-bg-light p-4 mb-2 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('button')}</h5>
                        <p>{t('settings-that-define-the-button-such-as-background-color-borders-etc')}</p>
                        <div className="row mb-3">
                          <label className="col-md-4 col-form-label">{t('name')}</label>
                          <div className="col-md-8" style={{ paddingLeft: 0 }}>
                            <Form.Item
                              name="name"
                              className="form-group pb-0 col"
                              rules={[
                                {
                                  required: true,
                                  message: t('enter-button-name'),
                                },
                              ]}
                            >
                              <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('give-your-button-a-name')} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setShowBackgroundColorPicker(!showBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: backgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={showBackgroundColorPicker}
                                color={backgroundColor}
                                onChange={(color) => {
                                  setBackgroundColor(color);
                                  setShowBackgroundColorPicker(!showBackgroundColorPicker);
                                }}
                                setVisible={setShowBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('border')}</label>
                          <div className="col-md-2" style={{ paddingLeft: 0 }}>
                            <Form.Item
                              name="border_radius"
                              className="form-group pb-0 col"
                              rules={[
                                {
                                  required: true,
                                  message: t('enter-border-radius'),
                                },
                              ]}
                            >
                              <Input type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              RADIUS
                            </div>
                          </div>
                          <div className="col-md-2">
                            <Form.Item
                              name="border_width"
                              className="form-group pb-0 col"
                              rules={[
                                {
                                  required: true,
                                  message: t('enter-border-width'),
                                },
                              ]}
                            >
                              <Input type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              WIDTH
                            </div>
                          </div>
                          <div className="col-md-2" style={{ paddingRight: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setShowBorderColorPicker(!showBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: borderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={showBorderColorPicker}
                                color={borderColor}
                                onChange={(color) => {
                                  setBorderColor(color);
                                  setShowBorderColorPicker(!showBorderColorPicker);
                                }}
                                setVisible={setShowBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={ButtonNew} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ padding: 15 }}>
                      <div className="col card-bg-light p-4 mb-2 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('texts')}</h5>
                        <p>{t('settings-that-define-texts-object-of-your-button')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setShowTextColorPicker(!showTextColorPicker);
                                }}
                                style={{
                                  backgroundColor: textColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={showTextColorPicker}
                                color={textColor}
                                onChange={(color) => {
                                  setTextColor(color);
                                  setShowTextColorPicker(!showTextColorPicker);
                                }}
                                setVisible={setShowTextColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="text_bold">
                              <Switch className="ch-switch" checked={textBold} defaultChecked={false} onChange={() => setTextBold(!textBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={ButtonNew} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mt-1 text-right">
                      <Button htmlType="submit" className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                        {type} {t('button')}
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import { IBaseResponse } from '../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';

class TestService {
  TestEmail = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.BILLING.TESTER.EMAIL, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  TestSms = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.BILLING.TESTER.SMS, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new TestService();

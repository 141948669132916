import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const OtaPromo = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid remove-hor-padding" style={{ height: 120 }}>
      <div className="row" style={{ marginTop: props.application.theme?.rails_top_margin ?? 5, marginBottom: props.application.theme?.rails_bottom_margin ?? 5, marginRight: -10, marginLeft: 5 }}>
        <div style={{ marginLeft: 10 }}>
          <div className="row" style={{ margin: 5 }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 100, backgroundColor: '#999' }} />
          </div>
        </div>
        <div className="col" style={{ marginLeft: 10 }}>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Input active={false} size={'large'} style={{ width: 200, height: 20, backgroundColor: '#999' }} />
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Input active={false} size={'large'} style={{ width: 500, height: 63, backgroundColor: '#999' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtaPromo;

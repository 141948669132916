import { Promotions_Survey } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/promotions/survey/survey";
import { Promotions_Survey as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/promotions/survey/survey";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Promotions_SurveyService {
    Add_Promotions_Survey = async (data: Promotions_Survey): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.SURVEY.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Promotions_Survey_Template = async (data: Promotions_Survey): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.SURVEY.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Promotions_Surveys = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.SURVEY.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Promotions_Survey = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.PROMOTIONS.SURVEY.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Promotions_Survey = async (data: Promotions_Survey): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.PROMOTIONS.SURVEY.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Promotions_Survey = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.PROMOTIONS.SURVEY.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Promotions_SurveyService();

import { Select } from 'antd';
import { Item } from '../../../../../common/components/form';
import { NamePath } from 'antd/lib/form/interface';
import { ICountry } from '../../../../../interfaces/responses/common/ICountriesResponse';
import { useTranslation } from 'react-i18next';

export const CountrySelect: React.FC<{ name: NamePath; extra: string; countries?: ICountry[]; useIso3?: boolean; useName?: boolean; }> = ({ name, extra, countries, useIso3, useName }) => {
  const {t} = useTranslation();
  return (
    <Item {...{ name, extra }} rules={[{ required: true, message: t('country-is-required') }]}>
      <Select
        showSearch
        loading={!countries}
        allowClear
        optionLabelProp="label"
        optionFilterProp="children"
        filterOption={(input, option) => {
          return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
        }}
      >
        {(countries ?? []).map((country: ICountry) => (
          <Select.Option value={useIso3 ? country.iso3 : useName ? country.name : country._id} label={`${country.emoji} ${country.name}`}>
            {`${country.emoji} ${country.name}`}
          </Select.Option>
        ))}
      </Select>
    </Item>
  );
};

import { ChartDesignResponse as Response } from '../../../../interfaces/responses/areas/reporting/chartdesigns/chartDesign';
import { IBaseResponse } from "../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../utils/axios";
import { API_ENDPOINTS } from "../../../_APIConstants";

class ChartDesigns_Service {
  List_ChartDesigns = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  ListIds_ChartDesigns = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.LIST, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_ChartDesigns = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          crm: data.query_params.crm,
          area: data.query_params.area
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_ChartDesigns = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          crm: data.query_params.crm,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_ChartDesigns = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          crm: data.query_params.crm,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  UpdateMany_ChartDesigns = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.UPDATE, data.chartDesigns, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          crm: data.query_params.crm,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_ChartDesigns = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.REPORTING.CHART_DESIGNS.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          crm: data.query_params.crm,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new ChartDesigns_Service();

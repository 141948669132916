import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Application as Response } from '../../../../../interfaces/responses/areas/appbuilder/applications/application/application';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class ApplicationService {
  Add_Application = async (data: Application): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Apps = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.LISTAPPS, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Applications = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Application_Dirs = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.LISTDIR, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Application_Jsons = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.LISTJSON, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Application = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Get_Application_Json = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.DETAILSJSON}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Application = async (data: Application): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Application = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.DELETE}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Publish_Application = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.APPLICATION.PUBLISH}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new ApplicationService();

import { Button, Input, Space } from "antd";
import React, { ForwardedRef, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterProps } from "react-table";

const ReactBCTextFilter = forwardRef(<T extends {}>({ column }: FilterProps<T>, filterRef: ForwardedRef<HTMLDivElement>) => {
  const { setFilter, Header } = column;
  const {t} = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string>();
  return (
    <div ref={filterRef} style={{ backgroundColor: "#ffffff", border: "1px solid #1ac884", padding: 8 }}>
      <div>
        <Input
          value={selectedKeys}
          style={{ minWidth: "200px" }}
          placeholder={`Search ${Header?.toString()}`}
          onChange={(e) => setSelectedKeys(e.target.value)}
        />
      </div>
      <Space style={{ marginTop: "10px" }}>
        <Button type="primary" disabled={!selectedKeys} onClick={() => setFilter({ filterValue: selectedKeys, filterType: "text" })} size="small">
          {t('search')}
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys("");
            setFilter("");
          }}
          size="small"
        >
          {t('reset')}
        </Button>
      </Space>
    </div>
  );
});
export default ReactBCTextFilter;

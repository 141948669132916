import { Button, DatePicker, Space } from "antd";
import React, { ForwardedRef, forwardRef, useState } from "react";
import { FilterProps } from "react-table";
import { IReactBCTableColumn } from "../Interfaces/IReactBCTableColumn.interface";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

const ReactBCDateFilter = forwardRef(<T extends {}>({ column }: FilterProps<T>, filterRef: ForwardedRef<HTMLDivElement>) => {
  const { setFilter, customData } = column;
  const {t} = useTranslation();
  let passedProps = customData.parentColumn as IReactBCTableColumn<T>;
  if (!passedProps.filterOption) passedProps.filterOption = "eq";
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>();
  return (
    <div ref={filterRef} style={{ backgroundColor: "#ffffff", border: "1px solid #1ac884", padding: 8 }}>
      <div>
        <DatePicker format={"DD MMM YYYY"} onChange={(date: dayjs.Dayjs | null, dateString: string) => setSelectedDate(date)} />
      </div>
      <Space style={{ marginTop: "10px" }}>
        <Button
          type="primary"
          disabled={!selectedDate}
          onClick={() =>
            setFilter({ filterValue: selectedDate, filterOption: passedProps.filterOption, filterType: "date", dateFormat: "DD MMM YYYY" })
          }
          size="small"
        >
          {t('search')}
        </Button>
        <Button
          onClick={() => {
            setSelectedDate(undefined);
            setFilter(undefined);
          }}
          size="small"
        >
          {t('reset')}
        </Button>
      </Space>
    </div>
  );
});

export default ReactBCDateFilter;

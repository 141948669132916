import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay } from '@fortawesome/pro-regular-svg-icons';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const AudioPlayer = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  const [color, setColor] = useState('#999999');

  return (
    <div className="container-fluid text-left" style={{ padding: 0 }}>
      <div
        className="col"
        style={{
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          border: 'none',
          borderRadius: 5,
          padding: 5,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', marginRight: 5 }} />
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', marginRight: 5 }} />
                <Skeleton.Button active={false} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999', marginRight: 5 }} />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: 30 }}></div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            <Skeleton.Image active={false} style={{ width: 125, height: 125, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Skeleton.Image active={false} style={{ width: 200, height: 200, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            </div>
            <Skeleton.Image active={false} style={{ width: 125, height: 125, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent:'center' , alignContent:'center', alignItems:'center', marginTop:10}}>
            <Skeleton.Button active={false} style={{ width: 400, height: 30, backgroundColor: color, marginBottom: 10, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 200, height: 20, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
            <FontAwesomeIcon icon={faBackward} color="#bfbfbf" size={'3x'} style={{ margin: 10 }}></FontAwesomeIcon>
            <FontAwesomeIcon icon={faPlay} color="#bfbfbf" size={'4x'} style={{ margin: 10 }}></FontAwesomeIcon>
            <FontAwesomeIcon icon={faForward} color="#bfbfbf" size={'3x'} style={{ margin: 10 }}></FontAwesomeIcon>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gridAutoColumns: '100%', height: '100%' }}>
            <div style={{ width: '100%', height: 5, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

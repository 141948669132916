import { useTranslation } from 'react-i18next';
import { FormSection, MultipleLanguagesSelector, CountryCurrencySelect, Timezone } from '../../../../../../../../common/components/form';
import { CommonContext } from '../../../../../../../../contexts/commonContext';
import { useContext } from 'react';

const DeploymentSettings = () => {
  const { countries, languages } = useContext(CommonContext);
  const {t} = useTranslation();

  return (
    <FormSection title={t('deployment-settings')}>
      <div className="row-md-12 pt-3">
        <div className="col-sm-6">
          <Timezone name={['deploymentTimezone']} extra={t('select-the-timezone-you-want-to-use-for-tvguide-import-and-other-content-related-stuff')} />
        </div>
      </div>
      <div className="row-md-12 pt-5">
        <div className="col-sm-6">
          <CountryCurrencySelect countries={countries ? countries : []} name={['countries']} extra={t('select-the-countries-and-currencies-you-want-to-use-for-this-deployment')} />
        </div>
      </div>
      <div className="row-md-12 pt-3">
        <div className="col-sm-6">
          <MultipleLanguagesSelector languages={languages ? languages : []} name={['languages']} extra={t('select-the-languages-you-want-to-use-for-this-deployment')} />
        </div>
      </div>
    </FormSection>
  );
};

export default DeploymentSettings;

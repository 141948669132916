import { Promotions_KaiFullscreen } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/promotions/kaifullscreen/kaifullscreen";
import { Promotions_KaiFullscreen as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/promotions/kaifullscreen/kaifullscreen";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Promotions_KaiFullscreenService {
    Add_Promotions_KaiFullscreen = async (data: Promotions_KaiFullscreen): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.KAIFULLSCREEN.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Promotions_KaiFullscreen_Template = async (data: Promotions_KaiFullscreen): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.KAIFULLSCREEN.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Promotions_KaiFullscreens = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.KAIFULLSCREEN.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Promotions_KaiFullscreen = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.PROMOTIONS.KAIFULLSCREEN.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Promotions_KaiFullscreen = async (data: Promotions_KaiFullscreen): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.PROMOTIONS.KAIFULLSCREEN.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Promotions_KaiFullscreen = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.PROMOTIONS.KAIFULLSCREEN.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Promotions_KaiFullscreenService();

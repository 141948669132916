import { Form, Input, Modal } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import LayoutPng from '../../../../assets/images/appbuilder/Layout.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
const { t } = useTranslation();

  useEffect(() => {
    if (data.layout != undefined) {
      form.setFieldsValue(data.layout);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var layout = {
      columns_mobile: values.columns_mobile,
      columns_tablet: values.columns_tablet,
      columns_browser: values.columns_browser,
      columns_television: values.columns_television,
    };

    let dataOut = {
      ...data,
      layout: layout,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-layout-settings')}</h4>
            <p>{t('define-the-number-of-columns-you-want-see-per-device-type-for-this-element')}</p>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row">
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('columns-mobile')}</label>
                        <Form.Item className="form-group pb-0 col-md-8" name="columns_mobile">
                          <Input defaultValue={0} max={6} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('component-name')} />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('columns-tablet')}</label>
                        <Form.Item className="form-group pb-0 col-md-8" name="columns_tablet">
                          <Input defaultValue={0} min={3} max={9} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('component-name')} />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('columns-browser')}</label>
                        <Form.Item className="form-group pb-0 col-md-8" name="columns_browser">
                          <Input defaultValue={0} min={4} max={12} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('component-name')} />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('columns-television')}</label>
                        <Form.Item className="form-group pb-0 col-md-8" name="columns_television">
                          <Input defaultValue={0} min={3} max={9} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('component-name')} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col card-bg-light p-4 mb-2 mr-1">
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={LayoutPng} alt={''}></img>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-layout')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import { useTranslation } from 'react-i18next';
import { Input, Item } from '../../../../../common/components/form';
import { Card as AntdCard } from 'antd';
import { TransactionReporting } from '@mwaretv/database/build/backend/models/reporting/transactions/transaction';
import { TransactionType } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';

export const TransactionDetails = ({ data }: { data: TransactionReporting }) => {
  const {t} = useTranslation();
    return (
      <>
        <AntdCard title={t('general')}>
          <Item className="col-md-8" label={t('date')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.date_unix ? new Date(data.date_unix * 1000).toString() : undefined} disabled />
          </Item>
          <Item className="col-md-8" label={t('transaction-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.transaction_subtype} disabled />
          </Item>
          <Item className="col-md-8" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.source} disabled />
          </Item>
          <Item className="col-md-8" label={t('amount-excluding-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.amount_ex_vat?.toFixed(2)} disabled />
          </Item>
          <Item className="col-md-8" label={t('amount-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.amount_vat?.toFixed(2)} disabled />
          </Item>
          <Item className="col-md-8" label={t('amount-including-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.amount_inc_vat?.toFixed(2)} disabled />
          </Item>
          <Item className="col-md-8" label={t('payment-provider')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.customer?.payment_provider} disabled />
          </Item>
          {/* <Item className="col-md-8" label={t('payment-reference-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.payment_provider_ref_id} disabled />
          </Item> */}
        </AntdCard>
        {data.transaction_maintype === TransactionType.SUBSCRIPTION &&
        <AntdCard title={t('subscription')} style={{ marginTop: 16 }}>
          <Item className="col-md-8" label={t('name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.subscription?.name} disabled />
          </Item>
          <Item className="col-md-8" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.subscription?.type} disabled />
          </Item>
          <Item className="col-md-8" label={t('variant')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.subscription?.variant} disabled />
          </Item>
          <Item className="col-md-8" label={t('plan-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.customer?.plan?.name} disabled />
          </Item>
          <Item className="col-md-8" label={t('duration-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.customer?.plan?.duration} disabled />
          </Item>
          <Item className="col-md-8" label={t('duration-period-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.customer?.plan?.duration_period_in ? t(data.customer?.plan?.duration_period_in) : undefined} disabled />
          </Item>
        </AntdCard>
        }
        {data.transaction_maintype === TransactionType.PAYPERVIEW &&
        <AntdCard title={t('subscription')} style={{ marginTop: 16 }}>
          <Item className="col-md-8" label={t('name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.payperview?.content?.name} disabled />
          </Item>
          <Item className="col-md-8" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.payperview?.content?.mainType} disabled />
          </Item>
        </AntdCard>
        }
      </>
    );
  };
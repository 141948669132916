import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';

class TranslationService {
  Reset_Translation = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.BILLING.TRANSLATIONS.RESET, data, {})
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  Install_Translation = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.BILLING.TRANSLATIONS.INSTALL, data, {})
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  Edit_Translation = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.BILLING.TRANSLATIONS.EDIT, data, {})
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new TranslationService();

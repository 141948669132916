import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../../../common/components/pageTitle';

export const CloudOf = () => {
  const {t} = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('api')} subtitle={t('api-database')} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <iframe width={'100%'} height={"100vh"}  title="frame" sandbox={t('allow-forms-allow-modals-allow-orientation-lock-allow-popups-allow-same-origin-allow-scripts')} seamless allow="fullscreen" loading="lazy" id="myiframe" src="https://thecloudof.media/">
            <p>{t('your-browser-does-not-support-iframes')}</p>
          </iframe>
        </div>
      </div>
    </div>
  );
};

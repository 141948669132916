import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import Apps from './apps'
import AppsAdd from './apps/add'
import AppDetails from './apps/details'
import Bundles from './bundles'
import BundleContent from './bundles/content'
import Lists from './lists'
import ListContent from './lists/content'
import Providers from './providers';
import Upload from './apps/upload';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/details/:_id`}><AppDetails /></Route>
      <Route path={`${path}/add`}><AppsAdd /></Route>
      <Route path={`${path}/upload`}>
        <Upload />
      </Route>
      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><Bundles /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route>
      <Route path={`${path}/providers`}><Providers /></Route>
      <Route path={`${path}/`}><Apps /></Route>
    </Switch>
  )
}

export default Index

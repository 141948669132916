import { useParams } from 'react-router-dom';
import Table from '../../../../../common/components/Content/Table';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';


const Albums = () => {
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { t } = useTranslation();

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
        <h2 className="page-title pt-4">{t('provider-validations')}</h2>
        <p className="h6 font-weight-normal line-height-26">{t('an-overview-providers')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>

      <div className="row">
        <div className="col-12 mt-4">
          <Table service_name={params.deployment} clientName={clientName} type={'games'} variant={'providers'}></Table>
        </div>
      </div>
  
    </div>
  );
};

export default Albums;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDaysFromPeriod = exports.Periods = void 0;
var Periods;
(function (Periods) {
    Periods["DAY"] = "days";
    Periods["WEEK"] = "weeks";
    Periods["MONTH"] = "months";
    Periods["YEAR"] = "years";
})(Periods = exports.Periods || (exports.Periods = {}));
const getDaysFromPeriod = (period) => {
    const mapper = {
        [Periods.DAY]: 1,
        [Periods.WEEK]: 7,
        [Periods.MONTH]: 30,
        [Periods.YEAR]: 365
    };
    return mapper[period];
};
exports.getDaysFromPeriod = getDaysFromPeriod;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import ChannelsIndex from './channels/index'
import ChannelsAdd from './channels/add'
import ChannelDetails from './channels/details/index'
import BundlesIndex from './bundles'
import BundleContent from './bundles/content'
import Lists from './lists'
import Settings from './settings'
import ListContent from './lists/content'
import Recordings from './recordings'
import Providers from './providers';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><ChannelsAdd /></Route>
      <Route path={`${path}/details/:_id`}><ChannelDetails /></Route>
      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><BundlesIndex /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route>
      <Route path={`${path}/settings`}><Settings /></Route>
      <Route path={`${path}/recordings`}><Recordings /></Route>
      <Route path={`${path}/providers`}><Providers /></Route>
      <Route path={`${path}/`}><ChannelsIndex /></Route>
    </Switch>
  )
}

export default Index

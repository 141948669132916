import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Select, Switch, Tooltip } from 'antd';
import Card from '../../../../../common/components/card';
import { CardForm, FormHeading, FormHeadingWithButton, Input, Item, Modal, ModalFormWrapper, Table, DatePicker, PrimaryButton } from '../../../../../common/components/form';
import { useProducts } from '../../../../../services/areas/billing/productService';
import { useParams } from 'react-router';
import { CustomerTitle } from '@mwaretv/database/build/backend/enums/customers/customerTitle';
import { CustomerStatus } from '@mwaretv/database/build/backend/enums/customers/customerStatus';
import { validPhoneNumber } from '../../../../../utils/validators';
import { Product } from '../../../../../interfaces/forms/areas/billing/products/product';
import { getCommunications, getTransactions, useCustomerLogs, useCustomerTickets } from '../../../../../services/areas/customers/customerService';
import { CustomerLogAction } from '@mwaretv/database/build/backend/enums/customers/customerLogActions';
import { CustomerLogSource } from '@mwaretv/database/build/backend/enums/customers/customerLogSource';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { TicketTableTable } from '../../tickets/list';
import dayjs from 'dayjs';
import { Spin } from '../../../../../common/components/spinner';
import { CustomersContext } from '../../../../../contexts/customersContext';
import { useShoppingCarts } from '../../../../../services/areas/billing/shoppingCartService';
import { IMSNameParams } from '../../../../dashboard';
import PhoneInput from 'react-phone-input-2';
//import 'react-phone-input-2/lib/style.css';
import { CountrySelect } from './countrySelector';
import { Plan } from '../../../../../interfaces/forms/areas/billing/products/plan';
import { NamePath } from 'antd/lib/form/interface';
import { ICountry } from '../../../../../interfaces/responses/common/ICountriesResponse';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TransactionDetails } from './transactionsDetails';
import { CommunicationsDetails } from './communicationsDetails';
import axiosInstance from '../../../../../utils/axios';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { CommonContext } from '../../../../../contexts/commonContext';
import { CommunicationType } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';
import { CommunicationReporting } from '@mwaretv/database/build/backend/models/reporting/communications/communication';
import { TransactionReporting } from '@mwaretv/database/build/backend/models/reporting/transactions/transaction';

export const CustomerFormFields = (params: { hideDates: boolean, shoppingCartSelected: boolean }) => {

  const [shoppingCartSelectedState, setShoppingCartSelected] = useState<boolean | undefined>(params.shoppingCartSelected);

  return (
    <>
      <Card>
        <ShoppingCartFields setShoppingCartSelected={setShoppingCartSelected} />
      </Card>
      {shoppingCartSelectedState && 
      <Card>
        <ProductSelectionFields hideDates={params.hideDates}  />
      </Card>
      }
      <Card>
        <CustomerDataFields />
      </Card>
      <Card>
        <CustomerAccountFields />
      </Card>
    </>
  );
};

export const RenewChangeFormFields = (params: { customer: any; hideDates: boolean }) => {
  return (
    <>
      {/* <Card>
        <ShoppingCartFields />
      </Card> */}
      <Card>
        <ProductSelectionFields customer={params.customer} hideDates={params.hideDates} />
      </Card>
    </>
  );
};

export const ShoppingCartFields = ({ setShoppingCartSelected }: { setShoppingCartSelected?: (shoppingCartSelected: boolean) => void }) => {
  const { clientName } = useParams<IMSNameParams>();
  const {t} = useTranslation();
  const { service, deployment } = React.useContext(CustomersContext);
  const { data: shoppingCarts } = useShoppingCarts({
    params: {
      instance: clientName,
      cms: deployment?.name ?? '',
      crm: service?.name ?? '',
      page: 1,
      limit: 9999,
    },
  });

  const options = shoppingCarts?.data?.docs.map<{ label: string; value: string | undefined | null }>((cart) => ({
    value: cart._id,
    label: cart.name,
  }));

  options?.splice(0, 0, {
    label: t('no-shopping-cart'),
    value: null,
  });

  return (
    <>
      <FormHeading>{t('shopping-cart')}</FormHeading>
      <Item className="col-md-8" label={t('shopping-cart')} rules={[{ required: true, message: t('shopping-cart-is-required') }]} name={['shopping_cart']} extra={t('select-the-shopping-cart-that-this-customer-should-use')}>
        <Select defaultValue={null} options={options} onChange={(value) => {

          if (setShoppingCartSelected) {
            setShoppingCartSelected(value != null);
          }

        }}

        />
      </Item>
    </>
  );
};

export const CustomerAccountFields = () => {
  const allowLogin = Form.useWatch(['can_login']);
  const {t} = useTranslation();
  return (
    <>
      <FormHeading>{t('authentication')}</FormHeading>
      <Item className="col-md-8" label={t('allow-login')} name={['can_login']} extra={t('allowing-users-to-their-own-customer-portal-and-manage-their-account')}>
        <Switch checked={allowLogin} />
      </Item>
      <Item className="col-md-8" label={t('username')} name={['username']} extra={t('the-username-for-the-apps-and-portal')} rules={[{ required: true, message: t('username-is-required')}]}>
        <Input />
      </Item>
      <Item className="col-md-8" label={t('password')} name={['password']} extra={t('the-password-for-the-apps-and-portal')} rules={[{ required: true, message: t('password-is-required') }]}>
        <Input />
      </Item>
      <Item className="col-md-8" name={['password_app']}>
        <Input hidden />
      </Item>
    </>
  );
};
export const CustomerAccountFieldsLocked = () => {
  const allowLogin = Form.useWatch(['can_login']);
  const username = Form.useWatch(['username']);
  const {t} = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(CustomersContext);
  const [isSendingMail, setIsSendingMail] = React.useState(false);

  const SendRecoveryMessage = async () => {
    setIsSendingMail(true);
    try {
      const instance = clientName;
      const cms = deployment?.name || '';
      const crm = service?.name || '';
      await axiosInstance.post(`v1/customers/customers/recovery?instance=${instance}&cms=${cms}&crm=${crm}`, { username });
    } catch (e) {}
    setIsSendingMail(false);
  };

  return (
    <>
      <FormHeading>{t('authentication')}</FormHeading>
      <Item className="col-md-8" label={t('allow-login')} name={['can_login']} extra={t('allowing-users-to-their-own-customer-portal-and-manage-their-account')}>
        <Switch checked={allowLogin} />
      </Item>
      <Item className="col-md-8" label={t('username')} name={['username']} extra={t('the-username-for-the-apps-and-portal')} rules={[{ required: true, message: t('username-is-required') }]}>
        <Input disabled />
      </Item>
      <Item className="col-md-8" label={t('password')}>
        <div>{t('passwords-are-hashed-and-only-known-by-the-customer')}</div>
      </Item>
      <div style={{ float: 'right' }}>
        <PrimaryButton disabled={isSendingMail} onClick={() => SendRecoveryMessage()}>
          {isSendingMail ? t('sending-message') : t('send-password-reset-message')}
        </PrimaryButton>
      </div>
    </>
  );
};

export const BillingCountrySelect: React.FC<{ name: NamePath; extra: string; countries?: ICountry[]; useIso3?: boolean }> = ({ extra, countries, useIso3, ...props }) => {
  return (
    <Select
      {...props}
      showSearch
      loading={!countries}
      mode={undefined}
      allowClear
      optionLabelProp="label"
      optionFilterProp="children"
      filterOption={(input, option) => {
        return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
      }}
    >
      {(countries ?? []).map((country: ICountry) => (
        <Select.Option value={useIso3 ? country.iso3 : country._id} label={`${country.emoji} ${country.name}`}>
          {`${country.emoji} ${country.name}, ${country.currency}`}
        </Select.Option>
      ))}
    </Select>
  );
};

export const CustomerDataFields = ({ isInAddMode = true }: { isInAddMode?: boolean }) => {
  const { countries } = useContext(CommonContext);
  const form = Form.useFormInstance();
  const { service } = useContext(CustomersContext);
  const {t} = useTranslation();
  const emailValue = Form.useWatch('email');
  const mobileValue = Form.useWatch('mobile');

  return (
    <>
      <FormHeading>{t('customer-data')}</FormHeading>
      <Item className="col-md-8" name={['title']} label={t('title')}>
        <Select options={Object.values(CustomerTitle).map((title) => ({ value: title, label: title }))} />
      </Item>
      <Item className="col-md-8" name={['first_name']} label={t('firstname')}>
        <Input />
      </Item>
      <Item className="col-md-8" name={['last_name']} label={t('lastname')}>
        <Input />
      </Item>
      <Item hidden className="col-md-8" name={['status']} label={t('status')}>
        <Input hidden defaultValue={CustomerStatus.ACTIVE} />
      </Item>
      <Item
        className="col-md-8"
        name={['email']}
        label={t('email')}
        rules={[
          { required: !mobileValue, message: t('email-or-mobile-required'), type: 'email' },
          { type: 'email', message: t('invalid-email') },
        ]}
      >
        <Input />
      </Item>
      <Item
        className="col-md-8"
        name={['phone']}
        label={t('phone')}
        rules={isInAddMode ? [
          {
            validator: (rule, value) => {
              if (!value) {
                return Promise.resolve();
              }

              return validPhoneNumber(rule, value);
            },
            message: t('the-input-is-not-a-valid-phone-number'),
          },
        ] : undefined}
      >
        {isInAddMode &&
          <PhoneInput specialLabel="" value={form.getFieldValue('phone') ?? ''} onChange={(value) => form.setFieldsValue({ phone: value })} country={'us'} inputClass={'ant-input form-control'} inputStyle={{ width: '100%', border: '1px solid #e9edf4', fontSize: '14px' }} buttonStyle={{ border: 'none', backgroundColor: 'transparent' }} />
        }
        {!isInAddMode &&
          <Input />
        }
      </Item>
      <Item
        className="col-md-8"
        name={['mobile']}
        label={t('mobile')}
        rules={isInAddMode ? [
          { required: !emailValue, message: t('mobile-or-email-required')},
          {
            validator: (rule, value) => {
              if (!value) {
                return Promise.resolve();
              }
              return validPhoneNumber(rule, value);
            },
            message: t('invalid-phone-number'),
          },
        ] : undefined}
      >
      {isInAddMode &&
        <PhoneInput specialLabel="" value={form.getFieldValue('mobile') ?? ''} onChange={(value) => form.setFieldsValue({ mobile: value })} country={'us'} inputClass={'ant-input form-control'} inputStyle={{ width: '100%', border: '1px solid #e9edf4', fontSize: '14px' }} buttonStyle={{ border: 'none', backgroundColor: 'transparent' }} />
      }
      {!isInAddMode &&
        <Input />
      }
      </Item>
      <Item className="col-md-8" name={['street']} label={t('street')}>
        <Input />
      </Item>
      <Item className="col-md-8" name={['zipcode']} label={t('zipcode')}>
        <Input />
      </Item>
      <Item className="col-md-8" name={['state']} label={t('state')}>
        <Input />
      </Item>
      <Item className="col-md-8" name={['city']} label={t('city')}>
        <Input />
      </Item>
      <Item className="col-md-8" label={t('country')} rules={[{ required: true, message: t('country-is-required') }]}>
        <CountrySelect useName name={['country']} countries={countries} extra={''} />
      </Item>
      <Item className="col-md-8" name={['billing_country']} label={t('billing-country')} rules={[{ required: true, message: t('billing-country-is-required') }]}>
        <BillingCountrySelect name={['billing_country']} countries={(service?.countries ?? []) as any} extra={''} useIso3 />
      </Item>
    </>
  );
};

export const ProductSelectionFields = (params: { customer?: any; hideDates?: boolean }) => {
  return (
    <>
      <BaseProductSelectionFields customer={params.customer} hideDates={params.hideDates} />
      <ExtraProductsSelectionFields customer={params.customer} hideDates={params.hideDates} />
    </>
  );
};

const BaseProductSelectionFields = (params: { customer?: any; hideDates?: boolean }) => {
  const form = Form.useFormInstance();
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const { deployment } = useContext(CustomersContext);
  const { isLoading, data } = useProducts({ params: { instance: clientName, cms: deployment?.name ?? '', crm: serviceName, limit: 9999, page: 1, filter: { type: 'BASE' } } });
  const products = data?.data.docs || [];
  const productId: string = Form.useWatch(['subscriptions', 'base', 'product'], { form, preserve: true })?._id;
  const shoppingCartId = Form.useWatch(['shopping_cart'], { form, preserve: true });
  const product = products.find((x) => x._id === productId);
  const {t} = useTranslation();
  const { data: shoppingCarts } = useShoppingCarts({
    params: {
      instance: clientName,
      cms: deployment?.name ?? '',
      crm: serviceName,
      page: 1,
      limit: 9999,
    },
  });

  const selectedShoppingCart = shoppingCarts?.data.docs.find((cart) => cart._id === shoppingCartId);
  const shoppingCartBaseProducts = !selectedShoppingCart || params.hideDates != undefined  ? products : products.filter((p) => selectedShoppingCart?.products.find((x) => x.toString() === p._id));

  useEffectOnce(() => {
    if (params.customer != undefined) {
      form.setFieldsValue(params.customer);
    }
  });

  return (
    <>
      <FormHeading subtitle={t('the-base-product-is-the-main-subscription-of-the-customer-choose-a-product-and-plan-to-make-the-included-services-and-packages-available-to-the-customer')}>{t('base-product')}</FormHeading>
      <Item hidden name={['shopping_cart']} />
      <Item hidden name={['subscriptions', 'base', 'product']} />
      <Item className="col-md-8" name={['subscriptions', 'base', 'product', '_id']} label={t('base-product')} extra={t('the-base-product-attached-to-the-customer')}>
        <Select
        disabled={params.hideDates == undefined ? true : false}
          showSearch
          loading={isLoading}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
          }}
          onChange={(value) => {
            
            form.setFieldValue(
              ['subscriptions', 'base', 'product'],
              shoppingCartBaseProducts.find((x) => x._id === value)
            );
            form.setFieldValue(['subscriptions', 'base', 'plan'], shoppingCartBaseProducts.find((x) => x._id === value)?.plans?.plans[0]);
          }}
          options={shoppingCartBaseProducts.map((p) => ({ value: p._id, label: p.name }))}
        />
      </Item>
      <Item hidden name={['subscriptions', 'base', 'plan']} />
      <Item className="col-md-8" name={['subscriptions', 'base', 'plan', '_id']} label={t('base-product-plan')} extra={t('the-plan-you-want-the-customer-to-be-on')}>
        <Select
             disabled={params.hideDates == undefined ? true : false}
          showSearch
          loading={isLoading}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
          }}
          onChange={(value) =>
            form.setFieldValue(
              ['subscriptions', 'base', 'plan'],
              product?.plans?.plans.find((x) => x._id === value)
            )
          }
          options={(product?.plans?.plans || []).map((plan: any) => ({ value: plan._id, label: `${plan.name} (${plan.duration} ${plan.duration_period_in})` }))}
        />
      </Item>
      <div hidden={params.hideDates}>
        <Item valuePropName={'value'} className="col-md-8" label={t('activation-date')} name={['subscriptions', 'base', 'activation_date']} extra={t('the-activation-date-shows-from-when-the-subscription-has-become-active')}>
          <Input disabled />
        </Item>
        <Item valuePropName={'value'} className="col-md-8" label={t('expiration-date')} name={['subscriptions', 'base', 'expiration_date']} extra={t('the-expiration-date-shows-till-when-the-subscription-will-be-active')}>
          <Input disabled />
        </Item>
      </div>
    </>
  );
};

const ExtraProductsSelectionFields = (customer?: any) => {
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const { deployment } = useContext(CustomersContext);
  const { isLoading, data } = useProducts({ params: { instance: clientName, cms: deployment?.name ?? '', crm: serviceName, limit: 9999, page: 1, filter: { type: 'EXTRA' } } });
  const {t} = useTranslation();
  const products = data?.data.docs || [];
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const form = Form.useFormInstance();
  const extraProducts = Form.useWatch(['subscriptions', 'extras']);
  const selectedIds: string[] = (extraProducts || []).map((x) => x.product._id);
  const shoppingCartId = Form.useWatch(['shopping_cart']);

  const { data: shoppingCarts } = useShoppingCarts({
    params: {
      instance: clientName,
      cms: deployment?.name ?? '',
      crm: serviceName,
      page: 1,
      limit: 9999,
    },
  });

  const selectedShoppingCart = shoppingCarts?.data.docs.find((cart) => cart._id === shoppingCartId);
  const shoppingCartExtraProducts = products.filter((p) => selectedShoppingCart?.products.find((x) => x.toString() === p._id));
  const filteredProducts = shoppingCartExtraProducts.filter((x: Product) => !selectedIds.includes(x._id));

  // subscriptions: {
  //   base: {
  //     ...customer.subscriptions?.base,
  //     activation_date: customer.subscriptions?.base?.activation_date ? (dayjs.unix(customer.subscriptions.base.activation_date) as any) : undefined,
  //     expiration_date: customer.subscriptions?.base?.expiration_date ? (dayjs.unix(customer.subscriptions.base.expiration_date) as any) : undefined,
  //   },
  //   extras: (customer.subscriptions?.extras || []).map((x) => ({
  //     ...x,
  //     activation_date: x.activation_date ? (dayjs.unix(x.activation_date) as any) : undefined,
  //   })),
  // }

  return (
    <>
      <FormHeadingWithButton title={t('extra-products')} buttonText={t('add-extra-product-s')} onClick={() => setModalOpen(true)} subtitle={t('extra-products-are-additional-products-extending-the-base-product-add-extra-products-to-the-customer-to-give-them-access-to-the-included-services-and-packages')} />
      <Item hidden name={['shopping_cart']} />
      <Item className="col-md-8" hidden name={['subscriptions', 'extras']} />
      <Table
        dataSource={(extraProducts || []).map((x) => {
          // TODO The types are a lie, the field is now a populated document
          const product = products.find((p) => p._id === (x.product as any)?._id);
          const plan = (product?.plans?.plans || []).find((p) => p._id === (x.plan as any)?._id);

          return {
            product,
            image: product?.external?.images[0]?.url,
            productId: product?._id,
            productName: product?.name,
            plan: `${plan?.name} (${plan?.duration} ${plan?.duration_period_in})`,
            planId: plan?._id,
            activationDate: x.activation_date,
            planObject: plan,
            expirationDate: x.expiration_date,
          };
        })}
        columns={[
          { title: t('product'), dataIndex: 'productName' },
         // { title: t('plan'), dataIndex: 'plan' },
          // { title: t('activation-date'), dataIndex: 'activationDate' },
          // { title: t('expiration-date'), dataIndex: 'expirationDate' },
          { title: t('actions'), width: '25%', render: (row) => <ExtraProductTableActions {...{ row, products: filteredProducts }} /> },
        ]}
      />
      <ExtraProductModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        products={filteredProducts}
        onFinish={(values) => {
          
          form.setFieldValue(['subscriptions', 'extras'], [...(extraProducts || []), ...[values]]);
          setModalOpen(false);
        }}
        {...{ isLoading }}
      />
    </>
  );
};

type ExtraProductTableActionsProps = {
  row: {
    image: string;
    productId: string;
    productName: string;
    product: Product;
    plan: Plan;
    activationDate: dayjs.Dayjs;
    planId: string;
  };
  products: Product[];
};

const ExtraProductTableActions = ({ row, products }: ExtraProductTableActionsProps) => {
  const form = Form.useFormInstance();
  const extraProducts = Form.useWatch(['subscriptions', 'extras']);
  const {t} = useTranslation();
  return (
    <>
      <Col style={{ display: 'flex', flexDirection: 'row', height:60 }}>
        <Tooltip placement="top" title={t('delete')}>
          <Button
            type="text"
            className="mx-2"
            onClick={() => {
              const filtered = extraProducts.filter((x) => x.product._id !== row.productId);
              form.setFieldValue(['subscriptions', 'extras'], filtered);
            }}
          >
            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
            <div>{t('delete')}</div>
          </Button>
        </Tooltip>
      </Col>
    </>
  );
};

type ExtraProductModalProps = {
  open: boolean;
  onCancel: () => void;
  onFinish: (values: { product: string; plan: string; activation_date: dayjs.Dayjs | null }) => void;
  products: Product[];
  isLoading: boolean;
  initialValues?: { product: string; plan: string; activation_date?: dayjs.Dayjs };
};

const ExtraProductModal = ({ open, onCancel, onFinish, products, isLoading, initialValues }: ExtraProductModalProps) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const productId: string = Form.useWatch(['product'], form)?._id;
  const product = products.find((x) => x._id === productId);

  //formInstance.setFieldValue(['plan'], null);

  return (
    <Modal width={720} {...{ open, onCancel }} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <ModalFormWrapper>
        <CardForm
          {...{ form, initialValues }}
          buttonBgWhite
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values: { product: string; plan: string; activation_date: any }) => {
            onFinish({ ...values, activation_date: values.activation_date });
            form.resetFields();
          }}
        >
          <FormHeading>{t('add-extra-product')}</FormHeading>
          <Item hidden name={['product']} />
          <Item className="col-md-12" label={t('extra-product')} name={['product', '_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
            <Select
              showSearch
              loading={isLoading}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
              }}
              onChange={(value) => {
                form.setFieldValue(
                  ['product'],
                  products.find((x) => x._id === value)
                );
                form.setFieldValue(['plan'], products.find((x) => x._id === value)?.plans?.plans[0]);
              }}
              options={products.map((p) => ({ value: p._id, label: p.name }))}
            />
          </Item>
          <Item hidden name={['plan']} />
          <Item className="col-md-12" label={t('extra-product-plan')} name={['plan', '_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
            <Select
              showSearch
              loading={isLoading}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
              }}
              onChange={(value) =>
                form.setFieldValue(
                  ['plan'],
                  product?.plans?.plans.find((x) => x._id === value)
                )
              }
              options={(product?.plans?.plans || []).map((plan: any) => ({ value: plan._id, label: `${plan.name} (${plan.duration} ${plan.duration_period_in})` }))}
            />
          </Item>
          {!product?.settings.start_from_login ? (
            <Item valuePropName={'value'} className="col-md-8" label={t('activation-date')} name={['activation_date']} extra={t('the-activation-date-determines-from-when-the-subscription-will-become-active-activate-start-from-login-in-your-product-if-you-want-the-activation-date-to-be-set-when-the-customer-logs-in-for-the-first-time')}>
              <DatePicker />
            </Item>
          ) : (
            <Item hidden valuePropName={'value'} className="col-md-8" label={t('activation-date')} name={['activation_date']} extra={t('the-activation-date-determines-from-when-the-subscription-will-become-active-activate-start-from-login-in-your-product-if-you-want-the-activation-date-to-be-set-when-the-customer-logs-in-for-the-first-time')}>
              <DatePicker />
            </Item>
          )}
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

export const LogFields = () => {
  const { clientName, service: serviceName, id } = useParams<{ clientName: string; service: string; id: string }>();
  const [pagination, setPagination] = React.useState<{ limit: number; page: number }>({ page: 1, limit: 50 });
  const {t} = useTranslation();
  const { deployment } = useContext(CustomersContext);
  const { isLoading, isError, data } = useCustomerLogs({ params: { instance: clientName, cms: deployment?.name ?? '', crm: serviceName, ...pagination }, id });

  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong-please-try-again-later')}</span>}
      {!isLoading && !isError && (
        <Table
          dataSource={data.data.docs || []}
          columns={[
            { title: t('action'), render: (row) => <span>{getActionText(row.action)}</span> },
            { title: t('date'), render: (row) => <span>{formatDate(row.createdAt)}</span> },
            { title: t('source'), render: (row) => <span>{getSourceText(row.source)}</span> },
            { title: t('by'), render: (row) => <span>{row.by.name}</span> },
          ]}
          pagination={{
            onChange: (page, pageSize) => setPagination({ page, limit: pageSize }),
          }}
        />
      )}
    </>
  );

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-US').format(date);
  }

  function getSourceText(source: CustomerLogSource) {
    switch (source) {
      case CustomerLogSource.FRONTEND:
        return 'TVMS';
      default:
        return t('unknown');
    }
  }

  function getActionText(action: CustomerLogAction) {

    switch (action) {
      case CustomerLogAction.CREATE:
        return t('customer-created');
      case CustomerLogAction.UPDATE:
        return t('customer-updated');
      case CustomerLogAction.DELETE:
        return t('customer-deleted');
      default:
        return t('unknown');
    }
  }
};

export const TicketFields = () => {
  const { clientName, service: serviceName, id } = useParams<{ clientName: string; service: string; id: string }>();
  const [pagination, setPagination] = React.useState<{ limit: number; page: number }>({ page: 1, limit: 50 });
  const {t} = useTranslation();
  const { deployment } = useContext(CustomersContext);
  const { isLoading, isError, data, refetch } = useCustomerTickets({ params: { instance: clientName, cms: deployment?.name ?? '', crm: serviceName, ...pagination }, id });

  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong-please-try-again-later')}</span>}
      {!isLoading && !isError && <TicketTableTable {...{ isLoading, isError, data, refetch, pagination, setPagination }} />}
    </>
  );
};

interface TableParams {
  pagination?: TablePaginationConfig;
}

export const TransactionsFields = () => {
  const { clientName, service: serviceName, id } = useParams<{ clientName: string; service: string; id: string }>();
  const { deployment } = useContext(CustomersContext);
  const {t} = useTranslation();
  const [transactions, setTransactions] = useState<TransactionReporting[]>([]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      const { data } = await getTransactions(clientName, deployment?.name ?? '', serviceName, id, tableParams.pagination!.current!, tableParams.pagination!.pageSize!);

      setTableParams((prevTableParams) => ({
        pagination: {
          ...prevTableParams.pagination,
          total: data.totalDocs,
        },
      }));

      setTransactions(data.docs);
      setLoading(false);
    };

    fetchTransactions();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTransactions([]);
    }
  };

  const columns: ColumnsType<TransactionReporting> = [
    {
      title: t('date'),
      dataIndex: 'date_unix',
      width: '15%',
      render: (value) => new Date(value * 1000).toString(),
    },
    {
      title: t('type'),
      dataIndex: 'transaction_subtype',
      width: '15%',
    },
    {
      title: t('source'),
      dataIndex: 'source',
      width: '15%',
    },
    {
      title: t('currency'),
      width: '15%',
      render: (value, record) => {
        return record.customer?.billing_currency;
      }
    },
    {
      title: t('amount-vat'),
      dataIndex: 'amount_vat',
      width: '15%',
      render: (value) => value?.toFixed(2),
    },
    {
      title: t('amount-including-vat'),
      dataIndex: 'amount_inc_vat',
      width: '15%',
      render: (value) => value?.toFixed(2),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={transactions}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      expandable={{
        columnTitle: t('transaction-details'),
        expandedRowRender: (record) => <TransactionDetails data={record} />,
      }}
    />
  );
};

export const CommunicationsFields = () => {
  const { clientName, service: serviceName, id } = useParams<{ clientName: string; service: string; id: string }>();
  const { deployment } = useContext(CustomersContext);
  const {t} = useTranslation();
  const [communications, setCommunications] = useState<CommunicationReporting[]>([]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCommunications = async () => {
      setLoading(true);
      const { data } = await getCommunications(clientName, deployment?.name ?? '', serviceName, id, tableParams.pagination!.current!, tableParams.pagination!.pageSize!);

      setTableParams((prevTableParams) => ({
        pagination: {
          ...prevTableParams.pagination,
          total: data.totalDocs,
        },
      }));

      setCommunications(data.docs);
      setLoading(false);
    };

    fetchCommunications();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCommunications([]);
    }
  };

  const columns: ColumnsType<CommunicationReporting> = [
    {
      title: t('timestamp'),
      dataIndex: 'timestamp',
      width: '30%',
      render: (value) => {
        return <div>{value ? new Date(value * 1000).toString() : undefined}</div>;
      },
    },
    {
      title: t('source'),
      dataIndex: 'source',
      width: '25%',
      render: (value) => {
        return <div>{value}</div>;
      },
    },
    {
      title: t('type'),
      dataIndex: 'type',
      width: '25%',
      render: (value) => {
        return <div>{value}</div>;
      },
    },
    // {
    //   title: t('email-sms'),
    //   dataIndex: '',
    //   width: '20%',
    //   render: (_, record) => {
    //     return <div>{record.type === CommunicationType.EMAIL ? record.email : record.mobile}</div>;
    //   },
    // },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={communications}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      expandable={{
        columnTitle: t('communication-details'),
        expandedRowRender: (record) => <CommunicationsDetails data={record} />,
      }}
    />
  );
};

import { Route, Switch, useRouteMatch } from 'react-router'
import Disclaimer from './services'
import DisclaimerAdd from './services/add'
import DisclaimerDetails from './services/details'

const Index = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            {/* 

      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><Bundles /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route> */}

            <Route path={`${path}/add`}><DisclaimerAdd /></Route>
            <Route path={`${path}/details/:_id`}><DisclaimerDetails /></Route>
            <Route path={`${path}/`}><Disclaimer /></Route>
        </Switch>
    )
}

export default Index

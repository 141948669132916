import React, { useContext } from 'react';
import { Col, Form, Tooltip } from 'antd';
import { Input, Item, InputNumber, FormHeading, Table, FormHeadingWithButton, Modal, Button, ModalFormWrapper } from '../../../../../common/components/form';
import { ExternalFields } from '../../shared/baseProductFields';
import { CardForm } from '../../../../../common/components/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BillingContext } from '../../../../../contexts/billingContext';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { MW_BASE_DB_CURRENCY } from '@mwaretv/database/build/backend/models/base/currency';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Plan } from '../../../../../interfaces/forms/areas/billing/products/plan';
import { useTranslation } from 'react-i18next';

export { CardForm as Form, ExternalFields };

export const FormFields = () => {
  return (
    <>
      <PlanFields />
      <ExternalFields />
    </>
  );
};



export const PlanFields = () => {
  const [planModalOpen, setPlanModalOpen] = React.useState<boolean>(false);
  const formInstance = Form.useFormInstance();
  const tiers = Form.useWatch(['tiers', 'tiers']) || [];
  const {t} = useTranslation();
  return (
    <div className={'card-bg-light mt-5'}>
      <div className={'pl-5 pr-5 pb-3'}>
        <FormHeadingWithButton title={t('wallet-tiers')} subtitle={t('setup-your-wallet-tiers-for-this-wallet-package')} buttonText={t('add-tier')} onClick={() => setPlanModalOpen(true)} />
        <Item hidden name={['tiers', 'tiers']} initialValue={tiers.map((p: any, i: number) => ({ ...p, index: i }))} />
        <Table
          dataSource={tiers.map((p: any, i: number) => ({ ...p, key: i, index: i }))}
          columns={[
            { title: t('name'), dataIndex: 'name', key: 'name' },
            { title: t('amount'), key: 'amount', dataIndex: 'amount' },
            {
              title: t('actions'),

              key: 'actions',
              render: (data) => (
                <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                  <Tooltip placement="top" title={t('delete')}>
                    <Button
                      type="text"
                      className="mx-2"
                      onClick={() => {
                        tiers.splice(data.index, 1);
                        formInstance.setFieldValue(['tiers', 'tiers'], [...tiers]);
                      }}
                    >
                      <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                      <div>{t('delete')}</div>
                    </Button>
                  </Tooltip>
                </Col>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => <PlanPricing key={record.index} plan={record} index={record.index} />,
            expandIcon: ({ expanded, onExpand, record }) => (
              <>
                {!expanded && <CaretRightOutlined onClick={(e) => onExpand(record, e)} />}
                {expanded && <CaretDownOutlined onClick={(e) => onExpand(record, e)} />}
              </>
            ),
          }}
        />
        <PlanModal
          open={planModalOpen}
          onCancel={() => setPlanModalOpen(false)}
          onOk={(values) => {
            formInstance.setFieldValue(['tiers', 'tiers'], [...tiers, ...[{ ...values, prices: [] }]]);
            setPlanModalOpen(false);
          }}
        />
        {/* <FormHeading>Tax Rate</FormHeading>
      <TaxSelect name={['tiers', 'tax_rate']} /> */}
      </div>
    </div>
  );
};

type PlanPricingProps = {
  plan: Plan & { index: number; key: number };
  index: number;
};

const PlanPricing = ({ plan, index }: PlanPricingProps) => {
  const { service } = useContext(BillingContext);
  const currencies = (service?.currencies as unknown as MW_BASE_DB_CURRENCY[]) || [];
  const {t} = useTranslation();
  return (
    <div>
      <FormHeading>{t('price-per-currency')}</FormHeading>
      <Table
        dataSource={currencies.map((c, i) => ({ code: c.isoCurrency, price: plan.prices.find((x) => x.currency === c.isoCurrency)?.price }))}
        columns={[
          { title: t('currency'), dataIndex: 'code' },
          { title: t('price'), dataIndex: 'price' },
          { title: t('actions'), width: '10%', render: (data) => <PlanActions {...{ index, data }} /> },
        ]}
      />
    </div>
  );
};

type PlanActionsProps = {
  index: number;
  data: any;
};

const PlanActions = ({ index, data }: PlanActionsProps) => {
  const formInstance = Form.useFormInstance();
  const [priceModelOpen, setPriceModelOpen] = React.useState<boolean>(false);
  const {t} = useTranslation();
  return (
    <div style={{ height: 60 }}>
      <Tooltip placement="top" title={t('details')}>
        <Button type="text" className="mx-2" onClick={() => setPriceModelOpen(true)}>
          <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
          <div>{t('edit-price')}</div>
        </Button>
      </Tooltip>
      <PlanPriceModal
        price={data.price}
        open={priceModelOpen}
        onCancel={() => setPriceModelOpen(false)}
        onOk={(price) => {
          const tiers: Plan[] = formInstance.getFieldValue(['tiers', 'tiers']) || [];
          const existingIndex = tiers[index].prices.findIndex((x) => x.currency === data.code);
          if (existingIndex === -1) {
            tiers[index].prices.push({
              currency: data.code,
              price,
              credits: 0,
            });
          } else {
            tiers[index].prices[existingIndex].price = price;
            tiers[index].prices[existingIndex].credits = 0;
          }

          formInstance.setFieldValue(['tiers', 'tiers'], [...tiers]);
          setPriceModelOpen(false);
        }}
      />
    </div>
  );
};

type PlanPriceModalProps = {
  open: boolean;
  price: string;
  onCancel: () => void;
  onOk: (price: string) => void;
};

const PlanPriceModal = ({ open, onCancel, onOk, price }: PlanPriceModalProps) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  return (
    <Modal
      {...{ open }}
      onCancel={() => {
        onCancel();
      }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <ModalFormWrapper>
        <CardForm
          buttonBgWhite
          {...{ form }}
          initialValues={{ price }}
          onFinish={() => {
            onOk(form.getFieldValue('price'));
          }}
        >
          <FormHeading>{t('price-curency')}</FormHeading>
          <Item label={t('price')} name={['price']} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <InputNumber  />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

type PlanModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk: (values: { name: string; duration_in_months: number }) => void;
};

const PlanModal = ({ open, onCancel, onOk }: PlanModalProps) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  return (
    <Modal
      {...{ open }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <ModalFormWrapper>
        <CardForm
          {...{ form }}
          layout="vertical"
          onFinish={(values) => {
            onOk(values);
            form.resetFields();
          }}
        >
          <FormHeading>{t('new-tier')}</FormHeading>
          <Item name={['name']} label={t('name')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-name-of-this-tier')}>
            <Input />
          </Item>
          <Item name={['amount']} label={t('amount')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('how-much-credits-for-this-tier')}>
            <InputNumber />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

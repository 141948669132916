import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Button, Col, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemo } from '@fortawesome/pro-regular-svg-icons';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { useTranslation } from 'react-i18next';
import { ErrorReporting } from '@mwaretv/database/build/backend/models/reporting/apps/error';
import type { DocumentType } from '@typegoose/typegoose';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { PaginationRequestData, TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import APPERROR_SERVICE from '../../../../services/areas/appbuilder/applications/apperrors';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat);

export const AppErrorList = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationRequestData>({ pageSize: 50, pageNumber: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [responseData, setResponseData] = useState<TvmsApiResponseData<ErrorReporting[]> | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const requestData: TvmsApiRequestData = {
          queryParams: {
            clientName: clientName,
            deploymentName: deployment?.name ?? "",
          },
          payload: {
            pagination: pagination,
            sorting: {
              fieldName: "timestamp",
              descending: true,
            }
          }
        }

        const responseData = await APPERROR_SERVICE.List_AppErrors(requestData);

        setResponseData(responseData);
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [pagination.pageNumber]);

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('apperrors')} subtitle={t('apperrors-subtitle')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong-sad-smiley')}</div>}
          {!isLoading && !isError && responseData?.data && (
            <ReactBCTable
              isLoading={isLoading}
              totalRecords={responseData.pagination?.totalDocs ?? responseData.data?.length ?? 0}
              pageSize={responseData.pagination?.limit ?? responseData.data?.length ?? 0}
              tableId={'appbuilder_errors_table'}
              columns={[
                {
                  title: t('timestamp'),
                  dataKey: 'timestamp',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.timestamp ? dayjs.unix(row.timestamp).format('lll') : undefined}</div>;
                  },
                },
                {
                  title: t('chart-design-error-type'),
                  dataKey: 'errorType',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.errorType}</div>;
                  },
                },
                {
                  title: t('chart-design-app-version'),
                  dataKey: 'app.version',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.app?.version}</div>;
                  },
                },
                {
                  title: t('chart-design-device-model'),
                  dataKey: 'device.model',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.device?.model}</div>;
                  },
                },
                {
                  title: t('country'),
                  dataKey: 'lcoation.country',
                  width: '20%',
                  cell: (row) => {
                    return <div>{row.location?.country}</div>;
                  },
                },
                {
                  title: t('actions'),
                  dataKey: 'actions',
                  width: '5%',
                  cell: (row) => {

                    const id = (row as DocumentType<ErrorReporting>)?._id;
                    if (!id) {
                      return <></>;
                    }

                    return (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        <Tooltip placement="top" title={t('open-details')}>
                          <Button
                            type="text"
                            className="mx-2"
                            onClick={() => {
                              history.push(`${url}/${id}`);
                            }}
                          >
                            <FontAwesomeIcon className="action-icon" icon={faMemo} />
                            <div>{t('details')}</div>
                          </Button>
                        </Tooltip>
                      </Col>
                    );
                  },
                },
              ]}
              data={responseData.data || []}
              currentPage={responseData.pagination?.page ?? 1}
              onChange={(page) => {
                setPagination({ ...pagination, pageNumber: page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import Tags from './tags';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/tags`}>
        <Tags />
      </Route>
    </Switch>
  );
};

export default Index;

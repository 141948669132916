import axiosInstance from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';
import { AxiosResponse } from 'axios';
import { Deployment, DeploymentService } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { MW_BASE_DB_COUNTRIES } from '@mwaretv/database/build/backend/models/base/countries';
import { MW_BASE_DB_CURRENCY } from '@mwaretv/database/build/backend/models/base/currency';

const { DEPLOYMENTS: ENDPOINTS } = API_ENDPOINTS.V1;

type Params = { client: string; service?: string };

type PaginationParams = {
  page: number;
  limit: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

export const list = ({ params }: { params: Params & PaginationParams }): Promise<AxiosResponse<PaginatedResults<Deployment[]>>> => {
  return axiosInstance.get<PaginatedResults<Deployment[]>>(ENDPOINTS.LIST, {
    params,
  });
};

type ListCountryParams = { params: Params; id: string };

export const listCountries = ({ params, id }: ListCountryParams): Promise<AxiosResponse<Deployment['countries']>> => {
  return axiosInstance.get<Deployment['countries']>(`${ENDPOINTS.GET}/${id}/countries`, { params });
};

export type AddParams = { params: Params } & {
  data: Omit<Deployment, 'id' | '_id'>;
};

export const add = ({ data, params }: AddParams): Promise<AxiosResponse<Deployment>> => {
  return axiosInstance.post<Deployment>(ENDPOINTS.ADD, data, { params });
};

export const softDelete = ({ id, params }: { id: string; params: Params }): Promise<AxiosResponse<Deployment>> => {
  return axiosInstance.delete<Deployment>(`v1/deployments/${id}`, { params });
};

export const serviceSoftDelete = ({ id, serviceId, params }: { id: string; serviceId: string; params: { client: string; service?: string } }): Promise<AxiosResponse<Deployment>> => {
  return axiosInstance.delete<Deployment>(`v1/deployments/${id}/services/${serviceId}`, { params });
};

export type PatchParams = { params: Params } & {
  data: Partial<Deployment>;
  id: string;
};

export const patch = ({ data, params, id }: PatchParams): Promise<AxiosResponse<Deployment>> => {
  return axiosInstance.patch<Deployment>(`${ENDPOINTS.PATCH}/${id}`, data, {
    params,
  });
};

// export type PutParams = { params : Params } & { data : Product, id : string }

// export const put = ({ data, params, id } : PutParams ) : Promise<AxiosResponse<Product>> => {
//   return axiosInstance.put<Product>(`${ENDPOINTS.PUT}/${id}`, data, { params })
// }

// type DeleteParams = { params : Params, id : string }

// export const del = ({ params, id } : DeleteParams) : Promise<AxiosResponse> => {
//   return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params })
// }

type GetParams = { params: Params; id: string };

export const get = ({ params, id }: GetParams): Promise<AxiosResponse<Deployment>> => {
  return axiosInstance.get<Deployment>(`${ENDPOINTS.GET}/${id}`, { params });
};
export const FetchDeployment = async ({ params, id }: GetParams): Promise<Deployment> => {
  return axiosInstance
    .get(`${ENDPOINTS.GET}/${id}/`, { params })
    .then((response) => {
      
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
export type PopulatedService = Omit<DeploymentService, 'currencies' | 'countries' | 'languages'> & {
  languages: MW_BASE_DB_LANGUAGE[];
  countries: MW_BASE_DB_COUNTRIES[];
  currencies: MW_BASE_DB_CURRENCY[];
};

export type PopulatedDeployment = Omit<Deployment, 'currencies' | 'countries' | 'languages' | 'services'> & {
  services: PopulatedService[];
  languages: MW_BASE_DB_LANGUAGE[];
  countries: MW_BASE_DB_COUNTRIES[];
  currencies: MW_BASE_DB_CURRENCY[];
  storage: any;
};

export type DeploymentWithPopulatedServices = Omit<Deployment, 'currencies' | 'countries' | 'languages' | 'services'> & {
  services: PopulatedService[];
};

export const find = (client: string, searchParams: Partial<Deployment>): Promise<AxiosResponse<PopulatedDeployment>> => {
  return axiosInstance.get<PopulatedDeployment>(`${ENDPOINTS.GET}/find`, {
    params: { client, s: { ...searchParams } },
  });
};

import { Form, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import messageList from '../../../../services/areas/appbuilder/applications/messages';
import { useParams } from 'react-router-dom';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [contentList, setContentList] = useState([] as any);
  const [text, setText] = useState([] as any);
  const [loading, setLoading] = useState(true);

  const onSubmitLocal = (values: any) => {
    var trans = contentList.find((c) => c._id == text);
    let dataOut = {
      ...data,
      translations: trans,
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    getContentList();
    if (data != undefined) {
      setText(data);
      form.setFieldsValue(data);
    }
    setLoading(false);
  }, [data, form]);

  const getContentList = () => {
    messageList
      .List_Messages({
        pageNumber: 0,
        pageSize: 1000,
        query_params: {
          cms: deployment?.name ?? '',
          instance: clientName,
          path: undefined,
        },
      })
      .then((result: any) => {
        setContentList(result.data.data);
      });
  };
  const setListLocal = (value: any) => {
    setText(value);
  };
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-message-settings')}</h4>
            <p>{t('select-the-message-you-want-to-show')}</p>
            <div className="row mt-5">
              <div className="col-12">
                {!loading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="col">
                      <div className="row" style={{ padding: 15 }}>
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('message-list')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item name={'_id'} className="form-group pb-0">
                                <Select onChange={setListLocal} className="default-select" placeholder={t('select-type')}>
                                  {contentList.map((list: any) => {
                                    return (
                                      <Select.Option value={list._id} key={list._id}>
                                        {list.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('update-message')}
                      </button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

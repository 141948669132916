import { Skeleton, Image } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import moment from 'moment-timezone';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const LogoTime = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid text-left">
      <div className="row" style={{ height: 90 }}>
        <div className="col-sm d-flex flex-wrap align-items-center remove-hor-padding">
          {(props?.application?.branding?.logos?.logo == undefined || props?.application?.branding?.logos?.logo == '') && (
            <div style={{ backgroundColor: '#999' }}>
              <Skeleton.Image active={true} style={{ width: 200, height:50}} />
            </div>
          )}
          {props?.application?.branding?.logos?.logo != undefined && props?.application?.branding?.logos?.logo != '' && <Image src={'https://cloudtv.akamaized.net/' + props?.application?.branding?.logos?.logo.replace('https://cloudtv.akamaized.net/','')} style={{ height: 50, width: 200 }} />}
        </div>
        <div className="col-sm d-flex flex-wrap align-items-center remove-hor-padding">
          <div className="col remove-hor-padding">
            <div className="col-sm  text-right" style={{ fontSize: 40, color: '#fff' }}>
              {moment().format('HH:MM')}
            </div>
            <div className="col-sm text-right" style={{ fontSize: 13, color: '#fff', marginTop: -15 }}>
              {moment().format('ll')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoTime;

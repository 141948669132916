import { DvrProgress } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/misc/dvrprogress/dvrprogress";
import { DvrProgress as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/misc/dvrprogress/dvrprogress";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class DvrProgressService {
    Add_DvrProgress = async (data: DvrProgress): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.MISC.DVRPROGRESS.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_DvrProgress_Template = async (data: DvrProgress): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.MISC.DVRPROGRESS.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_DvrProgresss = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.MISC.DVRPROGRESS.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_DvrProgress = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.MISC.DVRPROGRESS.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_DvrProgress = async (data: DvrProgress): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.MISC.DVRPROGRESS.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_DvrProgress = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.MISC.DVRPROGRESS.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new DvrProgressService();

import { Button,  Form} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import Translations from '../../../Categories/Translations';
import { IMSNameParams } from '../../../../../../pages/dashboard';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { useParams } from 'react-router-dom';

type IUpdatePackageModalProps = {
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: any
};

const CategoryModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [translations, setTranslations] = useState([] as any);
  const { t } = useTranslation();
 
  let [form] = useForm();
  useEffect(() => {
    async function fetchData() {
      if (initialData.selectedCategory?.translations && initialData.selectedCategory.translations.length > 0) {
        setTranslations(initialData.selectedCategory.translations);
      }
    }
    fetchData();
  }, []);

  const onSubmitLocal = (values) => {
    var id = initialData._id;
    onSubmit(
      {
        ...values,
        ...initialData.selectedCategory,
        translations,
        id,
      },
      initialData.newCat
    );
  };


  return (
    <div className="p-5" style={{ border: '1px solid #e2e2e2', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
            <div className="mt-2"></div>
            <Translations service_name={deployment} clientName={clientName} extra={initialData.name} data={translations} setTranslations={setTranslations} />

            <div className="col-md-12 p-0">
              {initialData.newCat && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                  {t('add-category')}
                </Button>
              )}
              {!initialData.newCat && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                  {t('update-category')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;

import axiosInstance from "../../../utils/axios";
import { API_ENDPOINTS } from "../../_APIConstants";
import { ShoppingCart } from "../../../interfaces/forms/areas/billing/shoppingCart"
import { AxiosResponse } from "axios";
import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";

const { BILLING : BILLING_ENDPONTS } = API_ENDPOINTS
const { SHOPPING_CARTS : ENDPOINTS } = BILLING_ENDPONTS

type Params = { instance: string, cms: string, crm: string }

type PaginationParams = {
  page : number,
  limit : number
}

type ResponseFormat<T> = {
  docs : T
}

type PaginatedResults<T> = {
  hasNextPage : boolean
  hasPrevPage : boolean
  limit : number
  nextPage : number | null
  page : number
  pagingCounter : number
  prevPage : null | number
  totalDocs : number,
  totalPages : number
} & ResponseFormat<T>

export const list = ({ params } : { params : Params & PaginationParams}) : Promise<AxiosResponse<PaginatedResults<ShoppingCart[]>>> => {
  return axiosInstance.get<PaginatedResults<ShoppingCart[]>>(ENDPOINTS.LIST, { params })
}

export type AddParams = { params : Params } & { data : ShoppingCart }

export const add = ({ data, params } : AddParams ) : Promise<AxiosResponse<ShoppingCart>> => {
  return axiosInstance.post<ShoppingCart>(ENDPOINTS.ADD, data, { params })
}

export type PatchParams = { params : Params } & { data : Partial<ShoppingCart>, id : string }

export const patch = ({ data, params, id } : PatchParams ) : Promise<AxiosResponse<ShoppingCart>> => {
  return axiosInstance.patch<ShoppingCart>(`${ENDPOINTS.PATCH}/${id}`, data, { params })
}

export type PutParams = { params : Params } & { data : ShoppingCart, id : string }

export const put = ({ data, params, id } : PutParams ) : Promise<AxiosResponse<ShoppingCart>> => {
  return axiosInstance.put<ShoppingCart>(`${ENDPOINTS.PUT}/${id}`, data, { params })
}

type DeleteParams = { params : Params, id : string }

export const del = ({ params, id } : DeleteParams) : Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params })
}

type GetParams = { params : Params, id : string }

export const get = ({ params, id } : GetParams) : Promise<AxiosResponse<ShoppingCart>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params })
}

export const getFonts = (params : Params) => {
  return axiosInstance.get<{
    items: {
      kind : string
      family : string,
      variants : string[]
      subsets : string[],
      version : string,
      lastModified : string,
      files : string[]
    }[],
    kind : string
  }>(`${ENDPOINTS.FONTS.GET}`, { params })
}

type GetShoppingParams = {
  clientName : string,
  deploymentId : string,
  serviceName : string,
  id : string
}

export const getShoppingUrl = ({ clientName, deploymentId, serviceName, id } : GetShoppingParams) : Promise<AxiosResponse<{url : string}>> => {
  return axiosInstance.get<{url : string}>(`${ENDPOINTS.GET}/${id}/shopUrl`, { params: { clientName, deploymentId, serviceName } })
}

export const useShoppingCarts = ({ params } : { params : Params & PaginationParams}) => useQuery(['shoppingCart', 'list', params], async () => {
  return list({params})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useShoppingCart = ({ params, id } : GetParams) => useQuery(['shoppingCart', 'get', params, id], async () => {
  return get({params, id})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useShoppingCartPatch = ({ params, id } : Omit<PatchParams, 'data'>, options : Omit<UseMutationOptions<AxiosResponse<ShoppingCart>, unknown, Partial<ShoppingCart>, unknown>, "mutationKey" | "mutationFn">) => useMutation(['products', 'patch', params.instance, params.crm, id], async (values : Partial<ShoppingCart>) => {
  return patch({data: values, params, id})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

export const useFonts = (params : Params) => useQuery(['shoppingCart', 'fonts', 'get', params], async () => {
  return getFonts(params)
  .then(r => Promise.resolve(r))
  .catch(e => Promise.reject(e))
})


import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomersContext } from '../../../../../contexts/customersContext';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export const WatchlistTable = ({ initialData }) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(CustomersContext);
  const [isLoading, setIsLoading] = useState([] as any);
  const [favorites, setFavorites] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/v1/customers/profiles?client=${clientName}&deployment=${deployment?.name}&service=${service?.name}&customer=${initialData?.username}`)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error(t('something-went-wrong'));
          })
          .then((responseJson) => {
            setFavorites(responseJson.watchlist);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    };

    fetchData();
  }, [clientName, deployment]);

  return (
    <>
      {contextHolder}
      {!isLoading && (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #d1d1d1', borderRadius: 5, padding: 10 }}>
        {favorites?.length == 0 && <div style={{ color: '#999' }}>{t('the-watchlist-is-empty')}</div>}
        {favorites?.map((profile: any, index) => {
          return (
            <>
              <div style={{ backgroundColor: '#e9e9e9', padding: 10, borderRadius: 5, color: '#555', marginBottom: 5 }}>
                <b>Profile: {profile.name}</b>
              </div>
              <div
                key={index}
                style={{
                  borderBottom: index < favorites?.length - 1 ? '1px solid #d1d1d1' : 'none',
                  display: 'flex',
                  width: '100%',
                  borderRadius: 5,
                  flexDirection: 'column',
                }}
              >
                {profile.channels != undefined && profile.channels.length > 0 && (
                  <div style={{  padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Channels:</b>
                    </div>
                    {profile.channels.map((channel: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', alignItems: 'center', padding: 20, width: '100%', flexDirection: 'row', borderBottom: index < profile.channels.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={channel.images.square} alt="" style={{ width: 60, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>
                                {channel.number}.{channel.name}
                              </b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.cams != undefined && profile.cams.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Cams:</b>
                    </div>
                    {profile.cams.map((cam: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.cams.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={cam.images.square} alt="" style={{ width: 60, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{cam.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.courses != undefined && profile.courses.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Courses:</b>
                    </div>
                    {profile.courses.map((course: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.courses.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={course.images.widescreen} alt="" style={{ width: 106.5, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{course.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.movies != undefined && profile.movies.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Movies:</b>
                    </div>
                    {profile.movies.map((movie: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.movies.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={movie.images.poster} alt="" style={{ width: 40, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{movie.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.music != undefined && profile.music.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Music:</b>
                    </div>
                    {profile.music.map((music: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.music.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={music.images.square} alt="" style={{ width: 60, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{music.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.podcasts != undefined && profile.podcasts.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Podcasts:</b>
                    </div>
                    {profile.podcasts.map((podcast: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.podcasts.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={podcast.images.square} alt="" style={{ width: 60, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{podcast.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.radios != undefined && profile.radios.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Radio:</b>
                    </div>
                    {profile.radios.map((radio: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.radios.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={radio.images.square} alt="" style={{ width: 60, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{radio.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile.series != undefined && profile.series.length > 0 && (
                  <div style={{ padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    <div style={{ borderBottom: '2px solid #d1d1d1', color: '#777' }}>
                      <b>Series:</b>
                    </div>
                    {profile.series.map((serie: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderBottom: index < profile.series.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 10 }}>
                              <img src={serie.images.widescreen} alt="" style={{ width: 106.5, height: 60, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>{serie.name}</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
      )}
    </>
  );
};

export default WatchlistTable;

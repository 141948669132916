import { Form, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

const Security_ = (params: { setSecurity: any; data?: any }) => {
  let [form] = useForm();
  const [akamai_token, setAkamai] = useState(false);
  const [flussonic_token, setFlussonic] = useState(false);
  const [legacy_token, setLegacy] = useState(false);
  const [drm_enabled, setDrmEnabled] = useState(false);
  const [drm_type, setDrmType] = useState('');
  const {t} = useTranslation();
  
  useEffectOnce(() => {
    form.setFieldsValue(params.data);
    params.setSecurity({
      akamai_token: akamai_token,
      flussonic_token: flussonic_token,
      legacy_token: legacy_token,
      drm: {
        drm_enabled: drm_enabled,
        drm_type: drm_type,
      },
    });
  });

  useEffect(() => {
    if (params.data != undefined) {
      if (params.data.akamai_token != undefined) {
        
        setAkamai(params.data.akamai_token);
        setFlussonic(params.data.flussonic_token);
        setLegacy(params.data.legacy_token);
        setDrmEnabled(params.data.drm.drm_enabled);
        setDrmType(params.data.drm.drm_type);
      }
    }
  }, [params.data]);

  const setDrmTypeLocal = (value: any) => {
    setDrmType(value);
    params.setSecurity({
      akamai_token: akamai_token,
      flussonic_token: flussonic_token,
      legacy_token: legacy_token,
      drm: {
        drm_enabled: drm_enabled,
        drm_type: value,
      },
    });
  };

  // buydrm: {
  //     license_key: string;
  //     expiration: string;
  //     use_hdcp: boolean;
  //     key_package: string;

  //     certificate_url:string;
  //     license_url_widevine: string;
  //     license_url_fairplay: string;
  // }
  // irdeto: {
  //     ic_host: string;
  //     license_url_widevine: string;
  //     license_url_fairplay: string;
  //     account_id: string;
  // }
  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('content-protection')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light p-5">
          <div className="col">
            <h4 className="text-primary mt-2 mb-3">{t('tokenizing')}</h4>
            <p>{t('token-protection-ads-a-security-access-token-to-the-end-of-the-stream-url-access-is-then-granted-based-on-device-ip-and-user-agent')}</p>
            <div className="row">
              <label className="col-md-3 col-form-label">{t('akamai-token')}</label>

              <Form.Item className="col-md-5" name="akamai_token">
                <Switch
                  className="mt-2"
                  onChange={(checked) => {
                    setAkamai(checked);
                    setFlussonic(false);
                    setLegacy(false);
                    params.setSecurity({
                      akamai_token: checked,
                      flussonic_token: false,
                      legacy_token: false,
                      drm: {
                        drm_enabled: drm_enabled,
                        drm_type: drm_type,
                      },
                    });
                  }}
                  checked={akamai_token}
                  defaultChecked={false}
                />
              </Form.Item>
            </div>
            <div className="row">
              <label className="col-md-3 col-form-label">{t('flussonic-token')}</label>
              <Form.Item className="col-md-5" name="flussonic_token">
                <Switch
                  className="mt-2"
                  onChange={(checked) => {
                    
                    setAkamai(false);
                    setFlussonic(checked);
                    setLegacy(false);
                    params.setSecurity({
                      akamai_token: false,
                      flussonic_token: checked,
                      legacy_token: false,
                      drm: {
                        drm_enabled: drm_enabled,
                        drm_type: drm_type,
                      },
                    });
                  }}
                  defaultChecked={false}
                  checked={flussonic_token}
                />
              </Form.Item>
            </div>
            <div className="row">
              <label className="col-md-3 col-form-label">{t('legacy-token-depreciated')}</label>
              <Form.Item className="col-md-5" name="legacy_token">
                <Switch
                  className="mt-2"
                  onChange={(checked) => {
                    setAkamai(false);
                    setFlussonic(false);
                    setLegacy(checked);
                    params.setSecurity({
                      akamai_token: false,
                      flussonic_token: false,
                      legacy_token: checked,
                      drm: {
                        drm_enabled: drm_enabled,
                        drm_type: drm_type,
                      },
                    });
                  }}
                  defaultChecked={false}
                  checked={legacy_token}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col">
            <h4 className="text-primary mt-4 mb-3">{t('digital-rights-management-drm')}</h4>
            <p>{t('drm-or-digital-rights-management-the-stream-gets-scrambled-during-creation-and-gets-unsrambled-upon-a-correct-key-pair-combination-in-the-player')}</p>
            <div className="row">
              <label className="col-md-3 col-form-label">{t('enable-drm')}</label>

              <Form.Item className="col-md-5" name="drm_enabled">
                <Switch
                  className="mt-2"
                  onChange={(checked) => {
                    setDrmEnabled(checked);
                    setDrmType('buydrm');
                    params.setSecurity({
                      akamai_token: akamai_token,
                      flussonic_token: flussonic_token,
                      legacy_token: legacy_token,
                      drm: {
                        drm_enabled: checked,
                        drm_type: 'buydrm',
                      },
                    });
                  }}
                  checked={drm_enabled}
                  defaultChecked={false}
                />
              </Form.Item>
            </div>
            <div className="row">
              {drm_enabled && <label className="col-md-3 col-form-label">{t('drm-provider')}</label>}
              {drm_enabled && (
                <div className="col-md-3">
                  <Select value={drm_type} onChange={(value) => setDrmTypeLocal(value)} className="default-select" placeholder={t('select-provider')}>
                    <Select.Option key={'buydrm'} value={'buydrm'}>
                      BuyDRM
                    </Select.Option>
                    <Select.Option key={'irdeto'} value={'irdeto'}>
                      {t('irdeto')}
                    </Select.Option>
                  </Select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security_;

import { faInboxOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { IFileUpload } from '../../../interfaces/responses/common/IFileUploadResult';
import common from '../../../services/common';

type IUpdateImageUploadModalProps = {
  onSubmit: (values: any) => void;
  initialData: {
    path: string;
    uploadedFile?: any;
    notMandatory?: boolean;
  };
};

const ImageUploader = (props: IUpdateImageUploadModalProps) => {
  const { onSubmit, initialData } = props;
  let [form] = useForm();
  const [upload, setUpload] = useState<IFileUpload>({ name: '' });
  const [error, setError] = useState([] as any);
  const {t} = useTranslation();
  
  const handleFileUpload = async (options: any) => {
    setError('');
    options.onError('');
    
    let response = await common.UploadFile({
      image: options.file,
      query_params: {
        path: initialData.path,
        name: options.file.name,
        type: options.file.type,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setUpload(response.data);
      onSubmit({
        fileName: options.file.name,
        uploaded: response.data,
      });
      options.onSuccess(null, response.data);
    } else {
      if (response.errors != undefined) {
        const message = response.errors[0] as any;
        
        setError(message.message);
        options.onError(() => t('an-error-occured') + message.message);
      }
    }
  };

  useEffectOnce(() => {
    form.setFieldsValue(initialData);
  });

  return (
    <div>
      <div className="col-md-12 card-bg-light">
        <div className="row" style={{ alignItems: 'center' }}>
          <Form.Item
            className="col-md-12 mb-0 mt-4"
            name={'uploaded_file'}
            valuePropName="fileList"
            getValueFromEvent={(e: any) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
            rules={[
              {
                required: initialData.uploadedFile != undefined ? false : initialData.notMandatory != undefined ? false : true,
                message: t('file-is-required'),
              },
            ]}
          >
            <Upload.Dragger
              listType="text"
              className="default-upload"
              maxCount={1}
              showUploadList={{
                showPreviewIcon: true,
              }}
              multiple={false}
              accept=".pem"
              customRequest={(options) => {
                handleFileUpload(options);
              }}
              onRemove={() => {
                setUpload({
                  name: '',
                });
              }}
            >
              <div className="media d-inline-flex align-items-center">
                <FontAwesomeIcon size="2x" className="mr-3" icon={faInboxOut} color="#1ac884" />
                <div className="media-body text-left my-3">
                  <h6 className="mt-0 text-primary">{t('upload-your-file')}</h6>
                  <p className="mb-0 h6 icon-gray font-weight-normal">
                    {t('drag-files-here-or')} <span className="text-secondary"> {t('browse')}</span>
                  </p>
                </div>
              </div>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item name={'file'} shouldUpdate={(pre, current) => pre.value !== current.value}>
            <div hidden>{upload.name}</div>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
export default ImageUploader;

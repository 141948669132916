import React from 'react'
import { useTranslation } from 'react-i18next';

const Caching = () => {
  const { t } = useTranslation();
  return (
    <span>{t('caching')}</span>
  )
}

export default Caching

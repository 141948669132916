import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Rectangle_Rails } from '../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/rectangle/rectangle';
import { getTitle } from './helpers/helper';

export type LayoutProps = {
  data: Rectangle_Rails;
  application: Application;
};

const MenuRails = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  return (
    <div style={{ backgroundColor: props.data.placement.background_color }}>
      <div
        className="container remove-hor-padding"
        style={{
          paddingTop: props.application.theme?.rails_top_margin ?? 5,
          paddingBottom: props.application.theme?.rails_bottom_margin ?? 5,
        }}
      >
        {props.data.content?.list?.show_title != false && (
          <div className="text-left" style={{ marginLeft: 5 }}>
            {props.data.content?.list?.title[0] && props.data.content?.list?.title[0].title != 'Edit to add your text' && <>{getTitle(props.data)}</>}
            {(!props.data.content?.list?.title[0] || props.data.content?.list?.title[0].title == 'Edit to add your text') && <Skeleton.Input active={true} size={'large'} style={{ width: 200, height: 30, backgroundColor: '#bf8c8c' }} />}
          </div>
        )}

        <div style={{ gridAutoColumns: '100%', display: 'grid' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'min-content',
              gridAutoFlow: 'column',
              gridAutoColumns: 175,
              gap: 5,
              overflowX: 'auto',
              overscrollBehaviorInline: 'contain',
              marginTop: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
              <Skeleton.Node active={false} style={{ width: 170, height: 100, backgroundColor: '#999' }}>
                <div className="col-sm remove-hor-padding text-left">
                  <div style={{ backgroundColor: '#7a7a7a', height: 30, width: 150, marginLeft: 10, borderRadius: 5 }}></div>
                </div>
              </Skeleton.Node>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuRails;

import { Route, Switch, useRouteMatch } from 'react-router';
import { CommunicationDetails } from './CommunicationDetails';
import { CommunicationList } from './CommunicationList';

export const Communications = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <CommunicationDetails />
      </Route>
      <Route path={`${path}`}>
        <CommunicationList />
      </Route>
    </Switch>
  );
};

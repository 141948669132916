import { Channel_Settings } from '../../../../../interfaces/forms/areas/content/channels/settings';
import { IGetForm } from '../../../../../interfaces/forms/common/IAPBExtraQuerytParams';
import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Settings_Service {
  Manage_Settings = async (data: Channel_Settings): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(`${API_ENDPOINTS.V1.CMS.CHANNELS.SETTINGS.MANAGE}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Get_Settings = async (data: Channel_Settings): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(`${API_ENDPOINTS.V1.CMS.CHANNELS.SETTINGS.GET}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  //   Get_Settings = async (data: IGetForm): Promise<Response> => {
  //     return await axiosInstance
  //       .get(`${API_ENDPOINTS.V1.CMS.CHANNELS.SETTINGS.GET}`, {
  //         params: {
  //           instance: data.query_params.instance,
  //           cms: data.query_params.cms,
  //         },
  //       })
  //       .then((response) => {
  //         return response.data;
  //       })
  //       .catch((err) => {
  //         return err;
  //       });
  //   };
}

export default new Settings_Service();

import React, { memo, Suspense, useContext } from 'react';
import { matchPath, Switch, useLocation, useParams, useRouteMatch, Route } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import PageNotFound from '../../common/components/PageNotFound';
import { DrawerContext } from '../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../context_api/DrawerContext/action-types';
import { IRoute } from '../../routes/AllAreaRoutes';
import getDatastoresMapping from '../../utils/DatastoresMapping';
import { IMSNameParams } from '../dashboard';
import Billing from './billing';
import Inventory from './inventory';
import Customers from './customers';
import Content from './content';
import AppBuilder from './appbuilder';
import Marketing from './marketing';
import Reporting from './reporting';
import { Spin } from 'antd';
import ReportingNarrowcast from './reporting/reportingnarrowcast';

const AreasLayout = () => {
  function MyLazyMethod(name: any, callToImport: any) {
    const lazyComponentsStore: any = React.useRef({}).current;
    if (!lazyComponentsStore[name]) {
      lazyComponentsStore[name] = React.lazy(callToImport);
    }
    return lazyComponentsStore[name];
  }
  const { path } = useRouteMatch();
  const { url } = useRouteMatch();
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const { service, service_name } = useParams<IMSNameParams>();

  const currentRouteLocation = useLocation();
  interface defaultKeysForSideBar {
    openKey?: string;
    selectedKey?: string;
  }
  const getDefaultOpenAndSelectedKey = (routes: IRoute[], pathToFind: string): defaultKeysForSideBar => {
    let defaultKeys: defaultKeysForSideBar = {};
    for (let i = 0; i < routes.length; i++) {
      let route = routes[i];
      if (route.routes && route.routes?.length > 0) {
        defaultKeys = getDefaultOpenAndSelectedKey(route.routes, pathToFind);
      } else {
        let pathsFound = matchPath(pathToFind, {
          path: route.path,
        });
        if (pathsFound && pathsFound.isExact) {
          return (defaultKeys = {
            openKey: route.key ?? '',
            selectedKey: route.is_invisible && route.highlitedKey ? route.highlitedKey : route.key,
          });
        }
      }
      if (defaultKeys && defaultKeys.openKey && defaultKeys.selectedKey) {
        defaultKeys.openKey = route.key;
        break;
      }
    }
    if (defaultKeys && defaultKeys.openKey && defaultKeys.selectedKey) return defaultKeys;
    return {
      openKey: '',
      selectedKey: '',
    };
  };
  useEffectOnce(() => {
    let subPath = currentRouteLocation.pathname.replace(url + '/', '');
    let routeToLookFor = subPath;
    let routesToRender: IRoute[] = [];
    let defaultSelectedKey = '';
    let defaultOpenKey = '';

    if (!DrawerState.parent_service_name && service !== 'cms' && service !== 'apb') {
      let mappedDatatstores = getDatastoresMapping(DrawerState.client_info?.datastore ?? []);
      let cmsName = '';
      mappedDatatstores.cms_info.map((cmsInfo) => {
        cmsInfo.crm?.forEach((value) => {
          if (service_name === value.name) cmsName = value.cms_name ?? '';
        });
      });

      DrawerDispatch({
        type: DrawerActionTypes.SET_PARENT_SERVICE_INFO,
        parent_service_name: cmsName,
      });
    }
    DrawerDispatch({
      type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
      default_selected_key: defaultSelectedKey,
      default_open_key: defaultOpenKey,
      routes: routesToRender,
      service_name: service_name!,
      service_type: service!,
      client_dashboard_path: path,
    });
  });

  let menuItemRoutes: IRoute[] = [];
  const renderRecursiveRoute = (item: IRoute) => {
    switch (item.type) {
      case 'collapse':
        const { routes = [] } = item;
        const isExpandable = routes && routes.length > 0;
        if (isExpandable) {
          item.routes?.map((item: any, index: any) => {
            return renderRecursiveRoute(item);
          });
        }
        break;
      case 'item': {
        menuItemRoutes.push(item);
      }
    }
  };

  return (
    <Suspense
      fallback={
        <div className="row text-center areas-spinner w-100">
          <div className="col-12 align-self-center">
            <div className="p-3 bg-white-semi-transparent d-inline-block rounded">
              <div>
                <Spin size="large" spinning={true} />
              </div>
            </div>
          </div>
        </div>
      }
    >
      <Switch>
        <Route path={`${path}/billing`} component={Billing}></Route>
        <Route path={`${path}/inventory`} component={Inventory}></Route>
        <Route path={`${path}/customers`} component={Customers}></Route>
        <Route path={`${path}/content`} component={Content}></Route>
        <Route path={`${path}/marketing`} component={Marketing}></Route>
        <Route path={`${path}/appbuilder`} component={AppBuilder}></Route>
        <Route path={`${path}/reportingnarrowcast`} component={ReportingNarrowcast}></Route>
        <Route path={`${path}/reporting`} component={Reporting}></Route>
        {menuItemRoutes.map((item: IRoute, index: any) => {
          let comp = MyLazyMethod(`${item.component_path}`, () => import(`${item.component_path}`));
          return <Route key={index} exact path={`${path}/${item.path}`} component={comp}></Route>;
        })}
        <Route path={`${path}/*`} component={() => <PageNotFound path={`${path}`} />}></Route>
      </Switch>
    </Suspense>
  );
};

export default memo(AreasLayout);

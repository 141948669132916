import { User } from "../../interfaces/forms/users/user";
import { AuthState } from "./reducer";

export enum AuthActionTypes {
  LOGIN_START = "LOGIN_START",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILIURE",

  SIGNUP_START = "SIGNUP_START",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_FAILURE = "SIGNUP_FAILURE",

  FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE",

  GET_USERINFO_START = "GET_USERINFO_START",
  GET_USERINFO_SUCCESS = "GET_USERINFO_SUCCESS",
  GET_USERINFO_FAILURE = "GET_USERINFO_FAILURE",

  LOGOUT = "LOGOUT",
}

interface LoginStartAction {
  type: typeof AuthActionTypes.LOGIN_START;
}

interface LoginSuccessAction {
  type: typeof AuthActionTypes.LOGIN_SUCCESS;
  auth: AuthState;
}

interface LoginFailureAction {
  type: typeof AuthActionTypes.LOGIN_FAILURE;
}

interface LogoutAction {
  type: typeof AuthActionTypes.LOGOUT;
}

interface SignUpDetailsSuccessAction {
  type: typeof AuthActionTypes.SIGNUP_SUCCESS;
}

interface ForgotPasswordSuccessAction {
  type: typeof AuthActionTypes.FORGOT_PASSWORD_SUCCESS;
}

interface GetUserInfoStartAction {
  type: typeof AuthActionTypes.GET_USERINFO_START;
}

interface GetUserInfoSuccessAction {
  type: typeof AuthActionTypes.GET_USERINFO_SUCCESS;
  user: User;
}

export type AuthActions =
  | LoginStartAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | SignUpDetailsSuccessAction
  | ForgotPasswordSuccessAction
  | GetUserInfoSuccessAction
  | GetUserInfoStartAction;

import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
// import {
//   noWhiteSpaces as noWhiteSpacesFn,
//   validEmail as validEmailFn,
//   validPhoneNumber as validPhoneFn,
//   validUrl as validUrlFn,
//   isStrongPassword as isStrongPasswordFn,
//   noSpecialCharacters as noSpecialCharactersFn,
// } from '@mwaretv/validators';

const antdValidatorWrapper = <T>(fn: (input: T) => boolean) => {
  return (rule: RuleObject, value: StoreValue) => (fn(value) ? Promise.resolve() : Promise.reject());
};

const noWhiteSpaces_ = (input: string) => !input.includes(' ');
const validEmail_ = (input: string) => /^\S+@\S+\.\S+$/.test(input);
const validUrl_ = (input: string) => /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(input);
const validPhoneNumber_ = (input: string) => /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(input);
const isStrongPassword_ = (input: string) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(input);
const noSpecialCharacters_ = (input: string) => /^[a-zA-Z0-9.-]*$/.test(input);
const capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator_ = (input: string) => /^[A-Z][a-zA-Z0-9_-]*$/.test(input);

export const noWhiteSpaces = antdValidatorWrapper<string>(noWhiteSpaces_);
export const validEmail = antdValidatorWrapper<string>(validEmail_);
export const validPhoneNumber = antdValidatorWrapper<string>(validPhoneNumber_);
export const validUrl = antdValidatorWrapper<string>(validUrl_);
export const isStrongPassword = antdValidatorWrapper<string>(isStrongPassword_);
export const noSpecialCharacters = antdValidatorWrapper<string>(noSpecialCharacters_);
export const capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator = antdValidatorWrapper<string>(capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator_);

import { createContext } from 'react';
import { DeploymentWithPopulatedServices, PopulatedService } from '../services/deployments/deployment';

type MarketingContextType = {
  deployment: DeploymentWithPopulatedServices | undefined;
  service: PopulatedService | undefined;
};

export const MarketingContext = createContext<MarketingContextType>({
  deployment: undefined,
  service: undefined,
});

export const MarketingContextProvider = MarketingContext.Provider;

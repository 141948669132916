import { Form, notification, } from 'antd'
import React, { useContext } from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { ContainerWrapper } from '../../../../common/components/containerWrapper'
import { CardForm } from '../../../../common/components/form'
import { PageTitle } from '../../../../common/components/pageTitle'
import { Ticket } from '../../../../interfaces/forms/areas/customers/tickets/ticket'
import { usePutMutation, useTicket } from '../../../../services/areas/customers/ticketService'
import { TicketFields } from './shared/form'
import dayjs from 'dayjs'
import { BackButton } from '../../../../common/components/BackButton'
import { Spin } from '../../../../common/components/spinner'
import { CustomersContext } from '../../../../contexts/customersContext'
import { useTranslation } from 'react-i18next'

const Details = () => {
  const { url } = useRouteMatch()
  const { deployment } = useContext(CustomersContext)
  const { clientName, service: serviceName, id } = useParams<{clientName : string, service : string, id : string}>()
  const { isLoading, isError, data, refetch } = useTicket({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName }, id })
  const {t} = useTranslation();
  return (
    <ContainerWrapper>
      <div className="row">
      <div className="col-12">
        <BackButton text={t('back-to-ticket-list')} url={url.split('/').slice(0, -1).join('/')} />
      </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('ticket-details')} subtitle={t('update-your-ticket')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          { isLoading && <Spin /> }
          { isError && <span>{t('something-went-wrong-while-fetching-your-ticket')}</span> }
          { !isLoading && !isError && (
            <DetailsForm ticket={data.data} />
          )}
        </div>
      </div>
    </ContainerWrapper>
  )
}

type DetailsFormProps = {
  ticket : Ticket
}

const DetailsForm = ({ ticket } : DetailsFormProps) => {
  const { deployment } = useContext(CustomersContext)
  const { clientName, service: serviceName, id } = useParams<{clientName : string, service : string, id : string}>()
  const [form] = Form.useForm()
  const {t} = useTranslation();
  const { isLoading, mutate } = usePutMutation({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName }, id }, {
    onSuccess: () => {
      notification.success({ message: t('ticket-updated') })
    },
    onError: () => {
      notification.error({ message: t('something-went-wrong-while-updating-your-ticket-please-try-again-later') })

    }
  })

  return (
    <CardForm {...{ form }} initialValues={{...ticket, date: dayjs(ticket.date)}} disabled={isLoading} onFinish={(values) => {
      mutate(values)
    }} >
      <TicketFields />
    </CardForm>
  )
}

export default Details

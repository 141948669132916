import { Skeleton, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import axios from 'axios';
import moment from 'moment';
import { getCorrectImage } from '../rails/helpers/helper';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const Slider = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  const [items, setItems] = useState([] as any);
  const [background, setBackground] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [color, setColor] = useState('#999999');

  useEffect(() => {
    async function fetchMyAPI() {
      if (props.data.type != undefined) {
        if (props.data.content.type === 'Content List') {
          try {
            var path = 'https://cloudtv.akamaized.net/' + props.application.client + '/' + props.application.deployment + '/' + props.data.content.list?.type?.toLocaleLowerCase() + '/lists/' + props.data.content.list?.list + '_list.json?t=' + moment().unix();
            var response = await axios.get(path);
            if (response.statusText == 'OK') {
              setIsActive(false);
              setItems(response.data[0].items);
              setBackground(getCorrectImage(response.data[0].items[0], 'Channels'));
            }
          } catch (e) {
            setColor('#bf8c8c');
          }
        } else {
          setIsActive(false);
        }
      } else {
        setIsActive(false);
      }
    }
    fetchMyAPI();
  }, [props.data]);

  return (
    <div className="container-fluid text-left" style={{ padding: 0 }}>
      <div
        className="col"
        style={{
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundImage: 'url(' + background + ')',
          backgroundPosition: 'center',
          backgroundSize: 'fit',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: 5,
                 backgroundColor:'rgba(0, 0, 0, 0.2)',
                 
        }}
      >
        <div style={{ marginLeft: 40 }}>
          <div className="row">
            {items.length == 0 && <Skeleton.Image active={isActive} style={{ width: 60, height: 60, backgroundColor: color }} />}
            {items.length > 0 && <Image src={'https://cloudtv.akamaized.net/' + items[0].images.square} style={{ width: 60, height: 60, float: 'left', borderRadius: 3 }} />}
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Image active={isActive} style={{ width: 300, height: 75, backgroundColor: color }} />
          </div>
          <div className="row" style={{ marginTop: 30 }}>
            <Skeleton.Input active={isActive} size={'large'} style={{ width: 400, height: 20, backgroundColor: color }} />
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Input active={isActive} size={'large'} style={{ width: 400, height: 5, backgroundColor: color }} />
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={isActive} size={'large'} shape={'square'} block={true} style={{ width: 150, backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={isActive} size={'large'} shape={'square'} block={true} style={{ width: 150, backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={isActive} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999' }} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Skeleton.Button active={isActive} size={'large'} shape={'circle'} block={false} style={{ backgroundColor: '#999' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;





import { IEmailGateway } from '../../../definitions';
import sgMail from '@sendgrid/mail';

const isHTML = (text: string): boolean => {
  const pattern = /<[a-z][\s\S]*>/i;
  return pattern.test(text);
};

export const sendgrid: IEmailGateway = {
  name: 'Sendgrid',
  email_gateway_id: 'sendgrid',
  fields: {
    apiKey: { label: 'API Key', type: 'string' },
    email: { label: 'Verified Sender Email', type: 'string' },
  },
  url: 'https://sendgrid.com/',
  helpText: 'Send emails with Sendgrid',
  infotext: 'Send emails with Sendgrid',
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/email-gateways/sendgrid.png',
  send: async ({ apiKey, email }, { subject, to, cc, bcc, content }) => {
    sgMail.setApiKey(apiKey);
    const message = {
      to,
      from: email,
      subject,
      text: '',
      html: '',
    };

    if (isHTML(content)) {
      message.html = content;
      delete message.text;
    } else {
      message.text = content;
      delete message.html;
    }

    try {
      await sgMail.send(message);
      return { isSend: true };
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        console.error(error.response.body);
      }
      return { isSend: false };
    }
  },
};

export default sendgrid;

import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import cmsAgeRatings from '../../../../../../services/areas/appbuilder/applications/ageratings/index';
import { IMSNameParams } from '../../../../../dashboard';
import Ageratings from '../../../../../../common/components/AppBuilder/AgeRatings/AgeRatings';
import { AppBuilderContext } from '../../../../../../contexts/appbuilderContext';
import { useEffectOnce } from 'react-use';

const AddAlbum = () => {
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const [ratings, setRatings] = useState([] as any);
  const [form] = useForm();
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(AppBuilderContext);
  const params = useParams<IMSNameParams & { deployment: string }>();

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    
    let response = await cmsAgeRatings.Update_AgeRating({
      name: values.name,
      ratings: ratings,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
    });
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  const handleFormFill = async () => {
    setIsLoading(true);
    let response = await cmsAgeRatings.Get_AgeRating({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: params._id ?? '',
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setData(resp);
      setRatings(resp.ratings);
      form.setFieldsValue(resp);
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-age-ratings-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('edit-age-rating')}</h2>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('age-rating')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('rating-setup-name')} />
                </Form.Item>
              </div>
            </div>
            <Ageratings service_name={deployment} clientName={clientName} data={ratings} setRatingsParent={setRatings} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-age-rating')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;

import { ISMSGateway } from '../../../definitions';
import axios from 'axios';

export const pindo: ISMSGateway = {
  name: 'Pindo',
  sms_gateway_id: 'pindo',
  fields: {
    apiToken: { label: 'API Access Token', type: 'string' },
    sender: { label: 'Sender', type: 'string' },
  },
  url: 'https://www.pindo.io/',
  helpText: 'Set up messaging with Pindo.',
  infotext: 'Set up messaging with Pindo.',
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/sms-gateways/pindo.png',
  send: async ({ apiToken, sender }, { phone, text }) => {
    if (!apiToken || !sender) {
      throw new Error('API Token or sender is missing.');
    }

    const sanitizedPhone = phone.startsWith('+') ? phone : `+${phone}`;

    try {
      const response = await axios.post(
        'https://api.pindo.io/v1/sms/',
        {
          to: sanitizedPhone,
          text: text,
          sender: sender,
        },
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      
      return {
        isSend: true,
      };
    } catch (e) {
      console.log(e.response?.data);
      return {
        isSend: false,
      };
    }
  },
};

export default pindo;

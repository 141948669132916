import { Button, Form, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PayPerView from '../../../../../../../common/components/Content/PayPerView/PayPerView';
import cmsService from '../../../../../../../services/areas/content/shorts/shorts';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import Ads from '../../../../../../../common/components/Content/Ads/Ads';

const EditShorts = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [payperview, setPayPerView] = useState([] as any);
  const [ads, setAds] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();

  useEffect(() => {
    setPayPerView(data.data.payperview);
    setAds(data.data.ads);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    var payperview_ = [] as any;
    if (payperview.rule?.access_length == undefined) {
      var curs = [] as any;
      deployment?.currencies.forEach((cur, index) => {
        curs.push({
          iso3: cur.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index,
        });
      });
      payperview_ = {
        enable: payperview.enable,
        rule: undefined,
        currencies: curs,
      };
    } else {
      payperview_ = payperview;
    }

    if (payperview != undefined) {
      if (payperview.currencies != undefined) {
        if (payperview.currencies.length > 0) {
            payperview_ = {
              enable: payperview.enable,
              rule: payperview.rule,
              currencies: payperview.currencies,
            };
          
        }
      }
    }

    values = {
      _id: _id,
      ...data.data,

      payperview: payperview_,
      ads: {
        vast_url: values.ads.vast_url,
      },

      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_Shorts(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <Ads data={ads} setAds={setAds} />
            <PayPerView data={payperview} service_name={service?.name ?? ''} clientName={clientName} setPayPerView={setPayPerView} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-shorts')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditShorts;

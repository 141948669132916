import axiosInstance from "../../../utils/axios";
import { API_ENDPOINTS } from "../../_APIConstants";
import { SubscriptionRules } from "../../../interfaces/forms/areas/billing/subscriptionRules"
import { AxiosResponse } from "axios";
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query'

const { BILLING : BILLING_ENDPONTS } = API_ENDPOINTS
const { SUBSCRIPTION_RULES : ENDPOINTS } = BILLING_ENDPONTS

type Params = { instance: string, cms: string, crm: string }

export type AddParams = { params : Params } & { data : SubscriptionRules }

export const add = ({ data, params } : AddParams ) : Promise<AxiosResponse<SubscriptionRules>> => {
  return axiosInstance.post<SubscriptionRules>(ENDPOINTS.ADD, data, { params })
}

export type GetParams = { params : Params }

export const get = ({ params } : GetParams ) : Promise<AxiosResponse<SubscriptionRules>> => {
  return axiosInstance.get<SubscriptionRules>(ENDPOINTS.GET,{ params })
}

export const useAddMutation = ({ params } : Omit<AddParams, 'data'>, options : Omit<UseMutationOptions<AxiosResponse<SubscriptionRules>, unknown, SubscriptionRules, unknown>, "mutationKey" | "mutationFn">) => useMutation(['products', 'patch', params.instance, params.crm], async (values : SubscriptionRules) => {
  return add({data: values, params})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

export const useSubscriptionRules = ({ params } : GetParams) => useQuery(['subscription-rules', params], async () => {
  return get({ params })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

import React from 'react';
import { FormSection, Input, Item, Table, FormHeading, TextArea, EditButtonWithFormModal, MultipleImageUpload } from '../../../../common/components/form';
import { Form } from 'antd';
import { BillingContext } from '../../../../contexts/billingContext';
import { noWhiteSpaces, noSpecialCharacters } from '../../../../utils/validators';
import { useTranslation } from 'react-i18next';

export const GeneralFields: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('details')}>
      <Item label={t('name')} name="name" rules={[{ required: true, message: t('name-is-required')}]} extra={t('the-product-name')}>
        <Input placeholder={t('name')} />
      </Item>
      <Item label={t('mapping-id')} name="mapping_id" rules={[{ required: false, message: t('mapping-id-is-required') }]} extra={t('a-choosen-id-to-map-this-product-to-your-own-systems')}>
        <Input placeholder={t('mapping-id')} />
      </Item>
      {children}
    </FormSection>
  );
};

export const ExternalFields = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('cart-setup')} subtitle={t('create-custom-translated-names-slugs-and-descriptions-for-your-product-to-be-used-shopping-cart-and-or-customer-portal')}>
      <FormHeading>{t('names')}</FormHeading>
      <Names />
      <FormHeading>{t('descriptions')}</FormHeading>
      <Descriptions />
      <FormHeading subtitle={t('images-will-be-shown-in-your-shopping-cart-and-can-be-used-on-your-own-platforms')}>{t('images')}</FormHeading>
      <Images />
    </FormSection>
  );
};

const Images = () => {
  const formInstance = Form.useFormInstance();
  const images = formInstance.getFieldValue(['external', 'images']) || [];

  return <MultipleImageUpload itemName={['external', 'images']} {...{ images }} />;
};

const Names = () => {
  const { service } = React.useContext(BillingContext);
  const formInstance = Form.useFormInstance();
  const {t} = useTranslation();
  Form.useWatch(['external', 'names']);

  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['external', 'names', index, 'name']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('name'),
          key: 'name',
          dataIndex: 'value',
          width: '50%',
          render: (name) => <span>{name || t('add-a-name-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['external', 'names', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`name-${key}`} name={['external', 'names', key, 'name']} rules={[{ required: false, message: t('name-is-required') }]} />

              <EditButtonWithFormModal
                heading={t('name')}
                onOk={(form) => {
                  formInstance.setFieldValue(['external', 'names', key, 'name'], form.getFieldValue('name'));
                }}
              >
                <Item label={t('name')} name="name" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: false, message: t('name-is-required')}]} initialValue={value} extra={t('add-a-translated-name-for-your-product')}>
                  <Input placeholder={t('name')} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};

const Slugs = () => {
  const formInstance = Form.useFormInstance();
  const { service } = React.useContext(BillingContext);
  Form.useWatch(['external', 'slugs']);
  const {t} = useTranslation();
  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['external', 'slugs', index, 'slug']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('slug'),
          key: 'slug',
          dataIndex: 'value',
          width: '50%',
          render: (slug) => <span>{slug || t('add-a-slug-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['external', 'slugs', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`slug-${key}`} name={['external', 'slugs', key, 'slug']} rules={[{ required: false, message: t('language-is-required') }]} />
              <EditButtonWithFormModal
                heading={t('slug')}
                onOk={(form) => {
                  formInstance.setFieldValue(['external', 'slugs', key, 'slug'], form.getFieldValue('slug'));
                }}
              >
                <Item
                  label={t('slug')}
                  name="slug"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValue={value}
                  rules={[
                    { required: false, message: t('slug-is-required')},
                    { validator: noWhiteSpaces, message: t('no-whitespaces-allowed') },
                    { validator: noSpecialCharacters, message: t('special-characters-are-not-allowed-in-the-slug') },
                  ]}
                  extra={t('add-a-translated-slug-to-this-product')}
                >
                  <Input placeholder={t('slug')} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};

const Descriptions = () => {
  const formInstance = Form.useFormInstance();
  const { service } = React.useContext(BillingContext);
  Form.useWatch(['external', 'descriptions']);
  const {t} = useTranslation();

  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['external', 'descriptions', index, 'description']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('description'),
          key: 'description',
          dataIndex: 'value',
          width: '50%',
          render: (description) => <span>{description || t('add-a-description-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['external', 'descriptions', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`description-${key}`} name={['external', 'descriptions', key, 'description']} rules={[{ required: false, message: t('description-is-required') }]} />

              <EditButtonWithFormModal
                heading={t('description')}
                onOk={(form) => {
                  formInstance.setFieldValue(['external', 'descriptions', key, 'description'], form.getFieldValue('description'));
                }}
              >
                <Item key={`description-${key}`} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={t('description')} name="description" rules={[{ required: false, message: t('description-is-required') }]} initialValue={value} extra={t('add-a-translated-description-to-your-product')}>
                  <TextArea placeholder={t('description')} rows={4} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};

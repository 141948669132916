import { parsePhoneNumberFromString } from "libphonenumber-js";

const ValidatePhoneNumber = (value: string): boolean => {
  if (!value || value.trim() === "") return false;
  let parsedPhoneNumber = parsePhoneNumberFromString(value);
  if (parsedPhoneNumber !== undefined && parsedPhoneNumber !== null) {
    if (parsedPhoneNumber.isValid()) {
      return true;
    } else return false;
  }
  return false;
};

export default ValidatePhoneNumber;

import { Form, Input, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

const ScopeFields = ({ show = true}: { show?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className="card-bg-light p-4 mb-5">
        <div className="p-4">
          <h4 className="text-primary">{t('details')}</h4>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('status')}</label>
            <Form.Item name={'status'} className="col-md-5">
              <Select className="default-select">
                <Select.Option value={'Trial'} key={'Trial'}>
                  {t('trial')}
                </Select.Option>
                <Select.Option value={'Onboarding'} key={'Onboarding'}>
                  {t('onboarding')}
                </Select.Option>
                <Select.Option value={'Live'} key={'Live'}>
                  {t('live')}
                </Select.Option>
                <Select.Option value={'Suspended'} key={'Suspended'}>
                  {t('suspended')}
                </Select.Option>
                <Select.Option value={'Stopped'} key={'Stopped'}>
                  {t('stopped')}
                </Select.Option>
                <Select.Option value={'Archived'} key={'Archived'}>
                  {t('archived')}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('onboarding')}</label>
            <Form.Item name={'onboarding'} className="col-md-5">
              <Select className="default-select">
                <Select.Option value={'Content & Streaming'} key={'Content & Streaming'}>
                  {t('content-and-streaming')}
                </Select.Option>
                <Select.Option value={'Billing & Apps'} key={'Billing & Apps'}>
                  {t('billing-and-apps')}
                </Select.Option>
                <Select.Option value={'Testing'} key={'Testing'}>
                  {t('testing')}
                </Select.Option>
                <Select.Option value={'Handover'} key={'Handover'}>
                  {t('handover')}
                </Select.Option>
                <Select.Option value={'Finished'} key={'Finished'}>
                  {t('finished')}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="card-bg-light p-4 mb-5">
        <div className="p-4">
          <h4 className="text-primary">{t('scope')}</h4>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('target-amount')}</label>
            <Form.Item
              extra={t('put-the-amount-of-customers-the-client-expects-to-get-within-a-set-period')}
              name={['scope', 'target']}
              className="col-md-5"
            
            >
              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('target-deadline')}</label>
            <Form.Item
              extra={t('set-the-deadline-date-for-when-the-target-amount-should-be-reached')}
              name={['scope', 'duration']}
              className="col-md-5"
            
            >
              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">How?</label>
            <Form.Item
              extra={t('explain-how-this-deadline-and-target-can-be-reached')}
              name={['scope', 'how']}
              className="col-md-5"
            
            >
              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('softlaunch-date')}</label>
            <Form.Item
              extra={t('set-the-expected-soft-launch-date-the-service-goes-live')}
              name={['scope', 'softlaunch']}
              className="col-md-5"
            
            >
              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('live-date')}</label>
            <Form.Item
              extra={t('set-the-expected-date-the-service-goes-live')}
              name={['scope', 'live']}
              className="col-md-5"
          
            >
              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="card-bg-light p-4 mb-5">
        <div className="p-4">
          <h4 className="text-primary">{t('chart-design-page-type-devices')}</h4>
          <div className="row">
            <div className="col">
              <div className="row">
                <label className="col-md-5 col-form-label">iPhone & iPad</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'ios']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">tvOS</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'tvos']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">Android Phone & Tablet</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'android']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">AndroidTV</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'androidtv']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">FireTV</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'firetv']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">LG WebOS</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'lg']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <label className="col-md-5 col-form-label">Samsung Tizen</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'tizen']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">Vidaa</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'vidaa']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">WebTV</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'webtv']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">KaiOS</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'kaios']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-5 col-form-label">Roku</label>
                <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'roku']}>
                  <Switch className="ch-switch" defaultChecked={false} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScopeFields;

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import THEOplayerWrapper from './TheoPlayerWrapper';
import 'theoplayer/ui.css';
import { useEffectOnce } from 'react-use';
import { getToken } from './PlayerHelpers';
import drmService from '../../../../services/areas/content/drm/drm';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onCancel: () => void;
  initialData: any;
  type: string;
};

const PlayerModal = (props: IUpdateLanguageModalProps) => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const { visible, onCancel, initialData, type } = props;
  const { t } = useTranslation();
  const [source, setSource] = useState([] as any);
  const [data, setData] = useState([] as any);

  const getStreamType = (url) => {
    var type = 'application/x-mpegURL';
    var url_ = url.split('?');
    var extension = url_[0].split('.').pop();
    if (extension === 'mp4') {
      type = 'video/mp4';
    }
    if (extension === 'mpd') {
      type = 'application/dahs+xml';
    }
    if (extension === 'm3u8') {
      type = 'application/x-mpegURL';
    }
    return type;
  };

  const getDrmDetails = async () => {
    let response = await drmService.List_DRM_Settings({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      startPlayer(response.data.data);
    }
  };

  useEffectOnce(() => {
    getDrmDetails();
  });

  const startPlayer = async (data) => {
    switch (type) {
      case 'channels':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data?.token, initialData.streams.channels.urls.standard_url),
              type: getStreamType(initialData.streams.channels.urls.standard_url),
            },
          ],
        });
        break;
      case 'cams':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data?.token, initialData.streams.cams.urls.standard_url),
              type: getStreamType(initialData.streams.cams.urls.standard_url),
            },
          ],
        });
        break;
      case 'radio':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data?.token, initialData.streams.radios.urls.standard_url),
              type: getStreamType(initialData.streams.radios.urls.standard_url),
            },
          ],
        });
        break;
      case 'movies':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data?.token, initialData.streams.movies.url),
              type: getStreamType(initialData.streams.movies.url),
            },
          ],
        });
        break;
      case 'shorts':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data?.token, initialData.streams.shorts.url),
              type: getStreamType(initialData.streams.shorts.url),
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  const onPlay = useCallback(() => {
    console.log('The player has started playing.');
  }, []);

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={760} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-4 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('player')}</h4>
            <THEOplayerWrapper source={source} onPlay={onPlay} autoplay={true}></THEOplayerWrapper>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlayerModal;

import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Debtor } from '../../../../../../interfaces/responses/clients/debtor';

const CommunicationFields = ({ debtorData }: { debtorData?: Debtor }) => {
  const { t } = useTranslation();

  return (
    <div className="card-bg-light p-4 mb-5">
      <div className="p-4">
        <h4 className="text-primary">{t('communication')}</h4>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('wefact-contact-person-name')}</label>
          <Input defaultValue={debtorData?.invoiceName} disabled={true} className="form-control bg-white font-size-14 text-default" placeholder={t('wefact-contact-person-name')} />
          </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('wefact-contact-person-email')}</label>
          <Input defaultValue={debtorData?.invoiceEmail} disabled={true} className="form-control bg-white font-size-14 text-default" placeholder={t('wefact-contact-person-email')} />
        </div>
      </div>
    </div>
  );
};

export default CommunicationFields;

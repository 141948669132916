import { useTranslation } from 'react-i18next';
import { FormSection, Timezone, CountryCurrencySelect, MultipleLanguagesSelector } from '../../../../../../../../common/components/form';
import { ICountry } from '../../../../../../../../interfaces/responses/common/ICountriesResponse';
import { ILanguage } from '../../../../../../../../interfaces/responses/common/ILanguagesResponse';

const ServiceSettings = ({ allowedCountries, allowedLanguages }: { allowedCountries: ICountry[]; allowedLanguages: ILanguage[] }) => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('service-settings')}>
      <div className="row-md-12 pt-5">
        <div className="col-sm-6">
          <Timezone name={['serviceTimezone']} extra={t('select-the-timezone-you-want-to-use-for-billing-subscriptions-guide-importing-etc')} />
        </div>
      </div>
      <div className="row-md-12 pt-5">
        <div className="col-sm-6">
          <CountryCurrencySelect countries={allowedCountries} name={['serviceCountries']} extra={t('select-the-countries-and-currencies-you-want-to-use-for-this-deployment')} />
        </div>
      </div>
      <div className="row-md-12 pt-3">
        <div className="col-sm-6">
          <MultipleLanguagesSelector languages={allowedLanguages} name={['serviceLanguages']} extra={t('select-the-languages-you-want-to-use-for-this-service')} />
        </div>
      </div>
    </FormSection>
  );
};

export default ServiceSettings;

import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Epg_Data as Response } from '../../../../../interfaces/responses/areas/content/epg/epg_data';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Epg_Data_Service {
  List_Epg_Data = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.EPG.DATA.LIST, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Epg_Data = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.EPG.DATA.IMPORT}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Clear_Epg_Data = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.EPG.DATA.CLEAR}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Epg_Data = async (data: any): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.EPG.DATA.DETAILS}/${data._id}/${data.day}/${data.month}/${data.year}/${data.type}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          day: data.query_params.day,
          month: data.query_params.month,
          year: data.query_params.year,
          type: data.query_params.type,
          _id: data.query_params._id,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Epg_Data_Day = async (data: any): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.EPG.DATA.IMPORTDAY}/${data._id}/${data.day}/${data.month}/${data.year}/${data.index}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          day: data.query_params.day,
          month: data.query_params.month,
          year: data.query_params.year,
          index: data.query_params.index,
          _id: data.query_params._id,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new Epg_Data_Service();

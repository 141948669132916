import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { WelcomeMessage } from '../../../interfaces/forms/areas/billing/welcomeMessage';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { WELCOME_MESSAGES: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string; cms: string; crm: string };
type ServiceType = WelcomeMessage;

export type AddParams = { params: Params } & { data: ServiceType };
export type PatchParams = { params: Params } & { data: Partial<ServiceType>; id: string };
export type PutParams = { params: Params } & { data: ServiceType; id: string };
type DeleteParams = { params: Params; id: string };
type GetParams = { params: Params; id: string };

export const useWelcomeMessageList = ({ params }: { params: Params }) =>
  useQuery(['welcome-messages', 'list', params], async () => {
    return axiosInstance.get<ServiceType[]>(ENDPOINTS.LIST, { params });
  });

export const useWelcomeMessageGet = ({ params, id }: GetParams) =>
  useQuery(['welcome-messages', 'get', id, params], async () => {
    return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
  });

export const useWelcomeMessageAdd = ({ params }: Omit<AddParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<ServiceType>, unknown, ServiceType, unknown>, 'mutationFn'>) =>
  useMutation(async (values: ServiceType) => {
    return axiosInstance.post<ServiceType>(ENDPOINTS.ADD, values, { params });
  }, options);

export const useWelcomeMessagePut = ({ params, id }: Omit<PutParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<ServiceType>, unknown, ServiceType, unknown>, 'mutationFn'>) =>
  useMutation(async (values: ServiceType) => {
    return axiosInstance.put<ServiceType>(`${ENDPOINTS.PUT}/${id}`, values, { params });
  }, options);

export const useWelcomeMessageDelete = ({ params }: Omit<DeleteParams, 'id'>, options: Omit<UseMutationOptions<AxiosResponse<ServiceType>, unknown, string, unknown>, 'mutationFn'>) =>
  useMutation(async (id) => {
    return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
  }, options);

import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { IMSNameParams } from '../../../../../../pages/dashboard';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { useParams } from 'react-router-dom';
import { ColorPicker } from '../../../../ColorPicker';
import ImageUploader from '../../../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';

type IUpdatePackageModalProps = {
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: any
};

const CategoryModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData, 
   } = props;
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const { t } = useTranslation();
  const [background1, setBackground1] = useState('#000000');
  const [background1Picker, setBackground1Picker] = useState(false);
  const [background2, setBackground2] = useState('#999999');
  const [background2Picker, setBackground2Picker] = useState(false);
  const [image, setImage] = useState([] as any);

  let [form] = useForm();

  useEffect(() => {
    async function fetchData() {
      if (initialData.selectedCategory.styling != undefined) {
        setImage(initialData.selectedCategory.styling?.image);
        setBackground1(initialData.selectedCategory.styling?.background1);
        setBackground2(initialData.selectedCategory.styling?.background2);
      }
    }
    fetchData();
  }, []);

  const onSubmitLocal = (values) => {
    var id = initialData._id;
    onSubmit(
      {
        ...initialData.selectedCategory,
        ...values,
        styling: {
          background1: background1,
          background2: background2,
          image: image.url === undefined ? (!Array.isArray(image) ? image : '') : image.url,
        },
        id,
      },
      false
    );
  };

  const getUploadPath = () => {
    switch (initialData.type) {
      case 'Albums':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.IMAGES;
      case 'Cams':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.IMAGES;
      case 'Channels':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.IMAGES;
      case 'Radios':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.IMAGES;
      case 'Movies':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES;
      case 'Shorts':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.IMAGES;
      case 'Series':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES;
      case 'Podcasts':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.IMAGES;
      case 'Courses':
        return STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES;
      default:
        return '';
    }
  };

  return (
    <div className="p-5" style={{ border: '1px solid #e2e2e2', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
            <div className="mt-2"></div>

            <div className="card-bg-light pl-5 pt-3 mb-4 p-5 mt-4">
              <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                {t('category-styling')}
              </h4>
              <p>{t('this-styling-is-applied-to-the-genre-rails-component-within-the-app-builder-you-can-choose-between-colors-or-an-image')}</p>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="row">
                    <label className="col-md-6 col-form-label">{t('background-color-one')}</label>
                    <div
                      className="col-form-label "
                      onClick={() => {
                        setBackground1Picker(!background1Picker);
                      }}
                      style={{
                        backgroundColor: background1,
                        borderRadius: '5px',

                        width: '60px',
                        border: '#e9edf4 1px solid',
                        height: '48px',
                      }}
                    ></div>
                    <ColorPicker
                      theme={[]}
                      visible={background1Picker}
                      color={background1}
                      onChange={(color) => {
                        setBackground1(color);
                        setBackground1Picker(!background1Picker);
                      }}
                      setVisible={setBackground1Picker}
                    />
                  </div>
                  <div className="row mt-4">
                    <label className="col-md-6 col-form-label">{t('background-color-two')}</label>

                    <div
                      className="col-form-label "
                      onClick={() => {
                        setBackground2Picker(!background2Picker);
                      }}
                      style={{
                        backgroundColor: background2,
                        borderRadius: '5px',

                        width: '60px',
                        border: '#e9edf4 1px solid',
                        height: '48px',
                      }}
                    ></div>
                    <ColorPicker
                      theme={[]}
                      visible={background2Picker}
                      color={background2}
                      onChange={(color) => {
                        setBackground2(color);
                        setBackground2Picker(!background2Picker);
                      }}
                      setVisible={setBackground2Picker}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="text-center"
                    style={{
                      color: 'yellow',
                      paddingTop: 80,
                      border: '1px solid #000',
                      height: 180,
                      width: 320,
                      background: 'linear-gradient(180deg, ' + background1 + ' 0%, ' + background2 + ' 100%)',
                    }}
                  >
                    {t('category-background-gradient-color')}
                  </div>
                </div>
              </div>

              <div className="row-md-12 mt-3">
                <ImageUploader
                  onSubmit={(values: any) => {
                    setImage(values);
                  }}
                  initialData={{
                    client: clientName,
                    deployment: deployment,
                    service: undefined,
                    uploadedImage: image,
                    uploadName: '',
                    uploadType: 'widescreen',
                    path: getUploadPath(),
                  }}
                />
              </div>
            </div>

            <div className="col-md-12 p-0">
            {initialData.newCat && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                  {t('add-category')}
                </Button>
              )}
                       {!initialData.newCat && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                  {t('update-category')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;

import { Route, Switch, useRouteMatch } from 'react-router';
import V1 from './v1';
import MyBundle from './mybundle';

const Index = () => {
  const { path } = useRouteMatch();
console.log(path)
  return (
    <Switch>
      <Route path={`${path}/mybundle`}>
        <MyBundle />
      </Route>
      <Route path={`${path}/v1`}>
        <V1 />
      </Route>
    </Switch>
  );
};

export default Index;

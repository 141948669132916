import { IAddCMSCurrencyForm } from '../../../../interfaces/forms/areas/content/common/IAddCMSCurrency';
import { IAddCMSLanguageForm } from '../../../../interfaces/forms/areas/content/common/IAddCMSLanguage';
import { IDeleteCMSCurrencyForm } from '../../../../interfaces/forms/areas/content/common/IDeleteCMSCurrencyForm';
import { IDeleteCMSLanguageyForm } from '../../../../interfaces/forms/areas/content/common/IDeleteCMSLanguageForm';
import { IUpdateCMSCurrencyForm } from '../../../../interfaces/forms/areas/content/common/IUpdateCMSCurrency';
import { IUpdateCMSLanguageForm } from '../../../../interfaces/forms/areas/content/common/IUpdateCMSLanguage';
import { IAddDRMForm } from '../../../../interfaces/forms/areas/content/settings/drm/IAddDRMForm';
import { IAddDRMSettingsForm } from '../../../../interfaces/forms/areas/content/settings/drm/IAddDRMSettingsForm';
import { IDeleteDRMForm } from '../../../../interfaces/forms/areas/content/settings/drm/IDeleteDRMForm';
import { IUpdateDRMForm } from '../../../../interfaces/forms/areas/content/settings/drm/IUpdateDRMForm';
import { IGetTokenSettingsForm } from '../../../../interfaces/forms/areas/content/settings/token_settings/IGetTokenSettingsForm';
import { IUpdateTokenSettingsForm } from '../../../../interfaces/forms/areas/content/settings/token_settings/IUpdateTokenSettingsForm';
import { ICMSQueryParams } from '../../../../interfaces/forms/common/ICMSQueryParams';
import { IFetchedCurrenciesResponse } from '../../../../interfaces/responses/areas/content/settings/currencies/IFetchedCurrenciesResponse';
import { IGetAvailableCurrenciesResponse } from '../../../../interfaces/responses/areas/content/settings/currencies/IGetAvailableCurrenciesResponse';
import { IGetDRMKeysListResponse } from '../../../../interfaces/responses/areas/content/settings/drm/IGetAllDRMKeysResponse';
import { IGetDRMDetailsResponse } from '../../../../interfaces/responses/areas/content/settings/drm/IGetDRMDetailsResponse';
import { IGetIrdetoSettingDetailsResponse } from '../../../../interfaces/responses/areas/content/settings/irdeto_settings';
import { IFetchedLanguagesResponse } from '../../../../interfaces/responses/areas/content/settings/languages/IFetchedLanguagesResponse';
import { IGetAvailableLanguagesResponse } from '../../../../interfaces/responses/areas/content/settings/languages/IGetAvailableLanguagesResponse';
import { IGetTokenSettingsDetailsResponse } from '../../../../interfaces/responses/areas/content/settings/token_settings/IGetTokenSettingDetailsResponse';
import { IBaseResponse } from '../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';

class CMSCommonService {
  ///
  getCMSLanguages = async (data: ICMSQueryParams): Promise<IFetchedLanguagesResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.LANGUAGES.GET_ALL_LANGUAGES, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  addCMSLanguage = async (data: IAddCMSLanguageForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SETTINGS.LANGUAGES.ADD_LANGUAGE, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  updateCMSLanguage = async (data: IUpdateCMSLanguageForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.CMS.SETTINGS.LANGUAGES.UDPATE_LANGUAGE, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  deleteCMSLanguage = async (data: IDeleteCMSLanguageyForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.SETTINGS.LANGUAGES.DELETE_LANGUAGE, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  getAvaialbleCMSLanguages = async (data: ICMSQueryParams): Promise<IGetAvailableLanguagesResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.LANGUAGES.GET_AVAILABLE_LANGUAGES, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };

  /////
  getCMSCurrencies = async (data: ICMSQueryParams): Promise<IFetchedCurrenciesResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.CURRENCIES.GET_ALL_CURRENCIES, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  addCMSCurrency = async (data: IAddCMSCurrencyForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SETTINGS.CURRENCIES.ADD_CURRENCY, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  updateCMSCurrency = async (data: IUpdateCMSCurrencyForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.CMS.SETTINGS.CURRENCIES.UDPATE_CURRENCY, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  deleteCMSCurrency = async (data: IDeleteCMSCurrencyForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.SETTINGS.CURRENCIES.DELETE_CURRENCY, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  getAvaialbleCMSCurrencies = async (data: ICMSQueryParams): Promise<IGetAvailableCurrenciesResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.CURRENCIES.GET_AVAILABLE_CURRENCIES, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };

  //DRM part starts

  getDRMKeys = async (data: ICMSQueryParams): Promise<IGetDRMKeysListResponse> => {
    return await axiosInstance
      .post(
        API_ENDPOINTS.V1.CMS.SETTINGS.DRM.DRM_KEY_PACKAGES_LIST,
        {},
        {
          params: data.query_params,
        }
      )
      .then((res) => res.data)
      .catch((error) => error);
  };
  getDRMDetails = async (data: ICMSQueryParams): Promise<IGetDRMDetailsResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.DRM.GET_DRM_KEY, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  addDRMKey = async (data: IAddDRMForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SETTINGS.DRM.ADD_DRM_KEY, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  updateDRMKey = async (data: IUpdateDRMForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.CMS.SETTINGS.DRM.UPDATE_DRM_KEY, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  deleteDRMKey = async (data: IDeleteDRMForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.SETTINGS.DRM.DELETE_DRM_KEY, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };

  // DRM Settings
  addDRMSettingKey = async (data: IAddDRMSettingsForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SETTINGS.DRM.ADD_DRM_SETTINGS_KEY, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  updateDRMSettingKey = async (data: IUpdateDRMForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.CMS.SETTINGS.DRM.UPDATE_DRM_SETTINGS_KEY, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };

  //DRM part ends

  //Irdito
  addIrdeto = async (data: IAddDRMForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SETTINGS.IRDETO.ADD_IRDETO, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };

  getIrdetoDetails = async (data: ICMSQueryParams): Promise<IGetIrdetoSettingDetailsResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.IRDETO.GET_DEATAILS_IRDETO, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };

  updateIrdetoDetails = async (data: IAddDRMForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.CMS.SETTINGS.IRDETO.UPDATE_IRDETO, data, {
        params: data.query_params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  deleteIrdeto = async (data: ICMSQueryParams): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.SETTINGS.IRDETO.UPDATE_IRDETO, {
        params: data.query_params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  //DRM part ends

  //Token Settings Part Starts

  getTokenSettingsDetails = async (data: IGetTokenSettingsForm): Promise<IGetTokenSettingsDetailsResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.CMS.SETTINGS.TOKEN_SETTINGS.GET_TOKEN_SETTINGS, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
  updateTokenSettingsDetails = async (data: IUpdateTokenSettingsForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(API_ENDPOINTS.V1.CMS.SETTINGS.TOKEN_SETTINGS.UPDATE_TOKEN_SETTINGS, data, {
        params: data.query_params,
      })
      .then((res) => res.data)
      .catch((error) => error);
  };
}
export default new CMSCommonService();

import { Route, Switch, useRouteMatch } from 'react-router';
import ServiceList from './list'
import AddService from './add'
import ServiceDetails from './details'

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><AddService /></Route>
      <Route path={`${path}/:id`}><ServiceDetails /></Route>
      <Route path={`${path}`}><ServiceList /></Route>
    </Switch>
  )
}

export default Index

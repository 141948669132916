import { Button, Form, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageUploader from '../../../../../../../common/components/ImageUploader/ImageUploader';
import cmsService from '../../../../../../../services/areas/content/courses/courses';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { Courses } from '../../../../../../../interfaces/forms/areas/content/courses/courses';
import { STORAGE_ENDPOINTS } from '../../../../../../../services/storage_paths';

const AddNewMusicAlbum = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  //const [logo, setLogo] = useState([] as any);
  const [poster, setPoster] = useState([] as any);
  const [backdrop, setBackdrop] = useState([] as any);
  const [widescreen, setWidescreen] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();

  useEffect(() => {
    //setLogo(data.data.images.logo);
    setBackdrop(data.data.images.background);
    setWidescreen(data.data.images.widescreen);
    setPoster(data.data.images.poster);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Courses) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      _id: _id,
      ...data.data,
      images: {
        poster: poster.url == undefined ? (!Array.isArray(poster) ? poster : '') : poster?.url?.replace('https://cloudtv.akamaized.net', ''),
        background: backdrop.url == undefined ? (!Array.isArray(backdrop) ? backdrop : '') : backdrop?.url?.replace('https://cloudtv.akamaized.net', ''),
        widescreen: widescreen.url == undefined ? (!Array.isArray(widescreen) ? widescreen : '') : widescreen.url.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    
    let response = await cmsService.Update_Course(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <ImageUploader
              onSubmit={(values: any) => {
                setPoster(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('course-poster'),
                uploadType: 'poster',
                uploadedImage: poster,
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setWidescreen(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('course-widescreen'),
                uploadType: 'widescreen',
                uploadedImage: widescreen,
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setBackdrop(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('course-background'),
                uploadType: 'background',
                uploadedImage: backdrop,
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.IMAGES,
              }}
            />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-course')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;

import { Channels, ChannelsExport, ChannelsImport } from '../../../../../interfaces/forms/areas/content/channels/channels';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Channels as Response } from '../../../../../interfaces/responses/areas/content/channels/channels';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Channel_Service {
  Add_Channel = async (data: Channels): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Channels = async (data: ChannelsImport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          addcats: data.query_params.addcats,
          createcats: data.query_params.createcats,
          bundles: data.query_params.bundles,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Channels = async (data: ChannelsExport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Channels = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  AutoComplete_List_Channels = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.AUTOCOMPLETE_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Details_Channel = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Channel = async (data: Channels): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Channel = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Channels = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.CHANNELS.CHANNELS.DELETE_GROUP, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Channel_Service();

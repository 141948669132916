import React from 'react';
import { useTranslation } from 'react-i18next';

const StepLaunchChecklist = () => {
  const {t} = useTranslation();
  return (
    <div className="step-section">
      <div className="row">
        <div className="col-12 text-center mt-5">
          <h3 className="text-primary mb-5">{t('adding-a-service')}</h3>
          <p>{t('here-you-can-add-a-new-service-to-your-deployment-you-can-add-as-many-services-as-you-want')}</p>
          <p>{t('services-are-a-great-way-to-appoint-resellers-or-target-different-countries-for-you-content')}</p>
        </div>
      </div>
    </div>
  );
};

export default StepLaunchChecklist;

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';
import { useTranslation } from 'react-i18next';

export const PieChart = ({ chartInput }: { chartInput: ChartInput }) => {
  const { t } = useTranslation();

  const labels = chartInput.series[0].data?.map((item) => {
    return item.key;
  });

  const options = {
    options: {
      chart: {
        type: 'pie',
      },
      responsive: [
        {
          options: {},
        },
      ],
      noData: {
        text: t('chart-design-no-data'),
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#505972',
          fontSize: '28px',
          fontFamily: undefined
        }
      }
    },
    labels: labels,
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: (value) => {
            if (chartInput.series[0].dataLabel != null) {
              return chartInput.series[0].dataLabel + ": " + value + ": ";
            }
            return value + ": ";
          }
        }
      }
    },
  };

  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,
  };

  const series = chartInput.series[0].data?.map((item) => {
    return item.value;
  }) ?? [];

  return <ReactApexChart options={chartOptions} series={series} type="pie" width={400} />;
};

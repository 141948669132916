import { Button, DatePicker, Form, Input, Select, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../../../common/components/Notification';
import playerService from '../../../../services/areas/content/player/player';
import { IMSNameParams } from '../../../dashboard';
import moment from 'moment';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { ContentContext } from '../../../../contexts/contentContext';

const TokenSettings = () => {
  const [form] = useForm();
const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState([] as any);

  const [file, setFile] = useState([] as any);
  const [fileName, setFileName] = useState([] as any);
  const [selectedDate, setSelectedDate] = useState('' as any);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);

  const getPlayerSettings = async () => {
    setIsLoading(true);
    let response = await playerService.List_Player_Settings({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data[0]);
      form.setFieldsValue(response.data.data[0]);
    }
    setIsLoading(false);
    setIsSubmitting(false);
  };
  const saveDrmDetails = async (values: any) => {
    setIsSubmitting(true);
    if (data != undefined) {
      let response = await playerService.Update_PlayerSetting({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.DRM.KEYS,
        },
        _id: data._id,
        ...values,
      });
      if (response && response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('success'),
        });
      } else CustomNotification({ error: response.errors });
    } else {
      let response = await playerService.Add_PlayerSetting({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
        },
        ...values,
      });
      if (response && response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('success'),
        });
      } else CustomNotification({ error: response.errors });
    }
    await getPlayerSettings();
  };

  useEffectOnce(() => {
    (async () => {
      await getPlayerSettings();
    })();
  });

  return (
    <>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">{t('player-settings')}</h2>
            <p>{t('configure-your-player-settings-to-optimize-adaptive-bitrate-streaming')}</p>
          </div>
        </div>
        <Form
          form={form}
          onFinish={(values) => {
            saveDrmDetails(values);
          }}
          onFinishFailed={(errorInfo) => {}}
        >
          <div className="card mt-5">
            <div className="card-body card-ch-padding mt-5">
              <h4 className="text-primary mt-5 mb-3">{t('mobiles')}</h4>
              <div className="card-bg-light pl-5 pt-3">
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'mobiles', 'min_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'mobiles', 'max_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'mobiles', 'abr_bandwidth']}>
                    <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                      <Select.Option value={"Bandwidth"} key={1}>
                        {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                      </Select.Option>
                      <Select.Option value={"Quality"} key={14}>
                        {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                      </Select.Option>
                      <Select.Option value={"Performance"} key={2}>
                        {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <h4 className="text-primary mt-5 mb-3">{t('tablet')}</h4>
              <div className="card-bg-light pl-5 pt-3">
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'tablets', 'min_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'tablets', 'max_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'tablets', 'abr_bandwidth']}>
                    <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                      <Select.Option value={"Bandwidth"} key={1}>
                        {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                      </Select.Option>
                      <Select.Option value={"Quality"} key={14}>
                        {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                      </Select.Option>
                      <Select.Option value={"Perfomance"} key={2}>
                        {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <h4 className="text-primary mt-5 mb-3">{t('televisions')}</h4>
              <div className="card-bg-light pl-5 pt-3">
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'televisions', 'min_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'televisions', 'max_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'televisions', 'abr_bandwidth']}>
                    <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                      <Select.Option value={"Bandwidth"} key={1}>
                        {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                      </Select.Option>
                      <Select.Option value={'Quality'} key={14}>
                        {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                      </Select.Option>
                      <Select.Option value={"Performance"} key={2}>
                        {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <h4 className="text-primary mt-5 mb-3">{t('browsers')}</h4>
              <div className="card-bg-light pl-5 pt-3">
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'browsers', 'min_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'browsers', 'max_bandwidth']}>
                    <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                  <Form.Item className="col-md-5" name={['strategy', 'browsers', 'abr_bandwidth']}>
                    <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                      <Select.Option value={"Bandwidth"} key={1}>
                        {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                      </Select.Option>
                      <Select.Option value={"Quality"} key={14}>
                        {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                      </Select.Option>
                      <Select.Option value={"Perfomance"} key={2}>
                        {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="text-right mb-4">
                <Button htmlType="submit" loading={isSubmitting} disabled={isLoading || isSubmitting} className="col-md-3 btn btn-secondary rounded-pill width-200 height-48 ml-3">
                  {t('save-settings')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TokenSettings;

import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../../../common/components/pageTitle';

export const Teamwork = () => {
  const {t} = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('jira')} subtitle={t('manage-your-tickets-with-our-ticket-system')} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <iframe width={'100%'} height={"100vh"} title="frame" sandbox={t('allow-forms-allow-modals-allow-orientation-lock-allow-popups-allow-same-origin-allow-scripts')} seamless allow="fullscreen" loading="lazy" id="myiframe" src="https://mwaretv1.teamwork.com/app/home/projects">
            <p>{t('your-browser-does-not-support-iframes')}</p>
          </iframe>
        </div>
      </div>
    </div>
  );
};

import { Translations_Overide } from '../../../../../interfaces/forms/areas/appbuilder/applications/translations/translations';
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../interfaces/forms/common/IAPBExtraQuerytParams';
import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class TranslationService {
  Reset_Translation = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.RESET, data, {})
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  Install_Translation = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.INSTALL, data, {})
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  Edit_Translation = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.INSTALL, data, {})
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  Add_Translations_Overide = async (data: Translations_Overide): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.OVERIDE.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Translations_Overide = async (
    data: IABP_Lists
): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.OVERIDE.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Translations_Overide = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.OVERIDE.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Translations_Overide = async (data: Translations_Overide): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.OVERIDE.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Translations_Overide = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.TRANSLATIONS.OVERIDE.DELETE}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new TranslationService();

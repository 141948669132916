import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { ChangeBaseSubscriptionMessage } from '../../../interfaces/forms/areas/billing/changeBaseSubscriptionMessage';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { CHANGE_BASE_SUBSCRIPTION_MESSAGES: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string; cms: string; crm: string };

type PaginationParams = {
  page: number;
  limit: number;
};

type ServiceType = ChangeBaseSubscriptionMessage;

type AddParams = { params: Params } & { data: ServiceType };
type PutParams = { params: Params } & { data: ServiceType; id: string };
type DeleteParams = { params: Params; id: string };
type GetParams = { params: Params; id: string };

export const useChangeBaseSubscriptionMessageList = ({ params }: { params: Params }) =>
  useQuery(['change-base-subscription-message', 'list', params], async () => {
    return axiosInstance.get<ServiceType[]>(ENDPOINTS.LIST, { params });
  });

export const useChangeBaseSubscriptionMessageGet = ({ params, id }: GetParams) =>
  useQuery(['change-base-subscription-message', 'get', id, params], async () => {
    return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
  });

export const useChangeBaseSubscriptionMessageAdd = ({ params }: Omit<AddParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<ServiceType>, unknown, ServiceType, unknown>, 'mutationFn'>) =>
  useMutation(async (values: ServiceType) => {
    return axiosInstance.post<ServiceType>(ENDPOINTS.ADD, values, { params });
  }, options);

export const useChangeBaseSubscriptionMessagePut = ({ params, id }: Omit<PutParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<ServiceType>, unknown, ServiceType, unknown>, 'mutationFn'>) =>
  useMutation(async (values: ServiceType) => {
    return axiosInstance.put<ServiceType>(`${ENDPOINTS.PUT}/${id}`, values, { params });
  }, options);

export const useChangeBaseSubscriptionMessageDelete = ({ params }: Omit<DeleteParams, 'id'>, options: Omit<UseMutationOptions<AxiosResponse<ServiceType>, unknown, string, unknown>, 'mutationFn'>) =>
  useMutation(async (id) => {
    return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
  }, options);

import { Route, Switch, useRouteMatch } from 'react-router';
import ApiBuilder from './apibuilder';
import ApiBuilderAdd from './apibuilder/add';
import ApiBuilderDetails from './apibuilder/details';
import React from 'react';
const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <ApiBuilderAdd />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <ApiBuilderDetails />
      </Route>
      <Route path={`${path}/`}>
        <ApiBuilder />
      </Route>
    </Switch>
  );
};

export default Index;

import { useContext } from 'react';
import { Card } from 'antd';
import { IMSNameParams } from '../../../dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { useQuery } from '@tanstack/react-query';
import { Item } from '../../../../common/components/form';
import * as CommunicationService from '../../../../services/areas/billing/communicationService';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CommunicationReporting } from '@mwaretv/database/build/backend/models/reporting/communications/communication';

export const CommunicationDetails = () => {
  const { id } = useParams<IMSNameParams & { id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  
  const { isLoading, isError, data } = useQuery(
    ['communication', id],
    async () => {
      return (
        await CommunicationService.get({
          params: {
            cms: deployment?.name ?? '',
            crm: service?.name || '',
            instance: clientName,
          },
          id,
        })
      ).data;
    },
    { retry: 3 }
  );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-communications-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mt-3 mb-3">
          <PageTitle title={t('communication-details')} subtitle={''} />
        </div>
      </div>
      {isError && <Error />}
      {isLoading && <Loading />}
      {!isLoading && data && <Details {...{ data }} />}
    </ContainerWrapper>
  );
};

const Details = ({ data }: { data: CommunicationReporting }) => {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
              <Item className="col-md-12" label={t('timestamp')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.timestamp ? moment.unix(data.timestamp).format('lll') : undefined} </div>
              </Item>
              <Item className="col-md-12" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.source} </div>
              </Item>
              <Item className="col-md-12" label={t('communication-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.type} </div>
              </Item>
              <Item className="col-md-12" label={t('message-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.message_type} </div>
              </Item>
            </div>
            <div className="card-bg-light pl-5 pb-4   mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('template')}</h4>
              <Item className="col-md-12" label={t('template-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.template_name} </div>
              </Item>
            </div>
            <div className="card-bg-light pl-5  pb-4   mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('customer')}</h4>
              <Item className="col-md-12" label={t('customer-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.username} </div>
              </Item>
              {/* <Item className="col-md-12" label={t('email')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.email} </div>
              </Item>
              <Item className="col-md-12" label={t('mobile')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.mobile} </div>
              </Item> */}
            </div>
            <div className="card-bg-light pl-5 pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('product')}</h4>
              <Item className="col-md-12" label={t('product-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.subscription?.name} </div>
              </Item>
              {/* <Item className="col-md-12" label={t('product-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.product_id} </div>
              </Item> */}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const Loading = () => {
  return (
    <div className="row text-center h-100 w-100">
      <div className="col-12 align-self-center">
        <Spin size="large" spinning={true} />
      </div>
    </div>
  );
};

const Error = () => {
  const { t } = useTranslation();
  return <div style={{ fontWeight: 'bold' }}>{t('something-went-wrong-sad-smiley')}</div>;
};

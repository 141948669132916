import { Promotions_CastoolaAds } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/promotions/castoolaads/castoolaads";
import { Promotions_CastoolaAds as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/promotions/castoolaads/castoolaads";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Promotions_CastoolaAdsService {
    Add_Promotions_CastoolaAds = async (data: Promotions_CastoolaAds): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.CASTOOLAADS.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Promotions_CastoolaAds_Template = async (data: Promotions_CastoolaAds): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.CASTOOLAADS.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Promotions_CastoolaAdss = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.CASTOOLAADS.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Promotions_CastoolaAds = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.PROMOTIONS.CASTOOLAADS.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Promotions_CastoolaAds = async (data: Promotions_CastoolaAds): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.PROMOTIONS.CASTOOLAADS.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Promotions_CastoolaAds = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.PROMOTIONS.CASTOOLAADS.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Promotions_CastoolaAdsService();

import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { WeFactProduct } from '../../../../../../interfaces/responses/clients/weFactProduct';
import { DatePicker, InputNumber } from '../../../../../../common/components/form';
import dayjs from 'dayjs';
import { Product } from '../../../../../../interfaces/forms/clients/clients';
import { useContext } from 'react';
import { CommonContext } from '../../../../../../contexts/commonContext';

const ProductModal = ({ isOpen, setIsOpen, availableWeFactProducts, productToBeEdited, updateSelectedProducts }: { isOpen: boolean, setIsOpen: (isOpen: boolean) => void, availableWeFactProducts: WeFactProduct[], productToBeEdited?: Product, updateSelectedProducts: (value: Product) => void }) => {
  const [form] = useForm();
  const { currencies } = useContext(CommonContext);
  const {t} = useTranslation();
  const isInEditMode = !!productToBeEdited;

  const convertStartDateFromUnixToDayJs = (product: Product): dayjs.Dayjs => {
    return dayjs.unix(product.startDate);
  }

  return (
     <Modal open={isOpen} style={{border:'1px solid #ebebeb'}} width={640} footer={false} closable={true} onCancel={_ => setIsOpen(false)}>
      <div className="p-5 mt-4" style={{border:'1px solid #ebebeb'}}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('client-product')}</h4>
            <p>{t('client-product-extra')}</p>
            <Form initialValues={productToBeEdited} form={form} onFinish={updateSelectedProducts}>
              <Form.Item name={'code'} labelCol={{ span: 24 }} label={t('code')} 
                rules={[
                  {
                    required: true,
                    message: t('product-code-is-required'),
                  },
              ]}>
                {!isInEditMode &&
                <Select optionFilterProp="label" className="default-select" placeholder={t('code')} showSearch onChange={(value) => {
                  const productName = availableWeFactProducts.find(weFactProduct => weFactProduct.code === value)?.name;
                  form.setFieldValue(['name'], productName);
                }}>
                  {availableWeFactProducts?.map((weFactProduct) => {
                    return (
                      <Select.Option label={weFactProduct.name} value={weFactProduct.code} key={weFactProduct.code}>
                        {`${weFactProduct.name} - ${weFactProduct.code}`}
                      </Select.Option>
                    );
                  }) ?? []}
                </Select>
                }
                {isInEditMode &&
                <Input disabled={true} className="form-control bg-white font-size-14 text-default" placeholder={t('code')} />
                }
              </Form.Item>
              <Form.Item hidden={true} name={'name'} />
              <Form.Item name={'startDateDayjsFormat'} labelCol={{ span: 24 }} label={t('start-date')}>
                <DatePicker 
                  defaultValue={productToBeEdited ? convertStartDateFromUnixToDayJs(productToBeEdited) : undefined}
                  format="YYYY-MM-DD"
                  style={{ marginBottom: 0 }} // style needed to override AntD DatePicker standard marginBottom: 30px
                />
              </Form.Item>
              <Form.Item name={'price'} labelCol={{ span: 24 }} label={t('price')}>
                <InputNumber defaultValue={productToBeEdited?.price ?? undefined} />
              </Form.Item>
              <Form.Item name={'pricePeriod'} labelCol={{ span: 24 }} label={t('price-period')}>
                <Select className="default-select" placeholder={t('period')}>
                  <Select.Option value={'day'} key={'day'}>
                    {t('day')}
                  </Select.Option>
                  <Select.Option value={'week'} key={'week'}>
                    {t('week')}
                  </Select.Option>
                  <Select.Option value={'month'} key={'month'}>
                    {t('month')}
                  </Select.Option>
                  <Select.Option value={'year'} key={'year'}>
                    {t('year')}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={'currency'} labelCol={{ span: 24 }} label={t('currency')}>
                <Select className="default-select" placeholder={t('currency')} showSearch>
                  {currencies?.map((currency) => {
                    return (
                      <Select.Option value={currency.isoCurrency} key={currency.isoCurrency}>
                        {currency.isoCurrency}
                      </Select.Option>
                    );
                  }) ?? []}
                </Select>
              </Form.Item>
              <Form.Item name={'taxRate'} labelCol={{ span: 24 }} label={t('tax-rate')}>
                <InputNumber defaultValue={productToBeEdited?.taxRate ?? undefined} />
              </Form.Item>
              <Form.Item name={'minimumOrderQuantity'} labelCol={{ span: 24 }} label={t('minimum-order-quantity')}>
                <InputNumber defaultValue={productToBeEdited?.minimumOrderQuantity ?? undefined} />
              </Form.Item>
              <Form.Item name={'recurring'} labelCol={{ span: 24 }} label={t('recurring')}>
                <Switch className="ch-switch" defaultChecked={productToBeEdited?.recurring ?? false} />
              </Form.Item>
              <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                {isInEditMode ? t('edit-product') : t('add-product')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;

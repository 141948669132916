import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import { useParams, useRouteMatch } from 'react-router';
import { IMSNameParams } from '../../../dashboard';
import { Product } from '../../../../interfaces/forms/areas/billing/products/product';
import { Button, Col, Modal, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemo, faTrashCan, faUpload } from '@fortawesome/pro-regular-svg-icons';
import * as ProductService from '../../../../services/areas/billing/productService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { BillingContext } from '../../../../contexts/billingContext';
import { FileImageOutlined } from '@ant-design/icons';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { useTranslation } from 'react-i18next';

const Products: React.FC = () => {
  const { confirm } = Modal;
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const history = useHistory();
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const [isLoading_, setIsLoading_] = useState(false);
  const {t} = useTranslation();
  
  const query = useQuery(
    ['products', pagination.page, pagination.limit],
    async () => {
      return (
        await ProductService.list({
          params: {
            cms: deployment?.name || '',
            crm: service?.name || '',
            instance: clientName,
            page: pagination.page,
            limit: pagination.limit,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  const { isLoading, isError, data } = query;

  const deleteMutation = useMutation(
    (id: string) => {
      return ProductService.del({ params: { instance: clientName, crm: service?.name || '', cms: deployment?.name || '' }, id });
    },
    {
      
      onSuccess: (data) => {
        notification.success({ message: t('subscription-deleted')});
        query.refetch();
      },
      onError: () => {
        notification.error({ message: t('delete-failed') });
      },
    }
  );

  const publishProduct = async (row) => {
    setIsLoading_(true);
    const response = await ProductService.publishProduct({
      instance: clientName,
      crm: service?.name || '',
      cms: deployment?.name || '',
      _id: row,
      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).PRODUCTS.PRODUCTS,
    });
    if (response == 'done') {
      notification.success({ message: t('subscription-published')});
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsLoading_(false);
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('subscriptions')} subtitle={t('manage-your-subscription-that-you-can-sell-to-your-customers-packages-are-build-up-from-bundles-and-services')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong-sad-smiley')}</div>}
          {!isError && data && (
            <ReactBCTable
              isLoading={!isLoading && !isLoading_ ? false : true}
              totalRecords={data.totalDocs}
              pageSize={data.limit}
              tableId={'billing_products_table'}
              columns={[
                {
                  title: t('image'),
                  dataKey: 'images',
                  width: '10%',
                  cell: (row: Product) => {
                    if (row.external?.images[0]) {
                      return <img width="160" height="90" src={row.external?.images[0].url} alt="" />;
                    }
                    return <></>;
                  },
                },
                {
                  title: t('name'),
                  dataKey: 'name',
                  width: '25%',
                  cell: (row: Product) => {
                    return <div>{row.name}</div>;
                  },
                },
                {
                  title: 'ID',
                  dataKey: '_id',
                  width: '25%',
                },
                {
                  title: t('type'),
                  dataKey: 'type',
                  width: '10%',
                },
                {
                  title: t('variant'),
                  dataKey: 'variant',
                  width: '10%',
                },
                {
                  title: t('actions'),
                  dataKey: 'actions',
                  cell: (row: Product) => {
                    return (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        <Button
                          type="text"
                          className="mx-2"
                          disabled={deleteMutation.isLoading}
                          onClick={() => {
                            confirm({
                              title: t('are-you-sure-you-want-to-delete-this-subscription'),
                              okText: t('yes'),
                              okType: 'danger',
                              cancelText: t('no'),
                              onOk() {
                                deleteMutation.mutate(row._id);
                              },
                              onCancel() {},
                            });
                          }}
                        >
                          <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                          <div>{t('delete')}</div>
                        </Button>
                        <Button
                          type="text"
                          className="mx-2"
                          disabled={deleteMutation.isLoading}
                          onClick={() => {
                            publishProduct(row._id);
                          }}
                        >
                          <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faUpload} />
                          <div>{t('publish')}</div>
                        </Button>
                        <Button
                          type="text"
                          className="mx-2"
                          disabled={deleteMutation.isLoading}
                          onClick={() => {
                            history.push(`${url}/${row._id}`);
                          }}
                        >
                          <FontAwesomeIcon className="action-icon" icon={faMemo} />
                          <div>{t('open-details')}</div>
                        </Button>
                      </Col>
                    );
                  },
                },
              ]}
              data={data.docs || []}
              currentPage={data.page}
              onChange={(page) => {
                setPagination({ ...pagination, page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};

const ActionBar: React.FC = () => {
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <div className="d-flex justify-content-center justify-content-md-end align-items-start">
      <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
       {t('add-subscription')}
      </Link>
    </div>
  );
};

export default Products;

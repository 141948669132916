import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/axios";
import { API_ENDPOINTS } from "../../_APIConstants";
import { SmsGateway } from '../../../interfaces/forms/areas/billing/smsGateway'
import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";

const { BILLING : BILLING_ENDPONTS } = API_ENDPOINTS
const { SMS_GATEWAYS : ENDPOINTS } = BILLING_ENDPONTS

type Params = { instance: string, cms: string, crm: string }

type PaginationParams = {
  page : number,
  limit : number
}

type ResponseFormat<T> = {
  docs : T
}

type PaginatedResults<T> = {
  hasNextPage : boolean
  hasPrevPage : boolean
  limit : number
  nextPage : number | null
  page : number
  pagingCounter : number
  prevPage : null | number
  totalDocs : number,
  totalPages : number
} & ResponseFormat<T>

export const list = ({ params } : { params : Params & PaginationParams}) : Promise<AxiosResponse<PaginatedResults<SmsGateway[]>>> => {
  return axiosInstance.get<PaginatedResults<SmsGateway[]>>(ENDPOINTS.LIST, { params })
}

type GetParams = { params : Params, id : string }

export const get = ({ params, id } : GetParams) : Promise<AxiosResponse<SmsGateway>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params })
}

export type AddParams = { params : Params } & { data : SmsGateway }

export const add = ({ data, params } : AddParams ) : Promise<AxiosResponse<SmsGateway>> => {
  return axiosInstance.post<SmsGateway>(ENDPOINTS.ADD, data, { params })
}

type DeleteParams = { params : Params, id : string }

export const del = ({ params, id } : DeleteParams) : Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params })
}

export type PutParams = { params : Params } & { data : SmsGateway, id : string }

export const put = ({ data, params, id } : PutParams ) : Promise<AxiosResponse<SmsGateway>> => {
  return axiosInstance.put<SmsGateway>(`${ENDPOINTS.PUT}/${id}`, data, { params })
}

export type PatchParams = { params : Params } & { data : SmsGateway, id : string }

export const patch = ({ data, params, id } : PatchParams ) : Promise<AxiosResponse<SmsGateway>> => {
  return axiosInstance.patch<SmsGateway>(`${ENDPOINTS.PATCH}/${id}`, data, { params })
}

export const useSmsGateways = ({ params } : { params : Params & PaginationParams}) => useQuery(['billing', 'settings', 'sms-gateways', 'list', params], async () => {
  return list({params})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useSmsGateway = ({ params, id } : GetParams) => useQuery(['billing', 'settings', 'sms-gateways', 'get', params, id], async () => {
  return get({params, id})
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useAddMutation = ({ params } : { params : Params }, options : Omit<UseMutationOptions<AxiosResponse<SmsGateway>, unknown, SmsGateway, unknown>, "mutationFn">) => useMutation(async (values : SmsGateway) => {
  return add({ params, data: values })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

export const usePutMutation = ({ params, id } : Omit<PutParams, 'data'>, options : Omit<UseMutationOptions<AxiosResponse<SmsGateway>, unknown, SmsGateway, unknown>, "mutationFn">) => useMutation(async (values : SmsGateway) => {
  return put({ params, data: values, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

export const useDeleteMutation = ({ params } : { params : Params }, options : Omit<UseMutationOptions<AxiosResponse, unknown, string, unknown>, "mutationFn">) => useMutation(async (id) => {
  return del({ params, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
}, options)

import { Route, Switch, useRouteMatch } from 'react-router'
import Ratings from './ageratings'
import RatingsAdd from './ageratings/add'
import RatingsDetails from './ageratings/details'

const Index = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            {/* 

      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><Bundles /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route> */}

            <Route path={`${path}/add`}><RatingsAdd /></Route>
            <Route path={`${path}/details/:_id`}><RatingsDetails /></Route>
            <Route path={`${path}/`}><Ratings /></Route>
        </Switch>
    )
}

export default Index

import { Route, Switch, useRouteMatch } from 'react-router';
import Customers from './list';
import AddCustomer from './add';
import DetailsCustomer from './details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <AddCustomer />
      </Route>
      <Route path={`${path}/list`}>
        <Customers />
      </Route>
      <Route path={`${path}/:id`}>
        <DetailsCustomer />
      </Route>
    </Switch>
  );
};

export default Index;

import { Promotions_Ads } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/promotions/ads/ads";
import { Promotions_Ads as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/promotions/ads/ads";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Promotions_AdsService {
    Add_Promotions_Ads = async (data: Promotions_Ads): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.ADS.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Promotions_Ads_Template = async (data: Promotions_Ads): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.ADS.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Promotions_Adss = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.ADS.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Promotions_Ads = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.PROMOTIONS.ADS.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Promotions_Ads = async (data: Promotions_Ads): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.PROMOTIONS.ADS.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Promotions_Ads = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.PROMOTIONS.ADS.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Promotions_AdsService();

import { AxiosResponse } from 'axios';
import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { SmsTemplate } from '../../../interfaces/forms/areas/billing/smsTemplate';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { SMS_TEMPLATES: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string, cms: string, crm: string }

type PaginationParams = {
  page: number;
  limit: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

type GetParams = { params: Params; id: string };

export type AddParams = { params: Params } & { data: SmsTemplate };

type DeleteParams = { params: Params; id: string };

export type PutParams = { params: Params } & { data: SmsTemplate; id: string };

export type PatchParams = { params: Params } & { data: SmsTemplate; id: string };

export const useSmsTemplateList = ({ params }: { params: Params & PaginationParams }) =>
  useQuery(['billing', 'settings', 'sms-templates', 'list', params], async () => {
    return axiosInstance.get<PaginatedResults<SmsTemplate[]>>(ENDPOINTS.LIST, { params });
  });

export const useSmsTemplateGet = ({ params, id }: GetParams) =>
  useQuery(['billing', 'settings', 'sms-templates', 'get', params, id], async () => {
    return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
  });

export const useSmsTemplateAdd = ({ params }: { params: Params }, options: Omit<UseMutationOptions<AxiosResponse<SmsTemplate>, unknown, SmsTemplate, unknown>, 'mutationFn'>) =>
  useMutation(async (values: SmsTemplate) => {
    return axiosInstance.post<SmsTemplate>(ENDPOINTS.ADD, values, { params });
  }, options);

export const useSmsTemplatePut = ({ params, id }: Omit<PutParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<SmsTemplate>, unknown, SmsTemplate, unknown>, 'mutationFn'>) =>
  useMutation(async (values: SmsTemplate) => {
    return axiosInstance.put<SmsTemplate>(`${ENDPOINTS.PUT}/${id}`, values, { params });
  }, options);

export const useSmsTemplateDelete = ({ params }: { params: Params }, options: Omit<UseMutationOptions<AxiosResponse<SmsTemplate>, unknown, string, unknown>, 'mutationFn'>) =>
  useMutation(async (id) => {
    return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
  }, options);

import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { Card } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import NewServicePNG from '../../../../../../../assets/images/new_serv.png';
import { useTranslation } from 'react-i18next';

export const NewServiceBanner = ({ deployment }: { deployment: Deployment }) => {
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <>
      <Card hoverable className="p-0 col-12" bodyStyle={{ padding: '0px'}}>
        <div className="col-12 border-left border-secondary border-width-4">
          <div className="card shadow-none" >
            <div className="card-body p-4 py-4 mb-2">
              <div className="row">
                <div className="col-md-3 col-lg-2 col-xl-1">
                  <img src={NewServicePNG} title={t('user')} alt={t('user')} />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-8 pl-xl-5 mt-3 mt-lg-0">
                  <h4 className="text-primary">{t('add-service')}</h4>
                  <p className="h6 font-weight-normal line-height-26" style={{ color: '#888' }}>
                    {t('adding-a-service-customers-billing-marketing-and-reports')}
                  </p>
                </div>
                <div className="col-md-3 d-flex justify-content-lg-end justify-content-center align-items-center mt-3 mt-lg-0">
                  <Link className="btn btn-secondary rounded-pill btn-default" to={`${url}/${deployment.name.toString()}/new-service`}>
                    {t('add-service')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

import { Route, Switch, useRouteMatch } from 'react-router'
import AppUpdate from './appupdates'
import AppUpdateAdd from './appupdates/add'
import AppDetails from './appupdates/details'

const Index = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/add`}><AppUpdateAdd /></Route>
            <Route path={`${path}/details/:_id`}><AppDetails /></Route>
            <Route path={`${path}/`}><AppUpdate /></Route>
        </Switch>
    )
}

export default Index

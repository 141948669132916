import { Button, Form, Input, Select, Switch, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import coupons from '../../../../../../services/areas/marketing/coupons/coupons';
import { IMSNameParams } from '../../../../../dashboard';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';
import { DatePicker } from '../../../../../../common/components/form';
import dayjs from 'dayjs';

const AddAlbum = () => {
  const history = useHistory();
  const { clientName, deployment, service } = useParams<IMSNameParams>();
  const [isAdding, setIsAdding] = useState(false);
  const [form] = useForm();
const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const [endDate, setEndDate] = useState(dayjs().add(99,'year'));
  const [startDate, setStartDate] = useState(dayjs());

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    values = {
      ...values,
      start: startDate.unix(),
      end: endDate.unix(),
      vanity: values.vanity.toUpperCase(),
      query_params: {
        instance: clientName,
        cms: deployment ?? '',
        crm: service,
      },
    };
    let response = await coupons.Add_Coupon(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-coupon-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-coupon')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('coupons-are-a-great-marketing-tool-to-get-new-customers-in-by-giving-them-a-signup-discount')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('coupon')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-5"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('coupon-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('start-date')}</label>
                <Form.Item initialValue={startDate} className="col-md-5">
                  <DatePicker value={startDate} defaultValue={startDate} showToday className="form-control height-48 bg-white font-size-14 text-default" onChange={(value) => setStartDate(dayjs(value))} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('end-date')}</label>
                <Form.Item initialValue={endDate} className="col-md-5">
                  <DatePicker value={endDate} showToday defaultValue={endDate} className="form-control height-48 bg-white font-size-14 text-default" onChange={(value) => setEndDate(dayjs(value))} />
                </Form.Item>
              </div>
            </div>

            <h4 className="text-primary mt-5 mb-3">{t('financial')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('vanity-code')}</label>
                <Form.Item
                  className="col-md-5"
                  name="vanity"
                  rules={[
                    {
                      required: true,
                      message: t('vanity-code-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('e-g-get-my-discount-or-dw8as99')} />
                </Form.Item>
              </div>

              {/* <div className="row">
                <label className="col-md-3 col-form-label">Customer Discount</label>
                <Form.Item
                  className="col-md-5"
                  rules={[
                    {
                      required: true,
                      message: 'App name is required',
                    },
                  ]}
                  name={['discount', 'type']}
                >
                  <Select className="default-select" placeholder="- Select Discount Type -">
                    <Select.Option value={'Percentage'} key={'Percentage'}>
                      Percentage
                    </Select.Option>
                    <Select.Option value={'Fixed'} key={'Fixed'}>
                      Fixed Value
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div> */}
              <div className="row">
                <label className="col-md-3 col-form-label">{t('discount-percentage')}</label>
                <Form.Item
                  className="col-md-5"
                  name={['discount', 'amount']}
                  rules={[
                    {
                      required: true,
                      message: t('vanity-code-is-required'),
                    },
                  ]}
                >
                  <Input min={0} type="number" className="form-control bg-white font-size-14 text-default p-3rem" placeholder="12" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-coupon')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;

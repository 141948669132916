import { usePaymentProviderSchemas } from '../paymentProviderSchemas';
import { Input, Item, Select, Switch } from '../../../../../common/components/form';
import { Form, Typography } from 'antd';
import { Input as AntdInput } from 'antd';
import { useTranslation } from 'react-i18next';

export const PaymentMethodFields = () => {
  return (
    <>
      <GeneralFields />
    </>
  );
};

const GeneralFields = () => {
  const form = Form.useFormInstance();
  const provider_id = form.getFieldValue('payment_provider_id');
  const {t} = useTranslation();
  const { data, isLoading, isError } = usePaymentProviderSchemas();
  const provider = !(isLoading || isError) ? data.find((x) => x.id === provider_id) : null;

  return (
    <>
      {provider && (
        <>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            <Typography.Title level={4}>{t('provider-name-settings', { providerName: provider.name })}</Typography.Title>
            <Item name={['payment_provider_id']} hidden />
            <Item name={['name']} label={t('name')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: t('name-is-required') }]}>
              <Input />
            </Item>
          </div>
          {/* <Item name={['enabled']} label="Enabled" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Switch defaultChecked={form.getFieldValue('enabled')} />
          </Item> */}
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            <Typography.Title level={5} style={{ marginLeft: 5, marginTop: 10 }}>
              {t('environment')}
            </Typography.Title>
            <Item name={['environment']} extra={t('the-current-active-environment')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Select
                options={Object.keys(provider.environments).map((x) => ({
                  value: x,
                  label: `${x.charAt(0).toUpperCase() + x.slice(1)}`,
                }))}
              />
            </Item>
            {Object.keys(provider.environments).map((x) => (
              <div className="mt-3" style={{ marginLeft: 5 }}>
                <Typography.Title level={5}>{t('x-charat-0-touppercase-x-slice-1-settings', { capitalizedEnvironmentKey: x.charAt(0).toUpperCase() + x.slice(1) })}</Typography.Title>
                {Object.keys(provider.environments[x].fields).map((k, i) => {
           
                  return (
                    <div key={i}>
                      {provider.environments[x].fields[k].type == 'string' && (
                        <Item key={i} name={['environment_settings', x, k]} label={provider.environments[x].fields[k].label} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Input />
                        </Item>
                      )}
                      {provider.environments[x].fields[k].type != 'string' && (
                        <Item key={i} valuePropName='checked' name={['environment_settings', x, k]} label={provider.environments[x].fields[k].label} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Switch />
                        </Item>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

// export const NewPaymentMethodForm = () => {
//   const [ form ] = Form.useForm()

//   return (
//     <Form {...{ form }}>

//     </Form>
//   )
// }

// type PaymentFormProps = {
//   settings : PaymentSetting
//   paymentId : string
// }

// const PaymentForm = ({ settings, paymentId } : PaymentFormProps) => {

//   const provider = paymentProviders.find(x => x.id === paymentId)

//   return (
//     <div className="col-12  mt-3">
//       { !provider && <span>Invalid payment provider</span> }
//       { provider && provider.environments && (
//         <>
//           <GeneralFields {...{ settings, provider }} />
//           <EnvironmentSettings {...{ settings, provider }} />
//         </>
//       )}
//     </div>
//   )
// }

// type GeneralFieldsProps = {
//   provider : IPaymentProvider
//   settings : PaymentSetting
// }

// const GeneralFields = ({ provider, settings } : GeneralFieldsProps) => {
//   return (
//     <>
//       <Item name={['name']} label='Name'>
//         <Input />
//       </Item>
//       <Item name={['enabled']} label='Enabled'>
//         <Switch defaultChecked={settings?.enabled || false} />
//       </Item>
//       <Item name={['environment']} label='Environment'>
//         <Select
//           defaultValue={settings?.environment}
//           options={Object.keys(provider.environments).map((x, i) => ({ label: provider.environments[x].name, value: x }))}
//         />
//       </Item>
//     </>
//   )
// }

// type EnvironmentSettingsProps = {
//   provider : IPaymentProvider
//   settings : PaymentSetting
// }

// const EnvironmentSettings = ({ provider, settings } : EnvironmentSettingsProps) => {

//   const environment = Form.useWatch(['environment'])

//   

//   return(
//     <>
//       { environment && (
//         <>
//           {
//             Object.keys(provider.environments[environment].fields).map((k, i) => {
//               return (
//                 <div key={i}>
//                   { provider.environments[environment].fields[k].type === 'string' && (
//                     <Item
//                       key={i}
//                       name={['environments', environment, k]}
//                       label={provider.environments[environment].fields[k].label}
//                     >
//                       <Input />
//                     </Item>
//                   )}
//                 </div>
//               )
//             })
//           }
//         </>
//       )}
//     </>
//   )
// }

// export default PaymentForm

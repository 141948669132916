import { Messages_Texts } from '../../../../../interfaces/forms/areas/appbuilder/applications/messages/messages';
import { Messages_Texts as Response } from '../../../../../interfaces/responses/areas/appbuilder/applications/messages/messages';
import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class MessageService {
  Add_Message = async (data: Messages_Texts): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.MESSAGES.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Messages = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.MESSAGES.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Message = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.MESSAGES.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Message = async (data: Messages_Texts): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.MESSAGES.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Message = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.MESSAGES.DELETE}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Publish_Message = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.MESSAGES.PUBLISH}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new MessageService();

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator'
import { AreaChart, BarChart, ColumnChart, PieChart, PlainValueChart } from '../../../../common/components/Charts';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { AvailableChartTypesReporting } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';
import { StatusCodes } from 'http-status-codes';
import { Button, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { GaugeChart } from '../../../../common/components/Charts/GaugeChart';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';
import { BillingContext } from '../../../../contexts/billingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import chartInputService from '../../../../services/areas/reporting/chartinputs/chartInputService';
import { AuthContext } from '../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../constants/TVMSRoles';
import { isInRoles } from '../../../../common/components/VisibleForRole';

const Dashboard = () => {
  const { t } = useTranslation();
  const { clientName, _id: clientId } = useParams<{ clientName: string, _id: string }>();
  const { deployment, service } = React.useContext(BillingContext);
  const baseUrl = `/client/${clientName}/${clientId}/${deployment?.name}`;
  const [chartInputs, setChartInputs] = useState<ChartInput[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [toCopyChartInputId, setToCopyChartInputId] = useState(undefined);
  const { state: AuthState } = useContext(AuthContext);
  const hasEditPermissions = isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]);

  const fetchData = async () => {
    try {
      const chartInputs = await chartInputService.Get_ChartInputs(clientName, deployment?.name ?? "", service?.name ?? "", "inventory");

      setChartInputs(chartInputs);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = async (): Promise<void> => {

    setIsLoading(true);

    const response = await dashboardService.Generate({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        crm: service?.name,
        area: "inventory",
      },
    });

    if (response.status_code === StatusCodes.OK) {
      await fetchData();
    }
    else {
      console.log(JSON.stringify(response));
    }

    setIsLoading(false);

    try {

    }
    catch (err) {
      console.log(err);
    }

  }

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('dashboard')}</h2>
        </div>
        <div className="col-md-4" />
        <div className="col-md-2 d-flex justify-content-center justify-content-md-end align-items-start">
          <Button disabled={isLoading} className="ant-btn btn btn-secondary rounded-pill width-240 height-48" onClick={() => handleRefresh()}>
            {t('chart-design-refresh-dashboard')}
          </Button>
        </div>
      </div>
      <div className="row mt-4">
      {chartInputs != null && chartInputs.map(chartInput => {

        const columnClassName = `col-md-${chartInput.width12column ?? 4}`;

        return (
          <>
            <div className={columnClassName} style={{ marginBottom: "30px" }}>
              <div style={chartInput.color ? { color: chartInput.color, backgroundImage: chartInput.backgroundImage, border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 } : { backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div>
                    <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                      {chartInput.title}
                    </p>
                  </div>
                  {hasEditPermissions === true &&
                  <div style={{ marginLeft: "auto" }}>
                    <Tooltip placement="top" title={t('chart-design-copy-chart')}>
                      <Button type="text" className="cursor-pointer" style={{ height: '100%', color: chartInput.color ?? '#505972' }} onClick={() => setToCopyChartInputId(chartInput.id)}>
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </Tooltip>
                  </div>
                  }
                </div>
                {chartInput.chartType === AvailableChartTypesReporting.BAR &&
                  <BarChart chartInput={chartInput} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.COLUMN &&
                  <ColumnChart chartInput={chartInput} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.LINE &&
                  <AreaChart chartInput={chartInput} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.PIE &&
                  <PieChart chartInput={chartInput} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.PLAIN_VALUE &&
                  <PlainValueChart series={chartInput.series} iconName={chartInput.iconName} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.GAUGE &&
                  <GaugeChart value={chartInput.series[0].data != null && chartInput.series[0].data.length > 0 ? chartInput.series[0].data[0].value : null} percentageWarning={chartInput.series[0].percentageWarning} />
                }
                <span style={{ marginLeft: 10 }}>{t('chart-design-data-as-of') + ": " + dayjs.unix(chartInput.timestamp).format("MMMM D, YYYY HH:mm")}</span>
              </div>
            </div>
          </>
        )
      })}
      </div>
      <Modal open={!!toCopyChartInputId} onCancel={() => setToCopyChartInputId(undefined)} okButtonProps={{href: `${baseUrl}/${service?.name}/reporting/copy/${toCopyChartInputId}`}}>
        <p>
        {`${t("chart-design-copy-chart-to-reporting")}`}
        </p>
      </Modal>
    </div>
  );
};

export default Dashboard;

import { useContext, useEffect, useState } from 'react';
import { faBoxOpen, faMemo, faMoneyBillTransfer, faMoneyCheckDollarPen, faTrashCan, faUpload, faUser, faUserCheck, faUserClock, faUserSlash, faUserXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, notification, Row, Table } from 'antd';
import { useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { useCustomerSearch, useDeleteMutation, useDisableMutation, usePublishMutation } from '../../../../services/areas/customers/customerService';
import confirm from 'antd/lib/modal/confirm';
import { RuleGroupType } from 'react-querybuilder';
import CustomerQueryBuilder from './shared/customerQueryBuilder';
import { Customer } from '../../../../interfaces/forms/areas/customers/customers/customer';
import { Spin } from '../../../../common/components/spinner';
import { CustomersContext } from '../../../../contexts/customersContext';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { IMSNameParams } from '../../../dashboard';
import { faBoxDollar } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import Renew from './renew';
import Change from './change';
import { useTranslation } from 'react-i18next';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';

const Customers = () => {
  const [query, setQuery] = useState<RuleGroupType>({ combinator: 'and', rules: [] });
  const { t } = useTranslation();
  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('customers')} subtitle={t('all-your-customers-that-a-registered-for-this-service')} />
        </div>
      </div>
      <div className="row-md-12">
        <ActionBar />
      </div>
      {/* <div className="row-md-12 mt-4 card-bg-light p-5">
        <div className="col-md-12">
          <StatusGrid />
        </div>
      </div> */}
      <div className="row-md-12 mt-4 card-bg-light p-5">
        <div className="col-md-8">
          <h4 className="page" style={{ color: '#485Fe0' }}>
            {t('search-customers')}
          </h4>
          <p className="h6 font-weight-normal line-height-26">{t('use-the-query-builder-to-easily-find-the-customer-s-you-are-looking-for')}</p>
        </div>
        <div className="m-2 pt-4">
          <CustomerQueryBuilder {...{ query }} onQueryChange={(q) => setQuery(q)} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <CustomerTable {...{ query }} />
        </div>
      </div>
    </ContainerWrapper>
  );
};

type CustomerTableProps = {
  query: RuleGroupType;
};

// type ResponseFromAkamai = ResponseFromAkamaiBody[];

// type ResponseFromAkamaiBody = {
//   date: string;
//   active: {
//     regular: number;
//     trial: number;
//   };
//   expired: {
//     regular: number;
//     trial: number;
//   };
//   pending: {
//     regular: number;
//     trial: number;
//   };
//   accounts: {
//     enabled: number;
//     disabled: number;
//   };
//   products: {
//     regular: number;
//     trial: number;
//     freemium: number;
//   };
// };

const CustomerTable = ({ query }: CustomerTableProps) => {
  const { url } = useRouteMatch();
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 100, page: 1 });
  const { deployment } = useContext(CustomersContext);
  const [showRenewModel, setShowRenewModal] = useState(false);
  const [showChangeModel, setShowChangeModal] = useState(false);
  const [customer, setCustomer] = useState([] as any);
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { t } = useTranslation();

  const { isLoading: deleteIsLoading, mutate: deleteCustomer } = useDeleteMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName } },
    {
      onSuccess: () => {
        notification.success({ message: t('customer-deleted') });
        refetch();
      },
      onError: () => {
        notification.error({ message: t('customer-could-not-be-deleted') });
      },
    }
  );
  const path = STORAGE_ENDPOINTS(clientName, deployment?.name, '').CUSTOMERS.CUSTOMERS;
  const { isLoading: disableIsLoading, mutate: disableCustomer } = useDisableMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, path: path } },
    {
      onSuccess: () => {
        notification.success({ message: t('customer-disabled') });
        refetch();
      },
      onError: () => {
        notification.error({ message: t('customer-could-not-be-disabled') });
      },
    }
  );
  const { isLoading: publishIsLoading, mutate: publishCustomer } = usePublishMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, path: path } },
    {
      onSuccess: () => {
        notification.success({ message: t('customer-updated') });
        refetch();
      },
      onError: () => {
        notification.error({ message: t('customer-could-not-be-updated') });
      },
    }
  );

  const handleChange = (pagination) => {
    const { current, pageSize } = pagination;
    const limit = pageSize;

    setPagination({ page: current, limit });
  };

  const { isLoading, isError, data, refetch } = useCustomerSearch({
    params: {
      instance: clientName,
      cms: deployment?.name || '',
      crm: serviceName,
      limit: pagination.limit,
      page: pagination.page,
    },
    query: JSON.stringify(query),
  });

  useEffect(() => {
    refetch();
  }, [pagination, refetch]);

  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong')}</span>}
      {showRenewModel && (
        <Renew
          visible={showRenewModel}
          customer={customer}
          onClose={() => {
            setShowRenewModal(false);
            refetch();
          }}
        ></Renew>
      )}
      {showChangeModel && (
        <Change
          visible={showChangeModel}
          customer={customer}
          onClose={() => {
            setShowChangeModal(false);
            refetch();
          }}
        ></Change>
      )}
      {!isLoading && !isError && (
        <Table
          dataSource={data?.data.docs || []}
          onChange={handleChange}
          columns={[
            {
              dataIndex: 'subscriptionStatus',
              title: t('subscription'),
              render: (text, record) => {
                const now = dayjs();
                const expirationDate = dayjs.unix(record.subscriptions?.base?.expiration_date ?? now.unix());
                const isActuallyExpired = record.subscriptions?.base?.expiration_date != null && now.isAfter(expirationDate);
                const trueStatus = isActuallyExpired ? 'expired' : text;

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px',
                    }}
                  >
                    {trueStatus === 'expired' && (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>
                          {' '}
                          <FontAwesomeIcon icon={faUserXmark} size={'lg'} style={{ color: '#c05453' }} />
                        </div>
                        <div style={{ fontSize: 12, fontWeight: 'bold' }}>EXPIRED</div>
                      </div>
                    )}
                    {trueStatus === 'active' && (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>
                          <FontAwesomeIcon icon={faUserCheck} size={'lg'} style={{ color: '#16a76e' }} />
                        </div>
                        <div style={{ fontSize: 12, fontWeight: 'bold' }}>ACTIVE</div>
                      </div>
                    )}
                    {trueStatus === 'pending' && (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>
                          <FontAwesomeIcon icon={faUserClock} size={'lg'} style={{ color: '#16a76e' }} />
                        </div>
                        <div style={{ fontSize: 12, fontWeight: 'bold' }}>PENDING</div>
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
              dataIndex: 'productStatus',
              title: t('product'),
              render: (text) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '20px',
                  }}
                >
                  {text === 'trial' && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div>
                        <FontAwesomeIcon icon={faBoxOpen} size={'lg'} style={{ color: '#999' }} />
                      </div>
                      <div style={{ fontSize: 12, fontWeight: 'bold' }}>TRIAL</div>
                    </div>
                  )}
                  {text === 'regular' && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div>
                        <FontAwesomeIcon icon={faBoxDollar} size={'lg'} style={{ color: '#999' }} />
                      </div>
                      <div style={{ fontSize: 12, fontWeight: 'bold' }}>REGULAR</div>
                    </div>
                  )}
                </div>
              ),
            },
            { dataIndex: 'first_name', title: t('firstname') },
            { dataIndex: 'last_name', title: t('lastname') },
            { dataIndex: 'username', title: t('username') },
            {
              title: t('expiration-date'),
              render: (row) => {
                const { subscriptions } = row as Customer;
                const now = dayjs();
                const expirationDate = subscriptions?.base?.expiration_date == undefined ? undefined : dayjs.unix(subscriptions?.base?.expiration_date);

                let textColor = '';

                if (!expirationDate) {
                  textColor = 'transparent';
                } else if (now.isAfter(expirationDate)) {
                  textColor = 'red';
                } else if (now.add(1, 'day').isAfter(expirationDate)) {
                  textColor = 'orange';
                } else {
                  textColor = 'green';
                }

                return (
                  <>
                    {/* {!subscriptions?.base?.expiration_date && <hr style={{ border: '1px solid', borderColor: textColor }} />} */}
                    {subscriptions?.base?.expiration_date && expirationDate != undefined && (
                      <span style={{ color: textColor }}>
                        {expirationDate.toDate().toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    )}
                  </>
                );
              },
            },
            {
              title: t('actions'),
              render: (row) => (
                <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                  <Button
                    type="text"
                    className="cursor-pointer"
                    onClick={() =>
                      confirm({
                        title: t('are-you-sure-you-want-to-delete-this-customer'),
                        okText: t('yes'),
                        okType: 'danger',
                        cancelText: t('no'),
                        async onOk() {
                          deleteCustomer(row._id);
                        },
                        onCancel() {},
                      })
                    }
                  >
                    <div style={{ height: 30, lineHeight: 1.5, paddingTop: 5 }}>
                      <FontAwesomeIcon style={{ fontSize: 24, color: '#888' }} icon={faTrashCan} />
                      <div>{t('delete')}</div>
                    </div>
                  </Button>
                  <Button
                    type="text"
                    className="cursor-pointer"
                    onClick={() =>
                      confirm({
                        title: row.status == 'active' ? 'Are you sure to disable selected customer?' : 'Are you sure to enable selected customer?',
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        async onOk() {
                          disableCustomer(row._id);
                        },
                        onCancel() {},
                      })
                    }
                  >
                    <div style={{ height: 30, lineHeight: 1.5, paddingTop: 5 }}>
                      <FontAwesomeIcon icon={row.status === 'active' ? faUser : faUserSlash} style={{ fontSize: 24, color: row.status === 'active' ? '#16a76e' : '#c05453' }} />
                      <div>{row.status === 'active' ? t('disable') : t('enable')}</div>
                    </div>
                  </Button>
                  <Button
                    type="text"
                    className="cursor-pointer"
                    onClick={() => {
                      setCustomer(row);
                      setShowRenewModal(true);
                    }}
                  >
                    <div style={{ height: 30, lineHeight: 1.5, paddingTop: 5 }}>
                      <FontAwesomeIcon style={{ fontSize: 24, color: '#888' }} icon={faMoneyBillTransfer} />
                      <div>{t('renew')}</div>
                    </div>
                  </Button>
                  <Button
                    type="text"
                    className="cursor-pointer"
                    onClick={() => {
                      setCustomer(row);
                      setShowChangeModal(true);
                    }}
                  >
                    <div style={{ height: 30, lineHeight: 1.5, paddingTop: 5 }}>
                      <FontAwesomeIcon style={{ fontSize: 24, color: '#888' }} icon={faMoneyCheckDollarPen} />
                      <div>{t('change')}</div>
                    </div>
                  </Button>
                  <Button type="text" className="cursor-pointer" onClick={() => publishCustomer(row._id)}>
                    <div style={{ height: 30, lineHeight: 1.5, paddingTop: 5 }}>
                      <FontAwesomeIcon style={{ fontSize: 24, color: '#888' }} icon={faUpload} />
                      <div>{t('publish')}</div>
                    </div>
                  </Button>
                  <Button type="text" className="cursor-pointer" href={`${redirectUrl}/${row._id}`}>
                    <div style={{ height: 30, lineHeight: 1.5, paddingTop: 5 }}>
                      <FontAwesomeIcon style={{ fontSize: 24, color: '#888' }} icon={faMemo} />
                      <div>{t('details')}</div>
                    </div>
                  </Button>
                </Col>
              ),
            },
          ]}
          pagination={{
            current: pagination.page,
            pageSize: pagination.limit,
            total: data?.data.totalDocs || 0,
            showSizeChanger: true,
            onChange: handleChange,
            showQuickJumper: false,
            showTotal: (total, range) => {
              return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
            },
          }}
        />
      )}
    </>
  );
};

const ActionBar = () => {
  const { clientName } = useParams<IMSNameParams & { deployment: string }>();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { service, deployment } = useContext(CustomersContext);
  const { isLoading, data } = useCustomerSearch({ params: { cms: deployment?.name ?? '', instance: clientName, crm: service?.name ?? '', limit: 9999, page: 1 }, query: '' });

  const exportCustomer = async () => {
    const worksheet = XLSX.utils.json_to_sheet(data?.data.docs ?? []);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, t('customers'));
    XLSX.writeFile(workbook, 'Customers_' + moment().format('YYYY-MM-DD') + '.xlsx');
  };

  return (
    <Row wrap justify={{ sm: 'end', xxl: 'end' }} style={{ width: '100%' }}>
      <Link className="btn btn-secondary rounded-pill btn-default width-240 mt-2 mt-md-0 height-48" to={`${redirectUrl}/actions/importer-excel`}>
        {t('import-customers')}
      </Link>
      <Button disabled={isLoading} loading={isLoading} className="btn btn-secondary rounded-pill btn-default width-240 mt-2 mt-md-0 height-48" onClick={exportCustomer}>
        {t('export-customers')}
      </Button>
      <Link className="btn btn-secondary rounded-pill btn-default width-240 mt-2 mt-md-0 height-48" to={{ pathname: `${redirectUrl}/customers/add` }}>
        {t('add-customer')}
      </Link>
      {/* <Link className="btn btn-secondary rounded-pill btn-default width-240 mt-2 mt-md-0 height-48" to={{ pathname: `${url}/add-range/` }}>
        Add Customer Range
      </Link> */}
    </Row>
  );
};

export default Customers;

import { User } from '../../interfaces/forms/users/user';
import { Data } from '../../interfaces/responses/users/user';
import TokenStorageService from '../../utils/TokenStorageService';
import { AuthActions, AuthActionTypes } from './action-types';

export type AuthState = {
  is_remember?: boolean;
  is_userinfo_loading: boolean;
  isAuthenticated: boolean;
  user: Data | null;
  token?: string;
  refresh_token?: string;
};

export const initialAuthState: AuthState = {
  is_remember: false,
  is_userinfo_loading: false,
  isAuthenticated: TokenStorageService.isAuthenticated(),
  user: null,
  token: TokenStorageService.getAccessToken(),
  refresh_token: TokenStorageService.getRefreshToken(),
};

const AuthReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      const { auth } = action;
      if (auth.is_remember) TokenStorageService.setToken(auth);
      
      return {
        ...state,
        is_remember: auth.is_remember,
        isAuthenticated: true,
        refresh_token: auth.refresh_token,
        token: auth.token,
        user: auth.user,
      } as AuthState;
    case AuthActionTypes.LOGOUT:
    case AuthActionTypes.LOGIN_FAILURE:
      TokenStorageService.clearToken();
      return {
        ...state,
        isAuthenticated: false,
        refresh_token: '',
        token: '',
      } as AuthState;
    case AuthActionTypes.GET_USERINFO_START:
      return {
        ...state,
        is_userinfo_loading: true,
      } as AuthState;
    case AuthActionTypes.GET_USERINFO_SUCCESS:
      const { user } = action;
      return {
        ...state,
        is_userinfo_loading: false,
        user: user,
      } as AuthState;
    default:
      return state;
  }
};
export default AuthReducer;

import { Tabs } from 'antd';
import { useContext, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faTv, faWindowRestore, faToggleOn, faListDropdown, faBlockBrick, faBrush, faCog, faListTree, faShieldKeyhole, faTableColumns, faFillDrip, faDistributeSpacingVertical } from '@fortawesome/pro-regular-svg-icons';
import Keyboards from '../../../../../common/components/AppBuilder/Components/Keyboards';
import Modals from '../../../../../common/components/AppBuilder/Components/Modal';
import Switches from '../../../../../common/components/AppBuilder/Components/Switch';
import Dropdown from '../../../../../common/components/AppBuilder/Components/Dropdown';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInputText } from '@fortawesome/pro-solid-svg-icons';
import Inputs from '../../../../../common/components/AppBuilder/Components/Inputs';
import Buttons from '../../../../../common/components/AppBuilder/Components/Buttons';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import { StatusCodes } from 'http-status-codes';
import MenuBuilder from './helpers/menu';
import { useTranslation } from 'react-i18next';

const Authentication = () => {
  const [menus, setMenus] = useState([{ name: 'Buttons' }, { name: 'Keyboards' }, { name: 'Inputs' }, { name: 'Modals' }, { name: 'Switches' }, { name: 'Dropdowns' }]);
  const [activeKeyAuth, setActiveKeyAuth] = useState('Buttons');
  const { TabPane } = Tabs;
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState(false);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [application, setApplication] = useState([] as any);
  const { t } = useTranslation();

  const getIcon = (name: any) => {
    switch (name) {
      case 'Keyboards':
        return faKeyboard;
      case 'Modals':
        return faWindowRestore;
      case 'Switches':
        return faToggleOn;
      case 'Dropdowns':
        return faListDropdown;
      case 'Inputs':
        return faInputText;
      case 'Buttons':
        return faDistributeSpacingVertical;
      default:
        return faTv;
    }
  };

  const getComponents = (type: any) => {
    switch (type) {
      case 'Keyboards':
        return <Keyboards />;
      case 'Modals':
        return <Modals />;
      case 'Switches':
        return <Switches />;
      case 'Dropdowns':
        return <Dropdown />;
      case 'Inputs':
        return <Inputs />;
      case 'Buttons':
        return <Buttons />;
    }
  };

  const getAppItems = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    getAppItems();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row-md-12">
        <div className="row-md-12">
          <div className="col-md-12 mb-2">
            <h2 className="page-title mt-4 mb-4">
              {t('components-parenthesis')}{application.branding?.name}/{application.branding?.packageid}{t('close-parenthesis')}
            </h2>
            <p>{t('setup-your-components-these-are-used-throughout-the-app-builder-where-they-are-needed')}</p>
          </div>
          <MenuBuilder application={application} redirectUrl={redirectUrl} id={id}></MenuBuilder>
        </div>
        <div className="col-md-12 mb-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="card">
            <div className="row">
              <div className="col-12  mt-3">
                {!isLoading && (
                  <Tabs className="highlight-tab" onChange={(activeKeyAuth) => setActiveKeyAuth(activeKeyAuth)} defaultActiveKey={'Languages'}>
                    {menus.map((menu: any) => {
                      return (
                        <TabPane
                          tab={
                            <div className="col text-center" style={{}}>
                              <div style={{ width: 100 }}>
                                <FontAwesomeIcon style={{ color: application.components?.buttons?.length == 0 && menu.name != 'Buttons' ? '#999999' : '#485Fe0' }} size={'xl'} icon={getIcon(menu.name) as any} />
                              </div>
                              <div style={{ fontSize: 12, color: '#999' }}>{menu.name}</div>
                            </div>
                          }
                          key={menu.name}
                          disabled={application.components?.buttons?.length == 0 && menu.name != 'Buttons' ? true : false}
                        >
                          {activeKeyAuth == menu.name && <>{getComponents(menu.name)}</>}
                        </TabPane>
                      );
                    })}
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Authentication;

import { useHistory, useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../../../dashboard';
import NestedSeasons from '../../../../../../../common/components/Content/Series/NestedSeasons';
import { Button, Select, notification } from 'antd';
import cmsService from '../../../../../../../services/areas/content/series/series';
import { ICMS_Lists } from '../../../../../../../interfaces/forms/common/ICMS_Lists';
import { useContext, useState } from 'react';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { StatusCodes } from 'http-status-codes';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

const EditMovie = (data: any) => {
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [seasons, setSeasons] = useState([] as any);
  const history = useHistory();
  const [selectedSeason, setSelectedSeason] = useState([] as any);
  const { t } = useTranslation();
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 0,
    pageSize: 50,
    filters: [],
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });

  const getSeasons = async () => {
    const response = await cmsService.List_Seasons({
      ...tableSearchState,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setSeasons(response.data.data);
    }
  };
  useEffectOnce(() => {
    getSeasons();
  });

  const handleStoreSeeasonToSeries = async () => {
    var season = seasons.find((s) => s._id === selectedSeason.value);
    var values = {
      ...data.data,
      seasons: [
        ...data.data.seasons,
        {
          ...season,
        },
      ],
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    
    let response = await cmsService.Update_Series(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.go(0);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };

  return (
    <div className="container-fluid ">
      <div className="card mt-4 pb-4">
        <div className="card-body card-ch-padding">
    
          <NestedSeasons series={data.data} data={data.data.seasons} _id={data.data._id} service_name={params.deployment} clientName={clientName}></NestedSeasons>
        </div>
      </div>
    </div>
  );
};

export default EditMovie;

import { IClientInfo } from "../../interfaces/responses/clients/IClientInfoResponse";
import { IRoute } from "../../routes/AllAreaRoutes";

export enum DrawerActionTypes {
  TOGGLE_DRAWER = "TOGGLE_DRAWER",
  PERMANENT_DRAWER = "PERMANENT_DRAWER",
  SET_SIDEBAR_ROUTES = "SET_SIDEBAR_ROUTES",
  SET_LATEST_OPEN_KEY = "SET_LATEST_OPEN_KEY",
  SET_PARENT_SERVICE_INFO = "SET_PARENT_SERVICE_INFO",
  SET_CLIENT_INFO = "SET_CLIENT_INFO",
  SET_CLIENT_INFO_LOADING = "SET_CLIENT_INFO_LOADING",
  UPDATE_WHITE_LABEL_INFO = "UPDATE_WHITE_LABEL_INFO",
}

interface DrawerToggleAction {
  type: typeof DrawerActionTypes.TOGGLE_DRAWER;
}
interface DrawerPermanentAction {
  type: typeof DrawerActionTypes.PERMANENT_DRAWER;
}
interface UpdateWhiteLabelInfo {
  type: typeof DrawerActionTypes.UPDATE_WHITE_LABEL_INFO;
  logo?: string;
  fav_icon?: string;
}

interface SetSidebarRoutes {
  type: typeof DrawerActionTypes.SET_SIDEBAR_ROUTES;
  routes: IRoute[];
  default_selected_key: string;
  default_open_key: string;
  service_type: string;
  service_name: string;
  client_dashboard_path: string;
}

interface SetClientInfo {
  type: typeof DrawerActionTypes.SET_CLIENT_INFO;
  client_info?: IClientInfo;
}

interface SetParentServiceInfo {
  type: typeof DrawerActionTypes.SET_PARENT_SERVICE_INFO;
  parent_service_name: string;
}

interface SetClientInfoLoading {
  type: typeof DrawerActionTypes.SET_CLIENT_INFO_LOADING;
  is_client_info_loading: boolean;
}
interface SetLatestOpenKey {
  type: typeof DrawerActionTypes.SET_LATEST_OPEN_KEY;
  default_open_key: string;
}

export type DrawerActions =
  | DrawerToggleAction
  | DrawerPermanentAction
  | SetSidebarRoutes
  | SetClientInfo
  | SetClientInfoLoading
  | SetParentServiceInfo
  | SetLatestOpenKey
  |UpdateWhiteLabelInfo;

import { 
  Data as DomainChartDesign,
  AccumulatorDesign as DomainAccumulatorDesign, 
  ArrayFilterDesign as DomainArrayFilterDesign, 
  BooleanFilterDesign as DomainBooleanFilterDesign, 
  DateTimeFilterDesign as DomainDateTimeFilterDesign, 
  NumberFilterDesign as DomainNumberFilterDesign, 
  StringFilterDesign as DomainStringFilterDesign, 
  ChoiceFilterDesign as DomainChoiceFilterDesign, 
} from '../../../../../interfaces/responses/areas/reporting/chartdesigns/chartDesign';
import { 
  ChartDesign as FormChartDesign,
  ArrayFilterDesign as FormArrayFilterDesign, 
  BooleanFilterDesign as FormBooleanFilterDesign, 
  DateTimeFilterDesign as FormDateTimeFilterDesign, 
  NumberFilterDesign as FormNumberFilterDesign, 
  StringFilterDesign as FormStringFilterDesign, 
  ChoiceFilterDesign as FormChoiceFilterDesign, 
} from '../../../../../interfaces/forms/areas/reporting/chartdesigns/chartDesign';
import { ChartDesignFilterDataTypes } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';
import dayjs from 'dayjs';
import { ChartDesignAvailability } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignConstants';
import { t } from 'i18next';

export const MapFormValuesToDomainValues = (formChartDesign: FormChartDesign): DomainChartDesign => {

  const domainChartDesign: DomainChartDesign = {
    name: formChartDesign.name,
    dashboard: formChartDesign.dashboard,
    entityType: formChartDesign.entityType,
    chartType: formChartDesign.chartType,
    order: formChartDesign.order,
    width12column: formChartDesign.width12column,
    filterLogicOperator: formChartDesign.filterLogicOperator,
    arrayFilters: [],
    stringFilters: [],
    choiceFilters: [],
    numberFilters: [],
    booleanFilters: [],
    dateTimeFilters: [],
    groupByFieldName: formChartDesign.groupByFieldName,
    sortBy: formChartDesign.sortBy,
    limit: formChartDesign.limit,
    percentageOperator: formChartDesign.percentageOperator,
    percentageTarget: formChartDesign.percentageTarget,
    percentageWarning: formChartDesign.percentageWarning,
    color: formChartDesign.color,
    backgroundImage: formChartDesign.backgroundImage,
    iconName: formChartDesign.iconName,
  };

  const domainAccumulator: DomainAccumulatorDesign = {
    displayName: formChartDesign["accumulator.displayName"],
    operator: formChartDesign["accumulator.operator"],
    operandFieldName: formChartDesign["accumulator.operandFieldName"],
  };

  setDefaultDisplayName(domainAccumulator, domainChartDesign);

  domainChartDesign.accumulator = domainAccumulator;

  const domainAccumulator2: DomainAccumulatorDesign = {
    displayName: formChartDesign["accumulator2.displayName"],
    operator: formChartDesign["accumulator2.operator"],
    operandFieldName: formChartDesign["accumulator2.operandFieldName"],
  };

  setDefaultDisplayName(domainAccumulator2, domainChartDesign);

  domainChartDesign.accumulator2 = domainAccumulator2;

  const domainAccumulator3: DomainAccumulatorDesign = {
    displayName: formChartDesign["accumulator3.displayName"],
    operator: formChartDesign["accumulator3.operator"],
    operandFieldName: formChartDesign["accumulator3.operandFieldName"],
  };

  setDefaultDisplayName(domainAccumulator3, domainChartDesign);

  domainChartDesign.accumulator3 = domainAccumulator3;

  formChartDesign.filters?.forEach(formFilter => {

    if (!formFilter) {
      return;
    }

    if (formFilter.dataType === ChartDesignFilterDataTypes.ARRAY) {
      const formArrayFilter = (formFilter as FormArrayFilterDesign);
      const domainArrayFilter: DomainArrayFilterDesign = {
        fieldName: formFilter.fieldName,
        stringFilters: [],
        choiceFilters: [],
        numberFilters: [],
        booleanFilters: [],
        dateTimeFilters: [],
      };

      formArrayFilter.elementFilters?.forEach(formElementFilter => {

        if (formElementFilter.dataType === ChartDesignFilterDataTypes.STRING) {
          const formStringElementFilter = (formElementFilter as FormStringFilterDesign);
          const domainStringElementFilter: DomainStringFilterDesign = {
            fieldName: formStringElementFilter.fieldName,
            operator: formStringElementFilter.operator,
            operand: formStringElementFilter.operand,
          };
          domainArrayFilter.stringFilters?.push(domainStringElementFilter);
        }
        else if (formElementFilter.dataType === ChartDesignFilterDataTypes.CHOICE) {
          const formChoiceElementFilter = (formElementFilter as FormChoiceFilterDesign);
          const domainChoiceElementFilter: DomainChoiceFilterDesign = {
            fieldName: formChoiceElementFilter.fieldName,
            operator: formChoiceElementFilter.operator,
            operand: formChoiceElementFilter.operand,
          };
          domainArrayFilter.choiceFilters?.push(domainChoiceElementFilter);
        }
        else if (formElementFilter.dataType === ChartDesignFilterDataTypes.NUMBER) {
          const formNumberElementFilter = (formElementFilter as FormNumberFilterDesign);
          const domainElementFilter: DomainNumberFilterDesign = {
            fieldName: formNumberElementFilter.fieldName,
            operator: formNumberElementFilter.operator,
            operand: formNumberElementFilter.operand,
          };
          domainArrayFilter.numberFilters?.push(domainElementFilter);
        }
        else if (formElementFilter.dataType === ChartDesignFilterDataTypes.BOOLEAN) {
          const formBooleanElementFilter = (formElementFilter as FormBooleanFilterDesign);
          const domainElementFilter: DomainBooleanFilterDesign = {
            fieldName: formBooleanElementFilter.fieldName,
            operand: formBooleanElementFilter.operand,
          };
          domainArrayFilter.booleanFilters?.push(domainElementFilter);
        }
        else if (formElementFilter.dataType === ChartDesignFilterDataTypes.DATETIME) {
          const formDateTimeElementFilter = (formElementFilter as FormDateTimeFilterDesign);
          const domainElementFilter: DomainDateTimeFilterDesign = {
            fieldName: formDateTimeElementFilter.fieldName,
            operator: formDateTimeElementFilter.operator,
            operand: formDateTimeElementFilter.operand?.unix(),
          };
          domainArrayFilter.dateTimeFilters?.push(domainElementFilter);
        }

      });

      domainChartDesign.arrayFilters?.push(domainArrayFilter);
    }
    else if (formFilter.dataType === ChartDesignFilterDataTypes.STRING) {
      const formStringFilter = (formFilter as FormStringFilterDesign);
      const domainStringFilter: DomainStringFilterDesign = {
        fieldName: formStringFilter.fieldName,
        operator: formStringFilter.operator,
        operand: formStringFilter.operand,
      };
      domainChartDesign.stringFilters?.push(domainStringFilter);
    }
    else if (formFilter.dataType === ChartDesignFilterDataTypes.CHOICE) {
      const formChoiceFilter = (formFilter as FormChoiceFilterDesign);
      const domainChoiceFilter: DomainChoiceFilterDesign = {
        fieldName: formChoiceFilter.fieldName,
        operator: formChoiceFilter.operator,
        operand: formChoiceFilter.operand,
      };
      domainChartDesign.choiceFilters?.push(domainChoiceFilter);
    }
    else if (formFilter.dataType === ChartDesignFilterDataTypes.NUMBER) {
      const formNumberFilter = (formFilter as FormNumberFilterDesign);
      const domainNumberFilter: DomainNumberFilterDesign = {
        fieldName: formNumberFilter.fieldName,
        operator: formNumberFilter.operator,
        operand: formNumberFilter.operand,
      };
      domainChartDesign.numberFilters?.push(domainNumberFilter);
    }
    else if (formFilter.dataType === ChartDesignFilterDataTypes.BOOLEAN) {
      const formBooleanFilter = (formFilter as FormBooleanFilterDesign);
      const domainBooleanFilter: DomainBooleanFilterDesign = {
        fieldName: formBooleanFilter.fieldName,
        operand: formBooleanFilter.operand,
      };
      domainChartDesign.booleanFilters?.push(domainBooleanFilter);
    }
    else if (formFilter.dataType === ChartDesignFilterDataTypes.DATETIME) {
      const formDateTimeFilter = (formFilter as FormDateTimeFilterDesign);
      const domainDateTimeFilter: DomainDateTimeFilterDesign = {
        fieldName: formDateTimeFilter.fieldName,
        operator: formDateTimeFilter.operator,
        operand: formDateTimeFilter.operand?.unix(),
      };
      domainChartDesign.dateTimeFilters?.push(domainDateTimeFilter);
    }

  });

  return domainChartDesign;

};

const setDefaultDisplayName = (domainAccumulator: DomainAccumulatorDesign, domainChartDesign: DomainChartDesign) => {

  if ((domainAccumulator.operandFieldName?.length ?? 0) > 0 && (domainAccumulator.displayName?.length ?? 0) === 0) {
    const entity = ChartDesignAvailability.getEntities().find(entity => entity.name === domainChartDesign.entityType);

    if (entity) {
      const field = entity.fields?.find(field => field.name === domainAccumulator.operandFieldName);

      if (field) {
        domainAccumulator.displayName = t(field.translationKey);
      }
    }
  }

}

export const MapDomainValuesToFormValues = (domainChartDesign: DomainChartDesign): FormChartDesign => {

  const formChartDesign: FormChartDesign = {
    name: domainChartDesign.name,
    dashboard: domainChartDesign.dashboard,
    entityType: domainChartDesign.entityType,
    chartType: domainChartDesign.chartType,
    order: domainChartDesign.order,
    width12column: domainChartDesign.width12column,
    filterLogicOperator: domainChartDesign.filterLogicOperator,
    filters: [],
    groupByFieldName: domainChartDesign.groupByFieldName,
    sortBy: domainChartDesign.sortBy,
    limit: domainChartDesign.limit,
    percentageOperator: domainChartDesign.percentageOperator,
    percentageTarget: domainChartDesign.percentageTarget,
    percentageWarning: domainChartDesign.percentageWarning,
    color: domainChartDesign.color,
    backgroundImage: domainChartDesign.backgroundImage,
    iconName: domainChartDesign.iconName,
  };

  formChartDesign["accumulator.displayName"] = domainChartDesign.accumulator?.displayName;
  formChartDesign["accumulator.operator"] = domainChartDesign.accumulator?.operator;
  formChartDesign["accumulator.operandFieldName"] = domainChartDesign.accumulator?.operandFieldName;

  formChartDesign["accumulator2.displayName"] = domainChartDesign.accumulator2?.displayName;
  formChartDesign["accumulator2.operator"] = domainChartDesign.accumulator2?.operator;
  formChartDesign["accumulator2.operandFieldName"] = domainChartDesign.accumulator2?.operandFieldName;

  formChartDesign["accumulator3.displayName"] = domainChartDesign.accumulator3?.displayName;
  formChartDesign["accumulator3.operator"] = domainChartDesign.accumulator3?.operator;
  formChartDesign["accumulator3.operandFieldName"] = domainChartDesign.accumulator3?.operandFieldName;

  formChartDesign.filters = [];

  domainChartDesign.arrayFilters?.forEach(domainArrayFilter => {

    const formFilter: FormArrayFilterDesign = {
      dataType: ChartDesignFilterDataTypes.ARRAY,
      fieldName: domainArrayFilter.fieldName,
      elementFilters: [],
    }

    domainArrayFilter.stringFilters?.forEach(domainStringElementFilter => {

      const formElementFilter: FormStringFilterDesign = {
        dataType: ChartDesignFilterDataTypes.STRING,
        fieldName: domainStringElementFilter.fieldName,
        operator: domainStringElementFilter.operator,
        operand: domainStringElementFilter.operand,
      }
  
      formFilter.elementFilters?.push(formElementFilter);
  
    });
  
    domainArrayFilter.choiceFilters?.forEach(domainChoiceElementFilter => {

      const formElementFilter: FormChoiceFilterDesign = {
        dataType: ChartDesignFilterDataTypes.CHOICE,
        fieldName: domainChoiceElementFilter.fieldName,
        operator: domainChoiceElementFilter.operator,
        operand: domainChoiceElementFilter.operand,
      }
  
      formFilter.elementFilters?.push(formElementFilter);
  
    });
  
    domainArrayFilter.numberFilters?.forEach(domainNumberElementFilter => {

      const formElementFilter: FormNumberFilterDesign = {
        dataType: ChartDesignFilterDataTypes.NUMBER,
        fieldName: domainNumberElementFilter.fieldName,
        operator: domainNumberElementFilter.operator,
        operand: domainNumberElementFilter.operand,
      }

      formFilter.elementFilters?.push(formElementFilter);
  
    });
  
    domainArrayFilter.booleanFilters?.forEach(domainBooleanElementFilter => {

      const formElementFilter: FormBooleanFilterDesign = {
        dataType: ChartDesignFilterDataTypes.BOOLEAN,
        fieldName: domainBooleanElementFilter.fieldName,
        operand: domainBooleanElementFilter.operand,
      }
  
      formFilter.elementFilters?.push(formElementFilter);
  
    });
  
    domainArrayFilter.dateTimeFilters?.forEach(domainDateTimeElementFilter => {

      const formElementFilter: FormDateTimeFilterDesign = {
        dataType: ChartDesignFilterDataTypes.DATETIME,
        fieldName: domainDateTimeElementFilter.fieldName,
        operator: domainDateTimeElementFilter.operator,
        operand: domainDateTimeElementFilter.operand ? dayjs.unix(domainDateTimeElementFilter.operand) : undefined,
      }
  
      formFilter.elementFilters?.push(formElementFilter);
  
    });
  
    formChartDesign.filters?.push(formFilter);

  });

  domainChartDesign.stringFilters?.forEach(domainStringFilter => {

    const formFilter: FormStringFilterDesign = {
      dataType: ChartDesignFilterDataTypes.STRING,
      fieldName: domainStringFilter.fieldName,
      operator: domainStringFilter.operator,
      operand: domainStringFilter.operand,
    }

    formChartDesign.filters?.push(formFilter);

  });

  domainChartDesign.choiceFilters?.forEach(domainChoiceFilter => {

    const formFilter: FormChoiceFilterDesign = {
      dataType: ChartDesignFilterDataTypes.CHOICE,
      fieldName: domainChoiceFilter.fieldName,
      operator: domainChoiceFilter.operator,
      operand: domainChoiceFilter.operand,
    }

    formChartDesign.filters?.push(formFilter);

  });

  domainChartDesign.numberFilters?.forEach(domainNumberFilter => {

    const formFilter: FormNumberFilterDesign = {
      dataType: ChartDesignFilterDataTypes.NUMBER,
      fieldName: domainNumberFilter.fieldName,
      operator: domainNumberFilter.operator,
      operand: domainNumberFilter.operand,
    }

    formChartDesign.filters?.push(formFilter);

  });

  domainChartDesign.booleanFilters?.forEach(domainBooleanFilter => {

    const formFilter: FormBooleanFilterDesign = {
      dataType: ChartDesignFilterDataTypes.BOOLEAN,
      fieldName: domainBooleanFilter.fieldName,
      operand: domainBooleanFilter.operand,
    }

    formChartDesign.filters?.push(formFilter);

  });

  domainChartDesign.dateTimeFilters?.forEach(domainDateTimeFilter => {

    const formFilter: FormDateTimeFilterDesign = {
      dataType: ChartDesignFilterDataTypes.DATETIME,
      fieldName: domainDateTimeFilter.fieldName,
      operator: domainDateTimeFilter.operator,
      operand: domainDateTimeFilter.operand ? dayjs.unix(domainDateTimeFilter.operand) : undefined,
    }

    formChartDesign.filters?.push(formFilter);

  });

  return formChartDesign;

};


import type { Client, User } from '@mwaretv/database/build/backend/models/base';
import { Menu, MenuItemProps } from 'antd';
import { useContext, ReactElement, cloneElement } from 'react';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { AuthContext } from '../../../context_api/AuthContext';
import './style.css';
import type { DocumentType } from '@typegoose/typegoose';

interface VisibleForRoleProps {
  children: ReactElement;
  roles: TVMSRoles[];
}

export const isInRoles = (role: string | undefined, roles: TVMSRoles[]) => {
  return role && roles.map((x) => x.toLowerCase()).includes(role.toLowerCase() as TVMSRoles);
};

type ServiceElements = 'customers' | 'billing' | 'marketing' | 'reporting';

type DeploymentElements = 'content' | 'apps';

export const hasAccessToClient = (user: User, client: string) => {
  if (isInRoles(user.role, [TVMSRoles.SuperAdmin])) {
    return true;
  }

  return user.clients?.some(x => (x as DocumentType<Client>).name === client);
};

export const hasAccessToService = (user: User, client: string, deployment: string, service: string, element: ServiceElements) => {
  if (!hasAccessToClient(user, client)) {
    return false;
  }

  if (isInRoles(user.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin])) {
    return true;
  }

  return user.services && user.services.includes(`${client}_${deployment}_${service}_${element}`);
};

export const hasAccessToDeployment = (user: User, client: string, deployment: string, element: DeploymentElements) => {
  if (!hasAccessToClient(user, client)) {
    return false;
  }

  if (isInRoles(user.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin])) {
    return true;
  }

  return user.deployments && user.deployments.includes(`${client}_${deployment}_${element}`);
};

export const VisibleForRole = ({ children, roles, ...props }: VisibleForRoleProps) => {
  const { state } = useContext(AuthContext);

  // if (isInRoles(state.user?.role, roles)) {
  //   return <div className="visible">{cloneElement(children, { ...children.props, ...props })}</div>;
  // } else {
  //   return <div className="notvisible">{cloneElement(children, { ...children.props, ...props })}</div>;
  // }

  if (isInRoles(state.user?.role, roles)) {
    return cloneElement(children, { ...children.props, ...props });
  } else {
    return <></>;
  }
};

export const NotVisibleForRole = ({ children, roles, ...props }: VisibleForRoleProps) => {
  const { state } = useContext(AuthContext);

  // if (isInRoles(state.user?.role, roles)) {
  //   return <div className="visible">{cloneElement(children, { ...children.props, ...props })}</div>;
  // } else {
  //   return <div className="notvisible">{cloneElement(children, { ...children.props, ...props })}</div>;
  // }

  if (isInRoles(state.user?.role, roles)) {
    return <></>;
  } else {
    return cloneElement(children, { ...children.props, ...props });
  }
};

import { Hero_Player } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/heros/player";
import { Hero_Player as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/heros/player";
import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";
import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import {IGetForm} from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Hero_Player_Service {
    Add_Hero_Player = async (data: Hero_Player): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.HERO.PLAYER.ADD, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Hero_Player = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.HERO.PLAYER.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Hero_Player = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.HERO.PLAYER.DETAILS}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    }; 

    Update_Hero_Player = async (data: Hero_Player): Promise<IBaseResponse> => {
        
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.HERO.PLAYER.UPDATE}`, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Hero_Player = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.HERO.PLAYER.DELETE}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Hero_Player_Service();

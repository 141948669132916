import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

const Ads = (params: { setAds: any; data?: any }) => {
  let [form] = useForm();
  const { t } = useTranslation();
  useEffectOnce(() => {
    form.setFieldsValue(params.data);
    params.setAds({
      vast_url: '',
    });
  });

  useEffect(() => {
    if (params.data != undefined) {
      form.setFieldsValue(params.data);
      if (params.data.ticker_enabled != undefined) {
      }
    } else {
    }
  }, [params.data]);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('advertisement')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light p-5">
          <h4 className="text-primary mt-4 mb-3">VAST</h4>
          <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('vast-url')}</label>
            <Form.Item extra={t('vast-ad-url-is-used-by-the-player-to-retrieve-video-and-or-audio-ads-via-an-external-ad-system')} className="col-md-5" name={['ads', 'vast_url']}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://yourvasturl.com" />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;

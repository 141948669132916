import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';
import { ErrorReporting } from '@mwaretv/database/build/backend/models/reporting/apps/error';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';

class AppErrorService {
  List_AppErrors = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ErrorReporting[]>> => {

    return await axiosInstance
      .post<TvmsApiResponseData<ErrorReporting[]>>(API_ENDPOINTS.V1.APB.APPLICATIONS.APPERRORS.LIST, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_AppError = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ErrorReporting>> => {

    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.APPERRORS.DETAILS}/${requestData.urlParams?.id}`, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
const APPERROR_SERVICE = new AppErrorService();
export default APPERROR_SERVICE;

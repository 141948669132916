import { Form, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Share = (params: { setShare: any; data?: any }) => {
  const [enableShare, setEnableShare] = useState(false);
  const [rating, setRating] = useState([] as any);
  const {t} = useTranslation();
  useEffect(() => {
    if (params.data != undefined) {
      setEnableShare(params.data.enabled ?? false);
      setRating(params.data.rating);
    }
  }, [params.data]);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('social-media-share')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 p-3 ">
            <div className="">
              <div className="card-body card-col-width rounded">
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('share')}</label>
                  <Form.Item extra={t('share-extra')} className="col-md-5 pb-0 mb-0" name={['share']}>
                    <Switch
                      defaultChecked={false}
                      onChange={(checked) => {
                        setEnableShare(checked);
                      }}
                      className="mt-2"
                      checked={enableShare}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;

import React, { useContext, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { add as addShoppingCart } from '../../../../services/areas/billing/shoppingCartService';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Form, FormFields } from './shared/form';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { ShoppingCart } from '../../../../interfaces/forms/areas/billing/shoppingCart';
import { notification } from 'antd';
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';

const AddHardware: React.FC = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(BillingContext);
  const [form] = useForm<ShoppingCart>();
  const history = useHistory();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);

  const { mutate, isLoading } = useMutation(
    (values: ShoppingCart) => {
      return addShoppingCart({ params: { instance: clientName, crm: service?.name || '', cms: deployment?.name || '' }, data: values });
    },
    {
      
      onSuccess: (data) => {
        notification.success({ message: t('shopping-cart-added') });
        history.push(redirectUrl);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-saving-the-shopping-cart')});
      },
    }
  );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-cart-and-portal-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('add-webshop')} subtitle={t('add-a-new-webshop-cart-and-portal')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            disabled={isLoading}
            onFinish={(values: ShoppingCart) => {
              mutate(values);
            }}
            onFinishFailed={(errorInfo) => {
              setModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
          >
            <FormFields />
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddHardware;

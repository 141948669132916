
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';



const MultiScreen = (params: { setMultiscreen: any, data?: any }) => {

  const {t} = useTranslation();
  useEffect(() => {
    if (params.data != undefined) {

    }
  }, [params.data]);


  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('multiscreen')}</h4>
      <div className="row">
        <div className="col-md-12">

        </div>
      </div>
    </div>
  );
};
export default MultiScreen;

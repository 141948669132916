import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Drawer, Modal, Space } from "antd";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const GridDrawer = (props: any) => {
  const { deleteItemTitleMsg, deleteItemButtonMsg } = props;
  const {t} = useTranslation();
  const deselectAllItem = (e: any) => {
    props.setRowSelectionState([]);
  };

  const deleteSelectedItem = () => {
    confirm({
      title: t('are-you-sure-to-delete-selected-deleteitembuttonmsg', { deleteItemButtonMsg: deleteItemButtonMsg }),
      okText: t('yes'),
      okType: "danger",
      cancelText: t('no'),
      async onOk() {
        await props.deleteSelectedItems();
      },
      onCancel() {
        
      },
    });
  };
  return (
    <Drawer
      className="channel-drawer"
      placement="bottom"
      closable={false}
      open={true}
      mask={false}
      height="103px"
    >
      <div className="row">
        <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-start mb-4 mb-md-0">
          {/* <Tooltip placement="topLeft" title={`Deselect All`}> */}
            <Checkbox
              className="deselect-all"
              checked={true}
              // onChange={deselectAllItem}
            ></Checkbox>
          {/* </Tooltip> */}
          <span className="badge badge-secondary-light rounded-pill d-flex justify-content-center align-items-center height-40 min-width-59 ml-4">
            {props.selectedRowKeys.length}
          </span>
          <p className="mb-0 ml-3 h6 font-weight-normal">
            {deleteItemTitleMsg}
          </p>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
          <Space direction="horizontal">
            <button
              className="btn btn-danger-light rounded-pill btn-default float-left"
              onClick={deselectAllItem}
            >
              <FontAwesomeIcon className="mr-2" icon={faCancel as IconProp} /> {t('cancel')}
            </button>
            <button
              className="btn btn-danger-light rounded-pill btn-default float-right"
              onClick={deleteSelectedItem}
            >
              <FontAwesomeIcon className="mr-2" icon={faTrashAlt as IconProp} /> {t('delete-selected')} {deleteItemButtonMsg}
            </button>
          </Space>
        </div>
      </div>
    </Drawer>
  );
};

export default memo(GridDrawer);

import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faCaretDown, faCaretUp, faCartArrowUp, faCircleCaretUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
interface IProps {
  open?: boolean;
  header: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
  children?: any;
}

const Collapsible: React.FC<IProps> = ({ open, collapsibleClassName = 'collapsible-card-edonec', headerClassName = 'collapsible-header-edonec', titleClassName = 'title-text-edonec', iconButtonClassName = 'collapsible-icon-button-edonec', contentClassName = 'collapsible-content-edonec', contentContainerClassName = 'collapsible-content-padding-edonec', children, header }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);
  return (
    <>
      <div className={collapsibleClassName}>
        <div style={{ fontSize: 13, paddingTop: 8, fontWeight: 'bold', height: 40, backgroundColor: '#485Fe0', paddingLeft: 10, color: '#ffffff', borderRadius: 5, borderTopRightRadius: 5 }}>
          <div className={headerClassName}>
            <button type="button" className={iconButtonClassName} onClick={handleFilterOpening}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div className="text-left" style={{ width: '80%', color:'#ffffff' , fontWeight:'bold', fontSize:11}}>{header}</div>
                <div className="text-right" style={{ width: '20%', marginRight:7, marginTop:3 }}>
                  <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} size={'lg'} color='#ffffff'></FontAwesomeIcon>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className={contentClassName} style={{ height }}>
          <div ref={ref}>
            <div className={contentContainerClassName}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;

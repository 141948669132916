import { Button, Result, Space } from 'antd';
import React from 'react';
import { Spin } from '../../../../../../../../common/components/spinner';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StepCongrats = ({ isLoading }: any) => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const history = useHistory();
  const {t} = useTranslation();
  const openDasboard = () => {
    history.push(redirectUrl);
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-bg-light pl-5 pt-3 pb-5">
          {isLoading && (
            <div>
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Space
                    size="middle"
                    style={{
                      height: '35vh',
                    }}
                  >
                    <Spin size="large" spinning={isLoading}></Spin>
                  </Space>
                </div>
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              <Result
                status="success"
                title={t('successfully-created-the-service')}
                subTitle={t('your-new-service-has-been-successfully-created')}
                extra={[
                  <Button className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48" type="primary" key="console" onClick={openDasboard}>
                    {t('open-dashboard')}
                  </Button>,
                ]}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepCongrats;

import { DebtorResponse } from '../../interfaces/responses/clients/debtor';
import axios from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class WeFactService {
  listWeFactDebtors = async (): Promise<DebtorResponse> => {
    return await axios
      .get(`${API_ENDPOINTS.V1.WEFACT.LIST_DEBTORS}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  getWeFactDebtorByDebtorCode = async (data: any): Promise<DebtorResponse> => {
    return await axios
      .get(`${API_ENDPOINTS.V1.WEFACT.GET_DEBTOR}/${data.debtorCode}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  listWeFactProducts = async () => {
    return await axios
      .get(`${API_ENDPOINTS.V1.WEFACT.LIST_PRODUCTS}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new WeFactService();

const alphabetical = (arr, getter, order = 'asc') => arr.sort(order === 'desc' ? (a, b) => getter(b).localeCompare(getter(a)) : (a, b) => getter(a).localeCompare(getter(b)));

export const getTagsContent = (autoFill, languagesDeployment, extraSheets, index, aitranslation, imdbDB, _id?) => {
  var tagsOut = [] as any;

  if (!autoFill) { 
    var numberOfTags = 1;
    var tagsSplitted = [] as any;
    languagesDeployment = alphabetical(languagesDeployment, (g) => g.name, 'asc');
    languagesDeployment.forEach((lang: any, indexLang: any) => {
      if (indexLang == 0) {
        if (_id === undefined && _id != '') {
          var sheet = extraSheets.find((s: any) => s.sheetName == 'tags_English');
          if (sheet?.sheetData[index] != undefined) {
            var tagsSheet = Object.values(sheet.sheetData[index])[0] as any;
            tagsSplitted = tagsSheet.split(', ');
            if (tagsSplitted != undefined) {
              numberOfTags = tagsSplitted.length;
            }
          }
        } else {
          var sheet = extraSheets.find((s: any) => s.sheetName == 'tags_' + lang.name);
          if (sheet?.sheetData[index] != undefined) {
            var testId = _id as any;
            if (testId == undefined) {
              numberOfTags = 0;
              testId = Object.values(sheet.sheetData[index])[0];
            }

            //
            for (let index_ = index; index_ < sheet.sheetData.length; index_++) {
              if (testId == Object.values(sheet.sheetData[index_])[0]) {
                numberOfTags++;
              } else {
                break;
              }
            }
          }
        }
      }
    });
    var hasTagSheet = false;
    if (_id == undefined) {
      for (let index_ = 0; index_ < numberOfTags; index_++) {
        var tagsIn = [] as any;
        var tags = [] as any;
        // eslint-disable-next-line no-loop-func
        languagesDeployment.forEach((lang: any) => {
          if (lang.name == 'English') {
            hasTagSheet = true;
            tags.push({
              name: lang.name,
              description: tagsSplitted[index_],
            });
          } else {
            tags.push({
              name: lang.name,
              description: aitranslation ? 'Translate:English' : tagsSplitted[index_],
            });
          }
        });
        if (hasTagSheet) {
          tagsIn.push({
            name: '',
            tag: tags,
          });
          tagsOut.push(...tagsIn);
        } else {
          return [];
        }
      }
    } else {
      for (let index_ = 0; index_ < numberOfTags; index_++) {
        var tagsIn = [] as any;
        var tags = [] as any;
        // eslint-disable-next-line no-loop-func
        languagesDeployment.forEach((lang: any) => {
      
          var sheet = extraSheets.find((s: any) => s.sheetName == 'tags_' + lang.name);
       
          if (sheet?.sheetData[index + index_] != undefined) {
            hasTagSheet = true;
            tags.push({
              name: lang.name,
              description: Object.values(sheet.sheetData[index + index_])[1],
            });
          } else {
            var sheet = extraSheets.find((s: any) => s.sheetName == 'tags_English');
            if (sheet?.sheetData[index + index_] != undefined) {
              tags.push({
                name: lang.name,
                description: aitranslation ? 'Translate:English' : Object.values(sheet.sheetData[index + index_])[1],
              });
            }
          }
        });
        if (hasTagSheet) {
          tagsIn.push({
            name: '',
            tag: tags,
          });
          tagsOut.push(...tagsIn);
        } else {
          return [];
        }
      }
    }
  } else {
    var numberOfTags = 0;
    if (imdbDB.tags != undefined) {
      imdbDB.tags.forEach((element) => {
        var tagsIn = [] as any;
        var tags = [] as any;
        var hasEnglish = languagesDeployment.find((l) => l.name == 'English');
        languagesDeployment = alphabetical(languagesDeployment, (g) => g.name, 'asc');
        languagesDeployment.forEach((lang: any) => {
          if (lang.name == 'English') {
            tags.push({
              name: lang.name,
              description: element.name != null ? element.name : '',
            });
          } else {
            tags.push({
              name: lang.name,
              description: aitranslation && hasEnglish != undefined && element.name != '' ? 'Translate:English' : element.name,
            });
          }
        });
        tagsIn.push({
          name: '',
          tag: tags,
        });
        tagsOut.push(...tagsIn);
      });
    } else if (imdbDB.genre != undefined) {
      imdbDB.genre.forEach((element) => {
        var tagsIn = [] as any;
        var tags = [] as any;
        var hasEnglish = languagesDeployment.find((l) => l.name == 'English');
        languagesDeployment = alphabetical(languagesDeployment, (g) => g.name, 'asc');
        languagesDeployment.forEach((lang: any) => {
          if (lang.name == 'English') {
            tags.push({
              name: lang.name,
              description: element != null ? element : '',
            });
          } else {
            tags.push({
              name: lang.name,
              description: aitranslation && hasEnglish != undefined && element != '' ? 'Translate:English' : element,
            });
          }
        });
        tagsIn.push({
          name: '',
          tag: tags,
        });
        tagsOut.push(...tagsIn);
      });
    }
  }
  var seenNames = [] as any;
  tagsOut = tagsOut.filter((currentObject) => {
    if (currentObject in seenNames) {
      return false;
    } else {
      seenNames.push(currentObject);
      return true;
    }
  });
  return {
    tags: tagsOut,
    numberOfTags: index + numberOfTags,
  } as any;
};

export const getCurrenciesContent = (extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values) => {
  let currenciesOut = [] as any;
  if (extraSheets.length > 0) {
    // eslint-disable-next-line no-loop-func
    currenciesDeployment.forEach((currency: any, index_: any) => {
      var sheet = extraSheets.find((s: any) => s.sheetName == 'Currencies');
      if (sheet != undefined && sheet.sheetData[index + index_] != undefined && getObjectKeyValue(row, values._Id) == Object.values(sheet.sheetData[index + index_])[0]) {
        currenciesOut.push({
          iso3: Object.values(sheet.sheetData[index + index_])[1],
          credits_rented: Object.values(sheet.sheetData[index + index_])[2],
          credits_purchased: Object.values(sheet.sheetData[index + index_])[3],
          key: index_,
        });
      } else {
        currenciesOut.push({
          iso3: currency.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index_,
        });
      }
    });
  }
  return currenciesOut;
};

export const getDescriptionsContent = (extraSheets, autoFill, fillGapsWithIMDB, imdbDB, languagesDeployment, index, aitranslation) => {
  let languagesOut = [] as any;
  let fillLanguage = '';
  var fillDescription = '' as any;
  if ((extraSheets.length > 0 && !autoFill && !fillGapsWithIMDB) || imdbDB == undefined) {
    languagesDeployment = alphabetical(languagesDeployment, (g) => g.name, 'asc');
    var sheetEnglish = extraSheets.find((s: any) => s.sheetName == 'English');

    if (sheetEnglish === undefined) {
      // eslint-disable-next-line no-loop-func
      languagesDeployment.forEach((lang: any) => {
        var sheet = extraSheets.find((s: any) => s.sheetName == lang.name);
        if (sheet != undefined && sheet.sheetData[index] != undefined) {
          languagesOut.push({
            name: lang.name,
            description: Object.values(sheet.sheetData[index])[3] ?? Object.values(sheet.sheetData[index])[2] ?? Object.values(sheet.sheetData[index])[0],
          });
          fillLanguage = lang.name;
          fillDescription = Object.values(sheet.sheetData[index])[3] ?? Object.values(sheet.sheetData[index])[2] ?? Object.values(sheet.sheetData[index])[0];
        } else {
          languagesOut.push({
            name: lang.name,
            description: aitranslation ? 'Translate:' + fillLanguage : fillDescription,
          });
        }
      });
    } else {
      // eslint-disable-next-line no-loop-func
      languagesDeployment.forEach((lang: any) => {
        if (lang.name == 'English') {
          languagesOut.push({
            name: lang.name,
            description: Object.values(sheetEnglish.sheetData[index])[3] ?? Object.values(sheetEnglish.sheetData[index])[2] ?? Object.values(sheetEnglish.sheetData[index])[0],
          });
        } else {
          languagesOut.push({
            name: lang.name,
            description: aitranslation ? 'Translate:English' : Object.values(sheetEnglish.sheetData[index])[3] ?? Object.values(sheetEnglish.sheetData[index])[0],
          });
        }
      });
    }
  } else {
    if (fillGapsWithIMDB) {
      var hasEnglish = languagesDeployment.find((l) => l.name == 'English');
      languagesDeployment = alphabetical(languagesDeployment, (g) => g.name, 'asc');
      // eslint-disable-next-line no-loop-func
      languagesDeployment.forEach((lang: any, index: any) => {
        if (lang.name == 'English') {
          languagesOut.push({
            name: lang.name,
            description: imdbDB.description != null ? imdbDB.description : '',
          });
        } else {
          languagesOut.push({
            name: lang.name,
            description: aitranslation && hasEnglish != undefined && imdbDB.description != '' ? 'Translate:English' : imdbDB.description,
          });
        }
      });
    }
  }
  return languagesOut;
};

export const getActorsContent = (autoFill, columns, row, values, getObjectKeyValue) => {
  var actorTeacherGuestArray = [] as any;
  if (!autoFill && columns[values.Actors] != 'IMDB_Auto_Fill') {
    if (getObjectKeyValue(row, values.Actors) != undefined) {
      var commaSeperatedString = getObjectKeyValue(row, values.Actors) as any;
      var seperatedString = commaSeperatedString.split(',');
      // eslint-disable-next-line no-loop-func
      seperatedString.forEach((element: any) => {
        element = element.trim();
        actorTeacherGuestArray.push({ name: element });
      });
    }
  }
  return actorTeacherGuestArray;
};
export const getTeachersContent = (autoFill, columns, row, values, getObjectKeyValue) => {
  var actorTeacherGuestArray = [] as any;
  if (!autoFill && columns[values.Actors] != 'IMDB_Auto_Fill') {
    if (getObjectKeyValue(row, values.Actors) != undefined) {
      var commaSeperatedString = getObjectKeyValue(row, values.Teachers) as any;
      var seperatedString = commaSeperatedString.split(',');
      // eslint-disable-next-line no-loop-func
      seperatedString.forEach((element: any) => {
        element = element.trim();
        actorTeacherGuestArray.push({ name: element });
      });
    }
  }
  return actorTeacherGuestArray;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerLogSource = void 0;
var CustomerLogSource;
(function (CustomerLogSource) {
    CustomerLogSource["FRONTEND"] = "frontend";
    CustomerLogSource["APP"] = "app";
    CustomerLogSource["CART"] = "shopping cart";
    CustomerLogSource["PORTAL"] = "customer portal";
})(CustomerLogSource = exports.CustomerLogSource || (exports.CustomerLogSource = {}));

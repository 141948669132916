import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, Menu } from 'antd';
import React, { useContext, useRef } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useClickAway } from 'react-use';
import MWare_Logo from '../../../assets/images/logo-blue.png';
import { DrawerContext } from '../../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../../context_api/DrawerContext/action-types';
import { IRoute } from '../../../routes/AllAreaRoutes';

const { Sider } = Layout;
const { SubMenu } = Menu;

const LeftSidebarPanel = () => {
  const ref = useRef(null);
  const routeMatch = useRouteMatch();
  const { url } = routeMatch;
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);

  useClickAway(ref, () => {
    document.body.classList.remove('mobile-sider');
  });

  const SidebarMenu = (routes: IRoute[]) =>
    routes.map((menu: IRoute, i) => {
      if (menu.routes) {
        return (
          <SubMenu
            key={menu.key}
            title={
              <span key={menu.key}>
                <FontAwesomeIcon size="lg" icon={menu.icon} />
                <span className="ml-2">{menu.title}</span>
              </span>
            }
          >
            {menu.routes.map((sub: IRoute, index) => {
              if (sub.routes) {
                return <span key={`${i}-${index}`}> {SidebarMenu(sub.routes)}</span>;
              } else {
                return (
                  <Menu.Item key={`${sub.key}-${i}-${index}`} hidden={sub.is_invisible}>
                    <Link to={`${url}/${sub.path}`}>{sub.title}</Link>
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item className="height-60 d-flex align-items-center" key={menu.key}>
            <FontAwesomeIcon size="lg" icon={menu.icon} />
            <Link to={`${url}/${menu.path}`}>
              <span>{menu.title}</span>
            </Link>
          </Menu.Item>
        );
      }
    });

  const hideDrawer = () => {
    document.body.classList.remove('mobile-sider');
    DrawerDispatch({
      type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
      default_selected_key: '',
      default_open_key: '',
      routes: [],
      service_name: '',
      service_type: '',
      client_dashboard_path: '',
    });
  };

  return (
    <Sider ref={ref} width="250" trigger={null} collapsible collapsed={DrawerState.is_collapsed}>
      <div style={{ padding: 20, marginTop: 20 }}>
        <Link to={`${url}`} onClick={hideDrawer} key="Mware">
          <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title="MwareTV" alt="MwareTV" style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
        </Link>
        {/* <Link to={`${url}`} onClick={hideDrawer} key="MwareCollapsed">
          <img
            className="collapsed"
            src={DrawerState.white_labeling.fav_icon || MWare_Logo_Collapsed}
            title="Mware"
            alt="Mware"
            style={DrawerState.white_labeling.fav_icon ? { maxWidth: 50, maxHeight: 50 } : {}}
          />
        </Link> */}
      </div>
      <Menu
        className="mt-1"
        mode="inline"
        style={{ background: 'transparent' }}
        defaultSelectedKeys={[DrawerState.defaultSelectedKey!]}
        // defaultOpenKeys={[DrawerState.defaultOpenKey!]}
        defaultOpenKeys={DrawerState.defaultOpenKey === 'all' && DrawerState.routes && DrawerState.routes?.length > 0 ? DrawerState.routes.filter((x) => x.key).map((x) => x.key!) : [DrawerState.defaultOpenKey!]}
        // onOpenChange={onOpenChange}
      >
        {SidebarMenu(DrawerState.routes ?? [])}
      </Menu>
    </Sider>
  );
};

const LeftSidebar = React.memo(LeftSidebarPanel);

export default LeftSidebar;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerLogAction = void 0;
var CustomerLogAction;
(function (CustomerLogAction) {
    CustomerLogAction["CREATE"] = "create";
    CustomerLogAction["UPDATE"] = "update";
    CustomerLogAction["DELETE"] = "delete";
    CustomerLogAction["PENDING"] = "pending to active";
})(CustomerLogAction = exports.CustomerLogAction || (exports.CustomerLogAction = {}));

import { notification } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useHistory, useParams } from 'react-router-dom';
import { CustomNotification } from '../../../common/components/Notification';
import userService from '../../../services/user';
import SystemUserForm from '../form';
import { useTranslation } from 'react-i18next';

const AddUser = () => {
  const { _id } = useParams<{ _id: string }>(); 
  const history = useHistory();
  const {t} = useTranslation();
  
  const handleSubmit = async (values: any) => {
    const response = await userService.AddUser({
      ...values,
      pincode: '0',
      enabled: true,
      type: 'TVMS',
      avatar: '',
    });

    if (response.status_code === StatusCodes.OK || response.status_code === StatusCodes.CREATED) {
      notification.success({
        message: t('user-added'),
        description: `User added successfully`,
      });
      history.push(`../users`);
    } else {
      CustomNotification({
        error: response.errors,
      });
    }
  };

  return (
    <section className="content">
      <div className=" m-auto p-0">
        <div className="row mb-4">
          <div className="col-12 ml-4 pt-5">
            <h4 className="page-title">{t('add')} {_id === undefined ? "TVMS" : t('client')} {t('user')}</h4>
          </div>
        </div>
        <div className="container-fluid wrapper p-3">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body card-ch-padding" style={{ margin: 25, marginTop: 50 }}>
                  <SystemUserForm type={'create'} onSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AddUser;

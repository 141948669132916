import { Form, Input, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import General1Png from '../../../../assets/images/appbuilder/Placement1_Rails.png';
import General2Png from '../../../../assets/images/appbuilder/Placement2_Rails.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [textColor, setTextColor] = useState('#ffffff');
  const [textBold, setTextBold] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#00000000');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const [shoTextColorPicker, setTextColorPicker] = useState(false);

  useEffect(() => {
    if (data.placement != undefined) {
      setBackgroundColor(data.placement.background_color);
      setTextBold(data.placement.title_bold);
      setTextColor(data.placement.title_color);
      setShowTitle(data.placement.show_title);
      form.setFieldsValue(data.placement);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var placement = {
      padding: values.padding,
      height: values.height,
      background_color: backgroundColor,
      title_color: textColor ?? '#ffffff',
      title_size: 'ExtraLarge', //Small, Normal, Large, Extra Large
      title_bold: textBold,
      show_title: showTitle,
      border_radius: values.border_radius ?? 0,
      margin_top: 0,
      margin_bottom: 0,
      margin_left: 0,
      margin_right: 0,
    };
    let dataOut = {
      ...data,
      placement: placement,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-placement-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row" style={{ padding: 15 }}>
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('placement')}</h5>
                      <p>{t('settings-that-define-the-object-such-as-background-color-borders-etc')}</p>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('background')}</label>
                        <div className="col">
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showBackgroundColorPicker}
                              color={backgroundColor}
                              onChange={(color) => {
                                setBackgroundColor(color);
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              setVisible={setShowBackgroundColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('rails-height')}</label>
                        <div className="col">
                          <Form.Item className="form-group pb-0 col" name="height">
                            <Input min={type == "Square Rails" || type =="Round Rails" || type == "Long Rails" ? 75 : type == "Morphing Rails" || type == "Player Rails" ? 150 : 100} defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            HEIGHT
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('padding')}</label>
                        <div className="col">
                          <Form.Item className="form-group pb-0 col" name="padding">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            PADDING
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('border')}</label>
                        <div className="col">
                          <Form.Item className="form-group pb-0 col" name="border_radius">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            RADIUS
                          </div>
                        </div>
                        <div className="col"> </div>
                        <div className="col"> </div>
                      </div>
          
            
            
                    </div>
                    <div className="col card-bg-light p-4 mb-2 ml-1" style={{ paddingLeft: 0 }}>
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={General1Png} alt={''}></img>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ padding: 15 }}>
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('text')}</h5>
                      <p>{t('text-the-text-settings')}</p>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('text-color')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setTextColorPicker(!shoTextColorPicker);
                              }}
                              style={{
                                backgroundColor: textColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={shoTextColorPicker}
                              color={textColor}
                              onChange={(color) => {
                                setTextColor(color);
                                setTextColorPicker(!shoTextColorPicker);
                              }}
                              setVisible={setTextColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                        <div className="col">
                          <Form.Item className="form-group pb-0" name="title_bold">
                            <Switch className="ch-switch" checked={textBold} defaultChecked={textBold} onChange={() => setTextBold(!textBold)} />
                          </Form.Item>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('show-text')}</label>

                        <div className="col">
                          <Form.Item className="form-group pb-0" name="title_show">
                            <Switch className="ch-switch" checked={showTitle} defaultChecked={showTitle} onChange={() => setShowTitle(!showTitle)} />
                          </Form.Item>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                    </div>
                    <div className="col card-bg-light p-4 mb-2 ml-1" style={{ paddingLeft: 0 }}>
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={General2Png} alt={''}></img>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-placement')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

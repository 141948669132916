import { AgeRatings } from '../../../../../interfaces/forms/areas/appbuilder/applications/ageratings/ageratings';
import { AgeRatings as Response } from '../../../../../interfaces/responses/areas/appbuilder/applications/ageratings/ageratings';
import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class AgeRatingService {
  Add_AgeRating = async (data: AgeRatings): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.AGERATING.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_AgeRatings = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.APPLICATIONS.AGERATING.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_AgeRating = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.AGERATING.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_AgeRating = async (data: AgeRatings): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.AGERATING.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_AgeRating = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.AGERATING.DELETE}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Publish_AgeRating = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.AGERATING.PUBLISH}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new AgeRatingService();

import React, { useContext, useState } from 'react';
import { Col, Form, Tooltip } from 'antd';
import { Product } from '../../../../../interfaces/forms/areas/billing/products/product';
import * as ServiceService from '../../../../../services/areas/billing/serviceService';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { FormSection, Input, Item, InputNumber, FormHeading, Table, Switch, FormHeadingWithButton, Modal, Select, Button, ModalFormWrapper } from '../../../../../common/components/form';
import { GeneralFields as BaseGeneralFields, ExternalFields } from '../../shared/baseProductFields';
import { CardForm } from '../../../../../common/components/form';
import { Service } from '../../../../../interfaces/forms/areas/billing/services/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BillingContext } from '../../../../../contexts/billingContext';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { MW_BASE_DB_CURRENCY } from '@mwaretv/database/build/backend/models/base/currency';
import commonCMSService from '../../../../../services/common';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Plan } from '../../../../../interfaces/forms/areas/billing/products/plan';
import { ProductType } from '@mwaretv/database/build/backend/enums/billing/productType';
import { ProductVariant } from '@mwaretv/database/build/backend/enums/billing/productVariant';
import { ReactComponent as AppleTablet } from '../../../../../assets/images/img_ipad.svg';
import { ReactComponent as AndroidTablet } from '../../../../../assets/images/android_tablet.svg';
import { ReactComponent as ApplePhone } from '../../../../../assets/images/img_apple_phone.svg';
import { ReactComponent as KaiOS } from '../../../../../assets/images/img_android_phone_kaios.svg';
import { ReactComponent as TizenSmartTv } from '../../../../../assets/images/tizen.svg';
import { ReactComponent as Vidaa } from '../../../../../assets/images/vidaa.svg';
import { ReactComponent as SetTopBox } from '../../../../../assets/images/img_set_top_box.svg';
import { ReactComponent as WindowsPhone } from '../../../../../assets/images/img_windows_phone.svg';
import { ReactComponent as WindowsSurface } from '../../../../../assets/images/windows_surface.svg';
import { ReactComponent as RokuTv } from '../../../../../assets/images/roku_blue.svg';
import { ReactComponent as AndroidPhone } from '../../../../../assets/images/img_android_phone.svg';
import { ReactComponent as FireTv } from '../../../../../assets/images/amazon-fire-tv_blue.svg';
import { ReactComponent as AndroidTV } from '../../../../../assets/images/androidtv.svg';
import { ReactComponent as WebOs } from '../../../../../assets/images/windows.svg';
import { ReactComponent as AppleTV } from '../../../../../assets/images/appletv_blue.svg';
import { ReactComponent as WebTV } from '../../../../../assets/images/img_web_tv.svg';
import { Periods } from '@mwaretv/database/build/backend/enums/billing/periods';
import { Spin } from '../../../../../common/components/spinner';
import ChannelBundleService from '../../../../../services/areas/content/channels/bundles';
import RadioBundleService from '../../../../../services/areas/content/radio/bundles';
import CamBundleService from '../../../../../services/areas/content/cams/bundles';
import MovieBundleService from '../../../../../services/areas/content/movies/bundles';
import ShortsBundleService from '../../../../../services/areas/content/shorts/bundles';
import SerieBundleService from '../../../../../services/areas/content/series/bundles';
import CoursesBundleService from '../../../../../services/areas/content/courses/bundles';
import PodcastBundleService from '../../../../../services/areas/content/podcasts/bundles';
import AlbumBundleService from '../../../../../services/areas/content/albums/bundles';
import GamesBundleService from '../../../../../services/areas/content/games/bundles';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';
import { CommonContext } from '../../../../../contexts/commonContext';

export { CardForm as Form, ExternalFields };

export const FormFields = () => {
  const [showSettings, setShowSettings] = useState(false);
  return (
    <>
      <GeneralFields setShowSettings={setShowSettings} />
      {showSettings &&
      <SettingsFields />
      }
      <ExternalFields />
      <ItemFields />
      <PlanFields />
    </>
  );
};

export const GeneralFields = ({ setShowSettings }: { setShowSettings?: (showSettings: boolean) => void }) => {
  const {t} = useTranslation();
  return (
    <BaseGeneralFields>
      <Item label={t('type')} name="type" rules={[{ required: true, message: t('product-type-is-required')}]} extra={t('choose-a-type-for-your-product-base-products-are-your-main-products-extra-are-additional-products-that-are-sold-seperately-to-extend-your-base-products')}>
        <Select className="default-select" options={Object.values(ProductType).map((t) => ({ label: t, value: ProductType[t] }))} onChange={(value) => {
          if (setShowSettings) {
            setShowSettings(value === 'BASE');
          }
        }} />
      </Item>
      <Item label={t('variant')} name="variant" rules={[{ required: true, message: t('variant-type-is-required')}]} extra={t('choose-a-variant-for-your-product-type-this-variant-is-used-within-the-apps-to-determine-if-a-user-can-register-via-the-app')}>
        <Select className="default-select" options={Object.values(ProductVariant).map((t) => ({ label: t, value: ProductVariant[t] }))} />
      </Item>
    </BaseGeneralFields>
  );
};

export const SettingsFields = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('settings')}>
      <Item label={t('trial-days')} name={['settings', 'trial_days']} rules={[{ required: true, message: t('trial-is-required')}]} extra={t('the-trial-period-for-the-product-allows-users-to-use-it-for-a-specific-number-of-days-before-it-officially-starts')}>
        <InputNumber placeholder={t('number-of-trial-days')} />
      </Item>
      <Item label={t('start-from-login')} name={['settings', 'start_from_login']} valuePropName="checked" extra={t('the-subscription-date-for-this-product-begins-when-the-customer-logs-in-for-the-first-time')}>
        <Switch />
      </Item>
      <Item label={t('auto-recurring')} name={['settings', 'recurring']}  valuePropName="checked"  extra={t('enable-your-customers-to-pay-via-automatic-recurring-payments-currently-this-method-is-exclusively-supported-through-the-stripe-payment-gateway')}>
      <Switch />
      </Item>
      <Item label={t('subscription-grace-period')} name={['settings', 'grace_period']} rules={[{ required: true, message: t('grace-is-required') }]} extra={t('the-grace-period-refers-to-the-number-of-days-after-the-due-date-during-which-payment-can-be-made-without-the-risk-of-the-account-expiring')}>
        <InputNumber placeholder={t('grace-period')} />
      </Item>

      <Item
        label={t('maximal-allowed-devices')}
        name={['settings', 'max_allowed_devices']}
        rules={[
          { required: true, message: t('maximal-allowed-devices-is-required') },

        ]}
        extra={t('maximal-number-of-devices-that-can-use-this-product-simultaneously')}
      >
        <InputNumber type={'number'} min={1} max={5} />
      </Item>
      <Item label={t('enable-wallet')} name={['settings', 'wallet_enabled']} valuePropName="checked" extra={t('give-your-customer-the-option-to-rent-or-buy-content-with-credit-via-the-wallet-system')}>
        <Switch />
      </Item>
      <Item label={t('onboarding-credits')} name={['settings', 'onboarding_wallet_credits']} extra={t('give-your-customer-at-registering-onboarding-a-start-wallet-balance-in-credits')}>
        <InputNumber placeholder="0" />
      </Item>
      <LockFields />
      <IpRanges />
      <DevicesFields />
    </FormSection>
  );
};

export const IpRanges = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const formInstance = Form.useFormInstance();
  const ipAddresses = Form.useWatch<string[]>(['settings', 'ip_access']) || [];
  const {t} = useTranslation();
  return (
    <>
      <FormHeadingWithButton
        title={t('ip-access')}
        subtitle={t('block-access-to-this-product-for-choosen-ip-adresses-and-ranges')}
        buttonText={t('add-ip-range')}
        onClick={() => {
          setModalOpen(true);
        }}
      />
      <Item hidden name={['settings', 'ip_access']} />
      <Table
        dataSource={ipAddresses.map((ip, index) => ({ index, ip })) || []}
        columns={[
          { title: t('ip-range'), dataIndex: 'ip', width: '75%' },
          {
            title: t('actions'),
            render: ({ index }) => {
              return (
                <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                  <Tooltip placement="top" title={t('delete')}>
                    <Button
                      type="text"
                      className="mx-2"
                      onClick={() => {
                        const ips: string[] = formInstance.getFieldValue(['settings', 'ip_access']) || [];
                        ips.splice(index, 1);
                        formInstance.setFieldValue(['settings', 'ip_access'], [...ips]);
                      }}
                    >
                      <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                      <div>{t('delete')}</div>
                    </Button>
                  </Tooltip>
                </Col>
              );
            },
          },
        ]}
      />
      <IPRangeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onOk={(ip) => {
          const ips = formInstance.getFieldValue(['settings', 'ip_access']) || [];
          formInstance.setFieldValue(['settings', 'ip_access'], [...ips, ip]);
          setModalOpen(false);
        }}
      />
    </>
  );
};

const IPRangeModal = ({ open, onClose, onOk }: { open: boolean; onClose: () => void; onOk: (ip: string) => void }) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  return (
    <Modal
      open={open}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <ModalFormWrapper>
        <CardForm
          {...{ form }}
          layout="vertical"
          onFinish={() => {
            onOk(form.getFieldValue('ip'));
            form.resetFields();
          }}
        >
          <FormHeading>{t('ip-address')}</FormHeading>
          <Item label={t('ip-address-mask')} name="ip" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('ip-address-help')}>
            <Input />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

export const LockFields = () => {
  const { countries } = useContext(CommonContext);
  const {t} = useTranslation();
  return (
    <FormSection title={t('geolocks')} subtitle={t('selected-countries-will-be-able-to-access-this-package')} backgroundWhite>
      <Item label={t('allowed-countries')} name={['settings', 'geolocks', 'countries']} extra={t('choose-which-countries-are-allowed-to-use-this-product')}>
        <Select className="default-select" mode="multiple" allowClear options={countries ? countries.map((country) => ({ value: country.name, label: country.name })) : []} filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())} />
      </Item>
    </FormSection>
  );
};

const LabelWithIcon = ({ Icon, text }: { Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; text: string }) => {
  return (
    <div className="d-flex align-items-center">
      <Icon width={40} height={40} />
      <span className="ml-1">{text}</span>
    </div>
  );
};

export const DevicesFields = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('devices')} subtitle={t('select-the-devices-this-product-is-available-for')}>
      <FormSection title={t('phones')} backgroundWhite>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={ApplePhone} text={t('apple')} />} valuePropName="checked" name={['settings', 'devices', 'phones', 'ios']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={AndroidPhone} text={t('android')} />} valuePropName="checked" name={['settings', 'devices', 'phones', 'android']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={WindowsPhone} text={t('windows')} />} valuePropName="checked" name={['settings', 'devices', 'phones', 'windows']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={KaiOS} text="KaiOS" />} valuePropName="checked" name={['settings', 'devices', 'phones', 'kaios']}>
          <Switch />
        </Item>
      </FormSection>

      <FormSection title={t('tablets')} backgroundWhite>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={AppleTablet} text={t('apple')} />} valuePropName="checked" name={['settings', 'devices', 'tablets', 'ios']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={AndroidTablet} text={t('android')} />} valuePropName="checked" name={['settings', 'devices', 'tablets', 'android']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={WindowsSurface} text={t('windows')} />} valuePropName="checked" name={['settings', 'devices', 'tablets', 'windows']}>
          <Switch />
        </Item>
      </FormSection>

      <FormSection title={t('mediaplayers')} backgroundWhite>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={RokuTv} text={t('roku')} />} valuePropName="checked" name={['settings', 'devices', 'mediaplayers', 'roku']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={AndroidTV} text={t('android')} />} valuePropName="checked" name={['settings', 'devices', 'mediaplayers', 'androidtv']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={FireTv} text="FireTV" />} valuePropName="checked" name={['settings', 'devices', 'mediaplayers', 'firetv']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={AppleTV} text="AppleTV" />} valuePropName="checked" name={['settings', 'devices', 'mediaplayers', 'appletv']}>
          <Switch />
        </Item>
      </FormSection>

      <FormSection title="WebTV" backgroundWhite>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={WebTV} text="PC" />} valuePropName="checked" name={['settings', 'devices', 'web', 'windows']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={WebTV} text={t('mac')} />} valuePropName="checked" name={['settings', 'devices', 'web', 'osx']}>
          <Switch />
        </Item>
      </FormSection>

      <FormSection title="STB" backgroundWhite>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={SetTopBox} text={t('linux')} />} valuePropName="checked" name={['settings', 'devices', 'stb', 'linux']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={SetTopBox} text={t('android')} />} valuePropName="checked" name={['settings', 'devices', 'stb', 'android']}>
          <Switch />
        </Item>
      </FormSection>

      <FormSection title={t('smart-tvs')} backgroundWhite>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={TizenSmartTv} text={t('tizen')} />} valuePropName="checked" name={['settings', 'devices', 'smart_tvs', 'tizen']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={WebOs} text="WebOs" />} valuePropName="checked" name={['settings', 'devices', 'smart_tvs', 'webos']}>
          <Switch />
        </Item>
        <Item className="mware-item-with-icon-label" label={<LabelWithIcon Icon={Vidaa} text={t('vidaa')} />} valuePropName="checked" name={['settings', 'devices', 'smart_tvs', 'vidaa']}>
          <Switch />
        </Item>
      </FormSection>
    </FormSection>
  );
};

export const NotificationFields = ({ notifications }: { notifications?: Product['notifications'] }) => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('messages')}>
      <Item label={t('registration')} valuePropName="checked" name={['notifications', 'registration']} initialValue={notifications?.registration}>
        <Switch />
      </Item>
      <Item label={t('invoice')} valuePropName="checked" name={['notifications', 'invoice']} initialValue={notifications?.invoice}>
        <Switch />
      </Item>
      <Item label={t('payment-confirmation')} valuePropName="checked" name={['notifications', 'payment_confirmation']} initialValue={notifications?.payment_confirmation}>
        <Switch />
      </Item>
      <Item label={t('new-subscription')} valuePropName="checked" name={['notifications', 'subscription_new']} initialValue={notifications?.subscription_new}>
        <Switch />
      </Item>
      <Item label={t('subscription-canceled')} valuePropName="checked" name={['notifications', 'subscription_canceled']} initialValue={notifications?.subscription_canceled}>
        <Switch />
      </Item>
      <Item label={t('subscription-changed')} valuePropName="checked" name={['notifications', 'subscription_change']} initialValue={notifications?.subscription_change}>
        <Switch />
      </Item>
      <Item label={t('subscription-expired')} valuePropName="checked" name={['notifications', 'subscription_expired']} initialValue={notifications?.subscription_expired}>
        <Switch />
      </Item>
      <Item label={t('pay-per-view')} valuePropName="checked" name={['notifications', 'pay_per_view']} initialValue={notifications?.pay_per_view}>
        <Switch />
      </Item>
    </FormSection>
  );
};

export const ItemFields = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('services-and-bundles')} subtitle="">
      <ItemServicesTable />
      {/* <ItemPackagesTable /> */}
      <Bundles />
    </FormSection>
  );
};


export const Bundles = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('bundles')} subtitle={t('add-or-remove-content-bundles-from-this-package-bundles-can-be-managed-in-content-and-will-make-up-the-content-customers-will-receive')}>
      <SelectChannelBundles />
      <SelectRadioBundles />
      <SelectCamBundles />
      <SelectMovieBundles />
      <SelectShortsBundles />
      <SelectSeriesBundles />
      <SelectCoursesBundles />
      <SelectPodcastBundles />
      <SelectAlbumBundles />
      <SelectGamesBundles />
    </FormSection>
  );
};
const SelectGamesBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();

  const { isLoading, isError, data } = useQuery(['content', 'games', 'bundles', clientName, deployment, 0, 9999], async () => {
    return GamesBundleService.List_Games_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });
  const {t} = useTranslation();

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('games')} name={['items', 'bundles', 'games']} extra={t('add-or-remove-games-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};
const SelectAlbumBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'albums', 'bundles', clientName, deployment, 0, 9999], async () => {
    return AlbumBundleService.List_Album_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('albums')} name={['items', 'bundles', 'albums']} extra={t('add-or-remove-album-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectPodcastBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'podcasts', 'bundles', clientName, deployment, 0, 9999], async () => {
    return PodcastBundleService.List_Podcast_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('podcasts')} name={['items', 'bundles', 'podcasts']} extra={t('add-or-remove-podcast-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectCoursesBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'courses', 'bundles', clientName, deployment, 0, 9999], async () => {
    return CoursesBundleService.List_Course_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('courses')} name={['items', 'bundles', 'courses']} extra={t('add-or-remove-courses-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectSeriesBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'series', 'bundles', clientName, deployment, 0, 9999], async () => {
    return SerieBundleService.List_Series_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('series')} name={['items', 'bundles', 'series']} extra={t('add-or-remove-serie-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectMovieBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'movies', 'bundles', clientName, deployment, 0, 9999], async () => {
    return MovieBundleService.List_Movie_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('movies')} name={['items', 'bundles', 'movies']} extra={t('add-or-remove-movie-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectShortsBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'shorts', 'bundles', clientName, deployment, 0, 9999], async () => {
    return ShortsBundleService.List_Shorts_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('shorts')} name={['items', 'bundles', 'shorts']} extra={t('add-or-remove-shorts-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectCamBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'cam', 'bundles', clientName, deployment, 0, 9999], async () => {
    return CamBundleService.List_Cam_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('cams')} name={['items', 'bundles', 'cams']} extra={t('add-or-remove-cam-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectRadioBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'radio', 'bundles', clientName, deployment, 0, 9999], async () => {
    return RadioBundleService.List_Radio_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('radio')} name={['items', 'bundles', 'radio']} extra={t('add-or-remove-radio-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};

const SelectChannelBundles = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['content', 'channels', 'bundles', clientName, deployment, 0, 9999], async () => {
    return ChannelBundleService.List_Channel_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment || '',
      },
      pageNumber: 0,
      pageSize: 9999,
    })
      .then((r) => Promise.resolve(r as any))
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
      {!isLoading && !isError && (
        <Item label={t('channels')} name={['items', 'bundles', 'channels']} extra={t('add-or-remove-channel-bundles-from-this-package')}>
          <Select
            className="default-select"
            mode="multiple"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data.data.map((x) => ({ label: x.name, value: x._id }))}
          />
        </Item>
      )}
    </>
  );
};



export const ItemServicesTable = () => {
  const formInstance = Form.useFormInstance();
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [services, setServices] = useState<string[]>(formInstance.getFieldValue(['items', 'services']) || []);
  const {t} = useTranslation();
  formInstance.setFieldValue(['items', 'services'], services || []);

  return (
    <>
      <FormHeadingWithButton
        title={t('service')}
        subtitle={t('manage-services-for-this-product')}
        buttonText={t('add-service')}
        onClick={() => {
          setFormOpen(true);
        }}
      />
      <Item hidden name={['items', 'services']} />
      <Table
        dataSource={services.map((service, index) => ({
          key: index,
          service,
        }))}
        columns={[
          {
            title: t('name'),
            width: '100%',
            render: ({ service }) => <ServiceNameColumn id={service} />,
          },
          {
            title: t('actions'),

            render: ({ service }) => (
              <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                <Tooltip placement="top" title={t('delete')}>
                  <Button
                    type="text"
                    className="mx-2"
                    onClick={() => {
                      setServices(services.filter((x) => x !== service.id));
                    }}
                  >
                    <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                    <div>{t('delete')}</div>
                  </Button>
                </Tooltip>
              </Col>
            ),
          },
        ]}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
      <ServiceModal
        open={formOpen}
        onCancel={() => setFormOpen(false)}
        filters={services}
        onOk={(service) => {
          services.push(service._id);
          setServices(services);
          setFormOpen(false);
        }}
      />
    </>
  );
};

const ServiceNameColumn = ({ id }: { id: string }) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const { isLoading, isError, data } = ServiceService.useService({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' }, id });

  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>-</span>}
      {!isLoading && !isError && <span>{data.data.name}</span>}
    </>
  );
};

const ServiceModal = ({ open, onOk, onCancel, filters }: { open: boolean; onCancel: () => void; onOk: (service: Service) => void; filters: string[] }) => {
  const [form] = Form.useForm();
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();
  const { data, isLoading, isError } = useQuery(['all_services'], async () => {
    return (await ServiceService.list({ params: { limit: 9999, page: 1, instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' } })).data;
  });

  const selectedService = Form.useWatch('service', form);

  return (
    <Modal
      {...{ open, onCancel }}
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{
        hidden: true,
      }}
    >
      <ModalFormWrapper>
        <CardForm
          {...{ form }}
          buttonBgWhite
          layout="vertical"
          onFinish={() => {
            const s = data?.docs.find((x) => x._id === selectedService);
            form.resetFields();
            if (s) onOk(s);
          }}
        >
          <FormHeading>{t('service')}</FormHeading>
          <Form.Item name="service" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} extra={t('select-a-service-to-add-to-your-product')}>
            <Select className="default-select" options={!isLoading && !isError && data ? data.docs.filter((x) => !filters.includes(x._id)).map((s) => ({ value: s._id, label: s.name })) : []} />
          </Form.Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};




export const PlanFields = () => {
  const [planModalOpen, setPlanModalOpen] = React.useState<boolean>(false);
  const formInstance = Form.useFormInstance();
  const plans = Form.useWatch(['plans', 'plans']) || [];
  const {t} = useTranslation();
  return (
    <div className={'card-bg-light mt-5'}>
      <div className={'pl-5 pr-5 pb-3'}>
        <FormHeadingWithButton title={t('payment-plans')} subtitle={t('setup-your-payment-plans-for-this-subscription')} buttonText={t('add-plan')} onClick={() => setPlanModalOpen(true)} />
        <Item
          name={['plans', 'plans']}
          rules={[
            {
              validator: (_, value) => {
                return value.length >= 1 ? Promise.resolve() : Promise.reject();
              },
              message: t('at-least-one-payment-plan-required'),
            },
          ]}
          initialValue={plans.map((p: any, i: number) => ({ ...p, index: i }))}
        >
          <Table
            dataSource={plans.map((p: any, i: number) => ({ ...p, key: i, index: i }))}
            columns={[
              { title: t('name'), dataIndex: 'name', key: 'name', width: '30%' },
              { title: t('plan-id'), dataIndex: '_id', key: '_id', width: '30%' },
              { title: t('duration'), key: 'duration', render: (row) => <span>{` ${row.duration} ${row.duration_period_in} `}</span>, width: '30%' },
              {
                title: t('actions'),

                key: 'actions',
                render: (data) => (
                  <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                    <Tooltip placement="top" title={t('delete')}>
                      <Button
                        type="text"
                        className="mx-2"
                        onClick={() => {
                          plans.splice(data.index, 1);
                          formInstance.setFieldValue(['plans', 'plans'], [...plans]);
                        }}
                      >
                        <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                        <div>{t('delete')}</div>
                      </Button>
                    </Tooltip>
                  </Col>
                ),
              },
            ]}
            expandable={{
              expandedRowRender: (record) => <PlanPricing key={record.index} plan={record} index={record.index} />,
              expandIcon: ({ expanded, onExpand, record }) => (
                <>
                  {!expanded && <CaretRightOutlined onClick={(e) => onExpand(record, e)} />}
                  {expanded && <CaretDownOutlined onClick={(e) => onExpand(record, e)} />}
                </>
              ),
            }}
          />
        </Item>
        <PlanModal
          open={planModalOpen}
          onCancel={() => setPlanModalOpen(false)}
          onOk={(values) => {
            formInstance.setFieldValue(['plans', 'plans'], [...plans, ...[{ ...values, prices: [] }]]);

            setPlanModalOpen(false);
          }}
        />
      </div>
    </div>
  );
};

type PlanPricingProps = {
  plan: Plan & { index: number; key: number };
  index: number;
};

const PlanPricing = ({ plan, index }: PlanPricingProps) => {
  const { service } = useContext(BillingContext);
  const currencies = (service?.currencies as unknown as MW_BASE_DB_CURRENCY[]) || [];
  const {t} = useTranslation();
  return (
    <div>
      <FormHeading>{t('price-per-currency')}</FormHeading>
      <Table
        dataSource={currencies.map((c, i) => ({ code: c.isoCurrency, price: plan.prices.find((x) => x.currency === c.isoCurrency)?.price, credits: plan.prices.find((x) => x.currency === c.isoCurrency)?.credits }))}
        columns={[
          { title: t('currency'), dataIndex: 'code' },
          { title: t('product-price'), dataIndex: 'price' },
          { title: t('wallet-credits'), dataIndex: 'credits' },
          { title: t('actions'), width: '15%', render: (data) => <PlanActions {...{ index, data }} /> },
        ]}
      />
    </div>
  );
};

type PlanActionsProps = {
  index: number;
  data: any;
};

const PlanActions = ({ index, data }: PlanActionsProps) => {
  const formInstance = Form.useFormInstance();
  const [priceModelOpen, setPriceModelOpen] = React.useState<boolean>(false);
  const {t} = useTranslation();
  return (
    <div style={{ height: 60 }}>
      <Tooltip placement="top" title={t('details')}>
        <Button type="text" className="mx-2" onClick={() => setPriceModelOpen(true)}>
          <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
          <div>{t('edit-price')}</div>
        </Button>
      </Tooltip>
      <PlanPriceModal
        price={data.price}
        credits={data.credits}
        open={priceModelOpen}
        onCancel={() => setPriceModelOpen(false)}
        onOk={(price, credits) => {
          const plans: Plan[] = formInstance.getFieldValue(['plans', 'plans']) || [];
          const existingIndex = plans[index].prices.findIndex((x) => x.currency === data.code);
          if (existingIndex === -1) {
            plans[index].prices.push({
              currency: data.code,
              price,
              credits,
            });
          } else {
            plans[index].prices[existingIndex].price = price;
            plans[index].prices[existingIndex].credits = credits;
          }

          formInstance.setFieldValue(['plans', 'plans'], [...plans]);
          setPriceModelOpen(false);
        }}
      />
    </div>
  );
};

type PlanPriceModalProps = {
  open: boolean;
  price: string;
  credits: number;
  onCancel: () => void;
  onOk: (price: string, credits: number) => void;
};

const PlanPriceModal = ({ open, onCancel, onOk, price, credits }: PlanPriceModalProps) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  return (
    <Modal
      {...{ open }}
      onCancel={() => {
        onCancel();
      }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <ModalFormWrapper>
        <CardForm
          buttonBgWhite
          {...{ form }}
          initialValues={{ price, credits }}
          onFinish={() => {
            onOk(form.getFieldValue('price'), form.getFieldValue('credits'));
          }}
        >
          <FormHeading>{t('price-and-credits')}</FormHeading>
          <Item label={t('price')} name={['price']} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <InputNumber  />
          </Item>
          <Item extra={t('give-your-customers-an-amount-of-wallet-credits-each-time-at-renewal-of-the-subscription')} label={t('credits')} name={['credits']} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <InputNumber />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

type PlanModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk: (values: { name: string; duration_in_months: number }) => void;
};

const PlanModal = ({ open, onCancel, onOk }: PlanModalProps) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  return (
    <Modal
      {...{ open }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <ModalFormWrapper>
        <CardForm
          {...{ form }}
          layout="vertical"
          onFinish={(values) => {
            onOk(values);
            form.resetFields();
          }}
        >
          <FormHeading>{t('new-plan')}</FormHeading>
          <Item name={['name']} label={t('name')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-name-of-this-plan')}>
            <Input />
          </Item>
          <Item name={['duration']} label={t('payment-interval')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('how-long-does-the-subscription-last-duration-period-in-determines-whether-this-is-in-days-weeks-months-or-years')}>
            <InputNumber />
          </Item>
          <Item name={['duration_period_in']} label={t('duration-period-in')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('this-determines-wether-the-set-duration-is-in-days-weeks-months-or-years')}>
            <Select className="default-select" options={Object.values(Periods).map((x) => ({ label: x.charAt(0).toUpperCase() + x.slice(1), value: x }))} />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

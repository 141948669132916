import { Button, DatePicker, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import cmsMessages from '../../../../../../services/areas/appbuilder/applications/messages/index';
import { IMSNameParams } from '../../../../../dashboard';
import MessageDisclaimer from '../../../../../../common/components/AppBuilder/MessageDisclaimer/Translations';
import { AppBuilderContext } from '../../../../../../contexts/appbuilderContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';

export const AddMessage = () => {
  const history = useHistory();
  const [translations, setTranslations] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(AppBuilderContext);
  const [isAdding, setIsAdding] = useState(false);
  const [image, setImage] = useState([] as any);
  const [date, setSelectedDate] = useState([] as any);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);

    let trans = [] as any;
    translations.forEach((translation) => {
      trans.push({
        name: translation.name,
        description: translation.description,
      });
    });

    const response = await cmsMessages.Add_Message({
      name: values.name,
      date: date,
      image: image.url === undefined ? image.url.replace('https://cloudtv.akamaized.net', '') : '',
      translations: trans,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
    });

    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}`}>{t('back-to-message-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-message')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-in-app-messages-and-share-them-with-your-customers')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('message')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-3"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('my-message-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('publish-date')}</label>
                <Form.Item
                  className="col-md-3"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: t('date-is-required'),
                    },
                  ]}
                >
                  <DatePicker className="form-control bg-white font-size-14 text-default" format={'DD-MMM-YYYY'} onChange={(date) => setSelectedDate(date)} />
                </Form.Item>
              </div>
            </div>
            <ImageUploader
              onSubmit={(values: any) => {
                setImage(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('image'),
                uploadType: 'tile',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
              }}
            />
            <MessageDisclaimer fullEditor={false} service_name={service?.name} clientName={deployment?.name} setTranslations={setTranslations}></MessageDisclaimer>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-message')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

import React from 'react'

type Props = {
  title : string,
  subtitle : string
}

export const PageTitle : React.FC<Props> = ({ title, subtitle } : Props) => {
  return (
    <div>
      <h2 className="page-title">{ title }</h2>
      <p className="h6 font-weight-normal line-height-26">{ subtitle }</p>
    </div>
  )
}

import { Route, Switch, useRouteMatch } from 'react-router';
import { TransactionList } from './TransactionList';
import { TransactionDetails } from './TransactionDetails';

export const Transactions = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <TransactionDetails />
      </Route>
      <Route path={`${path}`}>
        <TransactionList />
      </Route>
    </Switch>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerTitle = void 0;
var CustomerTitle;
(function (CustomerTitle) {
    CustomerTitle["MR"] = "Mr";
    CustomerTitle["MS"] = "Miss";
    CustomerTitle["MRS"] = "Mrs";
    CustomerTitle["DR"] = "Dr";
    CustomerTitle["Fam"] = "Fam";
    CustomerTitle["Empty"] = "";
})(CustomerTitle = exports.CustomerTitle || (exports.CustomerTitle = {}));

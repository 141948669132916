import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { Service } from '../../../interfaces/forms/areas/billing/services/service';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { SERVICES: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string; cms: string; crm: string };

type PaginationParams = {
  page: number;
  limit: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

export const list = ({ params }: { params: Params & PaginationParams }): Promise<AxiosResponse<PaginatedResults<Service[]>>> => {
  return axiosInstance.get<PaginatedResults<Service[]>>(ENDPOINTS.LIST, { params });
};

export type AddParams = { params: Params } & { data: Service };

export const add = ({ data, params }: AddParams): Promise<AxiosResponse<Service>> => {
  return axiosInstance.post<Service>(ENDPOINTS.ADD, data, { params });
};

export type PatchParams = { params: Params } & { data: Partial<Service>; id: string };

export const patch = ({ data, params, id }: PatchParams): Promise<AxiosResponse<Service>> => {
  return axiosInstance.patch<Service>(`${ENDPOINTS.PATCH}/${id}`, data, { params });
};

export type PutParams = { params: Params } & { data: Service; id: string };

export const put = ({ data, params, id }: PutParams): Promise<AxiosResponse<Service>> => {
  return axiosInstance.put<Service>(`${ENDPOINTS.PUT}/${id}`, data, { params });
};

type DeleteParams = { params: Params; id: string };

export const del = ({ params, id }: DeleteParams): Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
};

type GetParams = { params: Params; id: string };

export const get = ({ params, id }: GetParams): Promise<AxiosResponse<Service>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
};

export const useService = ({ params, id }: GetParams) =>
  useQuery(['services', 'get', params, id], async () => {
    return get({ params, id })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const publishService = async (params: any): Promise<any> => {
  return await axiosInstance
    .put(`${ENDPOINTS.PUBLISH}/${params._id}`, {
      params: {
        instance: params.instance,
        cms: params.cms,
        crm: params.crm,
        path: params.crm,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

import { Player_Grids } from '../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/grids/player/player';
import { Player_Grids as Response } from '../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/grids/player/player';

import { IBaseResponse } from '../../../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../../../_APIConstants';

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class PlayerService {
  Add_Player = async (data: Player_Grids): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.GRIDS.PLAYER.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Player_Template = async (data: Player_Grids): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.GRIDS.PLAYER.ADDTEMPLATE, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Players = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.GRIDS.PLAYER.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Player = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.GRIDS.PLAYER.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Player = async (data: Player_Grids): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.GRIDS.PLAYER.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Player = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.GRIDS.PLAYER.DELETE}/${data._id}`, {
        params: data.query_params,
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new PlayerService();

import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../..';
import { DeploymentDashboard } from './components';
import { useQuery } from '@tanstack/react-query';
import * as deploymentService from '../../../../services/deployments/deployment';
import { NewDeployment } from './components/dashboard/deployments/new_deployment';
import { Scope } from './components/dashboard/deployments/scope';
import { Spin } from '../../../../common/components/spinner';
import { VisibleForRole } from '../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../constants/TVMSRoles';
import { useTranslation } from 'react-i18next';

export const IMSHome = () => {
  const { clientName } = useParams<IMSNameParams>();
  const {t} = useTranslation();
  const { data, isLoading, isError } = useQuery(['deployments', clientName], async () => {
    return (
      await deploymentService.list({
        params: {
          client: clientName,
          page: 1,
          limit: 9999,
        },
      })
    ).data;
  });

  return (
    <div className="p-4 my-2">
      <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
        <Scope />
      </VisibleForRole>
      <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
        <NewDeployment />
      </VisibleForRole>
      {isLoading && <Spin />}
      {isError && <div>{t('error-while-loading-your-deployments')}</div>}
      {!isLoading && !isError && data.docs.filter((deployment) => !deployment.is_deleted).map((deployment, index) => <DeploymentDashboard {...{ deployment }} key={index} />)}
    </div>
  );
};

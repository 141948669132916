"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerSubscriptionStatus = void 0;
var CustomerSubscriptionStatus;
(function (CustomerSubscriptionStatus) {
    CustomerSubscriptionStatus["PENDING"] = "pending";
    CustomerSubscriptionStatus["ACTIVE"] = "active";
    CustomerSubscriptionStatus["EXPIRED"] = "expired";
    CustomerSubscriptionStatus["CANCELLED"] = "cancelled";
})(CustomerSubscriptionStatus = exports.CustomerSubscriptionStatus || (exports.CustomerSubscriptionStatus = {}));

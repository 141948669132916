import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const TvGuide = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  const [color, setColor] = useState('#999999');

  return (
    <div className="container-fluid text-left" style={{ padding: 0 }}>
      <div
        className="col"
        style={{
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          border: 'none',
          borderRadius: 5,
          padding:5
        }}
      >
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Button active={false} style={{ width: 150, height: 30, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 30, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 30, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 30, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 30, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 75, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 535, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 75, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />

            <Skeleton.Button active={false} style={{ width: 535, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 75, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 715, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 75, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 535, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton.Image active={false} style={{ width: 150, height: 75, backgroundColor: color, marginBottom: 5, marginRight: 5 }} />

            <Skeleton.Button active={false} style={{ width: 535, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
            <Skeleton.Button active={false} style={{ width: 175, height: 75, backgroundColor: '#bfbfbf', marginBottom: 5, marginRight: 5 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TvGuide;

import { Button, Checkbox, Form, Input } from 'antd';

import { Link } from 'react-router-dom';
import { ISignInFormValues } from '../../../interfaces/forms/auth/AuthInterfaces';
import { useTranslation } from 'react-i18next';

type ILoginFormProps = {
  onSubmit: (values: ISignInFormValues) => void;
  isLogingIn: boolean;
};
const LoginForm = (props: ILoginFormProps) => {
  const [loginForm] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (values: ISignInFormValues) => {
    props.onSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {};
  return (
    <Form
      onFinish={onFinish}
      initialValues={{
        email: '',
        password: '',
        remember_me: true,
      }}
      onFinishFailed={onFinishFailed}
      form={loginForm}
    >
      <div className="row">
        <Form.Item
          className="form-group col-md-12"
          name="email"
          rules={[
            { required: true, message: t('email-is-required') },
            { type: 'email', message: t('the-input-is-not-valid-e-mail') },
          ]}
        >
          <Input className="form-control" placeholder={t('email-or-username')} />
        </Form.Item>
        <Form.Item
          className="form-group col-md-12"
          name="password"
          validateFirst={true}
          rules={[
            { required: true, message: t('password-is-required') },
            {
              pattern: new RegExp('(?=.*[A-Z])(?=.*[0-9])'),
              message: t('password-should-be-at-least-one-capital-and-one-digit'),
            },
            {
              min: 5,
              message: t('password-should-be-at-least-5-characters-long'),
            },
          ]}
        >
          <Input className="form-control" type="password" placeholder={t('password')} />
        </Form.Item>
        <div className="form-group col-md-12">
          <Form.Item noStyle name="remember_me" valuePropName="checked">
            <Checkbox>{t('remember-me')}</Checkbox>
          </Form.Item>
          <Link className="text-secondary float-right" to={`/forgot-password`}>
            {t('forgot-password')}
          </Link>
        </div>
        <Form.Item className="col-md-12">
          <Button size="large" htmlType="submit" color="primary" loading={props.isLogingIn} disabled={props.isLogingIn} className="btn btn-secondary rounded-pill height-48">
            <span>{t('chart-design-action-type-login')}</span>
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default LoginForm;

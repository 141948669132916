import { TV_Guide_TV_Guide } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/tvguide/tvguide/tvguide";
import { TV_Guide_TV_Guide as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/tvguide/tvguide/tvguide";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class TVGuideService {
    Add_TV_Guide = async (data: TV_Guide_TV_Guide): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.TVGUIDE.TVGUIDE.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_TV_Guide_Template = async (data: TV_Guide_TV_Guide): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.TVGUIDE.TVGUIDE.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_TV_Guides = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.TVGUIDE.TVGUIDE.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_TV_Guide = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.TVGUIDE.TVGUIDE.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_TV_Guide = async (data: TV_Guide_TV_Guide): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.TVGUIDE.TVGUIDE.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_TV_Guide = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.TVGUIDE.TVGUIDE.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new TVGuideService();

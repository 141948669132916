import { Layout } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PageNotFound from '../../common/components/PageNotFound';
import DetailsUser from './oneform/details_user';
import { ListUsers } from './oneform/list_users';
import AddUser from './oneform/add_user';

export const Users = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <div className="row">
        <div className="col-md-12">
          <Layout>
            <Switch>
              <Route path={`${path}/details/:userId`} component={DetailsUser}></Route>
              <Route path={`${path}/add`} component={AddUser}></Route>
              <Route path={`${path}/`} component={ListUsers}></Route>
              <Route component={() => <PageNotFound path="" />}></Route>
            </Switch>
          </Layout>
        </div>
      </div>
    </Layout>
  );
};

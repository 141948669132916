import { Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Placement from '../../Content/Placement/Placement';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index: any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const [placement, setPlacement] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([] as any);
  const [formDetails, setFormDetails] = useState(data);
  const [occurrence, setOccurrence] = useState('');
  const [single, setSingle] = useState(true);

  const onSubmitLocal = (values: any) => {
    var types = [] as any;
    values.types.forEach((type) => {
      types.push({ name: type });
    });
    var ad = {
      ut_start: placement.ut_start,
      ut_end: placement.ut_end,
      publisher_id: values.publisher_id,
      types: types,
      occurrence: occurrence,
      single: single,
    };

    let dataOut = {
      ...data,
      settings: {
        ...data.settings,
        castoola: {
          ...ad,
        },
        strategy: { 
          mobiles: {
            min_bandwidth:  values.strategy.mobiles.min_bandwidth,
            max_bandwidth: values.strategy.mobiles.max_bandwidth,
            abr_bandwidth: values.strategy.mobiles.abr_bandwidth,
          },
          tablets: {
            min_bandwidth: values.strategy.tablets.min_bandwidth,
            max_bandwidth: values.strategy.tablets.max_bandwidth,
            abr_bandwidth: values.strategy.tablets.abr_bandwidth,
          },
          televisions: {
            min_bandwidth: values.strategy.televisions.min_bandwidth,
            max_bandwidth: values.strategy.televisions.max_bandwidth,
            abr_bandwidth: values.strategy.televisions.abr_bandwidth,
          },
          browsers: {
            min_bandwidth: values.strategy.browsers.min_bandwidth,
            max_bandwidth: values.strategy.browsers.max_bandwidth,
            abr_bandwidth: values.strategy.browsers.abr_bandwidth,
          }
        }
      },
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    if (data != undefined) {
      form.setFieldValue('url', data.url);
    }
    setLoading(false);
  }, [data, form]);

  const setOccurrenceLocal = (value) => {
    setOccurrence(value);
  };

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('player-settings')}</h4>
            <p>{t('manage-your-player-settings')}</p>
            <div className="row mt-5">
              <div className="col-12">
                {!loading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="col">
                      <div className="row" style={{ padding: 15 }}>
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('castoola-vast')}</h5>
                          <p>{t('to-enable-castoola-vast-ads-fill-out-the-form-below')}</p>
                          <div className="col">
                            <div className="row" style={{}}>
                              <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                                <div className="row mb-3">
                                  <label className="col-md-4 col-form-label">{t('publisher-id')}</label>
                                  <div className="col-md-8" style={{ paddingLeft: 0 }}>
                                    <Form.Item
                                      name="publisher_id"
                                      className="form-group pb-0 col"
                                      rules={[
                                        {
                                          required: true,
                                          message: t('enter-button-name'),
                                        },
                                      ]}
                                    >
                                      <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="" />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <label className="col-md-4 col-form-label">{t('content-type')}</label>
                                  <div className="col" style={{ marginRight: 15 }}>
                                    <Form.Item extra={t('please-choose-the-types-of-content-for-which-you-would-like-to-utilize-vast-ads')} name={'types'} className="form-group pb-0">
                                      <Select defaultValue={types} mode="multiple" className="default-select" placeholder={t('select-types')}>
                                        <Select.Option value={'Channels'} key={'Channels'}>
                                          {t('channels')}
                                        </Select.Option>
                                        <Select.Option value={'Cams'} key={'Cams'}>
                                          {t('cams')}
                                        </Select.Option>
                                        <Select.Option value={'CatchupTV'} key={'CatchupTV'}>
                                          CatchupTV
                                        </Select.Option>
                                        <Select.Option value={'Recordingds'} key={'Recordings'}>
                                          {t('recordings')}
                                        </Select.Option>
                                        <Select.Option value={'Movies'} key={'Movies'}>
                                          {t('movies')}
                                        </Select.Option>
                                        <Select.Option value={'Series'} key={'Series'}>
                                          {t('series')}
                                        </Select.Option>
                                        <Select.Option value={'Shorts'} key={'Shorts'}>
                                          {t('shorts')}
                                        </Select.Option>
                                        <Select.Option value={'Courses'} key={'Courses'}>
                                          {t('courses')}
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <label className="col-md-4 col-form-label">{t('content-type')}</label>
                                  <div className="col" style={{ marginRight: 15 }}>
                                    <Form.Item extra={t('please-choose-the-types-of-content-for-which-you-would-like-to-utilize-vast-ads')} name={'occurrence'} className="form-group pb-0">
                                      <Select onChange={(value) => setOccurrenceLocal(value)} className="default-select" placeholder={t('select-types')}>
                                        <Select.Option value={'eachtime'} key={'eachtime'}>
                                          {t('each-time-every-zap-stream-play')}
                                        </Select.Option>
                                        <Select.Option value={'everytime'} key={'everytime'}>
                                          {t('every-other-time-every-other-zap-stream-play')}
                                        </Select.Option>
                                        <Select.Option value={'onetime'} key={'ontime'}>
                                          {t('one-time-only-first-zap-stream-play')}
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <label className="col-md-4 col-form-label">{t('single-or-multiple')}</label>
                                  <div className="col" style={{ marginRight: 15 }}>
                                    <Form.Item extra={t('choose-between-one-single-ad-impression-per-session-or-multiple-ad-impression-per-session')} className="form-group pb-0 " name="single">
                                      <Switch className="ch-switch" checked={single} defaultChecked={false} onChange={() => setSingle(!single)} />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="row" style={{ padding: 15 }}>
                            <Placement data={placement} setPlacement={setPlacement}></Placement>
                          </div> */}
                        </div>
                      </div>

                      <div className="row" style={{ padding: 15 }}>
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('player-bitrate-strategies')}</h5>
                          <p>{t('use-these-settings-to-tailor-the-adaptive-bitrate-strategies-to-adopt-for-various-network-environments')}</p>

                          <h6 className="text-primary mt-5 mb-3">{t('mobiles')}</h6>
                          <div className="card-bg-light pl-5 pt-3">
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'mobiles', 'min_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'mobiles', 'max_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'mobiles', 'abr_bandwidth']}>
                                <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                                  <Select.Option value={'Bandwidth'} key={1}>
                                    {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                                  </Select.Option>
                                  <Select.Option value={'Quality'} key={14}>
                                    {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                                  </Select.Option>
                                  <Select.Option value={'Performance'} key={2}>
                                    {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <h6 className="text-primary mt-5 mb-3">{t('tablet')}</h6>
                          <div className="card-bg-light pl-5 pt-3">
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'tablets', 'min_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'tablets', 'max_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'tablets', 'abr_bandwidth']}>
                                <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                                  <Select.Option value={'Bandwidth'} key={1}>
                                    {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                                  </Select.Option>
                                  <Select.Option value={'Quality'} key={14}>
                                    {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                                  </Select.Option>
                                  <Select.Option value={'Perfomance'} key={2}>
                                    {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <h6 className="text-primary mt-5 mb-3">{t('televisions')}</h6>
                          <div className="card-bg-light pl-5 pt-3">
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'televisions', 'min_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'televisions', 'max_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'televisions', 'abr_bandwidth']}>
                                <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                                  <Select.Option value={'Bandwidth'} key={1}>
                                    {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                                  </Select.Option>
                                  <Select.Option value={'Quality'} key={14}>
                                    {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                                  </Select.Option>
                                  <Select.Option value={'Performance'} key={2}>
                                    {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <h6 className="text-primary mt-5 mb-3">{t('browsers')}</h6>
                          <div className="card-bg-light pl-5 pt-3">
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('minimum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'browsers', 'min_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('maximum-bitrate')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'browsers', 'max_bandwidth']}>
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-2 col-form-label">{t('adaptive-strategy')}</label>
                              <Form.Item className="col-md-5" name={['strategy', 'browsers', 'abr_bandwidth']}>
                                <Select style={{ width: '100%' }} labelInValue defaultValue={t('select-a-strategy')}>
                                  <Select.Option value={'Bandwidth'} key={1}>
                                    {t('bandwidth-the-player-will-optimize-the-abr-behavior-to-focus-on-displaying-the-most-optimal-quality-based-on-historic-data-of-available-bandwidth-and-knowledge-of-the-network-conditions-when-no-historic-data-is-available-the-player-will-start-playback-at-a-medium-bitrate-quality-up-to-2-5-mbps')}
                                  </Select.Option>
                                  <Select.Option value={'Quality'} key={14}>
                                    {t('quality-the-player-will-optimize-abr-behavior-to-focus-displaying-the-best-visual-quality-to-the-end-user-this-strategy-initiates-playback-with-the-highest-bit-rate-suitable-for-the-device')}
                                  </Select.Option>
                                  <Select.Option value={'Perfomance'} key={2}>
                                    {t('performance-the-player-will-optimize-abr-behavior-to-focus-on-the-performance-of-the-player-this-strategy-initiates-playback-with-the-lowest-quality-suitable-for-the-device-which-means-faster-start-up-time-this-is-usually-the-lowest-quality-available')}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('update')}
                      </button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

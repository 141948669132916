import React from 'react';
import { useTranslation } from 'react-i18next';

const Intro: React.FC = () => {
  const {t} = useTranslation();
  return (
    <>
      <h3 className="text-primary mb-3 mt-5">{t('adding-a-deployment-and-service')}</h3>
      <div>
        <div className="p-5 card-bg-light">
          {t('with-a-deployment-you-can-manage-your')}
          <br />
          <div style={{ color: '#999' }}>
            {t('content-manage-your-channels-radio-movies-series-shorts-courses-podcasts-and-cams')} <br />
            {t('apps-manage-your-apps-for-all-possible-platforms-like-ios-android-androidtv-firetv-webos-tizen-roku-pc-mac-pwa-and-kaios')} <br />
            <br />
          </div>
          {t('with-a-service-you-can-manage-your')}
          <br />
          <div style={{ color: '#999' }}>
            {t('customers-manage-your-customers-and-resellers')} <br />
            {t('billing-manage-your-webshops-via-our-buildin-shopping-cart-connect-to-100-webshops-or-via-our-apis')} <br />
            {t('marketing-manage-your-renewals-churns-but-also-sent-out-push-messages-emails-or-sms-on-new-content')} <br />
            {t('reports-manage-your-whole-tv-platform-from-our-unique-an-easy-to-use-report-query-system')} <br />
          </div>
          <br />
          {t('before-creating-the-deployment-we-recommend-thinking-about-these-settings-as-they-will-be-used-for-setting-up-your-content-apps-billing-and-products')}
          <br />
          <br />
          <div style={{ color: '#999' }}>
            {t('countries-which-countries-do-you-sell-your-services-from-this-deployement')} <br />
            {t('currencies-which-currencies-can-your-customers-pay-with-for-this-deployement')} <br />
            {t('languages-which-languages-do-you-want-to-support-in-your-content-billing-and-apps')} <br />
          </div>
          <br />
          {t('note-do-this-with-care-cause-you-can-not-change-these-settings-after-the-deployment-and-service-is-created')}
        </div>
      </div>
    </>
  );
};

export default Intro;

import { BrowserRouter, Switch } from 'react-router-dom';
import './App.scss';
import AuthContextProvider from './context_api/AuthContext';
import { AppRoutes } from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthRoute from './common/routes/AuthRoute';
import login from './pages/auth/login';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';


const queryClient = new QueryClient();

function App() {

  
  return (
    <I18nextProvider {...{ i18n }} >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthContextProvider>
            <Switch>
              <AuthRoute path="/login" component={login}></AuthRoute>
              <AppRoutes />
            </Switch>
          </AuthContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </I18nextProvider>
  );
}

export default App;

import { Route, Switch, useRouteMatch } from 'react-router';
import List from './list';
import Application from './details';
import Add from './add';

const Index = () => {
  const { path } = useRouteMatch();
  
  return (
    <>
      <Switch>
      <Route path={`${path}/add`}>
          <Add />
        </Route>
        <Route path={`${path}/details`}>
          <Application />
        </Route>
        <Route path={path}>
          <List />
        </Route>
      </Switch>
    </>
  );
};

export default Index;

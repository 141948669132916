import { Genre_Rails } from '../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/genre/genre';
import { Genre_Rails as Response } from '../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/rails/genre/genre';

import { IBaseResponse } from '../../../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../../../_APIConstants';

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class GenreService {
  Add_Genre = async (data: Genre_Rails): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.GENRE.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Genre_Template = async (data: Genre_Rails): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.GENRE.ADDTEMPLATE, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Genres = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.GENRE.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Genre = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.RAILS.GENRE.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Genre = async (data: Genre_Rails): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.RAILS.GENRE.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Genre = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.RAILS.GENRE.DELETE}/${data._id}`, {
        params: data.query_params,
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new GenreService();

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomersContext } from '../../../../../contexts/customersContext';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop, faMobile, faTablet, faTelevision, faTrash } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const DevicesTable = ({ initialData }) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(CustomersContext);
  const [devices, setDevices] = useState([] as any);
  const [times, setTimestamp] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/v1/customers/devices?client=${clientName}&deployment=${deployment?.name}&service=${service?.name}&customer=${initialData?.username}`)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error(t('something-went-wrong'));
          })
          .then((responseJson) => {
            setDevices(responseJson);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    };

    fetchData();
  }, [clientName, deployment]);

  const deleteDevice = async (index) => {
    devices.splice(index, 1);
    setDevices(devices);
    setTimestamp(moment().unix().toLocaleString());
    updateJson(devices);
  };

  const toAlphaNumeric = (input) => {
    if (input == '' || input == undefined || input == null) {
      return '';
    }
    input = input.toString().replace(/\s/g, '');
    return input.toString().replace(/[^A-Za-z0-9]/g, '');
  };

  const updateJson = (json) => {
    let path = clientName + '/' + deployment?.name + '/' + service?.name + '/users/' + toAlphaNumeric(initialData?.username).split('').join('/');
    fetch('https://jsonstorage.io/upload?path=' + encodeURIComponent(path) + '&name=devices.json', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).catch((error) => {});
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #d1d1d1', borderRadius: 5, padding: 10 }}>
        {devices.length == 0 && <div style={{ color: '#999' }}>{t('the-device-list-is-empty')}</div>}

        <>
          {devices.map((device: any, index) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom: index < devices.length - 1 ? '1px solid #d1d1d1' : 'none',
                  display: 'flex',
                  width: '100%',
                  height: 75,
                  borderRadius: 5,
                  flexDirection: 'row',
                }}
              >
                <div style={{ display: 'flex', padding: 20, justifyContent: 'center', alignItems: 'center', borderRight: '1px solid #d1d1d1' }}>
                  {device.formfactor == t('phone') && <FontAwesomeIcon size={'2x'} color={'#485fe0'} icon={faMobile} />}
                  {device.formfactor == t('tablet') && <FontAwesomeIcon size={'2x'} color={'#485fe0'} icon={faTablet} />}
                  {device.formfactor == t('desktop') && <FontAwesomeIcon size={'2x'} color={'#485fe0'} icon={faLaptop} />}
                  {device.formfactor == t('television') && <FontAwesomeIcon size={'2x'} color={'#485fe0'} icon={faTelevision} />}
                </div>
                <div style={{ display: 'flex', padding: 20, width: '100%', flexDirection: 'column', justifyContent: 'center', borderRight: '1px solid #d1d1d1' }}>
                  <div>
                    <b>{device.model}</b>
                  </div>
                  <div>{device.uuid}</div>
                </div>
                <div onClick={() => deleteDevice(index)} style={{ cursor: 'pointer', display: 'flex', padding: 20, justifyContent: 'center', alignItems: 'center' }}>
                  <div>
                    <FontAwesomeIcon color={'#999'} size={'2x'} icon={faTrash} />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </>
  );
};

export default DevicesTable;

import { DatePicker, Form, Switch } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ParentalControl = (params: { setPlacement: any; data?: any }) => {
  const [startDate1, setStartDate1] = useState(dayjs());
  const [endDate1, setEndDate1] = useState(dayjs().add(99 , 'year'));
  const [surppressPlayTrial, setSurpressPlayTrial] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (params.data != undefined) {
      if (params.data.ut_start != undefined) {
        setStartDate1(dayjs.unix(params.data.ut_start));
        setEndDate1(dayjs.unix(params.data.ut_end));
      }
    } else {
      params.setPlacement({
        no_play_on_trial: false,
        ut_end: dayjs(dayjs().add(99 , 'year')).unix(),
        ut_start: dayjs(dayjs()).unix(),
      });
    }
  }, [params.data]);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('content-placement')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 p-3 ">
            <div className="">
              <div className="card-body card-col-width rounded">
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('start-date')}</label>
                  <Form.Item initialValue={startDate1} className="form-group pb-0 ">
                    <DatePicker
                      value={startDate1}
                      defaultValue={startDate1}
                      showToday
                      className="form-control height-48 bg-white font-size-14 text-default"
                      onChange={(value) => {
                        setSurpressPlayTrial(!surppressPlayTrial);
                        params.setPlacement({
                          no_play_on_trial: surppressPlayTrial,
                          ut_end: dayjs(endDate1).unix(),
                          ut_start: dayjs(value).unix(),
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('end-date')}</label>
                  <Form.Item initialValue={endDate1} className="form-group pb-0 ">
                    <DatePicker
                      value={endDate1}
                      showToday
                      defaultValue={endDate1}
                      className="form-control height-48 bg-white font-size-14 text-default"
                      onChange={(value) => {
                        
                        params.setPlacement({
                          no_play_on_trial: surppressPlayTrial,
                          ut_end: dayjs(value).unix(),
                          ut_start: dayjs(startDate1).unix(),
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('surpress-on-trial')}</label>
                  <Form.Item extra={t('do-not-play-the-stream-when-customer-subscription-sub-status-is-on-trial-instead-a-message-is-shown-with-a-qr-code-link-to-customer-portal-payment-page-to-upgrade-to-a-paid-subscription')} className="col-md-5" name={['placement', 'no_play_on_trial']} valuePropName="checked">
                    <Switch
                      className="ch-switch mt-2"
                      checked={surppressPlayTrial}
                      defaultChecked={false}
                      onChange={() => {
                        setSurpressPlayTrial(!surppressPlayTrial);
                        params.setPlacement({
                          no_play_on_trial: surppressPlayTrial,
                          ut_end: dayjs(endDate1).unix(),
                          ut_start: dayjs(startDate1).unix(),
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentalControl;

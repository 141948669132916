import { Badge, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context_api/AuthContext';
import { Client } from '../../../../interfaces/forms/clients/clients';
import ClientCard from './components';
import moment from 'moment-timezone';
import { useEffectOnce } from 'react-use';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const AllClients = () => {
  const { state: AuthState } = useContext(AuthContext);

  const [totalClients, setTotalClients] = useState(0);
  const [totalTraffic, setTotalTraffic] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([] as any);
  const [baseClients, setBaseClients] = useState([] as any);
  const { t } = useTranslation();

  useEffectOnce(() => {
    const fetchData = async () => {
      var clients = 0;

      const endpoints = AuthState.user?.clients?.map((client) => 'https://cloudtv.akamaized.net/' + client.name + '/dashboards/dashboard_tvms_overview_customers_peak_input.json');

      if (endpoints) {
        await Promise.allSettled(endpoints.map((endpoint) => axios.get(endpoint))).then((results) => {
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              const data = result.value.data;
              if (data) {
                clients += data.monthlyPeak;
              }
            } else {
              console.log(result.reason);
            }
          });
        });
      }
      setTotalClients(clients);
      setIsLoading(false);
    };
    if (totalClients == 0) {
      fetchData();
    }
  });

  useEffectOnce(() => {
    const fetchData = async () => {
      var traffic = 0;

      const endpoints = AuthState.user?.clients?.map((client) => 'https://cloudtv.akamaized.net/' + client.name + '/dashboards/dashboard_tvms_overview_traffic_input.json');

      if (endpoints) {
        await Promise.allSettled(endpoints.map((endpoint) => axios.get(endpoint))).then((results) => {
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              const data = result.value.data;
              if (data) {
                traffic += data.trafficInGb;
              }
            } else {
              console.log(result.reason);
            }
          });
        });
      }
      setTotalTraffic(traffic);
      setIsLoading(false);
    };
    if (totalClients == 0) {
      fetchData();
    }
  });

  useEffectOnce(() => {
    const fetchData = async () => {
      var storage = 0;

      const endpoints = AuthState.user?.clients?.map((client) => 'https://cloudtv.akamaized.net/' + client.name + '/dashboards/dashboard_tvms_overview_storage_input.json');

      if (endpoints) {
        await Promise.allSettled(endpoints.map((endpoint) => axios.get(endpoint))).then((results) => {
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              const data = result.value.data;
              if (data) {
                storage += data.storageInGb;
              }
            } else {
              console.log(result.reason);
            }
          });
        });
      }
      setTotalStorage(storage);
      setIsLoading(false);
    };
    if (totalClients == 0) {
      fetchData();
    }
  });

  useEffect(() => {
    setClients(AuthState.user?.clients?.sort((a, b) => a.name.localeCompare(b.name)));
    setBaseClients(AuthState.user?.clients?.sort((a, b) => a.name.localeCompare(b.name)));
  }, [AuthState]);

  const handleSearchInput = (value: string) => {
    let newClients = baseClients.filter((client) => {
      if (client.name.toLowerCase().startsWith(value.toLowerCase())) return clients;
    });
    setClients(newClients);
  };

  return (
    <section className="content">
      <div className=" m-auto p-0 pt-3">
        <div className="container-fluid">
          {!isLoading && (
            <div className="card-bg-light p-2 mb-3" style={{ backgroundColor: '#ffffff' }}>
              <div className="card-bg-light  rounded m-3" style={{ display: 'flex', padding: 30 }}>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col mb-3" style={{ display: 'flex', color: '#485fe0', fontSize: 20, textAlign: 'start', fontWeight: 'bold' }}>
                    MwareTV {moment().format('MMMM YYYY')}:
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div className="col" style={{ display: 'flex', width: '33,5%', fontSize: 16, color: '#999', textAlign: 'start', fontWeight: 'bold' }}>
                      {t('total-clients')} {Math.round(totalClients)} {t('peak-active')}
                    </div>
                    <div className="col" style={{ display: 'flex', width: '33,5%', fontSize: 16, color: '#999', textAlign: 'start', fontWeight: 'bold' }}>
                      {t('total-traffic')} {Math.round(totalTraffic)} GB - {Math.round(totalTraffic / 1000 / 1000)} PB
                    </div>
                    <div className="col" style={{ display: 'flex', width: '33,5%', fontSize: 16, color: '#999', textAlign: 'start', fontWeight: 'bold' }}>
                      {t('total-storage')} {Math.round(totalStorage)} GB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card-bg-light p-2" style={{ backgroundColor: '#ffffff' }}>
            <div className="card-bg-light rounded m-3 mt-4" style={{ display: 'flex', padding: 30, border: '#e' }}>
              <div className="col" style={{ width: '100%' }}>
                <div className="row mb-3" style={{ display: 'flex', color: '#485fe0', fontSize: 20, textAlign: 'start', fontWeight: 'bold' }}>
                  {t('filter-clients')}
                </div>
                <div className="row">
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder={t('filter-clients')}
                    onChange={(e) => {
                      handleSearchInput(e.target.value);
                    }}
                  ></Input>
                </div>
              </div>
            </div>
            <div className="row p-3">
              {clients
                .filter((c) => c.status != 'Deleted')
                .map((client: Client, index: number) => (
                  <div className="col-4 p-3 btn" key={`${index}`}>
                    {client.onboarding != 'Finished' && client.onboarding != undefined && client.status != 'Live' && <ClientCard {...client} />}
                    {(client.onboarding == 'Finished' || client.onboarding == undefined || client.status == 'Live') && <ClientCard {...client} />}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllClients;

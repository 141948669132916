import { Radio_Lists } from "../../../../../interfaces/forms/areas/content/radio/lists";
import { IGetForm } from "../../../../../interfaces/forms/common/ICMSExtraQuerytParams";
import { ICMS_Lists } from "../../../../../interfaces/forms/common/ICMS_Lists";
import { Radio_Lists as Response } from "../../../../../interfaces/responses/areas/content/radio/lists";

import { IBaseResponse } from "../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../_APIConstants";

class List_Service {
    Add_List = async (data: Radio_Lists): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.CMS.RADIO.LIST.ADD, data, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    List_Lists = async (
        data: ICMS_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.CMS.RADIO.LIST.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Details_List = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.CMS.RADIO.LIST.DETAILS}/${data._id}`, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Update_List = async (data: Radio_Lists): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.CMS.RADIO.LIST.UPDATE}/${data._id}`, data, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_List = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.CMS.RADIO.LIST.DELETE}/${data._id}`, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Publish_List = async (data: Radio_Lists): Promise<IBaseResponse> => {
        return await axiosInstance
          .put(`${API_ENDPOINTS.V1.CMS.RADIO.LIST.PUBLISH}/${data._id}`, data, {
            params: {
              instance: data.query_params.instance,
              cms: data.query_params.cms,
              path: data.query_params.path
            },
          })
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            return err;
          });
      };


     
}

export default new List_Service();

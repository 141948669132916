import type { ISMSGateway } from './definitions';

import twilio from './gateways/twilio/src';
import miaSms from './gateways/miasms/src';
import africastalking from './gateways/africa-talking/src';
import { dagusms } from './gateways/dagusms/src';
import revesms from './gateways/revesms/src';
import pindo from './gateways/pindo/src';

export const smsGateways: ISMSGateway[] = [
  twilio,
  miaSms,
  africastalking,
  dagusms,
  pindo,
  revesms,
];

import { ISMSGateway } from '../../../definitions';
import Twilio from 'twilio';

export const twilio: ISMSGateway = {
  name: 'Twilio',
  sms_gateway_id: 'twilio',
  fields: {
    accountSid: { label: 'Account SID', type: 'string' },
    authToken: { label: 'Auth Token', type: 'string' },
    phoneNumber: { label: 'Phone Number', type: 'string' },
  },
  url: 'https://www.twilio.com',
  helpText:
    'Set up messaging with Twilio, a suite of tools for working with phone calls, text messages, and performing other communication functions.',
  infotext: 'Set up messaging with Twilio.',
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/sms-gateways/twillio.png',
  send: async ({ accountSid, authToken, phoneNumber }, { phone, text }) => {
    if (!accountSid || !authToken || !phoneNumber) {
      throw new Error(
        'Account SID, Auth Token or Phone Number was not provided. ' +
          accountSid +
          ' ' +
          ' ' +
          authToken +
          ' ' +
          phoneNumber
      );
    }

    if (text.length > 1600) {
      throw new Error('Message too long.');
    }

    const twilioApi = Twilio(accountSid, authToken);

    try {
      const message = await twilioApi.messages.create({
        body: text,
        to: phone,
        from: phoneNumber,
      });

      return { isSend: true };
    } catch (error) {
      

      return { isSend: false };
    }
  },
};

export default twilio;

import React, { createContext, useReducer } from "react";
import { ReactContextChildrenProps } from "..";
import { DrawerActions } from "./action-types";
import DrawerReducer, { DrawerState, initialDrawerState } from "./reducer";

interface DrawerContextType {
  dispatch: React.Dispatch<DrawerActions>;
  state: DrawerState;
}

export const DrawerContext = createContext<DrawerContextType>({
  state: {
    is_client_info_loading: false,
    client_info: undefined,
    is_sidebar_visible: false,
    is_collapsed: false,
    parent_service_name: "",
    service_name: "",
    service_type: "",
    client_dashboard_path: "",
    drawer_type: "permanent",
    defaultSelectedKey: "",
    routes: [],
    white_labeling: {
      fav_icon: "",
      logo: "",
    },
  },
  dispatch: () => null,
});
const DrawerContextProvider = ({ children }: ReactContextChildrenProps) => {
  const [state, dispatch] = useReducer(DrawerReducer, initialDrawerState);
  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerContextProvider;

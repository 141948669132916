import { createContext } from "react";
import { DeploymentWithPopulatedServices, PopulatedService } from '../services/deployments/deployment'

type BillingContextType = {
  deployment : DeploymentWithPopulatedServices  | undefined
  service : PopulatedService | undefined
}

export const BillingContext = createContext<BillingContextType>({
  deployment: undefined,
  service: undefined
});

export const BillingContextProvider = BillingContext.Provider


import { useTranslation } from 'react-i18next';
import ObjectStoragUploader from '../../../../../../common/components/ObjectStorageUploader/ObjectStorageUploader';

const AddNewMusicApp = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <h2 className="page-title mt-4">{t('upload-app')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('upload-a-app-to-collections')}</p>
        </div>
      </div>
      <ObjectStoragUploader type={'apps'}></ObjectStoragUploader>
    </div>
  );
};

export default AddNewMusicApp;

import { Cams, CamsExport, CamsImport } from '../../../../../interfaces/forms/areas/content/cams/cams';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Cams as Response } from '../../../../../interfaces/responses/areas/content/cams/cams';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Cam_Service {
  Add_Cam = async (data: Cams): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CAMS.CAMS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Cams = async (data: CamsImport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CAMS.CAMS.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          addcats: data.query_params.addcats,
          createcats: data.query_params.createcats,
          bundles: data.query_params.bundles,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Cams = async (data: CamsExport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CAMS.CAMS.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Cams = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CAMS.CAMS.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Cam = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.CAMS.CAMS.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Cam = async (data: Cams): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.CAMS.CAMS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Cam = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.CAMS.CAMS.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Cams = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.CAMS.CAMS.DELETE_GROUP, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  AutoComplete_List_Cams = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.CAMS.CAMS.AUTOCOMPLETE_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Cam_Service();

import { useTranslation } from 'react-i18next';
import ObjectStoragUploader from '../../../../../../common/components/ObjectStorageUploader/ObjectStorageUploader';

const AddNewMusicAlbum = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <h2 className="page-title mt-4">{t('upload-album')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('upload-a-album-to-collections')}</p>
        </div>
      </div>
      <ObjectStoragUploader type={'albums'}></ObjectStoragUploader>
    </div>
  );
};

export default AddNewMusicAlbum;

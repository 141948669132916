import React from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { Alert, Form as AntdForm, Tabs, notification } from 'antd';
import { useShoppingCart, useShoppingCartPatch } from '../../../../services/areas/billing/shoppingCartService';
import { IMSNameParams } from '../../../dashboard';
import { BillingContext } from '../../../../contexts/billingContext';
import { ShoppingCart } from '../../../../interfaces/forms/areas/billing/shoppingCart';
import { GeneralFields, ProductFields, EmailFields, CustomerFields, Form, DesignFields, PaymentMethodFields, NotificationFields, ReminderFields } from './shared/form';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

const ShoppingCartDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(BillingContext);
  const { url } = useRouteMatch();
  const { isLoading, isError, data } = useShoppingCart({ id, params: { crm: service?.name || '', instance: clientName, cms: deployment?.name || '' } });
  const {t} = useTranslation();

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-carts-and-portals-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={data && data.data ? t('update-data-data-name', { dataDataName: data.data.name }) : t('update-package')} subtitle="" />
        </div>
      </div>
      <div className="col-12  mt-3">
        {isLoading && <Spin />}
        {isError && <span>{t('something-went-wrong')}</span>}
        {!isLoading && data && <Details shoppingCart={data.data} />}
      </div>
    </ContainerWrapper>
  );
};

type DetailsProps = {
  shoppingCart: ShoppingCart;
};

const Details = ({ shoppingCart }: DetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(BillingContext);
  const {t} = useTranslation();
  const [generalForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [productForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [customerForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [designForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [paymentMethodForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [notificationsForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [reminderForm] = AntdForm.useForm<Partial<ShoppingCart>>();
  const [activeTab, setActiveTab] = React.useState<string>('general');

  const { isLoading, mutate } = useShoppingCartPatch(
    { id, params: { crm: service?.name || '', instance: clientName, cms: deployment?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('webshop-updated') });
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-while-updating-the-webshop-please-try-again-later') });
      },
    }
  );

  return (
    <Tabs
      activeKey={activeTab}
      className="default-tab"
      onChange={(activeKey: string) => setActiveTab(activeKey)}
      items={[
        {
          label: t('general'),
          key: 'general',
          children: (
            <Form form={generalForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <GeneralFields clientName={clientName} deployment={deployment?.name ?? ''} service={service?.name ?? ''} _id={id} />
            </Form>
          ),
        },
        // This is no longer needed
        // {
        //   label: "Email Settings", key: "email", children: (
        //     <Form form={emailForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
        //       <EmailFields />
        //     </Form>
        //   )
        // },
        {
          label: t('products'),
          key: 'products',
          children: (
            <Form form={productForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <ProductFields />
            </Form>
          ),
        },
        {
          label: t('form'),
          key: 'customer',
          children: (
            <Form form={customerForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <CustomerFields />
            </Form>
          ),
        },
        {
          label: t('design'),
          key: 'design',
          children: (
            <Form form={designForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <DesignFields />
            </Form>
          ),
        },
        {
          label: t('messages'),
          key: 'reminders',
          children: (
            <Form form={reminderForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <ReminderFields />
            </Form>
          ),
        },
        {
          label: t('payment-gateways'),
          key: 'payment-methods',
          children: (
            <Form form={paymentMethodForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <PaymentMethodFields />
            </Form>
          ),
        },
        {
          label: t('message-gateways'),
          key: 'notifications',
          children: (
            <Form form={notificationsForm} disabled={isLoading} onFinish={(values: Partial<ShoppingCart>) => mutate(values)} initialValues={shoppingCart}>
              <NotificationFields />
            </Form>
          ),
        },
      ]}
    />
  );
};

export default ShoppingCartDetails;

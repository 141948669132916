import axiosInstance from '../../../../utils/axios';
import { useQuery } from '@tanstack/react-query';
import type { IPaymentProvider } from '@mwaretv/payment';

export const usePaymentProviderSchemas = () =>
  useQuery(
    ['paymentSchemas'],
    async () => {
      const response = await axiosInstance.get<IPaymentProvider[]>(`/v1/billing/payment-provider-schemas`);
      return response.data;
    },
    {
      retry: true,
    }
  );

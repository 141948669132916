import { ISMSGateway } from '../../../definitions';
import axios from 'axios';
import MiaSmsResponse from './miaSmsResponse.interface';

export const miaSms: ISMSGateway = {
  name: 'Mia SMS',
  sms_gateway_id: 'miasms',
  fields: {
    token: { label: 'Authorization token', type: 'string' },
    senderId: { label: 'senderId', type: 'string' },
  },
  url: 'https://mianet.co.tz/',
  helpText: 'Set up messaging with MiaSMS.',
  infotext: 'Set up messaging with MiaSMS.',
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/sms-gateways/mianetsms.png',
  send: async ({ token, senderId }, { phone, text }) => {
    if (!token || !senderId) {
      throw new Error('Token  or SenderId was not provided.');
    }

    const miaSmsUrl = 'https://sms.mia.co.tz/api/v3/sms/send';
    const res = await axios.post(
      miaSmsUrl,
      {
        recipient: phone,
        sender_id: senderId,
        type: 'plain',
        message: text,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const validateSms = res.data as MiaSmsResponse;
    if (validateSms.status === 'success') {
      return { isSend: true };
    } else {
      return { isSend: false };
    }
  },
};

export default miaSms;

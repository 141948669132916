import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Switch, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import RatingModel from './AgeRatingModal';
import { useTranslation } from 'react-i18next';

const Translations = (params: { service_name: any; clientName: any; setRatingsParent: any; data?: any }) => {
  const [showRatingModal, setShowRatingModal] = useState<boolean>(false);
  const [ratingToUpdate, setRatingToUpdate] = useState([] as any);
  const [ratings, setRatings] = useState([] as any);
  const {t} = useTranslation();
  const ratingsColumns = [
    {
      title: t('rating-identifier'),
      dataIndex: 'name',
    },
    {
      title: t('parental-locked'),
      dataIndex: 'locked',
      render: (row: any) => (
        <span className="justify-content-start">
          <Switch className="ch-switch" defaultChecked={row} checked={row} />
        </span>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      width: 100,
      render: (row: any) => (
        <span className="justify-content-end">
          <Tooltip placement="top" title={`Edit`}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={(event) => {
                setRatingToUpdate(row);
                setShowRatingModal(!showRatingModal);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (params.data != undefined) {
      setRatings(params.data);
    }
  }, [params.data]);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('ratings')}</h4>
      <p>{t('make-sure-your-rating-list-in-age-sequential-order-meaning-from-low-age-to-higher-age')}</p>
      <div className="row">
        <div className="col-md-12">
          <div className="">
            <Button
              className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48 float-right"
              onClick={() => {
                setShowRatingModal(true);
              }}
            >
              {t('add-rating')}
            </Button>
          </div>
          {showRatingModal && (
            <RatingModel
              initialData={ratingToUpdate}
              onCancel={() => {
                setShowRatingModal(false);
              }}
              onSubmit={(values: any) => {
                var newArr = ratings;
                newArr.push(values);
                setRatings(newArr);
                params.setRatingsParent(newArr);
                setShowRatingModal(false);
              }}
              visible={showRatingModal}
            />
          )}
          <Table
            rowKey={(languagesLocal) => languagesLocal.name}
            columns={ratingsColumns}
            dataSource={ratings}
            key={JSON.stringify(ratings)}
            pagination={{
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) => {
                return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Translations;

import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { Wallet } from '../../../interfaces/forms/areas/billing/wallets/wallet';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { WALLETS: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string; cms: string; crm: string; path?: string };

type PaginationParams = {
  page: number;
  limit: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

export const list = ({ params }: { params: Params & PaginationParams & { filter?: { [key: string]: string } } }): Promise<AxiosResponse<PaginatedResults<Wallet[]>>> => {
  return axiosInstance.get<PaginatedResults<Wallet[]>>(ENDPOINTS.LIST, { params });
};

export type AddParams = { params: Params } & { data: Wallet };

export const add = ({ data, params }: AddParams): Promise<AxiosResponse<Wallet>> => {
  return axiosInstance.post<Wallet>(ENDPOINTS.ADD, data, { params });
};

export type PatchParams = { params: Params } & { data: Partial<Wallet>; id: string };

export const patch = ({ data, params, id }: PatchParams): Promise<AxiosResponse<Wallet>> => {
  return axiosInstance.patch<Wallet>(`${ENDPOINTS.PATCH}/${id}`, data, { params });
};

export type PutParams = { params: Params } & { data: Wallet; id: string };

export const put = ({ data, params, id }: PutParams): Promise<AxiosResponse<Wallet>> => {
  return axiosInstance.put<Wallet>(`${ENDPOINTS.PUT}/${id}`, data, { params });
};

type DeleteParams = { params: Params; id: string };

export const del = ({ params, id }: DeleteParams): Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
};

type GetParams = { params: Params; id: string };

export const get = ({ params, id }: GetParams): Promise<AxiosResponse<Wallet>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
};

export const useWallet = ({ params, id }: GetParams) =>
  useQuery(['wallets', 'get', params, id], async () => {
    return get({ params, id })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const useWallets = ({ params }: { params: Params & PaginationParams & { filter?: { [key: string]: string } } }) =>
  useQuery(['wallets', 'list', params], async () => {
    return list({ params })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const useWalletPatch = ({ params, id }: Omit<PatchParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<Wallet>, unknown, Partial<Wallet>, unknown>, 'mutationFn' | 'mutationKey'>) =>
  useMutation(
    ['wallets', 'patch', params.instance, params.crm, id],
    async (values: Partial<Wallet>) => {
      return patch({ data: values, params, id })
        .then((r) => Promise.resolve(r))
        .catch((e) => Promise.reject(e));
    },
    options
  );

import { Rectangle_Grids } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/grids/rectangle/rectangle";
import { Rectangle_Grids as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/grids/rectangle/rectangle";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class RectangleService {
    Add_Rectangle = async (data: Rectangle_Grids): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.GRIDS.RECTANGLE.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Rectangle_Template = async (data: Rectangle_Grids): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.GRIDS.RECTANGLE.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Rectangles = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.GRIDS.RECTANGLE.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Rectangle = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.GRIDS.RECTANGLE.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Rectangle = async (data: Rectangle_Grids): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.GRIDS.RECTANGLE.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Rectangle = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.GRIDS.RECTANGLE.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new RectangleService();

import { Form, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FontSelector = () => {
  const [font, setFont] = useState('');
  const { t } = useTranslation();

  const handleFontChange = (value: string) => {
    setFont(value);
  };

  return (
    <div className="row">
      <label className="col-md-3 col-form-label">{t('font-selection')}</label>
      <Form.Item
        rules={[
          {
            required: true,
            message: t('font-selection-is-required'),
          },
        ]}
        className="col-md-5"
        style={{ paddingLeft: 0 }}
        name={['fonts']}
      >
        <Select className="default-select" placeholder={t('select-type')} onChange={handleFontChange} style={{ fontFamily: font }}>
          <Select.Option value={'Helvetica'} key={'Helvetica'} className="Helvetica">
            Helvetica
          </Select.Option>
          <Select.Option value={'Abel'} key={'Abel-Regular'} style={{ fontFamily: 'Abel' }}>
            Abel
          </Select.Option>
          <Select.Option value={'Lato'} key={'Lato-Regular'} style={{ fontFamily: 'Lato' }}>
            Lato
          </Select.Option>
          <Select.Option value={'Montserrat'} key={'Montserrat-Regular'} style={{ fontFamily: 'Montserrat' }}>
            Montserrat
          </Select.Option>
          <Select.Option value={'Open Sans'} key={'OpenSans-Regular'} style={{ fontFamily: 'Open Sans' }}>
            Open Sans
          </Select.Option>
          <Select.Option value={'Oswald'} key={'Oswald-Regular'} style={{ fontFamily: 'Oswald' }}>
            Oswald
          </Select.Option>
          <Select.Option value={'Roboto Condensed'} key={'RobotoCondensed-Regular'} style={{ fontFamily: 'Roboto Condensed' }}>
            Roboto
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );
};

export default FontSelector;

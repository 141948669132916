import { useTranslation } from 'react-i18next';
import { Select, Item, Input } from '../../../../../../common/components/form';
import { AppTemplate } from '../../../../../../interfaces/forms/areas/billing/appTemplate';
import { EmailTemplate } from '../../../../../../interfaces/forms/areas/billing/emailTemplate';
import { SmsTemplate } from '../../../../../../interfaces/forms/areas/billing/smsTemplate';

const FormFields = ({ emailTemplates, smsTemplates, appTemplates }: { emailTemplates: EmailTemplate[]; smsTemplates: SmsTemplate[]; appTemplates: AppTemplate[] }) => {
  const {t} = useTranslation();
  return (
    <>
      <Item className="mb-3" label={t('name')} name={['name']} rules={[{ required: true, message: t('name-is-required')}]}>
        <Input />
      </Item>
      <Item label={t('email-template')} name={['email_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-email-template-used-to-contact-the-customer-can-be-edited-in-email-templates-under-settings')}>
        <Select className="default-select" options={emailTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
      <Item label={t('sms-template')} name={['sms_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-sms-template-used-to-contact-the-customer-can-be-edited-in-sms-templates-under-settings')}>
        <Select className="default-select" options={smsTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
      <Item label={t('app-template')} name={['app_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-in-app-template-used-to-contact-the-customer-can-be-edited-in-app-templates-under-settings')}>
        <Select className="default-select" options={appTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
    </>
  );
};

export default FormFields;

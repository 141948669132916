import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import Player from './player'

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/player`}><Player /></Route>

    </Switch>
  )
}

export default Index

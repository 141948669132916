import { IEmailGateway } from '../../../definitions';
import Mailgun from 'mailgun.js';
import formData from 'form-data';

const isHTML = (text: string): boolean => {
  const pattern = /<[a-z][\s\S]*>/i;
  return pattern.test(text);
};

export const mailgun: IEmailGateway = {
  name: 'Mailgun',
  email_gateway_id: 'mailgun',
  fields: {
    apiKey: { label: 'Domain API Key', type: 'string' },
    domain: { label: 'Domain', type: 'string' },
    sender: { label: 'Sender Address', type: 'string' },
    region: { label: 'API Region (US / EU)', type: 'string' },
  },
  url: 'https://mailgun.com/',
  helpText: 'Send emails with Mailgun',
  infotext: [
    'Domain API Key is the Sending API key for the current domain, obtained from Dashboard > Sending > Domain Settings > Sending API Keys > Generate new keys.',
    'Domain is the Mailgun domain generated from Dashboard > Sending > Domains.',
    'Sender Address is the email address used in the "From" field (ex. info@example.mailgun.com). Ideally it should have the same domain as the domain you\'re using for the gateway.',
    'API Region is the region you used when creating the domain. It should be "US" (for USA) or "EU" (for Europe), without quotes.',
  ].join('\n'),
  logo: 'https://cloudtv.akamaized.net/donotremove/tvms/billing/email-gateways/mailgun.png',
  send: async (
    { apiKey, domain, sender, region },
    { subject, to, cc, bcc, content }
  ) => {
    if (!(apiKey && domain && sender && region)) {
      throw new Error('API Key, domain, region or sender not supplied.');
    }

    if (region.toLowerCase() !== 'us' && region.toLowerCase() !== 'eu') {
      throw new Error('Region must be US or EU.');
    }

    const API_USERNAME = 'api';

    const EUApi = 'https://api.eu.mailgun.net';
    const USApi = 'https://api.mailgun.net/';

    const apiUrl = region.toLowerCase() == 'us' ? USApi : EUApi;

    const mailgun = new Mailgun(formData);
    const mg = mailgun.client({
      username: API_USERNAME,
      key: apiKey,
      url: apiUrl,
    });

    const html = isHTML(content) ? content : undefined;
    const text = isHTML(content) ? undefined : content;

    try {
      const response = await mg.messages.create(domain, {
        from: sender,
        to,
        cc,
        bcc,
        subject,
        text,
        html,
      });

      return {
        isSend: response.status === 200,
      };
    } catch (e) {
      console.log('Failed to send email.');
     
      return {
        isSend: false,
      };
    }
  },
};

const placeholderRegex = /{{(?:\s|&nbsp;)*(\S*?)(?:\s|&nbsp;)*}}/gm;

/** Replaces placeholders of format {{ data_key }} with values from data. */
export const replacePlaceholdersInText = (
  text: string,
  data: Record<string, string>
) => text.replace(placeholderRegex, (_, searchKey: string) => {
  for (const key in data) {
    if (key.toLowerCase() == searchKey.toLowerCase()) {
      return data[key] ?? '';
    }
  }
  return '';
});
import { Button, notification, Tooltip, Image, Col } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import confirm from 'antd/lib/modal/confirm';
import { faArrowsUpDown, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cmsCourses from '../../../../services/areas/content/series/series';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import ReactDragListView from 'react-drag-listview';
import { useTranslation } from 'react-i18next';

const NestedLevels = (params: { service_name: any; clientName: any; data: any; _id: string; series: any }) => {
  const params_ = useParams<IMSNameParams>();
  const { url } = useRouteMatch();
  const baseUrl = `${url.split('/').slice(0, -3).join('/')}`;
  const [data, setData] = useState([] as any);
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)

  // const handleUpdate = async (seasons: any) => {
  //   let response = await cmsCourses.Update_Season({
  //     query_params: {
  //       instance: params.clientName,
  //       cms: params.service_name ?? '',
  //     },
  //     ...params.series,
  //     seasons: seasons,
  //   });
  //   if (response && response.status_code === StatusCodes.OK) {
  //     setShowModal(false);
  //     handleFormFill();
  //   }
  // };

  // const handleFormFill = async () => {
  //   let response = await cmsCourses.Details_Season({
  //     query_params: {
  //       instance: params.clientName,
  //       cms: params.service_name ?? '',
  //     },
  //     _id: params_._id,
  //   });
  //   if (response && response.status_code === StatusCodes.OK) {
  //     var resp = response.data as any;
  //     setData(resp);
  //   }
  // };

  useEffectOnce(() => {
    setData(params.data);
  });

  const deleteSeason = async (row: any, index: any) => {
    var dataOut = data.filter((s: any) => s._id != row._id);
    let response = await cmsCourses.Update_Series({
      query_params: {
        instance: params.clientName,
        cms: params.service_name ?? '',
      },
      ...params.series,
      seasons: dataOut,
    });
    if (response.status_code === StatusCodes.OK) {
      setData(dataOut);
      notification.success({
        message: t('removed-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };
  const updateSeries = async (seasons: any) => {
    let response = await cmsCourses.Update_Series({
      query_params: {
        instance: params.clientName,
        cms: params.service_name ?? '',
      },
      ...params.series,
      seasons: seasons,
    });
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
      setData(seasons)
      setIsLoading(false)
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
      setIsLoading(false)
    }
  };

  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const data_ = data;
      const item = data_.splice(fromIndex, 1);
      data_.splice(toIndex, 0, ...item);
      setIsLoading(true);
      updateSeries(data_);
    },
    lineClassName: 'global-drag-line',
    handleSelector: '.drag-handle',
  };

  const packageColumns: IReactBCTableColumn<[]>[] = [
    {
      title: t('drag-and-drop'),
      dataKey: 'operate',
      width: '15%',
      cell: (row: any) => (
        <Tooltip placement="top" title={`Drag to Move Row`} key={'move'}>
          <div className="text-center">
            <FontAwesomeIcon className="action-icon drag-handle" color={'#999'} size={'xl'} icon={faArrowsUpDown} />
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('season-image'),
      dataKey: 'images',
      width: '20%',
      cell: (row: any) => (
        <div className="text-center">
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },
    {
      title: t('number'),
      dataKey: 'number',
      width: '10%',
      cell: (row: any) => <span>{row.number}</span>,
    },
    {
      title: t('season-name'),
      dataKey: 'name',
      width: '35%',
      cell: (row: any) => <span>{row.name}</span>,
    },
    {
      title: t('actions'),
      dataKey: 'action',
      width: '20%',
      cell: (row: any, index: any) => (
        <Col style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip placement="top" title={`Delete Season`} key={'Delete'}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={() =>
                confirm({
                  title: t('are-you-sure-you-want-to-delete-this-season'),
                  okText: t('yes'),
                  okType: 'danger',
                  cancelText: t('no'),
                  async onOk() {
                    await deleteSeason(row, index);
                  },
                  onCancel() {},
                })
              }
              type="text"
            >
              <FontAwesomeIcon className="action-icon  fa-trash-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={t('season-details')} key={'details'}>
            <a href={`${baseUrl}` + '/series/seasons/details/' + row._id}>
              <Button className="mx-2 cursor-pointer" type="text">
                <FontAwesomeIcon className="action-icon" icon={faMemo} />
                <div>{t('open-details')}</div>
              </Button>
            </a>
          </Tooltip>
        </Col>
      ),
    },
  ];
  //http://localhost:3000/client/gurdeep/60e7dd3e428e1c1e80ca23f7/3214/content/series/seasons/details/series/seasons/details/63d8f95768795d690dc1a3d6
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
          <span className="ml-3 ml-md-4 "></span>
        </div>
        {/* <div className="col-md-5">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${baseUrl}/seasons/add/${params._id}`}>
            {t('add-season')}
          </Link>
        </div> */}
      </div>
      {!isLoading &&
      <ReactDragListView {...dragProps}>
        <ReactBCTable  totalRecords={pagination.count} pageSize={pagination.pageSize} tableId={'user_interfaces_tables'} columns={packageColumns} data={data} isLoading={false} />
      </ReactDragListView>
}
    </div>
  );
};
export default NestedLevels;

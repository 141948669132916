import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { AuthContext } from '../../../context_api/AuthContext';
import { isInRoles } from '../../components/VisibleForRole';

interface PrivateRouteProps extends RouteProps {
  component: any;
}
const AuthRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { state: AuthState } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (AuthState.user === null || !AuthState.user.clients || AuthState.isAuthenticated === false) {
          return (
            <div className="app-main bg-auth">
              <Component {...props} />
            </div>
          );
        } else {
          if (isInRoles(AuthState.user.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin])) {
            return <Redirect to="/clients" />;
          } else {
            if (isInRoles(AuthState.user.role, [TVMSRoles.Vendor])) {
              const service =
                AuthState.user.services != undefined
                  ? AuthState.user.services[0]
                      .replace(AuthState.user.clients[0].name + '_', '')
                      .replace('_', '/')
                      .replace('_', '/')
                  : '';
              return <Redirect to={`/client/${AuthState.user.clients[0].name}/${AuthState.user.clients[0]._id}/${service}/customers/list`} />;
            } else if (isInRoles(AuthState.user.role, [TVMSRoles.Provider])) {
              return <Redirect to={`/client/${AuthState.user.clients[0].name}/${AuthState.user.clients[0]._id}`} />;
            } else {
              return <Redirect to={`/client/${AuthState.user.clients[0].name}/${AuthState.user.clients[0]._id}`} />;
            }
          }
        }
      }}
    />
  );
};
export default AuthRoute;

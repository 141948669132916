import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
type IUpdatePackageModalProps = {
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: any;
};

const PackageModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const [externalType, setExternalType] = useState('');
  const { t } = useTranslation();
  let [form] = useForm();

  useEffect(() => {
    setExternalType(initialData?.external?.cms_type);
    form.setFieldsValue(initialData);
  }, [initialData]);

  const onChangeExternalType = (value) => {
    setExternalType(value);
  };

  return (
    <div className="p-5" style={{ border: '1px solid #ebebeb', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmit(values, false)} onFinishFailed={(errorInfo) => {}}>
            <label className="col-form-label">CMS Provider</label>
            <Form.Item initialValue={'--Select a CMS--'} name={['external', 'cms_type']}>
              <Select onChange={(value) => onChangeExternalType(value)} className="default-select" defaultActiveFirstOption={true} defaultValue={'JSON'}>
                <Select.Option value={'BrightcoveCmsApi'} key={'BrightcoveCmsApi'}>
                  Brightcove CMS API
                </Select.Option>
                {/* <Select.Option  value={'VideoElephant'} key={'VideoElephant'}>
                  Video Elephant
                </Select.Option>
                <Select.Option value={'DistroTV'} key={'DistroTV'}>
                  DistroTV
                </Select.Option>
                <Select.Option value={'Comcast'} key={'Comcast'}>
                  Comcast
                </Select.Option> */}
              </Select>
            </Form.Item>
            {externalType == 'BrightcoveCmsApi' && (
              <div className="card-bg-light pl-5 pt-3 mb-4 p-5 mt-4">
                <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                  Brightcove Settings
                </h4>
                <label className="col-form-label">Account ID</label>
                <Form.Item
                  name={['external', 'brightcove', 'account_id']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Account ID is required',
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="j1324j124j" />
                </Form.Item>
                <label className="col-form-label">Policy Key</label>
                <Form.Item
                  name={['external', 'brightcove', 'policy_key']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Policy ID is required',
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="j1324j124j" />
                </Form.Item>
                <label className="col-form-label">Client ID</label>
                <Form.Item
                  name={['external', 'brightcove', 'client_id']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Client ID is required',
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="23jdjaj23" />
                </Form.Item>
                <label className="col-form-label">Client Secret</label>
                <Form.Item
                  name={['external', 'brightcove', 'client_secret']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Client Secret is required',
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="23jdjaj23" />
                </Form.Item>
                <label className="col-form-label">Refresh Rate</label>
                <Form.Item initialValue={'--Select a Rate--'} name={['external', 'refresh_rate']}>
                  <Select className="default-select" defaultActiveFirstOption={true} defaultValue={'Manual'}>
                  <Select.Option value={'Manual'} key={'Manual'}>
                      Manual
                    </Select.Option>
                    <Select.Option value={'OncePerDay'} key={'OncePerDay'}>
                      Once per Day
                    </Select.Option>
                    <Select.Option value={'OncePerWeek'} key={'OncePerWeek'}>
                      Once per Week
                    </Select.Option>
                    <Select.Option value={'OncePerMonth'} key={'OncePerMonth'}>
                      Once per Month
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}

            <Form.Item name="_id">
              <Input hidden value={initialData?._id}></Input>
            </Form.Item>
            <div className="col-md-12 p-0">
              {initialData._id == undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-bundle')}
                </Button>
              )}
              {initialData._id != undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-bundle')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;

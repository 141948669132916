import { Button, Form, Input, notification, } from "antd";
import { useForm } from "antd/lib/form/Form";
import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { CustomNotification } from "../../../../common/components/Notification";
import drmService from "../../../../services/areas/content/drm/drm";
import { IMSNameParams } from "../../../dashboard";
import { Spin } from "../../../../common/components/spinner";
import { useTranslation } from "react-i18next";

const TokenSettings = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [form] = useForm();
const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState([] as any);


  const getDrmDetails = async () => {
    setIsLoading(true);
    let response = await drmService.List_DRM_Settings({
      query_params: {
        cms: service_name || "",
        instance: clientName,
      },
      pageNumber: 0,
      pageSize: 10000
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data[0]);
    }
    setIsLoading(false);
  };
  const saveDrmDetails = async (values: any) => {
    setIsSubmitting(true);
    
    if (data != undefined) {
      let response = await drmService.Update_DRM_Setting({
        query_params: {
          cms: service_name || "",
          instance: clientName,
        },
        _id: data._id,
        ...values
      });
      if (response && response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('success'),
        });
      } else CustomNotification({ error: response.errors });
    } else {
      let response = await drmService.Add_DRM_Setting({
        query_params: {
          cms: service_name || "",
          instance: clientName,
        },
        ...values,
      });
      if (response && response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('success'),
        });
      } else CustomNotification({ error: response.errors });
    }
    setIsSubmitting(false);
  };

  useEffectOnce(() => {
    (async () => {
      await getDrmDetails();
    })();
  });

  useEffect(() => {
    (() => form.setFieldsValue(data))();
  }, [data]);


  return (
    <>
      <Spin spinning={isLoading}>
        <div className="container-fluid wrapper">
          <div className="row">
            <div className="col-md-6">
              <h2 className="page-title">{t('irdeto-settings')}</h2>
            </div>
          </div>
          <Form
            form={form}
            initialValues={data}
            onFinish={(values) => {
              saveDrmDetails(values);
            }}
            onFinishFailed={(errorInfo) => {
              
            }}
          >
            <div className="card mt-5">
              <div className="card-body card-ch-padding mt-5">
                <div className="row">
                  <label className="col-md-2 col-form-label">
                    {t('ic-host')}
                  </label>
                  <Form.Item className="col-md-5" name={["irdeto", "ic_host"]}>
                    <Input
                      className="form-control height-48 bg-white font-size-14 text-default"
                      placeholder={t('can-be-obtained-via-irdeto')}
                    />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">
                    {t('account-id')}
                  </label>
                  <Form.Item className="col-md-5" name={["irdeto", "account_id"]}>
                    <Input
                      className="form-control height-48 bg-white font-size-14 text-default"
                      placeholder={t('can-be-obtained-via-irdeto')}
                    />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">
                    {t('widevine-license-url')}
                  </label>
                  <Form.Item className="col-md-5" name={["irdeto", "license_url_widevine"]}>
                    <Input
                      className="form-control height-48 bg-white font-size-14 text-default"
                      placeholder="https://licenseurl.com"
                    />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-2 col-form-label">{t('widevine-license-url')}</label>
                  <Form.Item className="col-md-5" name={["irdeto", "license_url_fairplay"]}>
                    <Input
                      className="form-control height-48 bg-white font-size-14 text-default"
                      placeholder="https://licenseurl.com"
                    />
                  </Form.Item>
                </div>
                <div className="text-right mb-4">
                  <Button
                    htmlType="submit"
                    loading={isSubmitting}
                    disabled={isLoading || isSubmitting}
                    className="col-md-3 btn btn-secondary rounded-pill width-200 height-48 ml-3"
                  >
                    {t('save-settings')}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default TokenSettings;

import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Button, Form, Modal, notification, Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToLine, faTrashCan } from '@fortawesome/pro-regular-svg-icons';

import * as TaxService from '../../../../../services/areas/billing/taxService';
import { TaxRate } from '../../../../../interfaces/forms/areas/billing/taxRate';

import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../../common/components/pageTitle';
import { BillingContext } from '../../../../../contexts/billingContext';
import { Item, Input, ModalFormWrapper, CardForm, FormHeading } from '../../../../../common/components/form';
import { useTranslation } from 'react-i18next';

const List: React.FC = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const { url } = useRouteMatch();
  const history = useHistory();
  const {t} = useTranslation();
  
  const { isLoading, isError, data, refetch } = useQuery(['packages', pagination.page, pagination.limit], async () => {
    return (
      await TaxService.list({
        params: {
          cms: deployment?.name ?? '',
          crm: service?.name || '',
          instance: clientName,
          page: pagination.page,
          limit: pagination.limit,
        },
      })
    ).data;
  });

  useEffect(() => {
    if (data?.docs != undefined && data.docs.length > 0) {
      history.push(url + '/' + data.docs[0]._id);
    } else {
    }
  }, [data]);

  // const deleteMutation = useMutation(
  //   (id: string) => {
  //     return TaxService.del({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id });
  //   },
  //   {
  //     onSuccess: (data) => {
  //       notification.success({ message: 'Tax Rate deleted' });
  //       refetch();
  //     },
  //     onError: () => {
  //       notification.error({ message: 'Delete failed' });
  //     },
  //   }
  // );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('taxes')} subtitle={t('manage-the-tax-rates-you-want-to-use-for-your-products')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <ActionBar />
        </div>
      </div>
    </ContainerWrapper>
  );
};

const ActionBar: React.FC<{}> = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const { url } = useRouteMatch();
  const history = useHistory();
  const {t} = useTranslation();
  const { isLoading, isError, mutate } = useMutation(
    (values: TaxRate) => {
      return TaxService.add({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, data: values });
    },
    {
      onSuccess: (data: any) => {
        history.push(url + '/' + data.data._id);
      },
      onError: () => {
        notification.error({ message: t('could-not-add-tax-rate') });
      },
    }
  );

  return (
    <>
      <div className="d-flex justify-content-center justify-content-md-start align-items-start">
        <Button
          className="btn btn-secondary rounded-pill btn-default float-right  height-48"
          onClick={() =>
            mutate({
              name: 'Taxes',
              rates: [],
            })
          }
        >
          {t('add-tax-rates-for-your-products')}
        </Button>
      </div>
    </>
  );
};

const AddVatRateModal: React.FC<{ open: boolean; onCancel: () => void; onSuccess: () => void }> = ({ open, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();
  const { isLoading, isError, mutate } = useMutation(
    (values: TaxRate) => {
      return TaxService.add({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, data: values });
    },
    {
      onSuccess: (data) => {
        notification.success({ message: t('tax-rate-added') });
        onSuccess();
      },
      onError: () => {
        notification.error({ message: t('could-not-add-tax-rate')});
      },
    }
  );
  return (
    <Modal
      {...{ open, onCancel }}
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{
        hidden: true,
      }}
    >
      <ModalFormWrapper>
        <CardForm {...{ form }} buttonBgWhite onFinish={(values) => mutate(values)} disabled={isLoading} layout="vertical">
          <FormHeading>{t('tax-rate')}</FormHeading>
          <Item name="name" label={t('name')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: true, message: t('name-is-required') }]}>
            <Input />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

export default List;

import { Hero_Slider } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/heros/slider";
import { Hero_Slider as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/heros/slider";
import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";
import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import {IGetForm} from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Hero_Slider_Service {
    Add_Hero_Slider = async (data: Hero_Slider): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.HERO.SLIDER.ADD, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Hero_Slider = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.HERO.SLIDER.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Hero_Slider = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.HERO.SLIDER.DETAILS}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    }; 

    Update_Hero_Slider = async (data: Hero_Slider): Promise<IBaseResponse> => {
        
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.HERO.SLIDER.UPDATE}`, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Hero_Slider = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.HERO.SLIDER.DELETE}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Hero_Slider_Service();

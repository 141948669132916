import { Form, notification, Steps as AntdSteps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import type { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { IMSNameParams } from '../../../../../..';
import { ContainerWrapper } from '../../../../../../../../common/components/containerWrapper';
import { PrimaryButton } from '../../../../../../../../common/components/form';
import * as deploymentService from '../../../../../../../../services/deployments/deployment';
import Congrats from './congrats';
import InstanceSettings from './service';
import Intro from './intro';
import DeploymentSettings from './deployment';
import ServiceSettings from '../../services/add/serviceSettings';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ICountry } from '../../../../../../../../interfaces/responses/common/ICountriesResponse';
import { ILanguage } from '../../../../../../../../interfaces/responses/common/ILanguagesResponse';
import { CommonContext } from '../../../../../../../../contexts/commonContext';

enum Steps {
  Intro,
  Instance,
  DeploymentSettings,
  ServiceSettings,
  Congrats,
}



const NewDeployment: React.FC = () => {
  const {t} = useTranslation();

  const getButtonText = (currentStep: Steps) => {
  switch (currentStep) {
    case Steps.ServiceSettings:
      return t('save');
    case Steps.Congrats:
      return t('ok');
    default:
      return t('next');
  }
};
  const { countries, currencies, languages } = useContext(CommonContext);

  const history = useHistory();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [form] = useForm<Omit<Deployment, 'id' & '_id'>>();

  const { clientName } = useParams<IMSNameParams>();
  const [currentStep, setCurrentStep] = React.useState<Steps>(Steps.Intro);
  const { mutate, isLoading } = useMutation(
    (values: Omit<Deployment, 'id' | '_id'>) => {
      return deploymentService.add({ params: { client: clientName }, data: values });
    },
    {
      
      onSuccess: (data) => {
        notification.success({ message: t('instance-successfully-added') });
        setCurrentStep(currentStep + 1);
        //history.push(`/client/${clientName}`);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-saving-the-deployment') });
      },
    }
  );

  const handleClick = async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    if (currentStep === Steps.Instance) {
      try {
        const deployments = await deploymentService.list({
          params: {
            client: clientName,
            page: 1,
            limit: 9999,
          },
        });

        const foundExisting = deployments.data.docs.some((x) => x.name === form.getFieldValue('name'));

        if (foundExisting) {
          notification.error({ message: t('a-deployment-with-that-name-already-exists-please-choose-something-else') });
          return;
        }
      } catch (e) {
        notification.error({ message: t('an-unknown-error-occurred-please-try-again-later') });
        return;
      }
    }

    if (currentStep === Steps.ServiceSettings) {

      if (countries && currencies) {
        const formCountries = (form.getFieldValue('countries') as string[]).map((id) => countries.find((c) => c._id === id));
        const formCurrencies = [...new Set(formCountries.map((c) => c!.currency))];
        const formIds = formCurrencies.map((c) => currencies.find((d) => d.isoCurrency.toLowerCase() === c.toLowerCase())?._id);
        const serviceCountries = (form.getFieldValue(['serviceCountries']) as string[]).map((id) => countries.find((c) => c._id === id));
        const serviceFormCurrencies = [...new Set(serviceCountries.map((c) => c!.currency))];
        const serviceFormIds = serviceFormCurrencies.map((c) => currencies.find((d) => d.isoCurrency.toLowerCase() === c.toLowerCase())?._id);

        const data = {
          name: form.getFieldValue('name'),
          services: [
            {
              name: form.getFieldValue('serviceName'),
              timezone: form.getFieldValue('serviceTimezone'),
              is_deleted: false,
              countries: form.getFieldValue('serviceCountries'),
              currencies: serviceFormIds as any,
              languages: form.getFieldValue('serviceLanguages'),
            },
          ],
          countries: form.getFieldValue('countries'),
          timezone: form.getFieldValue('deploymentTimezone'),
          is_deleted: false,
          currencies: formIds as any,
          languages: form.getFieldValue('languages'),
        };

        data.services[0].currencies = serviceFormIds;

        mutate(data);
      }
    } else if (currentStep === Steps.Congrats) {
      history.push(redirectUrl);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <div className="card mt-4 pb-4">
            <div className="card-body card-ch-padding">
              <Form {...{ form, isLoading }}>
                <AntdSteps
                  current={currentStep}
                  type="navigation"
                  items={[
                    { title: t('important'), disabled: currentStep !== Steps.Intro },
                    { title: t('setup'), disabled: currentStep !== Steps.Instance },
                    { title: t('deployment'), disabled: currentStep !== Steps.DeploymentSettings },
                    { title: t('service'), disabled: currentStep !== Steps.ServiceSettings },
                    { title: t('congrats'), disabled: currentStep !== Steps.Congrats },
                  ]}
                />
                {currentStep === Steps.Intro && <Intro />}
                {currentStep === Steps.Instance && <InstanceSettings />}
                {currentStep === Steps.DeploymentSettings && <DeploymentSettings />}
                {currentStep === Steps.ServiceSettings && countries && languages && <ServiceSettings allowedCountries={(form.getFieldValue('countries') as string[]).map((c) => countries.find((x) => c === x._id)) as ICountry[]} allowedLanguages={(form.getFieldValue('languages') as string[]).map((c) => languages.find((x) => c === x._id)) as ILanguage[]} />}
                {currentStep === Steps.Congrats && <Congrats />}
              </Form>
            </div>
          </div>
          <div className="text-right">
            {currentStep != Steps.Congrats && (
              <PrimaryButton loading={isLoading} onClick={handleClick}>
                {getButtonText(currentStep)}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default NewDeployment;

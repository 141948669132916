import { Form, Input, Modal, Switch,Image } from 'antd';
import { Spin} from "../../spinner";
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';

export type ModalProps = {
  visible: boolean;
  data: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const ProgamModal = (props: ModalProps) => {
  const { visible, onSubmit, onCancel } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [blocked, setBlocked] = useState(false);
  const [img, setImg] = useState('');
  const [title, setTitle] = useState('');


  useEffect(() => {
    form.setFieldsValue(props.data);
    if (props.data.b != undefined) {
      setBlocked(props.data.b);
      setImg(props.data.m);
      setTitle(props.data.n)
    }
  }, [props.data]);

  const getBlockedProgram = () => {

  }
  const addBlockedProgram = () => {

  }
  const updateBlockedProgram = () => {
    
  }

  return (
    <Modal visible={visible} width={640} style={{ border: '1px solid' }} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary mb-4">Edit: {title}</h4>
            <Image src={img} height="100px" preview={false} />
            <Form
              form={form}
              initialValues={{
                name: '',
                url: '',
                add_to_top: false,
              }}
              onFinish={onSubmit}
              onFinishFailed={(errorInfo) => { }} >
              <label className="col-form-label">{t('name')}</label>
              <Form.Item
                className="form-group pb-0"
                name="n"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-program-name'),
                  },
                ]} >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-program-name')} />
              </Form.Item>
              <label className="col-form-label">{t('description')}</label>
              <Form.Item
                className="form-group pb-0"
                name="d"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-program-description'),
                  },
                ]}>
                <TextArea
                  className="form-control bg-white font-size-14 text-default"
                  rows={4}
                  placeholder={t('your-description-here')}
                />

              </Form.Item>
              <label className="col-form-label">{t('block-program')}</label>
              <Form.Item
                extra={t('block-this-program-from-watching-by-your-users-in-the-app')}
                className="form-group pb-0"
                name='b'>
                <Switch
                  onChange={(checked) => {
                    setBlocked(checked);
                  }}
                  checked={blocked}
                  className="ch-switch mt-2"
                  defaultChecked={false}
                />
              </Form.Item>
              <div className="col-md-12 p-0 mt-3">
                <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-program')}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProgamModal;

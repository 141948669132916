import { Button, Form, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Security from '../../../../../../../common/components/Content/Security/Security';
import cmsService from '../../../../../../../services/areas/content/shorts/shorts';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';

const EditShorts = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [security, setSecurity] = useState([] as any);
  const [streams, setStreams] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();

  useEffect(() => {
    setStreams(data.data.streams.shorts.urls);
    setSecurity(data.data.security);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      _id: _id,
      ...data.data,
      security: security,

      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_Shorts(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <Security data={security} setSecurity={setSecurity} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-shorts')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditShorts;

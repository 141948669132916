import moment from "moment";
import Akamai from 'akamai-auth-token';

export const getToken = async (content: any, tk: any, url: any) => {
    return await fetch('https://cloudtv.akamaized.net/ip.php?_=' + moment().unix()).then(async (response) => {
        let data = await response.json();
        return getTokenPerContentType(content, tk, url, data.ip);
    });
};
export const getTokenPerContentType = (content: any, tk: any, url: any, ip) => {
    if (content.security.akamai_token) {
        return getAkamaiToken(url, tk, ip);
    } else if (content.security.flussonic_token) {
        return url; //getFlussonicToken(url, tk, ip);
    } else if (content.security.legacy_token) {
        return  url; //getAkamaiTokenLegacy(url, tk, ip);
    } else {
        return url;
    }
};

export const getAkamaiToken = (url, tk, ip) => {
    if (url === '' || url === undefined) {
        return url;
    }
    if (tk == undefined || tk.akamai_key === undefined || tk.akamai_key === '') {
        return url;
    }
    var splitUrl = url.split('?');
    var urlToken = splitUrl[0].split('/');
    urlToken.pop();
    urlToken.shift();
    urlToken.shift();
    urlToken.shift();
    urlToken = urlToken.join('/');
    var config = {
        data: ip + '-TVMS',
        algorithm: 'SHA256',
        acl: '/' + urlToken + '/*',
        key: tk.akamai_key,
        window: 30000,
        encoding: false,
        time: new Date().getTime(),
    };
    var akamai = new Akamai(config);
    var token = akamai.generateToken();
    token = token + '&uid=' + encodeURIComponent('TVMS User'); //added UID request BTL
    url += url.indexOf('?') > -1 ? '&hdnts=' + token : '?hdnts=' + token;
    return url;
};
// export const getAkamaiTokenLegacy = (url, tk, ip) => {
//     if (url === '' || url === undefined) {
//         return url;
//     }
//     if (tk.akl === undefined || tk.akl === '') {
//         return url;
//     }
//     var url_ = url.split('?')[0].split('#')[0];
//     var comp = [] as any
//     var RandomNumber = Math.floor(Math.random() * 1000) + 1;
//     comp.push('random=' + RandomNumber);
//     comp.push('path=' + url_);
//     comp.push('checksum=' + encodeURIComponent(window.navigator.userAgent));
//     comp.push('ip=' + ip);
//     comp.push('date=' + new Date().getTime());
//     comp.push('userid=' + 'TVMS');
//     var token = encodeURIComponent(
//         'JP' +
//             CryptoJS.AES.encrypt(comp.join('~'), CryptoJS.enc.Hex.parse(tk.akl), {
//                 iv: CryptoJS.enc.Hex.parse('fedcba9876543210'),
//                 mode: CryptoJS.mode.ECB,
//                 padding: CryptoJS.pad.Pkcs7,
//             }).toString()
//     );
//     url += url.indexOf('?') > -1 ? '&token=' + token : '?token=' + token;
//     return url;
// };
// export const getFlussonicToken = (url, tk, ip) => {
//     if (url === '' || url === undefined) {
//         return url;
//     }
//     if (tk.fl === undefined || tk.fl === '') {
//         return url;
//     }
//     var salt = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
//     var splitUrl = url.split('?');
//     var streamName = splitUrl[0].split('/');
//     streamName.pop();
//     streamName.shift();
//     streamName.shift();
//     streamName.shift();
//     streamName = streamName.join('/');
//     var comp = [] as any;

//     comp.push(streamName);
//     comp.push(ip);
//     comp.push(moment().utc().unix());
//     comp.push(moment().utc().unix() + 9600);
//     comp.push(tk.fl);
//     comp.push(salt);

//     var hash = comp.join('');
//     var token = sha1(hash) + '-' + salt + '-' + (moment().utc().unix() + 9600) + '-' + (moment().utc().unix() - 180);
//     url += url.indexOf('?') > -1 ? '&token=' + token : '?token=' + token;
//     return url;
// };
// export const randomString = (length, chars) => {
//     var result = '';
//     for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
//     return result;
// };

import { Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type PropModal = {
  data: any;
  isOpenModal: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const ErrorModal = ({ data, isOpenModal, setIsOpen }: PropModal) => {
  const {t} = useTranslation();
  useEffect(() => {
    if (isOpenModal) {
      Modal.warning({
        title: t('there-are-some-errors-with-the-form-please-correct-and-try-again'),
        content: (
          <>
            {data.map((element, index) => (
              <div key={index}>{element.errors[0]}</div>
            ))}
          </>
        ),
        onOk: () => setIsOpen(false),
        onCancel: () => setIsOpen(false),
      });
    }
  }, [isOpenModal, data, setIsOpen]);

  return null;
};

import { Route, Switch, useRouteMatch } from 'react-router'
import { AppErrorDetails } from './AppErrorDetails';
import { AppErrorList } from './AppErrorList';

const AppErrorIndex = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:id`}><AppErrorDetails /></Route>
            <Route path={`${path}/`}><AppErrorList /></Route>
        </Switch>
    )
}

export default AppErrorIndex

import { Form, Modal, Select, Spin, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { FormSection, Item } from '../../../../../../../common/components/form';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as deploymentService from '../../../../../../../services/deployments/deployment';
import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { NamePath } from 'antd/lib/form/interface';
import { ICountry } from '../../../../../../../interfaces/responses/common/ICountriesResponse';
import { ILanguage } from '../../../../../../../interfaces/responses/common/ILanguagesResponse';
import { useContext, useState } from 'react';
import { CommonContext } from '../../../../../../../contexts/commonContext';

export type LayoutProps = {
  visible: boolean;
  clientName: string;
  deployment: string;
  service: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const ServiceSettings = (props: LayoutProps) => {
  const { visible, onCancel, clientName, deployment, onSubmit, service } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [isLoadind, setLoading] = useState(false);

  const { countries, currencies } = useContext(CommonContext);

  const { data: deploymentData } = useQuery(['deployment', deployment], async () => {
    if (deployment != undefined && deployment != '') {
      return deploymentService
        .find(clientName, { name: deployment })
        .then((r) => r.data)
        .catch((e) => Promise.reject(e));
    }
  });

  const { mutate } = useMutation(
    (values: Omit<Deployment, 'id' | '_id'>) => {
      return deploymentService.patch({ params: { client: clientName }, data: values, id: deploymentData?._id.toString() ?? '' });
    },
    {
      onSuccess: () => {
        notification.success({ message: t('changes-have-been-saved') });
        onSubmit();
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-saving-the-changes') });
      },
    }
  );

  const onSubmitLocal = () => {
    setLoading(true);
    if (countries && currencies && deploymentData) {
      var serviceIndex = deploymentData.services.findIndex((d) => d.name === service);

      if (form.getFieldValue('languages') != undefined) {
        deploymentData.services[serviceIndex] = {
          ...deploymentData.services[serviceIndex],
          languages: form.getFieldValue('languages'),
        };
      }
 
      if (form.getFieldValue('countries') != undefined) {
        const formCountries = (form.getFieldValue('countries') as string[]).map((id) => countries.find((c) => c._id === id));
        const formCurrencies = [...new Set(formCountries.map((c) => c!.currency))];
        const formIds = formCurrencies.map((c) => currencies.find((d) => d.isoCurrency.toLowerCase() === c.toLowerCase())?._id);

        deploymentData.services[serviceIndex] = {
          ...deploymentData.services[serviceIndex],
          countries: form.getFieldValue('countries'),
          currencies: formIds as any,
        };
      }
      mutate(deploymentData);
    }
  };



  const CountryCurrencySelect: React.FC<{ name: NamePath; extra: string; countries?: ICountry[]; label?: string; useIso3?: boolean; defaultCountries }> = ({ name, extra, countries, label = t('countries-and-currencies'), useIso3, defaultCountries }) => {
    return (
      <Item label={label} {...{ name, extra }}>
        <Select
          showSearch
          loading={!countries}
          mode="multiple"
          allowClear
          optionLabelProp="label"
          optionFilterProp="children"
          defaultValue={defaultCountries}
          filterOption={(input, option) => {
            return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
          }}
        >
          {(countries ?? []).map((country: ICountry) => (
            <Select.Option value={useIso3 ? country.iso3 : country._id} label={`${country.emoji} ${country.name}`}>
              {`${country.emoji} ${country.name}, ${country.currency}`}
            </Select.Option>
          ))}
        </Select>
      </Item>
    );
  };

  const MultipleLanguagesSelector: React.FC<{ name: NamePath; extra: string; languages?: ILanguage[]; defaultLanguages }> = ({ name, extra, languages, defaultLanguages }) => {
    return (
      <Item label={t('languages')} {...{ name, extra }}>
        <Select
          showSearch
          loading={!languages}
          mode="multiple"
          allowClear
          optionFilterProp="children"
          defaultValue={defaultLanguages}
          filterOption={(input, option) => {
            return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
          }}
          options={(languages ?? []).map((language) => ({ value: language._id, label: language.name }))}
        />
      </Item>
    );
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'60%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-2 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    {isLoadind == true && (
                      <div className="text-center h-100 w-100" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', zIndex: 9999 }}>
                        <div className="col-md-12 align-self-center" style={{ height: 495, paddingTop: 240 }}>
                          <Spin size="large" spinning={true} />
                        </div>
                      </div>
                    )}
                    <FormSection title={t('service-countries-currencies-and-languages')}>
                      <div className="row-md-12 pt-3">
                        <div className="col-sm-12">
                          <CountryCurrencySelect defaultCountries={deploymentData?.services.find((s) => s.name == service)?.countries?.map((c: any) => c._id)} countries={deploymentData?.countries as any} name={['countries']} extra={t('select-the-countries-and-currencies-you-want-to-use-for-this-deployment')} />
                        </div>
                      </div>
                      <div className="row-md-12 pt-3">
                        <div className="col-sm-12">
                          <MultipleLanguagesSelector defaultLanguages={deploymentData?.services.find((s) => s.name == service)?.languages?.map((c: any) => c._id)} languages={deploymentData?.languages as any} name={['languages']} extra={t('select-the-languages-you-want-to-use-for-this-deployment')} />
                        </div>
                      </div>
                    </FormSection>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-settings')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceSettings;

import { faArrowUpRightFromSquare, faMemo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const AddAlbum = () => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2"></div>
      </div>

      <div className="card mt-4 pb-4">
        <div className="card-body">
          <div className="card-bg-light pl-5 mb-4 p-5">
            <div className="row-md-12 text-right mb-2">
              <div
                className="text-right mt-2"
                onClick={() => {
                  navigator.clipboard.writeText('https://api.tvms.io/mybundle/openapi.json');
                }}
              >
                <FontAwesomeIcon className='mr-1' icon={faMemo}></FontAwesomeIcon>{t('copy-openapi-3-0-spec-url')}
              </div>
              <div
                className="text-right  mt-2"
                onClick={() => {
                  window.open('https://api.tvms.io/mybundle/openapi.json', '_blank')?.focus();
                }}
              >
                <FontAwesomeIcon className='mr-1' icon={faArrowUpRightFromSquare}></FontAwesomeIcon>{t('open-openapi-3-0-spec')}
              </div>
            </div>
            <iframe title="myiframe" id="myiframe" sandbox={t('allow-top-navigation-allow-forms-allow-modals-allow-orientation-lock-allow-popups-allow-same-origin-allow-scripts-allow-top-navigation')} scrolling="true" seamless allow="fullscreen" style={{ border: 'none', display: 'block', height: '100vh', width: '100%' }} loading="lazy" src="https://silent-vampirebat-57.redoc.ly/">
              <p>{t('your-browser-does-not-support-iframes')}</p>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAlbum;

import React from 'react';
import { Button, Result } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Congrats: React.FC = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const history = useHistory();
  const {t} = useTranslation();
  const openDasboard = () => {
    history.push(redirectUrl);
  };

  return (
    <>
      <Result
        status="success"
        title={t('successfully-created-the-deployment-and-service')}
        subTitle={t('your-new-deployment-and-service-have-been-created-successfully')}
        extra={[
          <Button className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48" type="primary" key="console" onClick={openDasboard}>
            {t('open-dashboard')}
          </Button>,
        ]}
      />
    </>
  );
};

export default Congrats;

import { Tabs } from 'antd';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv, faMobile, faTablet, faTelevision, faBrowser, faBlockBrick, faBrush, faCog, faListTree, faShieldKeyhole, faTableColumns, faFillDrip } from '@fortawesome/pro-regular-svg-icons';
import Mobile from '../../../../../common/components/AppBuilder/Navigation/Mobile';
import Tablet from '../../../../../common/components/AppBuilder/Navigation/Tablet';
import Television from '../../../../../common/components/AppBuilder/Navigation/Television';
import Browser from '../../../../../common/components/AppBuilder/Navigation/Browser';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../dashboard';
import { StatusCodes } from 'http-status-codes';
import MenuBuilder from './helpers/menu';
import { useTranslation } from 'react-i18next';

const Authentication = () => {
  const [menus, setMenus] = useState([] as any);
  const [activeKeyAuth, setActiveKeyAuth] = useState('Mobile');
  const { TabPane } = Tabs;
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { id } = useParams<any>();
  const [application, setApplication] = useState([] as any);
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(AppBuilderContext);
  const { t } = useTranslation();

  useEffectOnce(() => {
    setMenus([{ name: 'Mobile' }, { name: 'Tablet' }, { name: 'Television' }, { name: 'Browser' }]);
  });

  const getIcon = (name: any) => {
    switch (name) {
      case 'Mobile':
        return faMobile;
      case 'Tablet':
        return faTablet;
      case 'Television':
        return faTelevision;
      case 'Browser':
        return faBrowser;
      default:
        return faTv;
    }
  };

  const getAuthPage = (type: any) => {
    switch (type) {
      case 'Mobile':
        return <Mobile activeKey={type} />;
      case 'Tablet':
        return <Tablet activeKey={type} />;
      case 'Television':
        return <Television activeKey={type} />;
      case 'Browser':
        return <Browser activeKey={type} />;
    }
  };

  const handleFormFill = async () => {
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
    }
  };
  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row-md-12">
        <div className="row-md-12">
          <div className="col-md-12 mb-2">
            <h2 className="page-title mt-4 ">{t('navigators')}</h2>
          </div>
          <MenuBuilder application={application} redirectUrl={redirectUrl} id={id}></MenuBuilder>
        </div>
        <div className="col-md-12 mb-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="card">
            <div className="row">
              <div className="col-12  mt-3">
                <Tabs className="highlight-tab" onChange={(activeKeyAuth) => setActiveKeyAuth(activeKeyAuth)} defaultActiveKey={'Mobile'}>
                  {menus.map((menu: any) => {
                    
                    return (
                      <TabPane
                        tab={
                          <div className="col text-center" style={{}}>
                            <div style={{ width: 100 }}>
                              {' '}
                              <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={getIcon(menu.name) as any} />
                            </div>
                            <div style={{ fontSize: 12, color: '#999' }}>{menu.name}</div>
                          </div>
                        }
                        key={menu.name}
                      >
                        {activeKeyAuth == menu.name && <>{getAuthPage(menu.name)}</>}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Authentication;

import { Tabs } from 'antd';

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import NestedBundles from '../../../../../common/components/Content/Bundles/NestedBundles';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';

const Bundles = () => {
  let params = useParams<IMSNameParams & { deployment: string }>();
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>('1');
  const { t } = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6">
          <h2 className="page-title">{t('your-guide-bundles')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('guide-is-published-per-channel-or-radio-bundle-for-usage-in-the-apps')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={'1'}>
            <TabPane tab={t('channels')} key={'1'}>
              <div className="p-4">
                <NestedBundles type={'EpgTv'}></NestedBundles>
              </div>
            </TabPane>
            <TabPane tab={t('radio-stations')} key={'2'}>
              <div className="p-4">
                <NestedBundles type={'EpgRadio'}></NestedBundles>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Bundles;

export * from './email';
export * from './sms';
export * from './templates';

export { IEmailGateway } from './email/definitions';
export { sendgrid } from './email/gateways/sendgrid/src';

export { ISMSGateway } from './sms/definitions';
export { africastalking } from './sms/gateways/africa-talking/src';
export { miaSms } from './sms/gateways/miasms/src';
export { twilio } from './sms/gateways/twilio/src';

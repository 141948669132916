import { useContext, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../common/routes/PrivateRoute';
import { AuthContext } from '../context_api/AuthContext';
import { AuthActionTypes } from '../context_api/AuthContext/action-types';
import DrawerContextProvider from '../context_api/DrawerContext';
import ClientDashboard from '../pages/clients';
import { IMSDashboard } from '../pages/dashboard';
import userService from '../services/user';
import { TVMSRoles } from '../constants/TVMSRoles';
import { useEffectOnce } from 'react-use';
import { CommonContextProvider } from '../contexts/commonContext';
import { ICountry } from '../interfaces/responses/common/ICountriesResponse';
import { ICurrency } from '../interfaces/responses/common/ICurrenciesResponse';
import { ILanguage } from '../interfaces/responses/common/ILanguagesResponse';
import common from '../services/common';

export const AppRoutes = () => {
  const { state: AuthState, dispatch: AuthDispatch } = useContext(AuthContext);

  const [countries, setCountries] = useState([] as ICountry[]);
  const [currencies, setCurrencies] = useState([] as ICurrency[]);
  const [languages, setLanguages] = useState([] as ILanguage[]);

  useEffectOnce(() => {
    const countriesPromise = common.FetchCountries();
    const currenciesPromise = common.FetchCurrencies();
    const languagesPromise = common.FetchLanguages();

    Promise.all([countriesPromise, currenciesPromise, languagesPromise]).then(([countryData, currencyData, languageData]) => {
      setCountries(countryData.data.countries);
      setCurrencies(currencyData.data.currencies);
      setLanguages(languageData.data);
    }).catch(err => {
      console.log(err);
    });
  });

  useEffect(() => {
    if (!AuthState.user) {
      userService
        .Me()
        .then((user) => {
          AuthDispatch({
            type: AuthActionTypes.GET_USERINFO_SUCCESS,
            user: user as any,
          });
        })
        .catch((error) => {
          
        });
    }
  }, [AuthState.user, AuthDispatch]);

  return (
    <>
      {!AuthState.user && (
        <>
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">{/* <Spin size="large" spinning={true} /> */}</div>
          </div>
        </>
      )}
      {AuthState.user && (
        <Switch>
          {AuthState.isAuthenticated && (
            <DrawerContextProvider>
              <CommonContextProvider value={{
                countries: countries,
                currencies: currencies,
                languages: languages,
              }} >
                <Switch>
                  {/* 
                  <PrivateRoute path="/user/profile" component={SystemUserDetails}></PrivateRoute>
                  <PrivateRoute path="/user/security" component={UserSecurity}></PrivateRoute>
                  <PrivateRoute path="/user/roles" component={SystemUserDetails}></PrivateRoute>
                  <PrivateRoute path="/client/:clientName" allowedRoles={['SuperAdmin', 'administrator']} component={Dashboard}></PrivateRoute> 
                  */}
                  <PrivateRoute path="/client/:clientName/:_id" allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee, TVMSRoles.Auditor, TVMSRoles.Vendor, TVMSRoles.Provider]} component={IMSDashboard} />
                  <PrivateRoute component={ClientDashboard} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee, TVMSRoles.Auditor, TVMSRoles.Vendor, TVMSRoles.Provider]} />
                </Switch>
              </CommonContextProvider>
            </DrawerContextProvider>
          )}
        </Switch>
      )}
    </>
  );
};

import React from 'react'
import { Button, DatePicker, Form,  Table } from 'antd'

import { FormHeading, Input, Item, Modal, ModalFormWrapper, Select, TextArea} from '../../../../../common/components/form'
import { TicketSeverity } from '@mwaretv/database/build/backend/enums/customers/ticketSeverity'
import { TicketStatus } from '@mwaretv/database/build/backend/enums/customers/ticketStatus'
import { useCustomer, useCustomerSearch } from '../../../../../services/areas/customers/customerService'
import { useParams } from 'react-router'
import { RuleGroupType } from 'react-querybuilder'
import CustomerQueryBuilder from '../../customers/shared/customerQueryBuilder'
import Card from '../../../../../common/components/card'
import { Spin } from '../../../../../common/components/spinner'
import { CustomersContext } from '../../../../../contexts/customersContext'
import { useTranslation } from 'react-i18next';

export const TicketFields = () => {
  const {t} = useTranslation();
  return (
    <>
      <Item name={['title']} label={t('title')} extra={t('give-a-title-to-the-ticket-so-its-clear-what-this-ticket-is-about')}>
        <Input />
      </Item>
      <Item name={['date']} label={t('date')} extra={t('add-a-date-to-ticket')}>
        <DatePicker />
      </Item>
      <TicketCustomer />
      <Item name={['status']} label={t('status')} extra={t('the-status-of-the-ticket-will-most-likely-start-with-pending')}>
        <Select
          options={Object.values(TicketStatus).map(x => ({ label: x, value: x }))}
        />
      </Item>
      <Item name={['severity']} label={t('severity')} extra={t('how-much-should-this-ticket-be-prioritized')}>
        <Select
          options={Object.values(TicketSeverity).map(x => ({ label: x, value: x }))}
        />
      </Item>
      <Item name={['description']} label={t('description')} extra={t('add-a-clear-discription-as-to-why-this-ticket-is-created')}>
        <TextArea style={{height: '400px'}} size='large' />
      </Item>
    </>
  )
}

const TicketCustomer = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const formInstance = Form.useFormInstance()
  const {t} = useTranslation();
  const customerId = Form.useWatch(['customer'])

  return (
    <>
      <Item name={['customer']} label={t('customer')}>
        { customerId && <CustomerName {...{ customerId }} /> }
        <Button onClick={() => setModalOpen(true)}>{t('find-customer')}</Button>
      </Item>
      <SelectCustomerModal open={modalOpen} onClose={() => setModalOpen(false)} onCustomerSelect={(selectedId) => {
        formInstance.setFieldValue(['customer'], selectedId)
        setModalOpen(false)
      }} />
    </>
  )
}

type CustomerNameProps = {
  customerId : string
}

const CustomerName = ({ customerId } : CustomerNameProps) => {
  const { clientName, service: serviceName } = useParams<{clientName : string, service : string}>()
  const { deployment } = React.useContext(CustomersContext)
  const { isLoading, isError, data } = useCustomer({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName }, id: customerId })
  const {t} = useTranslation();
  
  return (
    <>
      { isLoading && <Spin /> }
      { isError && <span>{t('could-not-fetch-customer-data')}</span> }
      { !isError && !isError && data?.data && (<span className='mr-2'>{ `${data?.data.title} ${data?.data.first_name} ${data?.data.last_name}` }</span>)}
    </>
  )
}

type SelectCustomerModalProps = {
  open : boolean
  onClose : () => void
  onCustomerSelect : (customerId : string) => void
}

const SelectCustomerModal = ({ open, onClose, onCustomerSelect } : SelectCustomerModalProps) => {
  const [query, setQuery] = React.useState<RuleGroupType>({ combinator: 'and', rules: [] });
  const { deployment } = React.useContext(CustomersContext)
  const { clientName, service: serviceName } = useParams<{clientName : string, service : string}>()
  const [ pagination, setPagination ] = React.useState<{limit : number, page: number}>({ page: 1, limit: 50 })
  const {t} = useTranslation();
  const { isLoading, isError, data } = useCustomerSearch({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, ...pagination }, query: JSON.stringify(query)})

  return (
    <Modal width={900} {...{ open }} onCancel={() => onClose()} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
        <ModalFormWrapper>
            <Card>
              <FormHeading subtitle={t('search-for-a-customer-to-attach-to-this-ticket')}>{t('customer-search')}</FormHeading>
              <FormHeading>{t('query-builder')}</FormHeading>
              <p className="h6 font-weight-normal line-height-26">
                {t('use-the-query-builder-to-easily-find-the-customer-s-you-are-looking-for')}
              </p>
              <CustomerQueryBuilder {...{ query }} onQueryChange={(q) => setQuery(q)} />
              { isLoading && <Spin /> }
              { isError && <span>{t('something-went-wrong')}</span> }
              { !isLoading && !isError && (
                <Table
                  className='mt-5'
                  dataSource={data?.data.docs}
                  columns={[
                    { title: t('first-name'), dataIndex: 'first_name' },
                    { title: t('last-name'), dataIndex: 'first_name' },
                    { title: t('actiions'), render: (row) => (
                      <div>
                        <Button onClick={() => onCustomerSelect(row._id)}>{t('select')}</Button>
                      </div>
                    ) }
                  ]}
                  pagination={{
                    current: pagination.page,
                    pageSize: pagination.limit,
                    total: data.data.totalDocs,
                    onChange: (page, size) => setPagination({page, limit: size})
                  }}
                />
              )}
            </Card>
        </ModalFormWrapper>
    </Modal>
  )
}

import { Menu_Rails } from '../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/menu/menu';
import { Menu_Rails as Response } from '../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/rails/menu/menu';

import { IBaseResponse } from '../../../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../../../_APIConstants';

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class MenuService {
  Add_Menu = async (data: Menu_Rails): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.MENU.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Menu_Template = async (data: Menu_Rails): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.MENU.ADDTEMPLATE, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Menus = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.MENU.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Menu = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.RAILS.MENU.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Menu = async (data: Menu_Rails): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.RAILS.MENU.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Menu = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.RAILS.MENU.DELETE}/${data._id}`, {
        params: data.query_params,
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new MenuService();

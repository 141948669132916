import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const MenuBar = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container remove-hor-padding" style={{ height: 80}}>
      <div style={{ gridAutoColumns: '100%', display: 'grid' , marginLeft:5, }}>
        <div
          style={{
            display: 'flex',
            gridTemplateRows: 'min-content',
            gridAutoFlow: 'column',
            gridAutoColumns: 150,
            gap: 5,
            overflowX: 'auto',
            overscrollBehaviorInline: 'contain',
            marginTop: 10,
            backgroundColor: '#999',
            borderRadius: 5,
            padding: 10,
            marginRight:5
          }}
        >
          <div style={{ width: 150, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5 }}></div>
          <div style={{ width: 150, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5 }}></div>
          <div style={{ width: 150, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5 }}></div>
          <div style={{ width: 150, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5 }}></div>
          <div style={{ width: 150, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5 }}></div>
          <div style={{ width: 150, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5 }}></div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;

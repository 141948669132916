import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Teachers = (params: { setTeachers: any; data?: any }) => {
  const [teachers_, setTeachers_] = useState([] as any);
  const [teachers, setTeachers] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();
  
  useEffect(() => {
    if (params.data != undefined) {
      setIsLoading(true);
      var teachers = [] as any;
      params.data.forEach((element) => {
        teachers.push(element.name);
      });
      setTeachers_(teachers);
    } else {
      setIsLoading(false);
    }
  }, [params.data]);

  useEffect(() => {
    if (teachers_.length > 0) {
      setIsLoading(false);
    }
  }, [teachers_]);

  const onChangeTeachers = (values) => {
    setTeachers_(values);
    var teachers = [] as any;
    values.forEach((element) => {
      teachers.push({ name: element });
    });
    params.setTeachers(teachers);
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('course-teachers')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 mb-3 pt-3 mt-0 ">
            <label className="col-md-3 col-form-label">{t('teachers')}</label>
            <div className="col-md-9">
              {!isLoading && (
                <Select
                  style={{ width: '100%', margin: 5 }}
                  showSearch
                  loading={isLoading}
                  mode="tags"
                  allowClear
                  defaultValue={teachers_}
                  onChange={onChangeTeachers}
                  filterOption={(input, option) => {
                    return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                  }}
                  options={isLoading ? [] : teachers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Teachers;

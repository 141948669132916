import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import {  useState } from 'react';
import { useEffectOnce } from 'react-use';
import Epg from '../../../../../../../common/components/Content/Epg/Epg';

const AddNewMusicAlbum = (data: any) => {
  useEffectOnce(() => {
    setEpg(data.data.epg);
    form.setFieldsValue(data.data);
  });

  const [epg, setEpg] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();
 
  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
     
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <Epg data={epg} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;

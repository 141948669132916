import { Col, Row } from 'antd';
import { images } from './imagesData';
import { splitEvery } from './splitData';
import ImageComponent from './imageComponent';
import { ImagesProps } from './image.interface';

const ContentImages = () => (

  
  <Row gutter={[12, 20]}>
    {splitEvery(images, 5).map((imageChunk: ImagesProps[], index) => (
      <Col
        span={8}
        style={{
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        key={index}
      >
        {imageChunk.map(({ title, image, fileName }: ImagesProps, index: number) => (
          <ImageComponent key={index} title={title} image={image} fileName={fileName} />
        ))}
      </Col>
    ))}
  </Row>
);

export default ContentImages;

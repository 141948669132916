import { ApexOptions } from 'apexcharts';
import { t } from 'i18next';

export const baseOptions: ApexOptions = {
  colors: ['#2b3986', '#32439d', '#3a4cb3', '#4156ca', '#485fe0', '#5a6fe3', '#6d7fe6', '#7f8fe9', '#919fec'],
  chart: {
    animations: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
    background: '',
    foreColor: '#333333',
    fontFamily: 'Cabin',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: false,
  },
  noData: {
    text: t('chart-design-no-data'),
    style: {
      fontSize: '1.25rem',
    }
  },
};

import { createContext } from "react";
import { ICountry } from "../interfaces/responses/common/ICountriesResponse";
import { ICurrency } from "../interfaces/responses/common/ICurrenciesResponse";
import { ILanguage } from "../interfaces/responses/common/ILanguagesResponse";

type CommonContextType = {
  countries?: ICountry[];
  currencies?: ICurrency[];
  languages?: ILanguage[];
}

export const CommonContext = createContext<CommonContextType>({
  countries: undefined,
  currencies: undefined,
  languages: undefined,
});

export const CommonContextProvider = CommonContext.Provider


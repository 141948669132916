import { Modal } from 'antd';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import rgbHex from 'rgb-hex';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'react-i18next';

type ColorPickerProps = {
  color: string;
  theme?: any | [];
  visible: boolean;
  onChange: (color: string) => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ColorPicker = (props: ColorPickerProps) => {
  const [color, setColor] = useState<string>(props.color);
  const themes = Object.values(props.theme ?? []);
  const {t} = useTranslation();
  return (
    <>
      <Modal
        width={520}
        visible={props.visible}
        closable={false}
        onCancel={() => {
          props.setVisible(!props.visible);
        }}
        onOk={() => {
          props.onChange(color);
          props.setVisible(!props.visible);
        }}
      >
        <div className="row">
          <div className="col">
            <ChromePicker
              className="mt-3"
              styles={{
                default: {},
              }}
              color={color}
              onChangeComplete={(c) => {
                // setColor(_color.hex);
                setColor('#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
              }}
            />
          </div>
          <div
            className="col mt-3 chrome-picker"
            style={{
              marginTop: 25,
              marginRight: 15,
              backgroundColor: '#ffffff',
              borderRadius: 2,
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px',
              boxSizing: 'initial',
            }}
          >
            <div style={{ marginTop: 10, fontWeight: 'bold' }}>{t('theme-colors')}</div>
            {themes != undefined && (
              <div className="row mt-1" style={{ margin: 0 }}>
                {themes.map((th: any, index: any) => {
                  return (
                    <div key={index} style={{ width: 60, margin: 3 }}>
                      <div onClick={() => setColor(th.color)} style={{ cursor: 'pointer', width: 60, height: 42, borderRadius: 5 }}>
                        <div style={{ width: 60, height: 42, backgroundColor: th.color, borderRadius: 5, border: '2px #999999 dashed' }}></div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', fontSize: 8, width: 60 }}>{th.color}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

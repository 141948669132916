import { GlobalConstants } from "../constants/GlobalConstants";
import { AuthState } from "../context_api/AuthContext/reducer";

class TokenStorageService {
  setToken = (tokenObj: AuthState) => {
    localStorage.setItem(GlobalConstants.MWareAuthKey, tokenObj.token ?? "");
    localStorage.setItem(
      GlobalConstants.MWareRefreshKey,
      tokenObj.refresh_token ?? ""
    );
  };
  isAuthenticated = () => {
    return (
      this.getAccessToken() !== "" &&
      this.getAccessToken() !== null &&
      this.getAccessToken() !== undefined
    );
  };
  getAccessToken = () => {
    return localStorage.getItem(GlobalConstants.MWareAuthKey) ?? "";
  };
  getRefreshToken = () => {
    return localStorage.getItem(GlobalConstants.MWareRefreshKey) ?? "";
  };

  clearToken = () => {
    localStorage.removeItem(GlobalConstants.MWareAuthKey);
    localStorage.removeItem(GlobalConstants.MWareRefreshKey);
  };
}
export default new TokenStorageService();

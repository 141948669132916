import { Button, Image, Result } from 'antd';
import Icon404 from '../../../assets/images/404.png';
import { useTranslation } from 'react-i18next';

type PageNotFoundProps = {
  path: string;
};
const PageNotFound = ({ path }: PageNotFoundProps) => {
  const {t} = useTranslation();
  return <Result icon={<Image src={Icon404} preview={false} width={400} />} title="404" subTitle={t('sorry-the-page-you-visited-does-not-exist')} extra={<Button type="primary">{t('back-home')}</Button>} />;
};

export default PageNotFound;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import DefaultUserIcon from '../../../assets/images/user.png';
import { AuthContext } from '../../../context_api/AuthContext';
import { AuthActionTypes } from '../../../context_api/AuthContext/action-types';
import { DrawerContext } from '../../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../../context_api/DrawerContext/action-types';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;

const HomeDashboardHeader = () => {
  const history = useHistory();
  const { state: AuthState, dispatch: AuthDispatch } = useContext(AuthContext);
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const {t} = useTranslation();
  
  const handleMenuClick = ({ key }: any) => {
    document.body.classList.remove('menu-overlay');
    AuthDispatch({ type: AuthActionTypes.LOGOUT });
    history.push('/login');
  };

  const userMenu = () => {
    return (
      <Menu className="header-dropdown user-dropdown shadow">
        <div className="px-4 py-2">
          <div className="media border-bottom pb-3 d-flex">
            <img className="rounded-circle mr-3" src={DefaultUserIcon} title={t('user')} alt={t('user')} style={{ height: 45, width: 45 }} />
            <div className="media-body">
              <h5 className="mt-0 text-primary"  style={{ paddingBottom: 0, marginBottom: 0 }}>{AuthState.user?.name}</h5>
              <p className="mb-0">{AuthState.user?.email}</p>
              <Button className="mt-5" onClick={handleMenuClick}>
                {t('logout')}
              </Button>
            </div>
          </div>
        </div>
      </Menu>
    );
  };
  const dropdownVisibleChange = (isOpen: boolean) => {
    if (isOpen) document.body.classList.add('menu-overlay');
    else document.body.classList.remove('menu-overlay');
  };
  useEffectOnce(() => {
    DrawerDispatch({
      type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
      default_selected_key: '',
      default_open_key: '',
      routes: [],
      service_name: '',
      service_type: '',
      client_dashboard_path: '',
    });
  });
  return (
    <Header className="p-0 d-flex z-overly-10" style={{ backgroundColor: 'transparent' }}>
      <nav className="d-flex align-items-center w-100 px-3 px-lg-4 py-0">
        <div className="mr-auto">
          <div style={{ fontSize: 20 }}></div>
        </div>
        <Dropdown dropdownRender={() => userMenu()} trigger={['click']} onOpenChange={dropdownVisibleChange}>
          <Link to={``} className="nav-link pl-2 pl-lg-4 pr-0 user-menu">
            <img className="rounded-circle mr-2" src={DefaultUserIcon} title={t('user')} alt={t('user')} style={{ height: 45, width: 45 }} />
            <span className="d-none d-lg-inline-block">{AuthState.user?.name}</span>
            <FontAwesomeIcon className="ml-2 align-middle" icon={faAngleDown as IconProp} />
          </Link>
        </Dropdown>
      </nav>
    </Header>
  );
};

export default HomeDashboardHeader;

import { Placeholder } from '../types/placeholder';

export const customerPlaceholders: Placeholder[] = [
  {
    placeholder: 'first_name',
    description: 'Firstname of the customer',
  },
  {
    placeholder: 'last_name',
    description: 'Lastname of the customer',
  },
  {
    placeholder: 'address',
    description: 'Address of the customer',
  },
  {
    placeholder: 'phone',
    description: 'Phone number of the customer',
  },
  {
    placeholder: 'email',
    description: 'Email address of the customer',
  },
  {
    placeholder: 'service_id',
    description: 'Service ID of the linked service',
  },
  {
    placeholder: 'username',
    description: 'Username of the customer',
  },
  {
    placeholder: 'password_reset_link',
    description: "Link to reset a customer's password",
  },
  {
    placeholder: 'account_activation_link',
    description: "Link to active a customer's account",
  },
];
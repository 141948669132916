import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const SearchBar = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container remove-hor-padding" style={{ height: 80 }}>
      <div style={{ gridAutoColumns: '100%', display: 'grid', }}>
        <div
          style={{
            display: 'flex',
            gridTemplateRows: 'min-content',
            gridAutoFlow: 'column',
            gap: 5,
            overflowX: 'auto',
            overscrollBehaviorInline: 'contain',
            marginTop: 5,
            backgroundColor: '#999',
            borderRadius:5,
            padding: 10,
            width: '100%',
          }}
        >
          <div style={{ backgroundColor: '#888', width: 40, height: 40, borderRadius: 5 }}></div>
          <div style={{ flexGrow: 1, backgroundColor: '#bfbfbf', height: 40, borderRadius: 5  }}></div>
          <div style={{ backgroundColor: '#888', width: 150, height: 40, borderRadius: 5 }}></div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import Providers from './providers';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/providers`}>
        <Providers />
      </Route>
    </Switch>
  );
};

export default Index;

import { Tabs } from 'antd';
import { useContext, useState } from 'react';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import { StatusCodes } from 'http-status-codes';
import { useParams, useRouteMatch } from 'react-router-dom';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlbum, faCamcorder, faCameraSecurity, faCog, faDotCircle, faDownload, faFilm, faGamepad, faHeart, faHistory, faHome, faListCheck, faLoader, faMoneyBill, faPodcast, faRadio, faRectanglesMixed, faSchool, faSearch, faThList, faTv, faVideo } from '@fortawesome/pro-regular-svg-icons';
import Builder from '../../../../../common/components/AppBuilder/Builder';
import { Spin } from '../../../../../common/components/spinner';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import MenuBuilder from './helpers/menu';
import { useTranslation } from 'react-i18next';

const UserInterfaceAppDetails = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState([] as any);
  const [page, setPage] = useState([] as any);
  const [menus, setMenus] = useState([] as any);
  const { id } = useParams<any>();
  const [activeKey, setActiveKey] = useState('Home');
  const [activeKeySub, setActiveKeySub] = useState('0');
  const { TabPane } = Tabs;
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { t } = useTranslation();

  const getMenuItems = async () => {
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setMenus(resp.menus);
      var page = resp.screens.find((p: any) => p.type == activeKey);
      if (page != undefined) {
        setPage(page);
      } else {
        resp.screens.push({
          type: activeKey,
          header: [] as any,
          hero: [] as any,
          content: [] as any,
          footer: [] as any,
        });
        setPage({
          type: activeKey,
          header: [],
          hero: [],
          content: [],
          footer: [],
        });
      }
      setApplication(resp);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    (async () => await getMenuItems())();
  });

  const getIcon = (name: any) => {
    switch (name) {
      case 'Home':
        return faHome;
      case 'Video Player':
        return faYoutube;
      case 'Audio Player':
        return faYoutube;
      case 'Channels':
        return faTv;
      case 'Radio':
        return faRadio;
      case 'TV Guide':
        return faThList;
      case 'Program Details':
        return faThList;
      case 'CatchupTV':
        return faHistory;
      case 'CatchupTV Details':
        return faHistory;
      case 'Recordings':
        return faDotCircle;
      case 'Recording Details':
        return faDotCircle;
      case 'Games':
        return faGamepad;
      case 'Cams':
        return faCameraSecurity;
      case 'Movies':
        return faFilm;
      case 'Movie Details':
        return faFilm;
      case 'Shorts':
        return faCamcorder;
      case 'Short Details':
        return faCamcorder;
      case 'Series':
        return faVideo;
      case 'Serie Details':
        return faVideo;
      case 'Courses':
        return faSchool;
      case 'Course Details':
        return faSchool;
      case 'Music':
        return faAlbum;
      case 'Music Details':
        return faAlbum;
      case 'Podcasts':
        return faPodcast;
      case 'Podcast Details':
        return faPodcast;
      case 'Search':
        return faSearch;
      case 'Settings':
        return faCog;
      case 'Loader':
        return faLoader;
      case 'Favorites':
        return faHeart;
      case 'Watchlist':
        return faListCheck;
      case 'Downloads':
        return faDownload;
      case 'Rentals':
        return faMoneyBill;
      case 'Purchases':
        return faMoneyBill;
      default:
        return faRectanglesMixed;
    }
  };

  const subMenus = [
    {
      name: 'TV Guide',
      submenus: [{ name: 'TV Guide' }, { name: 'Program Details' }],
    },
    {
      name: 'Recordings',
      submenus: [{ name: 'Recordings' }, { name: 'Recording Details' }],
    },
    {
      name: 'Movies',
      submenus: [{ name: 'Movies' }, { name: 'Movie Details' }],
    },
    {
      name: 'Shorts',
      submenus: [{ name: 'Shorts' }, { name: 'Short Details' }],
    },
    {
      name: 'Series',
      submenus: [{ name: 'Series' }, { name: 'Seasons' }],
    },
    {
      name: 'Music',
      submenus: [{ name: 'Music' }, { name: 'Music Details' }],
    },
    {
      name: 'Podcasts',
      submenus: [{ name: 'Podcasts' }, { name: 'Podcast Details' }],
    },
    {
      name: 'Courses',
      submenus: [{ name: 'Courses' }, { name: 'Course Details' }],
    },
  ];

  const setActiveKeyLocal = (value: any) => {


    var page = application.screens.find((p: any) => p.type == value);
    if (page != undefined) {
      if (value == 'Shorts' || value == 'Movies' || value == 'TV Guide' || value == 'Series' || value == 'Music' || value == 'Courses' || value == 'Podcasts') {
        setActiveKeySubLocal(value);
      }
      setActiveKey(value);
      setPage(page);
    } else {
      application.screens.push({
        type: value,
        header: [] as any,
        hero: [] as any,
        content: [] as any,
        footer: [] as any,
      });
      setPage({
        type: value,
        header: [],
        hero: [],
        content: [],
        footer: [],
      });
    }
  };
  const setActiveKeySubLocal = (value: any) => {
    var page = application.screens.find((p: any) => p.type == value);
    if (page != undefined) {
      setActiveKeySub(value);
      setPage(page);
    } else {
      application.screens.push({
        type: value,
        header: [] as any,
        hero: [] as any,
        content: [] as any,
        footer: [] as any,
      });
      setPage({
        type: value,
        header: [],
        hero: [],
        content: [],
        footer: [],
      });
    }
  };
  return (
    <div className="container-fluid wrapper">
      <div className="row-md-12">
        <div className="row-md-12">
          <div className="col-md-12 mb-2">
            <h2 className="page-title mt-4 mb-5">
              {t('app-pages')}{application.branding?.name}/{application.branding?.packageid}{t('close-parenthesis')}
            </h2>
          </div>
          <MenuBuilder application={application} redirectUrl={redirectUrl} id={id}></MenuBuilder>
        </div>
        <div className="col-md-12 mb-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="card">
            <div className="row">
              <div className="col-12" style={{ minHeight: 500 }}>
                {isLoading && (
                  <div>
                    <div className="text-center  h-100 w-100" style={{ marginTop: 200 }}>
                      <div className="col-md-12 align-self-center">
                        <Spin size="large" spinning={true} />
                      </div>
                    </div>
                  </div>
                )}
                {!isLoading && (
                  <Tabs tabPosition="left" destroyInactiveTabPane={true} activeKey={activeKey} className="highlight-tab shadow" onChange={setActiveKeyLocal} defaultActiveKey={activeKey}>
                    {menus.map((menu: any) => {
                      return (
                        <TabPane
                          tab={
                            <div className="col text-center" style={{ width: 66, margin: 0, padding: 0, height: 50, paddingTop: 8 }}>
                              <div>
                                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={getIcon(menu?.name) as any} />
                              </div>
                              <div style={{ fontSize: 11, color: '#485Fe0', textAlign: 'center' }}>{menu?.name}</div>
                            </div>
                          }
                          key={menu?.name}
                        >
                          {activeKey === menu?.name && menu?.name != 'Recordings' && menu?.name != 'Shorts' && menu?.name != 'Movies' && menu?.name != 'Series' && menu?.name != 'Music' && menu?.name != 'Podcasts' && menu?.name != 'Courses' && menu?.name != 'TV Guide' && <Builder pageName={menu?.name} application={application} elements={[]} page={page} />}
                          {activeKey === menu?.name && (menu?.name == 'Recordings' || menu?.name == 'Shorts' || menu?.name == 'Movies' || menu?.name == 'Series' || menu?.name == 'Music' || menu?.name == 'Podcasts' || menu?.name == 'Courses' || menu?.name == 'TV Guide') && (
                            <Tabs destroyInactiveTabPane={true} activeKey={activeKeySub} className="highlight-tab mt-2" onChange={setActiveKeySubLocal} defaultActiveKey={activeKeySub}>
                              {subMenus
                                .find((s: any) => s.name == menu?.name)
                                ?.submenus.map((submenu: any, index: any) => {
                                  return (
                                    <TabPane
                                      key={submenu?.name}
                                      tab={
                                        <div className="col text-center" style={{ width: 140 }}>
                                          <div style={{ width: 120 }}>
                                            <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={getIcon(submenu?.name) as any} />
                                          </div>
                                          <div style={{ width: 120, fontSize: 11, color: '#485Fe0' }}>{submenu?.name}</div>
                                        </div>
                                      }
                                    >
                                      {activeKeySub === submenu?.name && <Builder pageName={submenu?.name} application={application} elements={[]} page={page} />}
                                    </TabPane>
                                  );
                                })}
                            </Tabs>
                          )}
                        </TabPane>
                      );
                    })}
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserInterfaceAppDetails;

import React from "react";
import { DeploymentWithPopulatedServices, PopulatedService } from '../services/deployments/deployment'

type CustomersContextType = {
  deployment : DeploymentWithPopulatedServices  | undefined
  service : PopulatedService | undefined
}

export const CustomersContext = React.createContext<CustomersContextType>({
  deployment: undefined,
  service: undefined
});

export const CustomersContextProvider = CustomersContext.Provider


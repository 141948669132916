import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { ReportingContext } from '../../../../contexts/reportingContext';
import Table from './Table';

const Designer = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = React.useContext(ReportingContext);

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('chart-design-designer')}</h2>
        </div>
        <div className="col-md-4" />
        <div className="col-md-2 d-flex justify-content-center justify-content-md-end align-items-start">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('chart-design-add-chart')}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table service={service} clientName={clientName} deployment={deployment} type={'chartdesigns'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Designer;

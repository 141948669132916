import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const DvrProgress = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid remove-hor-padding" style={{ height: 80 }}>
      <div style={{ gridAutoColumns: '100%', display: 'grid' }}>
        <div style={{ backgroundColor: '#999', borderRadius: 5, marginTop: 10 }}>
          <Skeleton.Node active={false} style={{ width: '100%', height: 60, backgroundColor: '#999' }}>
            <div className="col-sm remove-hor-padding text-left">
              <div style={{ backgroundColor: '#bfbfbf', height: 40, width: 500, marginLeft:10, borderTopLeftRadius:5, borderBottomLeftRadius:5 }}></div>
            </div>
          </Skeleton.Node>
        </div>
      </div>
    </div>
  );
};

export default DvrProgress;

import { Route, Switch, useRouteMatch } from 'react-router';
import { Messages } from './messages';
import { AddMessage } from './messages/add';
import { MessageDetails } from './messages/details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* 

      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><Bundles /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route> */}

      <Route path={`${path}/add`}>
        <AddMessage />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <MessageDetails />
      </Route>
      <Route path={`${path}`}>
        <Messages />
      </Route>
    </Switch>
  );
};

export default Index;

import { saveAs } from 'file-saver';

export function downloadExcel(fileName: string) {
  const filePath = process.env.PUBLIC_URL + `/excels/${fileName}`;
  fetch(filePath)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      saveAs(blob, fileName);
    })
    .catch((error) => {
      console.error(error);
    });
}

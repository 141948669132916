import { Button, Form, Input, Modal } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { MW_BASE_DB_CURRENCY } from '@mwaretv/database/build/backend/models/base/currency';
type IUpdateCurrencyModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: any;
};

const CurrencyModal = (props: IUpdateCurrencyModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const [isFetchingData] = useState<boolean>(false);
  const {t} = useTranslation();
  useEffectOnce(() => {
    form.setFieldsValue(initialData);
  });
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('update-credits')}</h4>
            {!isFetchingData && (
              <Form form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
                <label className="col-form-label">{t('credits-rented')}</label>
                <Form.Item
                  extra={t('set-to-0-if-no-renting-should-be-allowed')}
                  name="credits_rented"
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('credits-are-required'),
                    },
                    {
                      validator: (rule, value) => {
                        if (!/^[0-9]+$/.test(value.toString())) return Promise.reject(new Error(t('value-must-be-a-number')));
                        let numeric_value: number = typeof value === 'string' ? parseInt(value) : value;
                        if (numeric_value >= 0) return Promise.resolve();
                        else return Promise.reject(new Error(t('entered-value-cant-be-less-than-zero')));
                      },
                    },
                  ]}
                >
                  <Input min={0} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-credits-value')} />
                </Form.Item>
                <label className="col-form-label">{t('credits-purchased')}</label>
                <Form.Item
                  extra={t('set-to-0-if-no-purchased-should-be-allowed')}
                  name="credits_purchased"
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('credits-are-required'),
                    },
                    {
                      validator: (rule, value) => {
                        if (!/^[0-9]+$/.test(value.toString())) return Promise.reject(new Error(t('value-must-be-a-number')));
                        let numeric_value: number = typeof value === 'string' ? parseInt(value) : value;
                        if (numeric_value >= 0) return Promise.resolve();
                        else return Promise.reject(new Error(t('entered-value-cant-be-less-than-zero')));
                      },
                    },
                  ]}
                >
                  <Input min={0} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-credits-value')} />
                </Form.Item>
                <Form.Item name="iso3">
                  <Input value={initialData.iso3} hidden></Input>
                </Form.Item>

                <div className="col-md-12 p-0">
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-credits')}
                  </Button>
                </div>
              </Form>
            )}
            {isFetchingData && (
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Spin size="large" spinning={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CurrencyModal;

import appService from '../../../../services/areas/appbuilder/applications/application';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';

export const setThemeColors = (data, theme) => {
  
  const themes = Object.values(theme) as any;
  var newTheme = [] as any;
  var backgroundColors = findNestedObj(data, 'backgroundColor');
  backgroundColors.forEach((color) => {
    var test = themes.find((t) => t.color == color);
    if (test == undefined) {
      var test2 = newTheme.find((t) => t.color == color);
      if (test2 == undefined) {
        newTheme.push({
          color: color,
        });
      }
    }
  });
  var borderColors = findNestedObj(data, 'borderColor');
  borderColors.forEach((color) => {
    var test = themes.find((t) => t.color == color);
    if (test == undefined) {
      var test2 = newTheme.find((t) => t.color == color);
      if (test2 == undefined) {
        newTheme.push({
          color: color,
        });
      }
    }
  });
  var out = {
    ...theme,
    ...newTheme,
  };

  return out;
};

function findNestedObj(entireObj, keyToFind) {
  var foundObj = [] as any;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (typeof nestedValue !== 'object') {
      var test = nestedValue + '';
      if (test.indexOf('#') > -1) {
        foundObj.push(nestedValue);
      }
    }
    return nestedValue;
  });
  return foundObj;
}

export const updateTheme = async (theme: any, application_: any, deployment: any, clientName: any, id: any) => {
  application_ = {
    ...application_,
    settings: {
      ...application_.settings,
      theme: Object.values(theme),
    },
  };
  // await appService.Update_Application({
  //   query_params: {
  //     cms: deployment?.name ?? '',
  //     instance: clientName,
  //     path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', id).APPBUILDER.APP,
  //   },
  //   _id: id,
  //   client: clientName,
  //   deployment: deployment?.name ?? '',
  //   ...application_,
  // });
};

import { IClientInfo } from "../../interfaces/responses/clients/IClientInfoResponse";
import { IRoute } from "../../routes/AllAreaRoutes";
import { DrawerActions, DrawerActionTypes } from "./action-types";
export type DrawerState = {
  is_sidebar_visible: boolean;
  client_info?: IClientInfo;
  is_client_info_loading: boolean;
  routes?: IRoute[];
  defaultSelectedKey?: string;
  defaultOpenKey?: string;
  is_collapsed: boolean;
  parent_service_name: string;
  service_type: string;
  service_name: string;
  client_dashboard_path: string;
  drawer_type: "permanent" | "temporary";
  white_labeling: {
    logo: string;
    fav_icon: string;
  };
};

export const initialDrawerState: DrawerState = {
  is_client_info_loading: false,
  is_sidebar_visible: false,
  routes: [],
  defaultSelectedKey: "",
  defaultOpenKey: "",
  parent_service_name: "",
  service_type: "",
  service_name: "",
  client_dashboard_path: "",
  is_collapsed: false,
  drawer_type: "permanent",
  white_labeling: {
    logo: "",
    fav_icon: "",
  },
};

const DrawerReducer = (state: DrawerState, action: DrawerActions) => {
  switch (action.type) {
    case DrawerActionTypes.TOGGLE_DRAWER:
      return {
        ...state,
        is_collapsed: !state.is_collapsed,
        drawer_type: "temporary",
      } as DrawerState;
    case DrawerActionTypes.PERMANENT_DRAWER:
      return {
        ...state,
        is_collapsed: false,
        drawer_type: "permanent",
      } as DrawerState;
    case DrawerActionTypes.SET_PARENT_SERVICE_INFO:
      return {
        ...state,
        parent_service_name: action.parent_service_name,
      } as DrawerState;
    case DrawerActionTypes.SET_SIDEBAR_ROUTES:
      return {
        ...state,
        routes: action.routes,
        defaultSelectedKey: action.default_selected_key,
        defaultOpenKey: action.default_open_key,
        is_sidebar_visible: action.routes && action.routes.length > 0,
        service_name: action.service_name,
        service_type: action.service_type,
        client_dashboard_path: action.client_dashboard_path,
      } as DrawerState;
    case DrawerActionTypes.SET_CLIENT_INFO:
      return {
        ...state,
        client_info: action.client_info,
      } as DrawerState;
    case DrawerActionTypes.SET_CLIENT_INFO_LOADING:
      return {
        ...state,
        is_client_info_loading: action.is_client_info_loading,
      } as DrawerState;
    case DrawerActionTypes.SET_LATEST_OPEN_KEY:
      return {
        ...state,
        defaultOpenKey: action.default_open_key,
      } as DrawerState;
    case DrawerActionTypes.UPDATE_WHITE_LABEL_INFO:
      return {
        ...state,
        white_labeling: {
          fav_icon: action.fav_icon ?? state.white_labeling.fav_icon,
          logo: action.logo ?? state.white_labeling.logo,
        },
      } as DrawerState;
    default:
      return state;
  }
};
export default DrawerReducer;

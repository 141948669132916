import { Shorts_Bundles } from '../../../../../interfaces/forms/areas/content/shorts/bundles';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Shorts_Bundles as Response } from '../../../../../interfaces/responses/areas/content/shorts/bundles';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Shorts_Bundles_Service {
  Add_Shorts_Bundles = async (data: Shorts_Bundles): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.PACKAGES.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Shorts_Bundless = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.PACKAGES.LIST, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Shorts_Bundles = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SHORTS.PACKAGES.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Shorts_Bundles = async (data: Shorts_Bundles): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SHORTS.PACKAGES.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Shorts_Bundles = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SHORTS.PACKAGES.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Publish_Shorts_Bundles = async (data: Shorts_Bundles): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SHORTS.PACKAGES.PUBLISH}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Shorts_Bundles_Service();

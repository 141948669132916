import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const SettingsFields = ({ show = true}: { show?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: show ? 'block' : 'none' }} className="card-bg-light p-4 mb-5">
      <div className="p-4">
        <h4 className="text-primary">Akamai</h4>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('group-id-livetv-streaming')}</label>
          <Form.Item name="akamai_cp_code_cdn" className="col-md-5">
            <Input className="form-control bg-white font-size-14 text-default" placeholder={t('group-id')} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('group-id-storage')}</label>
          <Form.Item name="akamai_cp_code_storage" className="col-md-5">
            <Input className="form-control bg-white font-size-14 text-default" placeholder={t('group-id')} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('group-id-vod-streaming')}</label>
          <Form.Item name="akamai_cp_code" className="col-md-5">
            <Input className="form-control bg-white font-size-14 text-default" placeholder={t('group-id')} />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default SettingsFields;

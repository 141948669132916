import { Button, Form, Input, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { ContentContext } from '../../../../../contexts/contentContext';
import { Radio_Settings } from '../../../../../interfaces/forms/areas/content/radio/settings';
import cmsService from '../../../../../services/areas/content/radio/settings';
import { IMSNameParams } from '../../../../dashboard';

const AddNewMusicAlbum = () => {
  const history = useHistory();
  const params = useParams<IMSNameParams & { deployment: string }>();
  const [isAdding, setIsAdding] = useState(false);
  const [settingsId, setSettingsId] = useState('');
  const [form] = useForm();
const { t } = useTranslation();
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);

  const addSettings = async (values: Radio_Settings) => {
    setIsAdding(true);
    values = {
      ...values,
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
    };
    
    let response = await cmsService.Manage_Settings(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  const getSettings = async () => {
    setIsAdding(true);
    var values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Get_Settings(values as any);
    if (response !== undefined) {
      var resp = response as any;
      form.setFieldsValue(resp);
      setSettingsId(resp._id);
    }
    setIsAdding(false);
  };

  useEffectOnce(() => {
    (async () => await getSettings())();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6">
          <h2 className="page-title">{t('radio-settings')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('manage-your-radio-settings-for-interactivetv')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          addSettings(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5 pt-5 mt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('catchuptv-retention-days')}</label>
                <p>{t('number-of-days-your-customers-can-watch-back-on-catchuptv-be-sure-not-to-exceed-the-number-of-days-you-set-as-the-guide-retention-setting-in-guide-settings')}</p>
                <Form.Item extra={t('this-value-is-to-set-how-many-days-in-the-past-your-customers-have-access-to-the-catchuptv-service')} className="col-md-5" name="catchuptv_retention">
                  <Input defaultValue={0} type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="7" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('recordings-retention-days')}</label>
                <Form.Item
                  extra={t('this-value-is-to-set-how-many-days-the-set-recordings-by-your-customers-will-be-saved-on-the-servers')}
                  className="col-md-5"
                  name="recording_retention"
                  rules={[
                    {
                      required: true,
                      message: t('n-a-text-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder="7" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-update-settings')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;

import { Route, Switch, useRouteMatch } from 'react-router';
import ProductList from './list'
import AddProduct from './add'
import ProductDetails from './details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><AddProduct /></Route>
      <Route path={`${path}/:id`}><ProductDetails /></Route>
      <Route path={`${path}`}><ProductList /></Route>
    </Switch>
  )
}

export default Index


import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator } from '../../../../../../../../utils/validators';

const ServiceName = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="card mt-3">
        <div className="card-body">
          <div className="card-bg-light pl-5 pt-3 pb-5">
            <div className="row ml-3">
              <div className="col-md-10 ml-3">
                <h5 className="text-primary">{t('service-name')}</h5>
                <p>{t('set-the-name-for-the-service-the-name-should-be-similar-to-my-content-unique-and-not-longer-than-15-chars')}</p>
              </div>
            </div>
            <div className="row ml-5 mt-5">
              <label className="col-md-3 col-form-label">{t('service-name')}</label>
              <Form.Item
                name="crm_name"
                className="col-md-4 p-0"
                rules={[
                  { required: true, message: t('service-name-is-required') },
                  { max: 12, message: t('please-choose-a-shorter-name') },
                  { validator: capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator, message: t('service-name-can-only-contain-alphanumeric-characters-and-hyphens-a-z-a-z-zero-nine') },
                ]}
              >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('service-name')} />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceName;

import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification, Form as AntdForm, Typography, Col } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button, Form, Modal, Table, Tooltip } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { BillingContext } from '../../../../contexts/billingContext';
import { PaymentProvider } from '../../../../interfaces/forms/areas/billing/paymentProvider';
import { usePaymentProviderDelete, usePaymentProviderPut, usePaymentProviders } from '../../../../services/areas/billing/paymentProviderService';
import { IMSNameParams } from '../../../dashboard';
import { PaymentMethodFields } from './shared/form';
import { Spin } from '../../../../common/components/spinner';
import { usePaymentProviderSchemas } from './paymentProviderSchemas';
import { useTranslation } from 'react-i18next';

const MyGatewaysList = () => {
  const {t} = useTranslation();
  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('my-payment-gateways')} subtitle={t('overview-of-all-your-configured-payment-gateways')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <GatewayTable />
        </div>
      </div>
    </ContainerWrapper>
  );
};

const GatewayTable = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const {t} = useTranslation();
  const { isLoading, isError, data, refetch } = usePaymentProviders({
    params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', ...pagination },
  });

  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong-please-try-again-later')}</span>}
      {!isLoading && !isError && (
        <Table
          dataSource={data.data.docs}
          columns={[
            { title: t('name'), dataIndex: 'name', width: '25%' },
            {
              title: t('base-provider'),
              width: '25%',
              render: (row) => <span>{row.payment_provider_id}</span>,
            },
            // {
            //   title: 'Enabled',
            //   width: '25%',
            //   render: (row) => <span>{row.enabled ? 'Yes' : 'No'}</span>,
            // },
            { title: t('environment'), dataIndex: 'environment', width: '25%' },
            {
              title: t('actions'),

              render: (row) => <TableActions {...{ row }} onChangesMade={refetch} />,
            },
          ]}
          pagination={{
            onChange: (page, pageSize) => setPagination({ page, limit: pageSize }),
          }}
        />
      )}
    </>
  );
};

type TableActionsProps = {
  row: any;
  onChangesMade: () => void;
};

const TableActions = ({ row, onChangesMade }: TableActionsProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(BillingContext);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const {t} = useTranslation();
  const deleteMutation = usePaymentProviderDelete(
    { params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('payment-gateway-deleted') });
        onChangesMade();
      },
      onError: () => {
        notification.error({
          message: t('payment-gateway-could-not-be-deleted-please-try-again-later')
        });
      },
    }
  );

  return (
    <>
      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
        <Tooltip placement="top" title={t('delete')}>
          <Button
            type="text"
            className="mx-2"
            disabled={deleteMutation.isLoading}
            onClick={() => {
              deleteMutation.mutate(row._id);
            }}
          >
            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
            <div>{t('delete')}</div>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title={t('details')}>
          <Button type="text" className="mx-2" disabled={deleteMutation.isLoading} onClick={() => setModalOpen(true)}>
            <FontAwesomeIcon className="action-icon" icon={faMemo} />
            <div>{t('open-details')}</div>
          </Button>
        </Tooltip>
      </Col>
      <ProviderModal {...{ providerData: row, onChangesMade }} open={modalOpen} onCancel={() => setModalOpen(false)} />
    </>
  );
};

type ProviderModaldProps = {
  providerData: PaymentProvider;
  open: boolean;
  onCancel: () => void;
  onChangesMade: () => void;
};

export const ProviderModal = ({ providerData, open, onCancel, onChangesMade }: ProviderModaldProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const { data: paymentProviders } = usePaymentProviderSchemas();
  const provider = paymentProviders != null ? paymentProviders.find((x) => x.id === providerData.payment_provider_id) : null;
  const [form] = AntdForm.useForm();
  const {t} = useTranslation();
  const { isLoading, mutate } = usePaymentProviderPut(
    {
      params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' },
      id: providerData._id || '',
    },
    {
      onSuccess: () => {
        notification.success({ message: t('payment-gateway-updated') });
        onCancel();
      },
      onError: () => {
        notification.error({
          message: t('payment-gateway-could-not-be-updated-please-try-again-later'),
        });
      },
    }
  );

  const getProviderLogo = (logo: string) => {
    if (logo.includes('localhost')) {
      return new URL(new URL(logo).pathname, process.env.REACT_APP_BACKEND_API_BASE_URL).href;
    } else {
      return logo;
    }
  };

  return (
    <Modal
      {...{ open, onCancel }}
      width={1000}
      onOk={() => {
        form.submit();
      }}
    >
      <div className="container">
        <div className="row mt-3">
          <div className="col col-lg-6">
            <div className="card-bg-light pl-5 pt-3 mt-2">
              <img height={75} src={provider ? getProviderLogo(provider.logo) : undefined} alt={provider?.name} />
              <Typography.Title level={5} className="mt-2">
                {t('setup-information')}
              </Typography.Title>
              {(provider?.infotext as string)?.split('\n').map((text) => (
                <p className="mt-3">{text}</p>
              ))}
            </div>
            <div className="card-bg-light pl-5 pt-3 mt-2 pb-3">
              <Typography.Title level={5} className="mt-3">
                {t('payment-methods')}
              </Typography.Title>
              <div>
                {provider?.methods.map((method, i) => (
                  <img key={i} className="mr-2" height={48} src={getProviderLogo(method.logo)} alt={method?.name} />
                ))}
              </div>
            </div>
            <div className="card-bg-light pl-5 pt-3 mt-2 pb-3">
              <Typography.Title level={5} className="mt-3">
                {t('regions')}
              </Typography.Title>
              <ul>
                {provider?.regions.map((region, i) => (
                  <li key={i}>{region}</li>
                ))}
              </ul>
            </div>
            <div className="card-bg-light pl-5 pt-3 mt-2">
              <Typography.Title level={5} className="mt-3">
                {t('supported-currencies')}
              </Typography.Title>

              <ul>{provider?.currencies === 'Any'? provider?.currencies : provider?.currencies.map((country, i) => <li key={i}>{country}</li>)}</ul>
            </div>

            <div className="card-bg-light pl-5 pt-3 mt-2 pb-4">
              <Typography.Title level={5} className="mt-2">
                {t('more-info')}
              </Typography.Title>
              <a target="_blank" rel="noreferrer" href={provider?.url || ''}>
                {t('visit-website')}
              </a>
            </div>
          </div>
          <div className="col col-lg-6">
            <Form
              {...{ form }}
              layout="vertical"
              initialValues={providerData}
              disabled={isLoading}
              onFinish={(values) => {
                mutate(values);
                onChangesMade();
                onCancel();
              }}
            >
              <PaymentMethodFields />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MyGatewaysList;

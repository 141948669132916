import { Typography } from 'antd';
import { downloadExcel } from './downloadExcel';

export const DownloadLink = ({ fileName, displayName }) => {
  return (
    <Typography.Link target="_blank" style={{ color: '#485Fe0' }} onClick={() => downloadExcel(fileName)}>
      {displayName}
    </Typography.Link>
  );
};

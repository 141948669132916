import { ApiBuilder } from '../../../../interfaces/forms/areas/billing/apibuilder/apibuilder';
import { ApiBuilder as Response } from '../../../../interfaces/responses/areas/billing/apibuilder/apibuilder';
import { IBaseResponse } from '../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';
import { IABP_Lists } from '../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class ApiBuilderService {
  Add_ApiBuilder = async (data: ApiBuilder): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.BILLING.APIBUILDER.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_ApiBuilder = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.BILLING.APIBUILDER.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_ApiBuilder = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.BILLING.APIBUILDER.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_ApiBuilder = async (data: ApiBuilder): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.BILLING.APIBUILDER.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_ApiBuilder = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.BILLING.APIBUILDER.DELETE}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new ApiBuilderService();

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AddChartDesign from '../chartdesigns/add';
import DetailsChartDesign from '../chartdesigns/details';
import Designer from './designer';

const DesignerIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <AddChartDesign />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <DetailsChartDesign />
      </Route>
      <Route path={`${path}`}>
        <Designer />
      </Route>
    </Switch>

  );
};

export default DesignerIndex;

import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../..';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

export function Scope() {
  const { state: AuthState } = useContext(AuthContext);
  const { clientName } = useParams<IMSNameParams>();
  const [client, setClient] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();
  
  useEffectOnce(() => {
    setClient(AuthState.user?.clients?.find((c) => c.name == clientName));
    setIsLoading(false);
  });

  const showCard = () => {
    if ((client?.scope?.target != undefined && client?.scope?.target != '') || (client?.scope?.duration != undefined && client?.scope?.duration != '') || (client?.scope?.how != undefined && client?.scope?.how != '') || (client?.scope?.softlaunch != undefined && client?.scope?.softlaunch != '') || (client?.scope?.live != undefined && client?.scope?.live != '')) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {!isLoading && (
        <div className="row">
          {showCard() == true && (
            <div className="col-12 mb-4">
              <Card hoverable className="p-0" bodyStyle={{ padding: '0px' }}>
                <div className="py-4 p-4 border-left border-secondary border-width-4">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-8 pl-xl-5 mt-3 mt-lg-0">
                      <h4 className="text-primary">{t('client-scope')}</h4>
                      <p className="h6 font-weight-normal line-height-26" style={{ color: '#888' }}>
                        {t('the-client-scope-shows-agreements-made-and-signed-via-the-sow-document')}
                      </p>
                      <div style={{ fontSize: 12 }}>
                        {client?.scope?.target != undefined && client?.scope?.target != '' && <div>{t('expected-target')} <b>{client?.scope?.target}</b></div>}
                        {client?.scope?.duration != undefined && client?.scope?.duration != '' && <div>{t('expected-deadline-for-the-target')} <b>{client?.scope?.duration}</b></div>}
                        {client?.scope?.how != undefined && client?.scope?.how != '' && <div>{t('general-business-case')} <b>{client?.scope?.how}</b></div>}
                        {client?.scope?.softlaunch != undefined && client?.scope?.softlaunch != '' && <div>{t('expected-softlaunch-date')} <b>{client?.scope?.softlaunch}</b></div>}
                        {client?.scope?.live != undefined && client?.scope?.live != '' && <div>{t('expected-live-date')} <b>{client?.scope?.live}</b></div>}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      )}
    </>
  );
}

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';
import App from './App';
import './index.css';
import { createRoot } from 'react-dom/client';

//import reportWebVitals from "./reportWebVitals";

//ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

Bugsnag.start({
  apiKey: '78536eaf500d67f5852d19bac9ea3e2f',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
});

BugsnagPerformance.start({ apiKey: '78536eaf500d67f5852d19bac9ea3e2f' });
class NoopErrorBoundary extends React.Component {
  // define your Noop Error Boundary here
}
var ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? NoopErrorBoundary;

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

import { useTranslation } from 'react-i18next';
import { WeFactProduct } from '../../../../../../interfaces/responses/clients/weFactProduct';
import { IReactBCTableColumn } from '../../../../../../common/components/ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { Button, Col, Row, Tooltip } from 'antd';
import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactBCTable from '../../../../../../common/components/ReactBCTable';
import { PaginatedAndFilterResponseDefaultValues } from '../../../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import { useState } from 'react';
import ProductModal from './productModal';
import dayjs from 'dayjs';
import { Product } from '../../../../../../interfaces/forms/clients/clients';

const ServicesFields = ({ weFactProducts, selectedProducts, setSelectedProducts, show = true}: { weFactProducts: WeFactProduct[], selectedProducts: Product[], setSelectedProducts: (selectedProducts: Product[]) => void, show?: boolean }) => {
  const { t } = useTranslation();
  const [showProductModal, setShowProductModal] = useState(false);
  const [productCodeToBeEdited, setProductCodeToBeEdited] = useState<string | undefined>(undefined);

  const removeProduct = (code: string) => {
    selectedProducts = selectedProducts.filter(selectedProduct => selectedProduct.code !== code);
    setSelectedProducts(selectedProducts);
  };

  const updateSelectedProducts = (product: Product) => {
    if (!product) {
      return;
    }

    const domainProduct = convertFormProductToDomainProduct(product);

    if (!domainProduct) {
      return;
    }

    if (productCodeToBeEdited) {
      selectedProducts = selectedProducts.filter(selectedProduct => selectedProduct.code !== productCodeToBeEdited);
      selectedProducts = selectedProducts.concat(domainProduct);
      setSelectedProducts(selectedProducts);
    }
    else {
      selectedProducts = selectedProducts.concat(domainProduct);
      setSelectedProducts(selectedProducts);
    }
    setProductCodeToBeEdited(undefined);
    setShowProductModal(false);
  };

  const convertFormProductToDomainProduct = (product: Product): Product | undefined => {
    if (!product) {
      return;
    }

    const domainProduct: Product = {
      code: product.code,
      name: product.name,
      startDate: dayjs(product.startDateDayjsFormat).unix(),
      price: product.price,
      pricePeriod: product.pricePeriod,
      currency: product.currency,
      taxRate: product.taxRate,
      minimumOrderQuantity: product.minimumOrderQuantity,
      recurring: product.recurring,
    } as Product;

    return domainProduct;

  }

  const columns: IReactBCTableColumn<any>[] = [
    {
      title: t('name'),
      dataKey: 'name',
      width: '50%',
    },
    {
      title: t('code'),
      dataKey: 'code',
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      width: '15%',
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
            <Row className="align-items-center">
              <div onClick={() => {
                removeProduct(row.code);
              }}>
                <Tooltip placement="top" title={t('delete')}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                    <div>{t('delete')}</div>
                  </Button>
                </Tooltip>
              </div>
            </Row>
            <Row className="align-items-center">
              <div onClick={() => {
                setProductCodeToBeEdited(row.code);
                setShowProductModal(true);
              }}>
                <Tooltip placement="top" title={t('open-details')}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </Tooltip>
              </div>
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <div style={{ display: show ? 'block' : 'none' }} className="card-bg-light p-4 mb-5">
      <div className="p-4">
        <h4 className="text-primary">{t('products')}</h4>
        <div className="row">
          <div className="col-md-12">
            <div className="row float-right">
              <Button className="btn btn-secondary rounded-pill btn-default float-right height-48" onClick={() => {
                setProductCodeToBeEdited(undefined);
                setShowProductModal(true);
              }}>
                {t('add-product')}
              </Button>
            </div>
          </div>
        </div>
        <ReactBCTable isLoading={false} totalRecords={PaginatedAndFilterResponseDefaultValues.count} pageSize={PaginatedAndFilterResponseDefaultValues.pageSize} currentPage={PaginatedAndFilterResponseDefaultValues.pageNumber} tableId={'cms_table'} columns={columns} data={selectedProducts} />
        {showProductModal &&
          <ProductModal
            isOpen={showProductModal}
            setIsOpen={setShowProductModal}
            availableWeFactProducts={weFactProducts.filter(weFactProduct => !selectedProducts.map(selectedProduct => selectedProduct.code).some(productCode => productCode === weFactProduct.code))}
            productToBeEdited={selectedProducts.find(selectedProduct => selectedProduct.code === productCodeToBeEdited)}
            updateSelectedProducts={updateSelectedProducts}
          />
        }
        {/* <div className="row">
          <label className="col-md-3 col-form-label">CDN</label>
          <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'cdn']}>
            <Switch className="ch-switch" defaultChecked={false} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('storage')}</label>
          <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'storage']}>
            <Switch className="ch-switch" defaultChecked={false} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">Billing</label>
          <Form.Item
            className="col-md-5"
            valuePropName="checked"
            name={["products", "billing"]}>
            <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch3(!switch3)} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">Flussonic</label>
          <Form.Item
            className="col-md-5"
            valuePropName="checked"
            name={["products", "flussonic"]}>
            <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch4(!switch4)} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('streaming-support')}</label>
          <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'streaming_support']}>
            <Switch className="ch-switch" defaultChecked={false} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('managed-service')}</label>
          <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'managed_service']}>
            <Switch className="ch-switch" defaultChecked={false} />
          </Form.Item>
        </div> */}
      </div>
    </div>
  );
};

export default ServicesFields;

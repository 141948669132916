import React, { useContext, useState } from 'react';
import { notification, Tabs } from 'antd';
import { IMSNameParams } from '../../../dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as WalletService from '../../../../services/areas/billing/walletService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { Wallet } from '../../../../interfaces/forms/areas/billing/wallets/wallet';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { Form, ExternalFields, PlanFields } from './shared/form';
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';

const WalletDetails: React.FC = () => {
  const { id } = useParams<IMSNameParams & { id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const { url } = useRouteMatch();
  const {t} = useTranslation();

  const { isLoading, isError, data } = useQuery(
    ['wallet', id],
    async () => {
      return (
        await WalletService.get({
          params: {
            cms: deployment?.name || '',
            crm: service?.name || '',
            instance: clientName,
          },
          id,
        })
      ).data;
    },
    { retry: 3 }
  );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-wallet-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={data ? t('details-data-name', { dataName: data.name }) : t('update-wallet')} subtitle={t('update-an-existing-wallet')} />
        </div>
      </div>
      {isError && <Error />}
      {isLoading && <Loading />}
      {!isLoading && data && <Details {...{ data }} />}
    </ContainerWrapper>
  );
};

const Details = ({ data }: { data: Wallet }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();
  const { mutate, isLoading } = useMutation(
    (values: Partial<Wallet>) => {
      return WalletService.patch({ params: { instance: clientName, crm: service?.name || '', cms: deployment?.name || '' }, data: values, id: data._id });
    },
    {
      
      onSuccess: (data) => {
        notification.success({ message: t('wallet-package-updated')});
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-updating-the-wallet') });
      },
    }
  );

  const [generalForm] = useForm<Partial<Wallet>>();
  const [externalForm] = useForm<Partial<Wallet>>();
  const [planForm] = useForm<Partial<Wallet>>();

  const [activeTab, setActiveTab] = useState<string>('general');

  return (
    <div className="col-12  mt-3">
      <Tabs
        activeKey={activeTab}
        className="default-tab"
        onChange={(activeKey: string) => setActiveTab(activeKey)}
        items={[
          {
            label: t('tiers'),
            key: 'tiers',
            children: (
              <Form form={planForm} disabled={isLoading} onFinish={(values: Partial<Wallet>) => mutate(values)} initialValues={data}>
                <PlanFields />
              </Form>
            ),
          },
          {
            label: t('external'),
            key: 'external',
            children: (
              <Form form={externalForm} disabled={isLoading} onFinish={(values: Partial<Wallet>) => mutate(values)} initialValues={data}>
                <ExternalFields />
              </Form>
            ),
          },
        ]}
      />
    </div>
  );
};

const Loading = () => {
  return (
    <div className="row text-center h-100 w-100">
      <div className="col-12 align-self-center">
        <Spin size="large" spinning={true} />
      </div>
    </div>
  );
};

const Error = () => {
  const {t} = useTranslation();
  return <div>{t('something-went-wrong-sad-smiley')}</div>;
};

export default WalletDetails;

import { useContext, useEffect, useState } from 'react';
import { Card } from 'antd';
import { IMSNameParams } from '../../../dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import * as TransactionService from '../../../../services/areas/billing/transactionService';
import * as CustomerService from '../../../../services/areas/customers/customerService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { useQuery } from '@tanstack/react-query';
import { Item } from '../../../../common/components/form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TransactionReporting } from '@mwaretv/database/build/backend/models/reporting/transactions/transaction';
import { TransactionType } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';
import { Customer } from '../../../../interfaces/forms/areas/customers/customers/customer';

export const TransactionDetails = () => {
  const { id } = useParams<IMSNameParams & { id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const { url } = useRouteMatch();
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const {t} = useTranslation();

  const { isLoading, isError, data } = useQuery(
    ['transaction', id],
    async () => {
      return (
        await TransactionService.get({
          params: {
            cms: deployment?.name ?? '',
            crm: service?.name || '',
            instance: clientName,
          },
          id,
        })
      ).data;
    },
    { retry: 3 }
  );

  useEffect(() => {
    const fetchCustomer = async () => {
      if (data?.customer?.domainId) {
        const response = await CustomerService.getCustomerForTransaction(clientName, deployment?.name ?? '', service?.name ?? '', data?.customer?.domainId);
        setCustomer(response?.data);
      }
    };
    fetchCustomer();
  }, [clientName, data?.customer?.domainId, deployment?.name, service?.name]);

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-transaction-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mt-3 mb-3">
          <PageTitle title={t('transaction-details')} subtitle={''} />
        </div>
      </div>
      {isError && <Error />}
      {isLoading && <Loading />}
      {!isLoading && data && <Details {...{ data, customer }} />}
    </ContainerWrapper>
  );
};

const Details = ({ data, customer }: { data: TransactionReporting, customer?: Customer }) => {
  const {t} = useTranslation();
  return (
    <>
      <Card>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
              <Item className="col-md-12" label={t('timestamp')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.date_unix ? moment.unix(data.date_unix).format('lll') : undefined}</div>
              </Item>
              <Item className="col-md-12" label={t('transaction-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.transaction_subtype}</div>
              </Item>
              <Item className="col-md-12" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.source}</div>
              </Item>
            </div>
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('financial')}</h4>
              <Item className="col-md-12" label={t('amount-excluding-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.amount_ex_vat?.toFixed(2)}</div>
              </Item>
              <Item className="col-md-12" label={t('amount-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.amount_vat?.toFixed(2)}</div>
              </Item>
              <Item className="col-md-12" label={t('amount-including-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.amount_inc_vat?.toFixed(2)}</div>
              </Item>
              <Item className="col-md-12" label={t('payment-provider')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.payment_provider}</div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
      {customer &&
      <Card style={{ marginTop: 16 }}>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{t('customer')}</h4>
              <Item className="col-md-12" label={t('first-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{customer?.first_name}</div>
              </Item>
              <Item className="col-md-12" label={t('last-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{customer?.last_name}</div>
              </Item>
              <Item className="col-md-12" label={t('username')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{customer?.username}</div>
              </Item>
              <Item className="col-md-12" label={t('email')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{customer?.email}</div>
              </Item>
              <Item className="col-md-12" label={t('account-type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{customer?.account_type}</div>
              </Item>
              <Item className="col-md-12" label={t('status')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{customer?.status}</div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
      }
      {data.transaction_maintype === TransactionType.SUBSCRIPTION &&
      <Card style={{ marginTop: 16 }}>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{data.subscription?.name}</h4>
              <Item className="col-md-12" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.subscription?.type}</div>
              </Item>
              <Item className="col-md-12" label={t('variant')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.subscription?.variant}</div>
              </Item>
              <Item className="col-md-12" label={t('plan-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.plan?.name}</div>
              </Item>
              <Item className="col-md-12" label={t('duration-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.plan?.duration}</div>
              </Item>
              <Item className="col-md-12" label={t('duration-period-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.customer?.plan?.duration_period_in ? t(data.customer?.plan?.duration_period_in) : undefined}</div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
      }
      {data.transaction_maintype === TransactionType.PAYPERVIEW &&
      <Card style={{ marginTop: 16 }}>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5  pb-4  mb-3">
              <h4 className="text-primary mt-5 mb-3">{data.payperview?.content?.name}</h4>
              <Item className="col-md-12" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <div style={{ fontWeight: 'bold' }}>{data.payperview?.content?.mainType}</div>
              </Item>
            </div>
          </div>
        </div>
      </Card>
      }
    </>
  );
};

const Loading = () => {
  return (
    <div className="row text-center h-100 w-100">
      <div className="col-12 align-self-center">
        <Spin size="large" spinning={true} />
      </div>
    </div>
  );
};

const Error = () => {
  const {t} = useTranslation();
  return <div>{t('something-went-wrong-sad-smiley')}</div>;
};

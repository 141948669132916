import { Route, Switch, useRouteMatch } from 'react-router';
import Taxes from './taxes';
import SMSTemplates from './smsTemplates';
import EmailTemplates from './emailTemplates';
import AppTemplates from './appTemplates';
import Renewals from './renewals';
import Registration from './registration';
import Dunning from './dunning';
import Promotions from './promotions';
import SubscriptionRules from './subscriptionRules';
import WelcomeMessage from './welcomeMessage';
import PasswordRecovery from './passwordRecovery';
import Translations from './translations';
import PaymentReceivedMessage from './paymentReceivedMessage';
import ChangeBaseSubscriptionMessage from './changeBaseSubscriptionMessage';
import AddExtraSubscriptionMessage from './addExtraSubscriptionMessage';
import RemoveExtraSubscriptionMessage from './removeExtraSubscriptionMessage';
import PaymentFailedMessage from './paymentFailedMessage';
import RenewedSubscriptionMessage from './renewedSubscriptionMessage';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/registration`}>
        <Registration />
      </Route>
      <Route path={`${path}/welcome-message`}>
        <WelcomeMessage />
      </Route>
      <Route path={`${path}/password-recovery-message`}>
        <PasswordRecovery />
      </Route>
      <Route path={`${path}/renewals`}>
        <Renewals />
      </Route>
      <Route path={`${path}/dunning`}>
        <Dunning />
      </Route>
      <Route path={`${path}/promotions`}>
        <Promotions />
      </Route>
      <Route path={`${path}/payment-received-message`}>
        <PaymentReceivedMessage />
      </Route>
      <Route path={`${path}/payment-failed-message`}>
        <PaymentFailedMessage />
      </Route>
      <Route path={`${path}/renewed-subscription-message`}>
        <RenewedSubscriptionMessage />
      </Route>
      <Route path={`${path}/change-base-subscription-message`}>
        <ChangeBaseSubscriptionMessage />
      </Route>
      <Route path={`${path}/add-extra-subscription-message`}>
        <AddExtraSubscriptionMessage />
      </Route>
      <Route path={`${path}/remove-extra-subscription-message`}>
        <RemoveExtraSubscriptionMessage />
      </Route>
      <Route path={`${path}/email-templates`}>
        <EmailTemplates />
      </Route>
      <Route path={`${path}/app-templates`}>
        <AppTemplates />
      </Route>
      <Route path={`${path}/sms-templates`}>
        <SMSTemplates />
      </Route>
      <Route path={`${path}/taxes`}>
        <Taxes />
      </Route>
      <Route path={`${path}/translations`}>
        <Translations />
      </Route>
      <Route path={`${path}/subscription-rules`}>
        <SubscriptionRules />
      </Route>
      <Route path={`${path}`}>
        <General />
      </Route>
    </Switch>
  );
};

export default Index;

const General = () => {
  const {t} = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('billing-settings')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('here-you-can-find-all-the-setting-you-need-for-your-store-to-function')}</p>
        </div>
      </div>
    </div>
  );
};

import { Empty, Input, Modal, Image } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronsLeft, faChevronsRight, faXmark } from '@fortawesome/pro-regular-svg-icons';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

var images = [{ image: 'allthecontent.png' }, { image: 'howdoiwatch.png' }, { image: 'howdoiwatch2.png' }, { image: 'nopeskycontracts.png' }, { image: 'securedprofiles.png' }, { image: 'tvthewayyoulike.png' }, { image: 'watchanydevice.png' }];

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
const { t } = useTranslation();

  const [items, setItems] = useState(images);
  const [selected, setSelected] = useState([] as any);

  useEffect(() => {
    if (data.slides != undefined) {
      setSelected(data.slides);
      var newItems = items;
      data.slides.forEach((element) => {
        newItems = newItems.filter((e) => e.image < element.image);
      });
      setItems(newItems);
    }
  }, [data, form]);

  const onSubmitLocal = () => {
    let dataOut = {
      ...data,
      slides: selected,
    };

    onSubmit(dataOut, type, props.index);
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const moveRight = (droppableSource: any, droppableDestination: any) => {
    const left = items;
    const right = selected;
    const [removed] = left.splice(droppableSource.index, 1);
    setItems(left);
    right.push(removed);
    setSelected(right);
  };
  const moveLeft = (droppableSource: any, droppableDestination: any) => {
    const left = items;
    const right = selected;
    const [removed] = right.splice(droppableSource.index, 1);
    left.push(removed);
    setItems(left);
    setSelected(right);
  };

  const onDragEnd = (result: { source: any; destination?: any }) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'destination') {
        const itemsReOrder = reorder(selected, source.index, destination.index);
        setSelected(itemsReOrder);
        // updateContent(itemsReOrder);
      }
    } else {
      if (source.droppableId === 'source') {
        moveRight(source, destination);
      } else {
        moveLeft(source, destination);
      }
    }
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    padding: 7,
    margin: `0 0 8px 0`,
    background: isDragging ? '#ebebeb' : '#ffffff',
    border:'1px solid #ebebeb',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? '#667ca839' : '#ffffff',
    paddingLeft: 8,
    paddingRight: 8,
    border: '1px solid #ebebeb',
    paddingTop: 8,
    paddingBottom: 1,
    marginBottom: 20,
    height: '80vh',
    borderRadius: 2,
    overflow: "Scroll",
  });

  const deleteItem = (index: any) => {
    const sourceClone = selected;
    const destClone = items;
    const [removed] = sourceClone.splice(index, 1);
    destClone.splice(index, 0, removed);
    setItems(destClone);
    setSelected(sourceClone);
    // updateContent(sourceClone);
  };
  const moveAllRight = () => {
    const left = items;
    const right = selected;
    const newClone = right.concat(left);
    setItems([]);
    setSelected(newClone);
    //updateContent(newClone);
  };
  const moveAllLeft = () => {
    const right = selected;
    const left = items;
    const newClone = left.concat(right);
    setItems(newClone);
    setSelected([]);
    // updateContent([]);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'50%'} footer={false} closable={true} onCancel={onCancel}>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h4 className="text-primary">{t('onboarding-slides')}</h4>
              <div className="row-md-12 mt-5">
                <div className="col-md-12">
                  <div className="row-md-12">
                    <div className="row">
                      <div className="col-md-5">
                        <h4 className="text-primary mt-3">{t('available-slides')} </h4>
                        <p>{t('drag-slides-to-the-right-to-add-them-to-your-onboarding-slide-collection')} </p>
                        <Droppable droppableId="source">
                          {(provided, snapshot) => (
                            <div className="ant-table ant-table-override" ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                              {items.map((item: any, index: any) => (
                                <Draggable key={index} draggableId={'item-' + String(index)} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      //onClick={(e) => handleRowSelection(index, e.metaKey, e.shiftKey)}
                                      className="row"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <Image src={'https://cloudtv.akamaized.net/donotremove/tvms/onboardings/' + item.image} width="150px" preview={false} />
                                      <div style={{ alignSelf: 'center', marginLeft: 20 }}>{item.name}</div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                      <div className="col-md-2 mt-5" style={{ textAlign: 'center', paddingTop: 100 }}>
                        <h4 className="text-primary mt-3 mb-3">&nbsp;</h4>
                        <div className="mb-2">
                          <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => moveAllRight()} color={'#999999'} size={'2x'} className="align-left, align-center" icon={faChevronsRight} />
                        </div>
                        <div>
                          <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => moveAllLeft()} color={'#999999'} size={'2x'} className="align-left, align-center" icon={faChevronsLeft} />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <h4 className="text-primary mt-3">{t('your-slides')} </h4>
                        <p>{t('drag-slides-to-the-left-to-remove-them-and-up-down-to-alter-position')}</p>
                        <Droppable droppableId="destination">
                          {(provided, snapshot) => (
                            <div className="ant-table ant-table-override" ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                              {selected.length == 0 && (
                                <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: 200 }}>
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                              )}
                              {selected.map((item: any, index: any) => (
                                <Draggable key={index} draggableId={'item' + String(index)} index={index}>
                                  {(provided, snapshot) => (
                                    <div className="row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                      <Image src={'https://cloudtv.akamaized.net/donotremove/tvms/onboardings/' + item.image} width="75px" preview={false} />
                                      <div style={{ alignSelf: 'center', marginLeft: 20 }}>{item.name}</div>
                                      <FontAwesomeIcon onClick={() => deleteItem(index)} color={'#999999'} size={'xl'} className="align-right" style={{ position: 'absolute', right: 25, cursor: 'pointer', paddingLeft: 10, paddingRight: 10, paddingTop: 13 }} icon={faXmark} />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mt-3">
                      <button onClick={onSubmitLocal} type={'button'} className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                        {t('update-slides')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    </Modal>
  );
};

export default LayoutModel;

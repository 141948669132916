import React from 'react';

type CardProps = {
  children?: React.ReactNode;
};

const Card = ({ children }: CardProps) => {
  return (
    <div className="card mt-4 pb-4">
      <div className="card-body card-ch-padding">{children}</div>
    </div>
  );
};

export default Card;

import { useParams } from 'react-router-dom';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReportingContextProvider } from '../../../contexts/reportingContext';
import Dashboard from './dashboard/dashboard';

const ReportingNarrowcast = () => {
  const { t } = useTranslation();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  return (
    <>
    {isError && <span>{t('something-went-wrong')}</span>}
    {!isLoading && !isError && data &&
    <ReportingContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
      <Dashboard showActionButtons={false} />
    </ReportingContextProvider>
    }
    </>
  );
};

export default ReportingNarrowcast;

import { Route, Switch, useRouteMatch } from 'react-router'
import ExcelImporter from './importer/excel'

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/importer-excel`}><ExcelImporter /></Route>
    </Switch>
  )
}

export default Index

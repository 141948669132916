import { Route, Switch, useRouteMatch } from 'react-router';
import List from './list';
import Details from './details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <Details />
      </Route>
      <Route path={`${path}`}>
        <List />
      </Route>
    </Switch>
  );
};

export default Index;

import { Navigation_Menu } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/navigation/menu/menu";
import { Navigation_Menu as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/navigation/menu/menu";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Navigation_MenuService {
    Add_Navigation_Menu = async (data: Navigation_Menu): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.NAVIGATION.MENUS.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Navigation_Menu_Template = async (data: Navigation_Menu): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.NAVIGATION.MENUS.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Navigation_Menus = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.NAVIGATION.MENUS.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Navigation_Menu = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.NAVIGATION.MENUS.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Navigation_Menu = async (data: Navigation_Menu): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.NAVIGATION.MENUS.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Navigation_Menu = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.NAVIGATION.MENUS.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Navigation_MenuService();

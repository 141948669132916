import { IBaseResponse } from '../../interfaces/responses/IBaseResponse';
import { IAuthorizedUsersOfDatastoreResponse } from '../../interfaces/responses/users/IGetAuthorizedUsersOfDatastore';
import { User, Data } from '../../interfaces/responses/users/user';
import { IUsersByRoleResponse } from '../../interfaces/responses/users/IGetUsersListWithRoleResponse';
import { IUpdateUserDetailsForm } from '../../interfaces/responses/users/IUpdateUserDetails';
import axios from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class UserService {
  GetUserList = (data: any, query: any): Promise<User> => {
    return axios
      .get(`${API_ENDPOINTS.V1.User.LIST}/${query.client}`, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  AddUser = (values: User): Promise<any> => {
    return axios
      .post(API_ENDPOINTS.V1.User.ADD, values)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  DetailsUser = async (data: any): Promise<User> => {
    return axios
      .get(`${API_ENDPOINTS.V1.User.DETAILS}/${data._id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Me = async (): Promise<Data> => {
    return axios
      .get(API_ENDPOINTS.V1.User.ME)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  ///old
  GetUserInfo = () => {
    return axios
      .get(API_ENDPOINTS.V1.User.GET_USER_INFO)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  GetUsersListWithRole = ({ role, datastore_id, client_id }: any): Promise<IUsersByRoleResponse> => {
    return axios
      .post(`${API_ENDPOINTS.V1.User.GET_USERS_LIST_WITH_ROLE}/${role}?instance_id=${client_id}&datastore_id=${datastore_id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  GetAuthorizedUsersListForDatastores = ({ datastore_id }: any): Promise<IAuthorizedUsersOfDatastoreResponse> => {
    return axios
      .post(`${API_ENDPOINTS.V1.Datastore.GET_LIST_OF_AUTHORISED_DATASTORE_USERS}`, {
        datastore_id,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  DeleteUserById = async (user_id: string): Promise<IBaseResponse> => {
    return axios
      .delete(`${API_ENDPOINTS.V1.User.DELETE_USER}/${user_id}`, {
        data: {
          user_id,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  UpdateUserDetails = async (model: IUpdateUserDetailsForm): Promise<IBaseResponse> => {
    return axios
      .put(`${API_ENDPOINTS.V1.User.UPDATE}/${model._id}`, model)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new UserService();

import { Top10_Rails } from '../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/top10/top10';
import { Top10_Rails as Response } from '../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/rails/top10/top10';

import { IBaseResponse } from '../../../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../../../_APIConstants';

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from '../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams';

class Top10Service {
  Add_Top10 = async (data: Top10_Rails): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.TOP10.ADD, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Top10_Template = async (data: Top10_Rails): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.TOP10.ADDTEMPLATE, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List_Top10s = async (data: IABP_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.APB.RAILS.TOP10.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get_Top10 = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.APB.RAILS.TOP10.DETAILS}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Top10 = async (data: Top10_Rails): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.APB.RAILS.TOP10.UPDATE}`, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Top10 = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.APB.RAILS.TOP10.DELETE}/${data._id}`, {
        params: data.query_params,
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Top10Service();

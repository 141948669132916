import { Promotions_Messages } from "../../../../../../../interfaces/forms/areas/appbuilder/builder/elements/promotions/messages/messages";
import { Promotions_Messages as Response } from "../../../../../../../interfaces/responses/areas/appbuilder/builder/elements/promotions/messages/messages";

import { IBaseResponse } from "../../../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../../../_APIConstants";

import { IABP_Lists } from '../../../../../../../interfaces/forms/common/IABP_Lists';
import { IGetForm } from "../../../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Promotions_MessagesService {
    Add_Promotions_Messages = async (data: Promotions_Messages): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.MESSAGES.ADD, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Add_Promotions_Messages_Template = async (data: Promotions_Messages): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.MESSAGES.ADDTEMPLATE, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Promotions_Messagess = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.PROMOTIONS.MESSAGES.LIST, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Promotions_Messages = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.PROMOTIONS.MESSAGES.DETAILS}/${data._id}`, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Update_Promotions_Messages = async (data: Promotions_Messages): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.PROMOTIONS.MESSAGES.UPDATE}`, data, {
                 params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Promotions_Messages = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.PROMOTIONS.MESSAGES.DELETE}/${data._id}`, {
                 params: data.query_params,
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Promotions_MessagesService();

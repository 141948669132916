import { Registrations_Texts } from "../../../../../interfaces/forms/areas/appbuilder/applications/registration/registration";
import { Registrations_Texts as Response } from "../../../../../interfaces/responses/areas/appbuilder/applications/registration/registration";

import { IBaseResponse } from "../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../_APIConstants";
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import {IGetForm} from "../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Registrations_TextsService {
    Add_Registrations_Texts = async (data: Registrations_Texts): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.APPLICATIONS.REGISTRATION.ADD, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Registrations_Texts = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.APPLICATIONS.REGISTRATION.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Registrations_Texts = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.REGISTRATION.DETAILS}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    }; 

    Update_Registrations_Texts = async (data: Registrations_Texts): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.REGISTRATION.UPDATE}`, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Registrations_Texts = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.REGISTRATION.DELETE}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Publish_Registrations_Texts = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.REGISTRATION.PUBLISH}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Registrations_TextsService();

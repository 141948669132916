import { Button, Form, Input, Select, Switch, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import affiliates from '../../../../../../services/areas/marketing/affiliates/affiliates';
import { IMSNameParams } from '../../../../../dashboard';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';

const AddAlbum = () => {
  const history = useHistory();
  const { clientName, deployment, service } = useParams<IMSNameParams>();
  const [isAdding, setIsAdding] = useState(false);
  const [form] = useForm();
const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const [checked, setChecked] = useState(true);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    values = {
      ...values,
      vanity: values.vanity.toUpperCase(),
      enabled: checked,
      query_params: {
        instance: clientName,
        cms: deployment ?? '',
        crm: service,
      },
    };
    let response = await affiliates.Add_Affiliates(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-affiliate-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-affiliate')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('affiliates-or-influencers-are-a-great-way-to-spread-the-word-about-your-services-and-get-new-customers-to-signup')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('affiliate')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-5"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('affiliate-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('email')}</label>
                <Form.Item
                  className="col-md-5"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('affiliate-email')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('phone')}</label>
                <Form.Item
                  className="col-md-5"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('affiliate-phone-or-mobile')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">Enabled/Active</label>
                <Form.Item className="col-md-5" name="enabled">
                  <Switch className="ch-switch" checked={checked} defaultChecked={true} onChange={setChecked} />
                </Form.Item>
              </div>
            </div>
            <h4 className="text-primary mt-5 mb-3">{t('outlets')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('facebook')}</label>
                <Form.Item className="col-md-5" name="facebook">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('facebook-address')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('instagram')}</label>
                <Form.Item className="col-md-5" name="instagram">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('instagram-address')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">TikTok</label>
                <Form.Item className="col-md-5" name="tiktok">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('tiktok-address')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('website')}</label>
                <Form.Item className="col-md-5" name="website">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('website')} />
                </Form.Item>
              </div>
            </div>
            <h4 className="text-primary mt-5 mb-3">{t('financial')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('vanity-code')}</label>
                <Form.Item
                  className="col-md-5"
                  name="vanity"
                  rules={[
                    {
                      required: true,
                      message: t('vanity-code-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('e-g-get-my-discount-or-dw8as99')} />
                </Form.Item>
              </div>

              {/* <div className="row">
                <label className="col-md-3 col-form-label">Customer Discount</label>
                <Form.Item
                  className="col-md-5"
                  rules={[
                    {
                      required: true,
                      message: 'App name is required',
                    },
                  ]}
                  name={['discount', 'type']}
                >
                  <Select className="default-select" placeholder="- Select Discount Type -">
                    <Select.Option value={'Percentage'} key={'Percentage'}>
                      Percentage
                    </Select.Option>
                    <Select.Option value={'Fixed'} key={'Fixed'}>
                      Fixed Value
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div> */}
              <div className="row">
                <label className="col-md-3 col-form-label">{t('discount-percentage')}</label>
                <Form.Item
                  className="col-md-5"
                  name={['discount', 'amount']}
                  rules={[
                    {
                      required: true,
                      message: t('vanity-code-is-required'),
                    },
                  ]}
                >
                  <Input min={0} type="number" className="form-control bg-white font-size-14 text-default p-3rem" placeholder="12" />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('affiliate-commission')}</label>
                <Form.Item
                  className="col-md-5"
                  rules={[
                    {
                      required: true,
                      message: t('app-name-is-required'),
                    },
                  ]}
                  name={['commission', 'type']}
                >
                  <Select className="default-select" placeholder={t('select-commission-type')}>
                    <Select.Option value={"Percentage"} key={'Percentage'}>
                      {t('percentage')}
                    </Select.Option>
                    <Select.Option value={"Fixed"} key={'Fixed'}>
                      {t('fixed-value')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('commission-percentage')}</label>
                <Form.Item
                  className="col-md-5"
                  name={['commission', 'amount']}
                  rules={[
                    {
                      required: true,
                      message: t('vanity-code-is-required'),
                    },
                  ]}
                >
                  <Input min={0} type="number" className="form-control bg-white font-size-14 text-default p-3rem" placeholder="12" />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-affiliate')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;

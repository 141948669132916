import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faUserClock, faUserCheck, faUserXmark, faClock, faCircleCheck, faMoneyBill, faArrowPointer, faBug, faCctv, faFilm, faMemo, faMusic, faPodcast, faRadio, faSchool, faTelevision, faVideo, faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChartDataSeries } from "@mwaretv/database/build/backend/util/queryGenerator";

export const PlainValueChart = ({ series, iconName }: { series: ChartDataSeries[], iconName?: string }) => {

  const getIconDefinitionFromChartInput = (iconName?: string): IconDefinition | undefined => {

    switch (iconName) {
      case "pending": {
        return faUserClock;
      }
      case "active": {
        return faUserCheck;
      }
      case "expired": {
        return faUserXmark;
      }
      case "trial": {
        return faClock;
      }
      case "regular": {
        return faCircleCheck;
      }
      case "money": {
        return faMoneyBill;
      }
      case "channel": {
        return faTelevision;
      }
      case "radio": {
        return faRadio;
      }
      case "cam": {
        return faCctv;
      }
      case "movie": {
        return faFilm;
      }
      case "series": {
        return faVideo;
      }
      case "course": {
        return faSchool;
      }
      case "album": {
        return faMusic;
      }
      case "podcast": {
        return faPodcast;
      }
      case "action": {
        return faArrowPointer;
      }
      case "page": {
        return faMemo;
      }
      case "error": {
        return faBug;
      }
      case "enabled": {
        return faEye;
      }
      case "disabled": {
        return faEyeSlash;
      }
      default: {
        return undefined;
      }
    }

  }

  const icon = getIconDefinitionFromChartInput(iconName);

  return (
    <div style={{ display: 'flex', marginBottom: '10px', marginLeft: '10px', fontSize: '1.2rem' }}>
      <div>
        {series?.map(seriesInstance => {
          return (
            <>
              <span style={{ lineHeight: "normal" }}>{seriesInstance.data ? seriesInstance.data[0].key : undefined}: {seriesInstance?.data ? seriesInstance.data[0].value != null ? seriesInstance.data[0].value.toFixed(seriesInstance.decimals ?? 0) : "" : ""}</span>
              <br />
            </>
          );
        })}
      </div>
      {icon &&
      <div style={{ position: 'absolute', right: 30,bottom:30, opacity: 0.2, fontSize: '5rem' }}>
        <FontAwesomeIcon icon={icon} color={'rgb(255, 255, 255, 0.5)'}></FontAwesomeIcon>
      </div>
      }
    </div>
  );

};

import { Modal, Spin } from 'antd';
import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import common from '../../../../../../../services/common';

import baseTranslation from '../../../../../../../pages/areas/appbuilder/translations/helper/english.json';

import translationService from '../../../../../../../services/areas/billing/translations';
import customerService from '../../../../../../../services/areas/customers/customers';
import productService from '../../../../../../../services/areas/billing/products';

import { STORAGE_ENDPOINTS } from '../../../../../../../services/storage_paths';
import axios from 'axios';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base';

export type LayoutProps = {
  visible: boolean;
  clientName: string;
  deployment: Deployment;
  service: any;
  onCancel: () => void;
  onSubmit: () => void;
};

const DeploymentTransfer = (props: LayoutProps) => {
  const { visible, onCancel, clientName, deployment, service, onSubmit } = props;
  const [isLoading, setLoading] = useState(false);
  const languagesDeployment = (service?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, [props]);

  const startTransfer = async () => {
    setLoading(true);

    await startCustomers();
    await startProducts();



    setLoading(false);
  };


  const startCustomers = async () => {
    let response = await customerService.List_Customers({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        crm: service.name,
      },
      pageNumber: 1,
      pageSize: 100000,
    });

    if (response as any) {
      var customers = response.docs as any;
      for (var customer of customers) {
        await customerService.Update_Customer({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            crm: service.name,
          },
          ...(customer as any),
        });
      }
    }
  };
  const startProducts = async () => {
    let response = await productService.List_Products({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        crm: service.name,
      },
      pageNumber: 1,
      pageSize: 100000,
    });

    if (response as any) {
      var products = response.docs as any;
      for (var product of products) {
        await productService.Update_Product({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            crm: service.name,
            path: STORAGE_ENDPOINTS(clientName, deployment.name, service.name).PRODUCTS.PRODUCTS,
          },
          ...(product as any),
        });
      }
    }
  };

  const getImageBuffer = async (fileName, type, path) => {
    try {
      const res = await axios.get(fileName.indexOf('https://') > -1 ? fileName : 'https://cloudtv.akamaized.net/' + fileName.replace('https://cloudtv.akamaized.net/', ''), { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const binaryDataBuffer = res.data;
        const bufferArray = new Uint8Array(binaryDataBuffer).buffer;
        const blob = new Blob([bufferArray]);
        var fileName_ = fileName.split('/');
        common.UploadBuffer({
          image: blob,
          query_params: {
            path: path,
            name: fileName_[fileName_.length - 1],
            client: clientName,
            deployment: deployment.name,
            service: service.name,
          },
        });
      }
    } catch (e) {}
  };

  return (
    <Modal visible={visible} style={{ border: '1px solid #ebebeb', borderRadius: 10 }} width={'60%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-2 mt-4" style={{ border: '1px solid #ebebeb', height: 495 }}>
        <div className="row justify-content-center" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="p-2">
              <h5> {t('transfer')}</h5>
            </div>
            <div className="row" style={{ height: '100%' }}>
              <div className="col-12" style={{ height: '100%' }}>
                <div style={{ width: '100%', height: '100%' }}>
                  {isLoading && (
                    <div className="text-center w-100" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', zIndex: 9999 }}>
                      <div className="col-md-12 align-self-center" style={{ paddingTop: 175 }}>
                        <Spin size="large" spinning={true} />
                      </div>
                    </div>
                  )}
                  {!isLoading && (
                    <div className="col-12" style={{ paddingLeft: 0, height: '100%' }}>
                      <div className="col-md-12 p-0 mt-3">
                        <div style={{ height: 300, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Hit the transfer button to start transfering from NetStorage to ObjectStorage<br/><br/>You have to manually publish the cart & portal translation files!</div>
                        <button type="button" onClick={startTransfer} className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                          {t('transfer')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeploymentTransfer;

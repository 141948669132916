import { useParams } from 'react-router-dom';
import NestedBundles from '../../../../../common/components/Content/Bundles/NestedBundles';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';

const Bundles = () => {
  let params = useParams<IMSNameParams & { deployment : string }>();
  const { clientName, _id } = useParams<IMSNameParams>();
  const { t } = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6">
          <h2 className="page-title">{t('your-movie-bundles')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('bundles-are-an-easy-way-to-bundle-content-to-be-used-in-your-products')}<br/>{t('each-package-contains-categories-who-in-return-contain-content-items')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <NestedBundles type={"Movies"}></NestedBundles>
        </div>
      </div>
    </div>
  );
};

export default Bundles;

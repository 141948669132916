import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Affiliates from './affiliates';
import AffiliatesAdd from './affiliates/add';
import AffiliatesDetails from './affiliates/details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/details/:_id`}>
        <AffiliatesDetails />
      </Route>
      <Route path={`${path}/add`}>
        <AffiliatesAdd />
      </Route>
      <Route path={`${path}/`}>
        <Affiliates />
      </Route>
    </Switch>
  );
};

export default Index;

import { Tabs, Image, Badge, Modal } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { Spin } from '../../../common/components/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd, faArrowsUpDown, faBlockBrick, faDiagramSubtask, faGlobe, faImage, faMessage, faMessageLines, faPenRuler, faRectangleHistory, faRectangleWide, faRibbon, faTableList, faTextSize, faTrash, faXmark } from '@fortawesome/pro-regular-svg-icons';
import TabPane from 'antd/lib/tabs/TabPane';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import EditGeneral from './Edit/Edit_General';
import EditMessage from './Edit/Edit_Message';
import EditAds from './Edit/Edit_Ads';
import EditKaiAds from './Edit/Edit_KaiAds';
import EditKaiFullscreen from './Edit/Edit_KaiFullscreen';
import EditCastoolaAds from './Edit/Edit_CastoolaAds';
import EditCastoolaFullscreen from './Edit/Edit_CastoolaFullscreen';
import EditSurvey from './Edit/Edit_Survey';
import EditTVGuide from './Edit/Edit_Tvguide';
import EditInfobar from './Edit/Edit_Infobar';
import EditPromoMessage from './Edit/Edit_PromoMessage';
import EditText from './Edit/Edit_Text';
import EditTextDetails from './Edit/Edit_Text_Details';
import EditRibbon from './Edit/Edit_Ribbon';
import EditLayout from './Edit/Edit_Layout';
import EditPlacementRails from './Edit/Edit_Placement_Rails';
import EditPlacementGrid from './Edit/Edit_Placement_Grid';
import EditContent from './Edit/Edit_Content';
import EditScheduler from './Edit/Edit_Scheduler';
import EditComponents from './Edit/Edit_Components';
import EditMenu from './Edit/Edit_Menu';
import EditWebview from './Edit/Edit_Webview';
import EditVideoPlayer from './Edit/Edit_VideoPlayer';
import EditLogoWide from './Edit/Edit_LogoWide';

import { AppBuilderContext } from '../../../contexts/appbuilderContext';
import { useHistory, useParams } from 'react-router-dom';
import appService from '../../../services/areas/appbuilder/applications/application';
import { Data as Application } from '../../../interfaces/responses/areas/appbuilder/applications/application/application';
import Utils from './Utils';
import { STORAGE_ENDPOINTS } from '../../../services/storage_paths';
import { IMSNameParams } from '../../../pages/dashboard';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { setThemeColors, updateTheme } from './Helpers/helpers';
import { useTranslation } from 'react-i18next';
import Collapsible from '../Collapsible/Collapsible';

const PageBuilder = (params: { pageName: string; application: any; elements: any; page: any }) => {
  const ref = useRef(null);
  const application_ = useRef(params.application as any);
  const history = useHistory();

  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoaded(false);

      var hasChanges = false;
      var changes = [] as any;
      //merge to v2
      if (params.application) {
        if (params.application.screens) {
          var screens = params.application.screens;
          for (var screen of screens) {
            var index = 0;
            for await (var header of screen.header) {
              var key = Object.keys(header);
              var element = header[key[0]];
              if (element?.type === undefined && key.length > 0) {
                hasChanges = true;
                changes.push(header);
                application_.current = await Utils.convertToAppbuilderV2(header, deployment, clientName, application_.current, screen.type, index, 'header');
              }
              index++;
            }
            index = 0;
            for await (var hero of screen.hero) {
              var key = Object.keys(hero);
              var element = hero[key[0]];
              if (element?.type === undefined && key.length > 0) {
                hasChanges = true;
                changes.push(hero);
                application_.current = await Utils.convertToAppbuilderV2(hero, deployment, clientName, application_.current, screen.type, index, 'hero');
              }
              index++;
            }
            index = 0;
            for await (var content of screen.content) {
              var key = Object.keys(content);
              var element = content[key[0]];
              if (element?.type === undefined && key.length > 0) {
                hasChanges = true;
                changes.push(content);
                application_.current = await Utils.convertToAppbuilderV2(content, deployment, clientName, application_.current, screen.type, index, 'content');
              }
              index++;
            }
            index = 0;
            for await (var footer of screen.footer) {
              var key = Object.keys(footer);
              var element = footer[key[0]];
              if (element?.type === undefined && key.length > 0) {
                hasChanges = true;
                changes.push(footer);
                application_.current = await Utils.convertToAppbuilderV2(footer, deployment, clientName, application_.current, screen.type, index, 'footer');
              }
              index++;
            }
          }
        }
      }

      if (params.application) {
        //add new player setups and remove old one
        // if (params.application.menus?.find((m) => m?.name == 'Video Player') == undefined) {
        //   hasChanges = true;
        //   changes += 'vidoe';
        //   application_.current.menus.push({ name: 'Video Player' });
        // }
        // if (params.application.menus?.find((m) => m?.name == 'Audio Player') == undefined) {
        //   hasChanges = true;
        //   changes += 'audio';
        //   application_.current.menus?.push({ name: 'Audio Player' });
        // }
        // if (params.application.menus?.findIndex((m) => null) > -1) {
        //   changes += 'null';
        //   hasChanges = true;
        //   delete application_.current.menus[params.application.menus?.findIndex((m) => null)];
        // }
      }

      if (hasChanges === true) {
        await updateApplication(application_.current);
        setApplication(application_.current);
        //history.go(0);
      }
      setIsLoaded(true);
    }
    fetchMyAPI();
  }, [params.application]);

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    background: isDragging ? 'transparent' : 'transparent',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? '#efefef' : 'transparent',
  });

  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const { t } = useTranslation();
  const [pageName] = useState(params.pageName);
  const [activeKey, setActiveKey] = useState('Header');
  const [application, setApplication] = useState<Application>(params.application);
  const [page, setPage] = useState(params.page);
  const [EditGeneralMessageModal, setEditGeneralMessageModal] = useState(false);
  const [EditMessageMessageModal, setEditMessageMessageModal] = useState(false);
  const [EditAdsModal, setEditAdsModal] = useState(false);
  const [EditKaiAdsModal, setEditKaiAdsModal] = useState(false);
  const [EditKaiFullscreenModal, setEditKaiFullscreenModal] = useState(false);
  const [EditMenuModal, setEditMenuModal] = useState(false);
  const [EditCastoolaAdsModal, setEditCastoolaAdsModal] = useState(false);
  const [EditCastoolaFullscreenModal, setEditCastoolaFullscreenModal] = useState(false);
  const [EditSurveyModal, setEditSurveyModal] = useState(false);
  const [EditPromoMessageModal, setEditPromoMessageModal] = useState(false);
  const [EditTextMessageModal, setEditTextMessageModal] = useState(false);
  const [EditTextDetailsMessageModal, setEditTextDetailsMessageModal] = useState(false);
  const [EditLogoWideModal, setEditLogoWideModal] = useState(false);
  const [EditWebviewModel, setEditWebviewModal] = useState(false);
  const [EditVideoPlayerModel, setEditVideoPlayerModal] = useState(false);
  const [EditShowTVGuideModal, setEditShowTVGuideModal] = useState(false);
  const [EditShowInfobarModal, setEditShowInfobarModal] = useState(false);
  const [EditShowRibbonModal, setEditShowRibbonModal] = useState(false);
  const [EditShowLayoutModal, setEditShowLayoutModal] = useState(false);
  const [EditShowPlacementGridModal, setEditShowPlacementGridModal] = useState(false);
  const [EditShowPlacementRailsModal, setEditShowPlacementRailsModal] = useState(false);
  const [editSchedulereModal, setEditSchedulereModal] = useState(false);
  const [editComponentModal, setEditComponentModal] = useState(false);
  const [EditShowContentModal, setEditShowContentModal] = useState(false);
  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');
  const [editIndex, setEditIndex] = useState(0);
  const [editScreenType, setEditScreenType] = useState('');
  const { id } = useParams<any>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [headerContent] = useState(Utils.getHeaderContent());
  const [heroContent] = useState(Utils.getHeroContent());
  const [contentContent] = useState(Utils.getContentContent());
  const [footerContent] = useState(Utils.getFooterContent());
  const [isLoaded, setIsLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState(params.application.settings.theme);

  const updateApplication = async (application_: any) => {
    await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application_,
    });
  };
  // const publishApplication = async () => {
  //   await appService.Publish_Application({
  //     query_params: {
  //       cms: deployment?.name ?? '',
  //       instance: clientName,
  //       path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
  //     },
  //     _id: id,
  //   });
  // };
  const deleteItemFromApplication = async (location: any, contentIndex: any) => {
    if (location == 'header') {
      application.screens.find((p: any) => p.type == pageName)?.header?.pop();
    }
    if (location == 'hero') {
      application.screens.find((p: any) => p.type == pageName)?.hero?.pop();
    }
    if (location == 'content') {
      application.screens.find((p: any) => p.type == pageName)?.content?.splice(contentIndex, 1);
    }
    if (location == 'footer') {
      application.screens.find((p: any) => p.type == pageName)?.footer?.splice(contentIndex, 1);
    }
    setApplication({ ...application });
    await updateApplication(application);
    //  await publishApplication();
  };

  const changePageSection = (type: any) => {
    setActiveKey(type);
  };
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result: { draggableId: any; source: any; destination?: any }) => {
    console.log(result)
    if (result.destination == undefined) {
      return;
    }
    if (result.source == undefined) {
      return;
    }
    if (result.source.droppableId == 'content_500') {
      const itemsReOrder = reorder(page.content, result.source.index, result.destination.index);
      var newPage = {
        ...page,
        content: itemsReOrder,
      };
      setPage(newPage);
      var index = application.screens.findIndex((p) => p.type == pageName);
      application.screens[index] = newPage;
      setApplication(application);
      updateApplication(application);
    } else {
      let application_ = Utils.addElementToApplication(result, application as any, pageName) as any;
      var page_ = application_.screens.find((p) => p.type == pageName);
      setPage(page_);
      setApplication(application_);
      updateApplication(application_);
    }
  };

  const editComponent = async (data: any, type: any, index: any) => {
    setEditShowPlacementGridModal(false);
    setEditShowPlacementRailsModal(false);
    setEditShowContentModal(false);
    setEditGeneralMessageModal(false);
    setEditMessageMessageModal(false);
    setEditMenuModal(false);
    setEditAdsModal(false);
    setEditKaiAdsModal(false);
    setEditKaiFullscreenModal(false);
    setEditCastoolaAdsModal(false);
    setEditSurveyModal(false);
    setEditCastoolaFullscreenModal(false);
    setEditPromoMessageModal(false);
    setEditShowRibbonModal(false);
    setEditShowTVGuideModal(false);
    setEditShowInfobarModal(false);
    setEditTextMessageModal(false);
    setEditTextDetailsMessageModal(false);
    setEditShowLayoutModal(false);
    setEditSchedulereModal(false);
    setEditComponentModal(false);
    setEditWebviewModal(false);
    setEditVideoPlayerModal(false);
    setEditLogoWideModal(false);
    setIsLoading(true);
    var newTheme = setThemeColors(data, theme);
    setTheme(newTheme);
    await updateTheme(newTheme, application, deployment, clientName, id);

    var application_ = Utils.editElementToApplication(type, application, pageName, data, index, editScreenType);
    await updateApplication(application_);
    // await publishApplication();

    setIsLoading(false);
  };

  const getEditButtons = (element: any, location: any, contentIndex: any, item) => {
    if (element.type == undefined) {
      var key = Object.keys(item);
      var elementNotFound = key[0];
      if (elementNotFound != 'details_top') {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'crimson', color: '#ffffff', padding: 10, borderRadius: 5, fontWeight: 'bold' }}>
            <div className="text-left" style={{ width: '50%', marginLeft: 10 }}>
              {t('no-match-found-so-add-manually-and-delete-this-red-box')} {elementNotFound}
            </div>
            <div
              className="text-right"
              style={{ width: '50%', marginRight: 10 }}
              onClick={() => {
                deleteItemFromApplication(location, contentIndex);
              }}
            >
              <FontAwesomeIcon style={{ color: '#ffffff' }} size={'xl'} icon={faXmark} />
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    return (
      <div className="container-fluid no-gutters p-0" style={{ marginLeft: 0 }}>
        {Utils.getComponent(element, application)}
        <div
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            borderRadius: 5,
            top: 0,
            width: '100%',
          }}
        >
          <div style={{ width: 60, marginBottom: 10, marginTop: 5 }}>
            {location == 'content' && element.type.indexOf('Guide') < 0 && (
              <div className="ant-card-hoverable" style={{ height: '100%', width: 60, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, margin: 5, backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', display: 'flex', verticalAlign: 'middle', justifyContent: 'center' }}>
                <div className="text-center d-flex align-items-center">
                  <FontAwesomeIcon style={{ color: '#333333' }} size={'xl'} icon={faArrowsUpDown} />
                </div>
              </div>
            )}
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', flexDirection: 'row', margin: 5, marginTop: location == 'content' && element.type.indexOf('Guide') < 0 ? 10 : 5, marginLeft: 10, marginRight: location == 'content' && element.type.indexOf('Guide') < 0 ? 15 : 5, marginBottom: 0, height: 25, width: '100%', opacity: '1', color: '#333333', borderTopRightRadius: 5, backgroundColor: 'rgba(255, 255, 255, 0.85)', padding: 2, fontSize: 12, fontWeight: 'bold' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 10, fontWeight: 'bold', alignSelf: 'center' }}>
                  <b>Component: {element.type}</b>
                </div>
                {element.content && (
                  <>
                    {element?.content?.type != '' && element?.content?.list?.type != '' && (
                      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10, fontWeight: 'bold', alignSelf: 'center' }}>
                        {t('content')}: {element?.content?.type}/{element?.content?.list?.type}
                      </div>
                    )}
                    {element?.content?.type == '' && element?.content?.list?.type == '' && <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-end', paddingRight: 10, fontWeight: 'bold', alignSelf: 'center', color: 'crimson' }}>{t('content')}{t('content-type-and-list-type-are-not-set')}</div>}
                    {element?.content?.type != '' && element?.content?.list?.type == '' && <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-end', paddingRight: 10, fontWeight: 'bold', alignSelf: 'center', color: 'crimson' }}>{t('content')}{t('content-type-is-not-set')}</div>}
                  </>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: location == 'content' && element.type.indexOf('Guide') < 0 ? 10 : 0 }}>
              <div style={{ width: 5, height: 45, cursor: 'pointer', borderBottomLeftRadius: 5, backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}></div>
              {(element.type.indexOf('List') > -1 || element.type.indexOf('Grid') > -1 || element.type.indexOf('Rails') > -1 || element.type.indexOf('Details') > -1 || element.type == 'Single' || element.type == 'Video Player' || element.type == 'Audio Player' || element.type == 'Slider' || element.type == 'Menu' || element.type == 'Search Bar' || element.type == 'Guide Horizontal' || element.type == 'Filter Bar') && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditComponentModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faBlockBrick} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('components').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Ads' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditAdsModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faAd} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'KaiAds' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditKaiAdsModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faAd} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'KaiFS' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditKaiFullscreenModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faAd} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'CastoolaAds' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditCastoolaAdsModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faAd} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Logo & Wide' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditLogoWideModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faImage} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Webview' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditWebviewModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faGlobe} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Video Player' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditVideoPlayerModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faGlobe} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('settings').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'CastoolaFS' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditCastoolaFullscreenModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faAd} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Survey' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditSurveyModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faAd} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Menu Rails' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditMenuModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faDiagramSubtask} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type == 'Messages' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditPromoMessageModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faMessageLines} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}

              {element.message && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditMessageMessageModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faMessage} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('message').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.tvguide && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowTVGuideModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faTableList} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('tvguide').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.infobar && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowInfobarModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRectangleWide} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('infobar').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}

              {element.general && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditGeneralMessageModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRectangleHistory} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('placement').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.texts && pageName.indexOf('Details') == -1 && pageName.indexOf('Seasons') == -1 && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditTextMessageModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faTextSize} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('text').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.texts && (pageName.indexOf('Details') > -1 || pageName.indexOf('Seasons') > -1) && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditTextDetailsMessageModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faTextSize} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('text').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}

              {element.type.indexOf('Rails') > -1 && element.type != 'Menu Rails' && element.type != 'Top10 Rails' && element.type != 'Genre Rails' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowRibbonModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRibbon} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('ribbon').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}

              {element.layout && pageName != 'Home' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowLayoutModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faPenRuler} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('layout').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type.indexOf('List') > -1 && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowPlacementGridModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRectangleHistory} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('placement').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type.indexOf('Grid') > -1 && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowPlacementGridModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRectangleHistory} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('placement').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type.indexOf('Rails') > -1 && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowPlacementRailsModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRectangleHistory} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('placement').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.content && element.type != 'Menu Rails' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditShowContentModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faDiagramSubtask} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('content').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              {element.type.indexOf('Rails') > -1 && element.type != 'Menu Rails' && element.type != 'Genre Rails' && (
                <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                  <div
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => {
                      setEditData(element);
                      setEditType(element.type);
                      setEditIndex(contentIndex);
                      setEditScreenType(location);
                      setEditSchedulereModal(true);
                    }}
                    className="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faCalendar} />
                      <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>{t('scheduler').toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5 }}>
                <div
                  style={{ pointerEvents: 'auto' }}
                  onClick={() => {
                    deleteItemFromApplication(location, contentIndex);
                  }}
                  className="h-100 d-flex align-items-center justify-content-center"
                >
                  <div>
                    <FontAwesomeIcon style={{ cursor: 'pointer', color: 'red' }} size={'1x'} icon={faTrash} />
                    <div style={{ color: 'red', fontSize: 8, fontWeight: 'bold' }}>{t('delete').toUpperCase()}</div>
                  </div>
                </div>
              </div>
              <div style={{ width: 5, height: 45, cursor: 'pointer', borderBottomRightRadius: 5, backgroundColor: 'rgba(255, 255, 255, 0.85)', opacity: '1', marginTop: 5, marginBottom: 5, marginRight: 5 }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const getMenuItem = (item: any, name: any, menuIndex: any) => {
    return (
      <div key={item.type + '_' + menuIndex} style={{ margin: 5 }}>
        <Draggable key={item.type + '_' + menuIndex} draggableId={item.type + '_' + name} index={menuIndex}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
              <div className="ant-card-hoverable col" style={{ border: '2px solid #d7d7d7', borderRadius: 5, display: 'inline-table', backgroundColor: '#ffffff', paddingLeft: 0, paddingRight: 0 }}>
                {/* {(item.type == 'Single' || item.type == 'Slider') && (
                  <Badge.Ribbon text="New" color="green">
                    <div className=" col-4 justify-content-center" style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                      <div>
                        <div style={{ position: 'absolute', top: 15, left: 10, fontSize: 10, fontWeight: 'bold', color: '#1ac783', backgroundColor: '#ffffff', padding: 2, paddingRight: 8, paddingLeft: 8, borderRadius: 100 }}>{Utils.getVersion(item.type)}</div>
                        <div className="text-center" style={{ backgroundColor: '#dee2e6', borderTopLeftRadius: 5, borderTopRightRadius: 5, padding: 20 }}>
                          <Image src={Utils.getImage(item.type)} width="240px" height="147px" preview={false} />
                        </div>
                        <div style={{ borderTop: '2px solid #d7d7d7', padding: 10 }}>
                          <div style={{ color: '#485Fe0', fontSize: 12, fontWeight: 'bold' }}>{Utils.getTitle(item.type)}</div>
                          <div style={{ color: '#666', fontSize: 11, textOverflow: 'clip' }}>{Utils.getDescription(item.type)}</div>
                        </div>
                      </div>
                    </div>
                  </Badge.Ribbon>
                )}
                {item.type != 'Single' && item.type != 'Slider' && ( */}
                <div className=" col-4 justify-content-center" style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div style={{ position: 'absolute', top: 15, left: 10, fontSize: 10, fontWeight: 'bold', color: '#1ac783', backgroundColor: '#ffffff', padding: 2, paddingRight: 8, paddingLeft: 8, borderRadius: 100 }}>{Utils.getVersion(item.type)}</div>
                    <div className="text-center" style={{ backgroundColor: '#dee2e6', borderTopLeftRadius: 5, borderTopRightRadius: 5, padding: 20 }}>
                      <Image src={Utils.getImage(item.type)} width="240px" height="147px" preview={false} />
                    </div>
                    <div style={{ borderTop: '2px solid #d7d7d7', padding: 10 }}>
                      <div style={{ color: '#485Fe0', fontSize: 12, fontWeight: 'bold' }}>{Utils.getTitle(item.type)}</div>
                      <div style={{ color: '#666', fontSize: 11, textOverflow: 'clip' }}>{Utils.getDescription(item.type)}</div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          )}
        </Draggable>
      </div>
    );
  };
  const getTabMenus = (type: any, menuItems: any) => {
    if (menuItems.length == 0) {
      return null;
    }
    return (
      <>
        {application != undefined && (
          <Droppable isDropDisabled={true} droppableId={'source_' + type}>
            {(provided, snapshot) => (
              <div style={getListStyle(snapshot.isDraggingOver)} className="source" {...provided.droppableProps} ref={provided.innerRef}>
                {menuItems.map((cont: any, menuIndex: any) => {
                  if (pageName != 'Video Player' && pageName != 'Audio Player' && cont.name == 'Players') {
                    return null;
                  }

                  if (pageName == 'Video Player' && (cont.name == "Hero's" || cont.name == 'Rails' || cont.name == 'Grids' || cont.name == 'Guides' || cont.name == 'Miscellaneous' || cont.name == 'Details' || cont.name == 'Navigation' || cont.name == 'Guides' || cont.name == 'Grids' || cont.name == 'Lists')) {
                    return null;
                  }
                  if (pageName == 'Audio Player' && (cont.name == "Hero's" || cont.name == 'Rails' || cont.name == 'Grids' || cont.name == 'Guides' || cont.name == 'Miscellaneous' || cont.name == 'Details' || cont.name == 'Navigation' || cont.name == 'Guides' || cont.name == 'Grids' || cont.name == 'Lists')) {
                    return null;
                  }

                  if (pageName == 'Home' && (cont.name == 'Details' || cont.name == 'Navigation' || cont.name == 'Guides' || cont.name == 'Grids' || cont.name == 'Lists')) {
                    return null;
                  }
                  if (pageName == 'Course Details' && cont.name == 'Navigation') {
                    return null;
                  }
                  if (pageName == 'Channels' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'TV Guide' && (cont.name == 'Navigation' || cont.name == 'Miscellaneous' || cont.name == 'Details' || cont.name == 'Grids')) {
                    return null;
                  }
                  if (pageName == 'Radio' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Recordings' && (cont.name == 'Navigation' || cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Cams' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Movies' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Shorts' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Games' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Series' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if ((pageName == 'Favorites' || pageName == 'Rentals' || pageName == 'Watchlist' || pageName == 'Downloads' || pageName == 'Purchases') && (cont.name == 'Details' || cont.name == 'Guides' || cont.name == 'Grids' || cont.name == 'Navigation')) {
                    return null;
                  }
                  if (pageName == 'Loader' && (cont.name == 'Miscellaneous' || cont.name == 'Navigation' || cont.name == 'Rails' || cont.name == 'Grids' || cont.name == 'Guides')) {
                    return null;
                  }

                  if (pageName == 'Seasons' && (cont.name == "Hero's" || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Music' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Podcasts' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Courses' && (cont.name == 'Details' || cont.name == 'Guides')) {
                    return null;
                  }
                  if (pageName == 'Search' && (cont.name == 'Navigation' || cont.name == 'Details' || cont.name == 'Guides' || cont.name == 'Grids')) {
                    return null;
                  }

                  if (pageName.indexOf('Details') > -1 && pageName != 'Course Details' && (cont.name == 'Grids' || cont.name == 'Guides' || cont.name == 'Navigation' || cont.name == "Hero's")) {
                    return null;
                  }
                  if (pageName.indexOf('Details') > -1 && pageName == 'Course Details' && (cont.name == 'Guides' || cont.name == "Hero's")) {
                    return null;
                  }

                  if (pageName != 'Seasons' && pageName != 'Course Details' && pageName != 'Downloads' && cont.name == 'Lists') {
                    return null;
                  }
                  if (pageName == 'Video Player' && cont.name == 'Ads & Messages' && activeKey == 'Content') {
                    return null;
                  }
                  if (pageName.indexOf('Details') > -1 && cont.name == "Hero's") {
                    return null;
                  }

                  return (
                    <div key={menuIndex + type} style={{ marginTop: 10, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                      <Collapsible open={false} header={cont.name}>
                        <div className="no-gutters" style={{ height: 750, overflow: 'scroll', width: '100%' }}>
                          {cont.name == 'Grids' && (
                            <>
                              {cont.items.map((item: any, index: any) => {
                                if (pageName == 'Channels' && (item.type == 'Square Grid' || item.type == 'Tile Grid' || item.type == 'Wide Grid' || item.type == 'Long Grid')) {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Cams' && (item.type == 'Square Grid' || item.type == 'Tile Grid' || item.type == 'Wide Grid' || item.type == 'Long Grid')) {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Radio' && (item.type == 'Square Grid' || item.type == 'Tile Grid')) {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Recordings' && item.type == 'Wide Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Movies' && item.type == 'Rectangle Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Shorts' && item.type == 'Wide Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Series' && item.type == 'Wide Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Seasons' && item.type == 'Wide Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Music' && (item.type == 'Square Grid' || item.type == 'Tile Grid')) {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Podcasts' && (item.type == 'Square Grid' || item.type == 'Tile Grid')) {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Courses' && item.type == 'Wide Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else if (pageName == 'Games' && item.type == 'Wide Grid') {
                                  return getMenuItem(item, cont.name, menuIndex);
                                } else {
                                  return null;
                                }
                              })}
                            </>
                          )}
                          {cont.name != 'Grids' && (
                            <>
                              {cont.items.map((item: any, index: any) => {
                                if (type != 'header' && item.type == 'Logo & Wide') {
                                  return null;
                                }
                                if (type != 'header' && item.type == 'Logo & Time') {
                                  return null;
                                }
                                if (type != 'content' && item.type == 'Webview') {
                                  return null;
                                }

                                if (pageName.indexOf('Movie Details') > -1 && item.type == 'Audio Details Standard') {
                                  return null;
                                }
                                if (pageName.indexOf('Course Details') > -1 && item.type == 'Audio Details Standard') {
                                  return null;
                                }
                                if (pageName.indexOf('Program Details') > -1 && item.type == 'Audio Details Standard') {
                                  return null;
                                }
                                if (pageName.indexOf('Seasons') > -1 && item.type == 'Audio Details Standard') {
                                  return null;
                                }
                                if (pageName.indexOf('Podcast Details') > -1 && item.type == 'Video Details Standard') {
                                  return null;
                                }
                                if (pageName.indexOf('Music Details') > -1 && item.type == 'Video Details Standard') {
                                  return null;
                                }
                                if (pageName == 'Video Player') {
                                  if (item.type == 'Video Player') {
                                    return getMenuItem(item, cont.name, menuIndex);
                                  } else {
                                    return null;
                                  }
                                }
                                if (pageName == 'Audio Player') {
                                  if (item.type == 'Audio Player') {
                                    return getMenuItem(item, cont.name, menuIndex);
                                  } else {
                                    return null;
                                  }
                                }

                                if (pageName == 'Loader') {
                                  if (item.type != 'Messages' && item.type != 'Survey') {
                                    if (activeKey == 'Content') {
                                      if (item.type == 'CastoolaFS' || item.type == 'KaiFS') {
                                        return getMenuItem(item, cont.name, menuIndex);
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      if (item.type != 'CastoolaFS' && item.type != 'KaiFS') {
                                        return getMenuItem(item, cont.name, menuIndex);
                                      } else {
                                        return null;
                                      }
                                    }
                                  } else {
                                    return null;
                                  }
                                } else {
                                  if (item.type != 'CastoolaFS' && item.type != 'KaiFS') {
                                    return getMenuItem(item, cont.name, menuIndex);
                                  }
                                }
                              })}
                            </>
                          )}
                        </div>
                      </Collapsible>
                    </div>
                  );
                })}
              </div>
            )}
          </Droppable>
        )}
      </>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="container-fluid h-100" style={{ paddingLeft: 0 }}>
        <div className="card">
          <div className="card-body" style={{ paddingBottom: 0 }}>
            <div className="row">
              <h5 className="text-primary pb-3 pt-2">
                {t('current-page')} {pageName}
              </h5>
            </div>
            <div className="row">
              {isLoading && (
                <Modal visible={true} footer={null} closable={false} centered={true}>
                  <div className="p-5">
                    <div className="col-md-12 align-self-center">
                      <div>
                        <Spin size="large" spinning={true} />
                      </div>
                      <div className="text-center" style={{ paddingTop: 120 }}>
                        ...Updating App
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
              {!application != undefined && (
                <>
                  {editSchedulereModal && (
                    <EditScheduler
                      visible={editSchedulereModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditSchedulereModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {editComponentModal && (
                    <EditComponents
                      visible={editComponentModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditComponentModal(false);
                      }}
                      application={application}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditPromoMessageModal && (
                    <EditPromoMessage
                      visible={EditPromoMessageModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditPromoMessageModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditMenuModal && (
                    <EditMenu
                      menus={application.menus}
                      visible={EditMenuModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditMenuModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditAdsModal && (
                    <EditAds
                      visible={EditAdsModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditAdsModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditKaiAdsModal && (
                    <EditKaiAds
                      visible={EditKaiAdsModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditKaiAdsModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditKaiFullscreenModal && (
                    <EditKaiFullscreen
                      visible={EditKaiFullscreenModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditKaiFullscreenModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditCastoolaAdsModal && (
                    <EditCastoolaAds
                      visible={EditCastoolaAdsModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditCastoolaAdsModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditCastoolaFullscreenModal && (
                    <EditCastoolaFullscreen
                      visible={EditCastoolaFullscreenModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditCastoolaFullscreenModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditSurveyModal && (
                    <EditSurvey
                      visible={EditSurveyModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditSurveyModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditMessageMessageModal && (
                    <EditMessage
                      visible={EditMessageMessageModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditMessageMessageModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditGeneralMessageModal && (
                    <EditGeneral
                      theme={theme}
                      visible={EditGeneralMessageModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditGeneralMessageModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowTVGuideModal && (
                    <EditTVGuide
                      theme={theme}
                      visible={EditShowTVGuideModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowTVGuideModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowInfobarModal && (
                    <EditInfobar
                      theme={theme}
                      visible={EditShowInfobarModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowInfobarModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditTextMessageModal && (
                    <EditText
                      theme={theme}
                      visible={EditTextMessageModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditTextMessageModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditTextDetailsMessageModal && (
                    <EditTextDetails
                      theme={theme}
                      visible={EditTextDetailsMessageModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditTextDetailsMessageModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditWebviewModel && (
                    <EditWebview
                      visible={EditWebviewModel}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditWebviewModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditVideoPlayerModel && (
                    <EditVideoPlayer
                      visible={EditVideoPlayerModel}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditVideoPlayerModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditLogoWideModal && (
                    <EditLogoWide
                      visible={EditLogoWideModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditLogoWideModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowRibbonModal && (
                    <EditRibbon
                      theme={theme}
                      visible={EditShowRibbonModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowRibbonModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowLayoutModal && (
                    <EditLayout
                      visible={EditShowLayoutModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowLayoutModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowPlacementGridModal && (
                    <EditPlacementGrid
                      theme={theme}
                      visible={EditShowPlacementGridModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowPlacementGridModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowPlacementRailsModal && (
                    <EditPlacementRails
                      theme={theme}
                      visible={EditShowPlacementRailsModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowPlacementRailsModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                  {EditShowContentModal && (
                    <EditContent
                      visible={EditShowContentModal}
                      onSubmit={editComponent}
                      onCancel={() => {
                        setEditShowContentModal(false);
                      }}
                      data={editData}
                      type={editType}
                      index={editIndex}
                    />
                  )}
                </>
              )}

              <div className="col-md-9" style={{ paddingBottom: 20, paddingLeft: 0 }}>
                <div
                  style={{
                    backgroundImage: !isLoaded ? 'none' : 'url(https://cloudtv.akamaized.net/' + application.branding.background_images.landscape.replace('https://cloudtv.akamaized.net/', '') + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingLeft: 0,
                    border: 'none',
                    borderRadius: 5,
                    paddingRight: 0,
                    marginRight: 15,
                    paddingTop: 2,
                    paddingBottom: 2,
                    backgroundColor: '#f2f2f2',
                  }}
                >
                  {!isLoaded && (
                    <div>
                      <div className="text-center w-100" style={{ marginTop: 200, height: 1000 }}>
                        <div className="col-md-12 align-self-center">
                          <div>
                            <Spin size="large" spinning={true} />
                          </div>
                          <div style={{ paddingTop: 100 }}>Converting your App to App Builder V2</div>
                        </div>
                      </div>
                    </div>
                  )}
                  {page != undefined && isLoaded && (
                    <>
                      {pageName != 'Player' && pageName != 'Video Player' && pageName != 'Audio Player' && (
                        <div onClick={() => changePageSection('Header')} style={{ backgroundColor: 'rgba(0,0,0,0.2)', cursor: 'pointer', border: activeKey == 'Header' ? '2px dashed #ffffff' : '2px solid #999', borderRadius: 5, margin: 10 }}>
                          <Droppable droppableId={'header'} isDropDisabled={activeKey == 'Header' && Utils.getHeaderCurrentCount(page) > 0 ? false : true}>
                            {(provided, snapshot) => (
                              <div
                                style={{
                                  ...getListStyle(snapshot.isDraggingOver),
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  textAlign: 'center',
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {page.header.length > 0 && (
                                  <>
                                    {page.header.map((item: any, index: any) => {
                                      var element = [] as any;
                                      if (item.ads != undefined) {
                                        element = item.ads;
                                      }
                                      if (item.kaiads != undefined) {
                                        element = item.kaiads;
                                      }
                                      if (item.castoolaads != undefined) {
                                        element = item.castoolaads;
                                      }
                                      if (item.otas != undefined) {
                                        element = item.otas;
                                      }
                                      if (item.messages != undefined) {
                                        element = item.messages;
                                      }
                                      if (item.filterbars != undefined) {
                                        element = item.filterbars;
                                      }
                                      if (item.detailsbars != undefined) {
                                        element = item.detailsbars;
                                      }
                                      if (item.searchbars != undefined) {
                                        element = item.searchbars;
                                      }
                                      if (item.dvrprogress != undefined) {
                                        element = item.dvrprogress;
                                      }
                                      if (item.logotime != undefined) {
                                        element = item.logotime;
                                      }
                                      if (item.logowide != undefined) {
                                        element = item.logowide;
                                      }
                                      if (item.menus != undefined) {
                                        element = item.menus;
                                      }
                                      if (item.dropdowns != undefined) {
                                        element = item.dropdowns;
                                      }
                                      if (item.audio != undefined) {
                                        element = item.audio;
                                      }

                                      return (
                                        <div className="col content" key={index} style={{ paddingLeft: 0, paddingRight: 0, padding: 5 }}>
                                          <div className="w-100 h-100" style={{ display: 'inline-table' }}>
                                            <div className="text-center col-4 justify-content-center" style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                                              {getEditButtons(element, 'header', 0, item)}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                                {page.header.length == 0 && (
                                  <div
                                    className="content"
                                    key={'headers' + 1}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      backgroundColor: '#ffffff',
                                      borderBottomLeftRadius: 5,
                                      borderBottomRightRadius: 5,
                                      fontWeight: 'bold',
                                      fontSize: 12,
                                      padding: 5,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  >
                                    HEADER
                                  </div>
                                )}
                                {page.header.length == 0 && (
                                  <div
                                    className="content"
                                    key={'headers' + 0}
                                    style={{
                                      height: 95,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      flexDirection: 'column',
                                      width: '99%',
                                      borderRadius: 5,
                                      marginTop: 5,
                                      textAlign: 'center',
                                      display: 'flex',
                                      color: '#ffffff',
                                    }}
                                  >
                                    {t('drag-a-component-from-the-left-to-build-up-this-layout')}
                                  </div>
                                )}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}

                      {pageName != 'Search' && pageName != 'Loader' && pageName != 'Player' && pageName != 'Video Player' && pageName != 'Audio Player' && (
                        <div onClick={() => changePageSection('Hero')} style={{ backgroundColor: 'rgba(0,0,0,0.2)', cursor: 'pointer', border: activeKey == 'Hero' ? '2px dashed #ffffff' : '2px solid #999', borderRadius: 5, margin: 10 }}>
                          <Droppable droppableId={'hero'} isDropDisabled={activeKey == 'Hero' && Utils.getHeroCurrentCount(page) == 0 ? false : true}>
                            {(provided, snapshot) => (
                              <div
                                style={{
                                  ...getListStyle(snapshot.isDraggingOver),
                                  display: 'flex',
                                  height: pageName == 'Program Details' || pageName == 'Seasons' || pageName == 'Course Details' || pageName == 'Movie Details' || pageName == 'Music Details' || pageName == 'Podcast Details' ? '100%' : '100%',
                                  width: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  textAlign: 'center',
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {page.hero.length > 0 && (
                                  <>
                                    {page.hero.map((item: any, index: any) => {
                                      var element = [] as any;
                                      if (item.singles != undefined) {
                                        element = item.singles;
                                      }
                                      if (item.sliders != undefined) {
                                        element = item.sliders;
                                      }
                                      if (item.players != undefined) {
                                        element = item.players;
                                      }

                                      if (item.details_video_standard != undefined) {
                                        element = item.details_video_standard;
                                      }
                                      if (item.details_audio_standard != undefined) {
                                        element = item.details_audio_standard;
                                      }
                                      if (element.length == 0) {
                                        return null;
                                      }
                                      return (
                                        <div className="col content" key={index} style={{ paddingLeft: 0, paddingRight: 0, padding: 5 }}>
                                          <div className="w-100 h-100" style={{ display: 'inline-table' }}>
                                            <div className="text-center col-4 justify-content-center" style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                                              {getEditButtons(element, 'hero', 0, item)}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                                {(page.hero.length == (pageName == 'Home' ? 0 : 1) || page.hero.length == 0) && (
                                  <div
                                    className="content"
                                    key={'heros' + 0}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      backgroundColor: '#ffffff',
                                      borderBottomLeftRadius: 5,
                                      borderBottomRightRadius: 5,
                                      fontWeight: 'bold',
                                      fontSize: 12,
                                      padding: 5,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  >
                                    HERO
                                  </div>
                                )}
                                {(page.hero.length == (pageName == 'Home' ? 0 : 1) || page.hero.length == 0) && (
                                  <div
                                    className="content"
                                    key={'heros' + 1}
                                    style={{
                                      height: pageName == 'Program Details' || pageName == 'Seasons' || pageName == 'Course Details' || pageName == 'Movie Details' || pageName == 'Music Details' || pageName == 'Podcast Details' ? 470 : 280,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      flexDirection: 'column',
                                      width: '99%',
                                      borderRadius: 5,
                                      textAlign: 'center',
                                      display: 'flex',
                                      color: '#ffffff',
                                    }}
                                  >
                                    {t('drag-a-component-from-the-left-to-build-up-this-layout')}
                                  </div>
                                )}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}

                      <div ref={ref} onClick={() => changePageSection('Content')} style={{ backgroundColor: 'rgba(0,0,0,0.2)', cursor: 'pointer', border: activeKey == 'Content' ? '2px dashed #ffffff' : '2px solid #999', borderRadius: 5, margin: 10 }}>
                        <Droppable droppableId={'content_' + 500} isDropDisabled={activeKey == 'Content' ? false : true}>
                          {(provided, snapshot) => (
                            <div
                              style={{
                                ...getListStyle(snapshot.isDraggingOver),
                                ...layoutCSS,
                                display: 'flex',
                                width: '100%',
                                minHeight: pageName != 'Player' ? 500 : '100%',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                                textAlign: 'center',
                              }}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {page.content.length > 0 && (
                                <>
                                  {page.content.map((item: any, index: any) => {
                                    var element = [] as any;
                                    if (item.ads != undefined) {
                                      element = item.ads;
                                    }
                                    if (item.logowide != undefined) {
                                      element = item.logowide;
                                    }
                                    if (item.kaiads != undefined) {
                                      element = item.kaiads;
                                    }
                                    if (item.kaifullscreen != undefined) {
                                      element = item.kaifullscreen;
                                    }
                                    if (item.castoolaads != undefined) {
                                      element = item.castoolaads;
                                    }
                                    if (item.castoolavast != undefined) {
                                      element = item.castoolavast;
                                    }
                                    if (item.castoolafullscreen != undefined) {
                                      element = item.castoolafullscreen;
                                    }
                                    if (item.otas != undefined) {
                                      element = item.otas;
                                    }
                                    if (item.messages != undefined) {
                                      element = item.messages;
                                    }
                                    if (item.filterbars != undefined) {
                                      element = item.filterbars;
                                    }
                                    if (item.searchbars != undefined) {
                                      element = item.searchbars;
                                    }
                                    if (item.dvrprogress != undefined) {
                                      element = item.dvrprogress;
                                    }
                                    if (item.logotime != undefined) {
                                      element = item.logotime;
                                    }
                                    if (item.menus != undefined) {
                                      element = item.menus;
                                    }
                                    if (item.dropdowns != undefined) {
                                      element = item.dropdowns;
                                    }
                                    if (item.audio != undefined) {
                                      element = item.audio;
                                    }
                                    if (item.square_rails != undefined) {
                                      element = item.square_rails;
                                    }
                                    if (item.menu_rails != undefined) {
                                      element = item.menu_rails;
                                    }
                                    if (item.round_rails != undefined) {
                                      element = item.round_rails;
                                    }
                                    if (item.rectangle_rails != undefined) {
                                      element = item.rectangle_rails;
                                    }
                                    if (item.top10_rails != undefined) {
                                      element = item.top10_rails;
                                    }
                                    if (item.widescreen_rails != undefined) {
                                      element = item.widescreen_rails;
                                    }
                                    if (item.player_rails != undefined) {
                                      element = item.player_rails;
                                    }
                                    if (item.morphing_rails != undefined) {
                                      element = item.morphing_rails;
                                    }
                                    if (item.long_rails != undefined) {
                                      element = item.long_rails;
                                    }
                                    if (item.tile_rails != undefined) {
                                      element = item.tile_rails;
                                    }
                                    if (item.genre_rails != undefined) {
                                      element = item.genre_rails;
                                    }
                                    if (item.square_grids != undefined) {
                                      element = item.square_grids;
                                    }
                                    if (item.rectangle_grids != undefined) {
                                      element = item.rectangle_grids;
                                    }
                                    if (item.widescreen_grids != undefined) {
                                      element = item.widescreen_grids;
                                    }
                                    if (item.player_grids != undefined) {
                                      element = item.player_grids;
                                    }

                                    if (item.long_grids != undefined) {
                                      element = item.long_grids;
                                    }
                                    if (item.tile_grids != undefined) {
                                      element = item.tile_grids;
                                    }
                                    if (item.guide_horizontal != undefined) {
                                      element = item.guide_horizontal;
                                    }

                                    if (item.list_downloads != undefined) {
                                      element = item.list_downloads;
                                    }
                                    if (item.survey != undefined) {
                                      element = item.survey;
                                    }
                                    if (item.webview != undefined) {
                                      element = item.webview;
                                    }
                                    if (item.player_video != undefined) {
                                      element = item.player_video;
                                    }
                                    if (item.player_audio != undefined) {
                                      element = item.player_audio;
                                    }

                                    return (
                                      <div className="col content" key={index} style={{ flexGrow: 0, paddingLeft: 0, paddingRight: 0, padding: 5 }}>
                                        <div className="w-100" style={{ display: 'inline-table' }}>
                                          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <Draggable key={element.type + '_' + index} draggableId={element.type + '_' + index} index={index}>
                                              {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                  {getEditButtons(element, 'content', index, item)}
                                                </div>
                                              )}
                                            </Draggable>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                              {page.content.length == 0 && (
                                <div
                                  className="content"
                                  key={'content' + 4}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    backgroundColor: '#ffffff',
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  }}
                                >
                                  CONTENT
                                </div>
                              )}
                              {page.content.length == 0 && (
                                <div
                                  key={'contents' + 2}
                                  style={{
                                    minHeight: pageName != 'Player' ? 500 : 500,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    display: 'flex',

                                    width: '99%',
                                    margin: 5,
                                    borderRadius: 5,
                                    color: '#ffffff',
                                  }}
                                >
                                  {t('drag-a-component-from-the-left-to-build-up-this-layout')}
                                </div>
                              )}
                            </div>
                          )}
                        </Droppable>
                      </div>

                      {pageName == 'Loader' && (
                        <div ref={ref} onClick={() => changePageSection('Footer')} style={{ backgroundColor: 'rgba(0,0,0,0.2)', cursor: 'pointer', border: activeKey == 'Footer' ? '2px dashed #ffffff' : '2px solid #999', borderRadius: 5, margin: 10 }}>
                          <Droppable droppableId={'footer'} isDropDisabled={activeKey == 'Footer' && Utils.getFooterCurrentCount(page) > 0 ? false : true}>
                            {(provided, snapshot) => (
                              <div
                                style={{
                                  ...getListStyle(snapshot.isDraggingOver),
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  textAlign: 'center',
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {page.footer.length > 0 && (
                                  <>
                                    {page.footer.map((item: any, index: any) => {
                                      var element = [] as any;
                                      if (item.ads != undefined) {
                                        element = item.ads;
                                      }
                                      if (item.kaiads != undefined) {
                                        element = item.kaiads;
                                      }

                                      return (
                                        <div className="col content" key={index} style={{ paddingLeft: 0, paddingRight: 0, padding: 5 }}>
                                          <div className="w-100 h-100" style={{ display: 'inline-table' }}>
                                            <div className="text-center col-4 justify-content-center" style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                                              {getEditButtons(element, 'header', 0, item)}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                                {page.footer.length == 0 && (
                                  <div
                                    className="content"
                                    key={'footers' + 1}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      backgroundColor: '#ffffff',
                                      borderBottomLeftRadius: 5,
                                      borderBottomRightRadius: 5,
                                      fontWeight: 'bold',
                                      fontSize: 12,
                                      padding: 5,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  >
                                    FOOTER
                                  </div>
                                )}
                                {page.footer.length == 0 && (
                                  <div
                                    className="content"
                                    key={'footers' + 0}
                                    style={{
                                      height: 95,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      flexDirection: 'column',
                                      width: '99%',
                                      borderRadius: 5,
                                      marginTop: 5,
                                      textAlign: 'center',
                                      display: 'flex',
                                      color: '#ffffff',
                                    }}
                                  >
                                    {t('drag-a-component-from-the-left-to-build-up-this-layout')}
                                  </div>
                                )}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-3 shadow" style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 20, paddingBottom: 10, borderLeft: '1px solid #dfdfdf', borderRight: '1px solid #dfdfdf', backgroundColor: '#ffffff', borderRadius: 5, height: '100%' }}>
                <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={'Header'}>
                  <TabPane disabled={pageName == 'Player' || pageName == 'Video Player' || pageName == 'Audio Player'} tab="Header" key={'Header'} style={{ borderRight: '1px solid #f2f2f2' }}>
                    {params.elements != undefined && getTabMenus('header', headerContent)}
                  </TabPane>
                  <TabPane disabled={pageName == 'Loader' || pageName == 'Video Player' || pageName == 'Audio Player'} tab="Hero" key={'Hero'} style={{ borderRight: '1px solid #f2f2f2' }}>
                    {params.elements != undefined && getTabMenus('hero', heroContent)}
                  </TabPane>
                  <TabPane tab="Content" key={'Content'} style={{ borderRight: '1px solid #f2f2f2' }}>
                    {params.elements != undefined && getTabMenus('content', contentContent)}
                  </TabPane>
                  <TabPane disabled={pageName != 'Loader'} tab="Footer" key={'Footer'} style={{ borderRight: '1px solid #f2f2f2' }}>
                    {params.elements != undefined && getTabMenus('footer', footerContent)}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default PageBuilder;

import { Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import FocusPng from '../../../../assets/images/appbuilder/Focus.png';
import { ColorPicker } from '../../ColorPicker';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  theme: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [focusType, setFocusType] = useState('');
  const [focusColor, setFocusColor] = useState('#ffffff');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [borderColor, setBorderColor] = useState('#ffffff');
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);

  useEffect(() => {
    //new setup
    if (data.type != undefined) {
      setFocusType(data.type);
      setFocusColor(data.focus.backgroundColor);
      setBorderColor(data.focus.borderColor);
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var focusColor_ = focusColor;
    if (focusColor_ != undefined && focusColor_.length == 9) {
      focusColor_ = focusColor_.substring(0, 7);
    }
    var borderColor_ = borderColor;
    if (borderColor_ != undefined && borderColor_.length == 9) {
      borderColor_ = borderColor_.substring(0, 7);
    }
    let animation = [] as any;
    if (focusType == 'border') {
      animation = {
        element: data.element,
        type: 'border',
        focus: {
          duration: 150,
          borderColor: borderColor_,
          borderWidth: values.focus.borderWidth ?? 2,
          //borderRadius: values.focus.borderRadius ?? 0,
        },
        blur: {
          borderWidth: 0,
        },
      };
    }
    if (focusType == 'background') {
      animation = {
        element: data.element,
        type: 'background',
        focus: {
          duration: 150,
          backgroundColor: focusColor_,
        },
        blur: {
          backgroundColor: 'transparent',
        },
      };
    }
    if (focusType == 'background_width_border') {
      animation = {
        element: data.element,
        type: 'background_width_border',
        focus: {
          duration: 150,
          borderColor: borderColor_,
          borderWidth: values.focus.borderWidth ?? 0,
          //borderRadius: values.focus.borderRadius ?? 0,
          backgroundColor: focusColor_,
        },
        blur: {
          borderWidth: 0,
          backgroundColor: 'transparent',
        },
      };
    }
    if (focusType == 'scale') {
      animation = {
        element: data.element,
        type: 'scale',
        focus: {
          duration: 150,
          scale: 1.1,
        },
        blur: {},
      };
    }
    if (focusType == 'scale_with_border') {
      animation = {
        element: data.element,
        type: 'scale_with_border',
        focus: {
          duration: 150,
          scale: 1.1,
          borderColor: borderColor_,
          borderWidth: values.focus.borderWidth ?? 0,
        },
        blur: {},
      };
    }
    
    onSubmit(animation, type, props.index);
  };

  const setNewFocusType = (value: any) => {
    setFocusType(value);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-focus-settings')}{type}{t('close-parenthesis')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row">
                    <div className="col card-bg-light p-4 mb-2 mr-4">
                      <h5 className="text-primary">{t('focus-settings')}</h5>
                      <p>{t('set-how-you-want-the-button-selection-focus-to-work-and-what-color-it-should-use-if-background-is-selected')}</p>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('focus-type')}</label>
                        <div className="col">
                          <Form.Item name={['type']}>
                            <Select onChange={setNewFocusType} className="default-select" placeholder={t('select-type')}>
                              <Select.Option value={'border'} key={'border'}>
                                {t('border')}
                              </Select.Option>
                              <Select.Option value={'background'} key={'background'}>
                                {t('background')}
                              </Select.Option>
                              <Select.Option value={'background_width_border'} key={'background_width_border'}>
                                {t('background-border')}
                              </Select.Option>
                              {(type == "Rails" || type == "Grids") && (
                                <Select.Option value={'scale'} key={'scale'}>
                                  {t('3d-zoom')}
                                </Select.Option>
                              )}
                              {(type == "Rails"|| type == "Grids") && (
                                <Select.Option value={'scale_with_border'} key={'scale_with_border'}>
                                  {t('3d-zoom-border')}
                                </Select.Option>
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      {(focusType == 'background_width_border' || focusType == 'scale_with_border' || focusType == 'border') && (
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('border')}</label>
                          {/* <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name={['focus', 'borderRadius']}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              RADIUS
                            </div>
                          </div> */}
                          <div className="col">
                            <Form.Item className="form-group pb-0 col" name={['focus', 'borderWidth']} style={{ paddingLeft: 0 }}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              WIDTH
                            </div>
                          </div>
                          <div className="col" style={{ paddingRight: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setShowBorderColorPicker(!showBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: borderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={showBorderColorPicker}
                                color={borderColor}
                                onChange={(color) => {
                                  setBorderColor(color);
                                  setShowBorderColorPicker(!showBorderColorPicker);
                                }}
                                setVisible={setShowBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(focusType == 'background' || focusType == 'background_width_border' || focusType == 'background') && (
                        <div className="row mt-2">
                          <label className="col-md-4 col-form-label">{t('focus-color')}</label>
                          <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className="form-group pb-0 col" style={{ paddingRight: 5 }}>
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setShowColorPicker(!showColorPicker);
                                }}
                                style={{
                                  backgroundColor: focusColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={showColorPicker}
                                color={focusColor}
                                onChange={(color) => {
                                  setFocusColor(color);
                                  setShowColorPicker(!showColorPicker);
                                }}
                                setVisible={setShowColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                        </div>
                      )}
                    </div>
                    <div className="col card-bg-light p-4 mb-2 mr-1">
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={FocusPng} alt={''}></img>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-focus')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;

import axiosInstance from "../../../utils/axios";
import { API_ENDPOINTS } from "../../_APIConstants";
import { Product } from "../../../interfaces/forms/areas/billing/products/product"
import { AxiosResponse } from "axios";
import { useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";
import { Ticket } from "../../../interfaces/forms/areas/customers/tickets/ticket";
import { RuleGroupType } from "react-querybuilder";

const { CUSTOMERS : CUSTOMER_ENDPONTS } = API_ENDPOINTS
const { TICKETS : ENDPOINTS } = CUSTOMER_ENDPONTS

type Params = { instance: string, cms: string, crm: string }

type PaginationParams = {
  page : number,
  limit : number
}

type ResponseFormat<T> = {
  docs : T
}

export type PaginatedResults<T> = {
  hasNextPage : boolean
  hasPrevPage : boolean
  limit : number
  nextPage : number | null
  page : number
  pagingCounter : number
  prevPage : null | number
  totalDocs : number,
  totalPages : number
} & ResponseFormat<T>

type ListParams = {
  params : Params & PaginationParams,
}

export const list = ({ params } : ListParams) : Promise<AxiosResponse<PaginatedResults<Ticket[]>>> => {
  return axiosInstance.get<PaginatedResults<Ticket[]>>(ENDPOINTS.LIST, { params })
}

export type AddParams = { params : Params } & { data : Ticket }

export const add = ({ data, params } : AddParams ) : Promise<AxiosResponse<Ticket>> => {
  return axiosInstance.post<Ticket>(ENDPOINTS.ADD, data, { params })
}

export type PatchParams = { params : Params } & { data : Partial<Product>, id : string }

export const patch = ({ data, params, id } : PatchParams ) : Promise<AxiosResponse<Ticket>> => {
  return axiosInstance.patch<Ticket>(`${ENDPOINTS.PATCH}/${id}`, data, { params })
}

export type PutParams = { params : Params } & { data : Ticket, id : string }

export const put = ({ data, params, id } : PutParams ) : Promise<AxiosResponse<Ticket>> => {
  return axiosInstance.put<Ticket>(`${ENDPOINTS.PUT}/${id}`, data, { params })
}

type DeleteParams = { params : Params, id : string }

export const del = ({ params, id } : DeleteParams) : Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params })
}

type GetParams = { params : Params, id : string }

export const get = ({ params, id } : GetParams) : Promise<AxiosResponse<Ticket>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params })
}

export const useTickets = ({ params } : ListParams) => useQuery(['tickets', 'list', params], async () => {
  return list({ params })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useTicket = ({ params, id } : GetParams) => useQuery(['tickets', 'get', params, id], async () => {
  return get({ params, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.reject(e))
})

export const useDeleteMutation = ({ params } : Omit<DeleteParams, 'id'>, options? : Omit<UseMutationOptions<any, unknown, string, unknown>, "mutationFn">) => useMutation(async (id : string) => {
  return del({ params, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.resolve(e))
}, options)

export const useAddMutation = ({ params } : Omit<AddParams, 'data'>, options? : Omit<UseMutationOptions<Ticket, unknown, Ticket, unknown>, "mutationFn">) => useMutation(async (values) => {
  return add({ params, data: values })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.resolve(e))
}, options)

export const usePatchMutation = ({ params, id } : Omit<PatchParams, 'data'>, options? : Omit<UseMutationOptions<Ticket, unknown, Ticket, unknown>, "mutationFn">) => useMutation(async (values) => {
  return patch({ params, data: values, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.resolve(e))
}, options)

export const usePutMutation = ({ params, id } : Omit<PatchParams, 'data'>, options? : Omit<UseMutationOptions<Ticket, unknown, Ticket, unknown>, "mutationFn">) => useMutation(async (values) => {
  return put({ params, data: values, id })
    .then(r => Promise.resolve(r))
    .catch(e => Promise.resolve(e))
}, options)

import { Logins_Texts } from "../../../../../interfaces/forms/areas/appbuilder/applications/login/login";
import { Logins_Texts as Response } from "../../../../../interfaces/responses/areas/appbuilder/applications/login/login";

import { IBaseResponse } from "../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../_APIConstants";
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import {IGetForm} from "../../../../../interfaces/forms/common/IAPBExtraQuerytParams";

class Logins_TextsService {
    Add_Logins_Texts = async (data: Logins_Texts): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.APPLICATIONS.LOGIN.ADD, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    List_Logins_Texts = async (
        data: IABP_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.APB.APPLICATIONS.LOGIN.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Get_Logins_Texts = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.LOGIN.DETAILS}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    }; 

    Update_Logins_Texts = async (data: Logins_Texts): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.APB.APPLICATIONS.LOGIN.UPDATE}`, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Logins_Texts = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.APB.APPLICATIONS.LOGIN.DELETE}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Publish_Logins_Texts = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.APB.APPLICATIONS.LOGIN.PUBLISH}/${data._id}`, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}

export default new Logins_TextsService();

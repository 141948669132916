import { Customers } from '../../../../interfaces/forms/areas/customers/customers/customer';
import { IGetForm } from '../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { Customers as Response } from '../../../../interfaces/responses/areas/customers/customers/customers';
import { IBaseResponse } from '../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';

class Customer_Service {
  // Add_Customer = async (data: Customers): Promise<Response> => {
  //   return await axiosInstance
  //     .post(API_ENDPOINTS.V1.CRM.CUSTOMERS.ADD_CUSTOMER, data, {
  //       params: {
  //         instance: data.query_params.instance,
  //         cms: data.query_params.cms,
  //       },
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };


  List_Customers = async (data: ICMS_Lists): Promise<any> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CRM.CUSTOMERS.GET_CUSTOMERS_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  // Details_Customer = async (data: IGetForm): Promise<Response> => {
  //   return await axiosInstance
  //     .get(`${API_ENDPOINTS.V1.CRM.CUSTOMERS.GET_CUSTOMER_DETAILS}/${data._id}`, {
  //       params: {
  //         instance: data.query_params.instance,
  //         cms: data.query_params.cms,
  //       },
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };
  Update_Customer = async (data: Customers): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(`${API_ENDPOINTS.V1.CRM.CUSTOMERS.PUBLISH_CUSTOMER}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          crm: data.query_params.crm,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  // Delete_Customer = async (data: IGetForm): Promise<IBaseResponse> => {
  //   return await axiosInstance
  //     .delete(`${API_ENDPOINTS.V1.CRM.CUSTOMERS.DELETE_CUSTOMER}/${data._id}`, {
  //       params: {
  //         instance: data.query_params.instance,
  //         cms: data.query_params.cms,
  //         path: data.query_params.path,
  //       },
  //       data,
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };
}

export default new Customer_Service();

import React, { useEffect } from 'react';
import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { CardForm, FormSection, Item, Switch } from '../../../../../common/components/form';
import { PageTitle } from '../../../../../common/components/pageTitle';
import { Form, notification } from 'antd';
import { useAddMutation, useSubscriptionRules } from '../../../../../services/areas/billing/subscriptionRulesService';
import { IMSNameParams } from '../../../../dashboard';
import { BillingContext } from '../../../../../contexts/billingContext';
import { useParams } from 'react-router';
import { Spin } from '../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

const SubscriptionRules = () => {
  const [form] = Form.useForm();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = React.useContext(BillingContext);
  const {t} = useTranslation();
  const { isLoading, isError, data } = useSubscriptionRules({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } });

  const { mutate } = useAddMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('subscription-rules-saved') });
      },
      onError: () => {
        notification.error({ message: t('could-not-save-subscription-rules-please-try-again-later') });
      },
    }
  );

  const onFinish = (values: any) => {
    mutate(values);
  }

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('subscription-rules')} subtitle={t('manage-how-subscriptions-are-handled-when-it-comes-to-renewals-upgrades-and-downgrades')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {isLoading && <Spin />}
          {isError && <span>{t('something-went-wrong-while-fetching-the-subscription-rules')}</span>}
          {!isLoading && !isError && (
            <CardForm {...{ form }} initialValues={data?.data} disabled={isLoading} onFinish={onFinish}>
              <FormSection title={t('subscription-rules')}>
                <Item name={['allow_partial_cancel']} label={t('allow-partial-cancel')} extra={t('allow-customers-to-cancel-before-the-subscription-expiration-date')}>
                  <Switch defaultChecked={data?.data?.allow_partial_cancel ?? false} />
                </Item>
                <Item name={['allow_partial_upgrades']} label={t('allow-partial-upgrades')} extra={t('allow-customers-to-upgrade-before-the-subscription-expiration-date-this-means-the-subscription-change-takes-place-right-away-and-the-customer-pays-for-the-difference-or-gets-assigned-a-credit-when-the-newly-requested-subscription-is-cheaper')}>
                  <Switch defaultChecked={data?.data?.allow_partial_upgrades ?? false} />
                </Item>
                <Item name={['allow_downgrades']} label={t('allow-downgrades')} extra={t('are-customers-allowed-to-downgrade-their-subscriptions-based-on-price-so-for-example-go-from-100-per-month-to-50-per-month')}>
                  <Switch defaultChecked={data?.data?.allow_downgrades ?? false} />
                </Item>
              </FormSection>
            </CardForm>
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default SubscriptionRules;

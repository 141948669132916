import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

type UpdateModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  row: any;
  isUpdating: boolean;
};
const UpdateModal = (props: UpdateModalProps) => {
  const { visible, onSubmit, onCancel, row, isUpdating } = props;
  const { t } = useTranslation();
  let [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(row);
  }, [row]);

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">{t('update-ppv')}</h4>
            <Form
              initialValues={{
                name: '',
                quantity: 0,
                type: '',
              }}
              form={form}
              onFinish={(values) => onSubmit(values)}
              onFinishFailed={(errorInfo) => {}}
            >
              <label className="col-form-label pb-2 d-block text-default">{t('name')}</label>
              <Form.Item
                className="form-group pb-0"
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('please-enter-a-name'),
                  },
                ]}
              >
                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('rule-name')} />
              </Form.Item>
              <label className="col-form-label">{t('rule-type')}</label>
              <Form.Item
                name="access_type"
                rules={[
                  {
                    required: true,
                    message: t('please-select-a-rule-type'),
                  },
                ]}
              >
                <Select className="default-select" placeholder={t('select-rule-type')}>
                  <Select.Option key={'Day'} value={"Day"}>
                    {t('day')}
                  </Select.Option>
                  <Select.Option key={'Month'} value={"Month"}>
                    {t('month')}
                  </Select.Option>
                  <Select.Option key={'Year'} value={"Year"}>
                    {t('year')}
                  </Select.Option>
                </Select>
              </Form.Item>
              <label className="col-form-label">{t('rule-length')}</label>
              <Form.Item extra={t('this-is-the-number-of-days-months-years-the-customer-has-access-to-the-content')} className="form-group pb-0" name="access_length">
                <Input defaultValue={0} type={'number'} className="form-control height-48 p-2 bg-white default-input-number font-size-14 text-default" placeholder={t('quantitty')} />
              </Form.Item>
              <div className="col-md-12 p-0">
                <Button loading={isUpdating} disabled={isUpdating} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-rule')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;

export enum TVMSRoles {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Auditor = 'Auditor',
  Reseller = 'Reseller',
  Employee = 'Employee',
  Vendor = 'Vendor',
  Provider = 'Provider',
  DeploymentAPI = 'DeploymentAPI',
  ServiceAPI = 'ServiceAPI',
}
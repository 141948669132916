import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';

export type LayoutProps = {
  data: [] | any;
  application: Application;
};

const InfoPromo = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);

  return (
    <div className="container-fluid remove-hor-padding" style={{ height: 120 }}>
      <div className="row" style={{        marginTop: props.application.theme?.rails_top_margin ?? 5,
        marginBottom: props.application.theme?.rails_bottom_margin ?? 5,marginRight:-10,
        marginLeft: 5,}}>
        <div className="col" style={{ marginLeft: 10 }}>
          <div className="row" style={{ marginTop: 10 }}>
            <Skeleton.Input active={false} size={'large'} style={{ width: 200, height: 20, backgroundColor: '#999' }} />
          </div>

          <div style={{ gridAutoColumns: '100%', display: 'grid', marginTop: 10, marginLeft: -15 }}>
            <Skeleton.Node active={false} style={{ width: '100%', height: 63, backgroundColor: '#999' }}>
              <div></div>
            </Skeleton.Node>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPromo;

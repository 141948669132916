import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Debtor } from '../../../../../../interfaces/responses/clients/debtor';
import { capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator } from '../../../../../../utils/validators';

const CompanyFields = ({ isInAddClientMode, debtors, show = true }: { isInAddClientMode: boolean, debtors?: Debtor[], show?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: show ? 'block' : 'none' }} className="card-bg-light p-4 mb-5">
      <div className="p-4">
        <h4 className="text-primary">{t('details')}</h4>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('wefact-debtorcode')}</label>
          <Form.Item name={'weFact_debtorCode'} className="col-md-5">
            {isInAddClientMode && debtors &&
            <Select optionFilterProp="label" className="default-select" placeholder={t('wefact-debtorcode')} showSearch>
              {debtors.map((debtor) => {
                return (
                  <Select.Option label={debtor.companyName} value={debtor.code} key={debtor.code}>
                    {`${debtor.companyName} - ${debtor.code}`}
                  </Select.Option>
                );
              }) ?? []}
            </Select>
            }
            {!isInAddClientMode &&
            <Input disabled={true} className="form-control bg-white font-size-14 text-default" placeholder={t('wefact-debtorcode')} />
            }
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('name')}</label>
          <Form.Item
            name="name"
            className="col-md-5"
            rules={[
              { required: true, message: t('name-is-required') },
              { max: 15, message: t('please-choose-a-shorter-name') },
              { validator: capitalizedNoSpecialCharsExceptDashAndUnderscoreValidator, message: t('name-can-only-contain-alphanumeric-characters-and-hyphens-a-z-a-z-zero-nine') },
            ]}
          >
            <Input disabled={!isInAddClientMode} className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
          </Form.Item>
        </div>
        <div className="row">
          <label className="col-md-3 col-form-label">{t('alias-name')}</label>
          <Form.Item
            name="alias_name"
            className="col-md-5"
            rules={[
              {
                required: true,
                message: t('alias-name-is-required'),
              },
            ]}
          >
            <Input disabled={!isInAddClientMode} className="form-control bg-white font-size-14 text-default" placeholder={t('alias-name')} />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default CompanyFields;

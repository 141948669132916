import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import tagsService from '../../../../services/areas/content/tags/tags/index';
import { StatusCodes } from 'http-status-codes';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams } from 'react-router-dom';
import Tag from '../../Content/Tag/Tag';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

const Tags = (params: { setTags: any; data?: any }) => {
  const [tags_, setTags_] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [tagList, setTagList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [showAddTag, setShowAddTag] = useState(false);
  const [translations, setTranslations] = useState([] as any);
  const {t} = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    if (params.data != undefined) {
      var tags = [] as any;
      params.data.forEach((element) => {
        
        if (element.name != '') {
          tags.push({
            label: element.name,
            value: element._id,
          });
        } else {

          var tag = '';
          element.tag?.forEach((el, index) => {

            tag += el.name + ': ' + el.description + (index == element.tag?.length - 1 ? '' : '|');
          });
          tags.push({
            label: tag,
            value: element._id,
          });
        }
      });
      setTags_(tags);
    }
    getTags();
  },[params.data]);

  const getTags = async () => {
    let response = await tagsService.List_Tags({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var tags = [] as any;
      setTagList(response.data.data);
      response.data.data.forEach((element: any) => {
        if (element.tag?.length == 0) {
          tags.push({
            label: element.name,
            value: element._id,
          });
        } else {
          var tag = '';
          element.tag?.forEach((el, index) => {
            tag += el.name + ': ' + el.description + (index == element.tag?.length - 1 ? '' : '|');
          });
          tags.push({
            label: tag,
            value: element._id,
          });
        }
      });
      setTags(tags);
    }
    setIsLoading(false);
  };

  const onChangeTags = (values) => {
    var tagsOut = [] as any;
    values.forEach((element) => {
      var test = tagList.find((t) => t._id == element);
      if (test != undefined) {
        tagsOut.push({
          _id: element,
          name: test.name,
          tag: test.tag,
        });
      }
    });
    params.setTags(tagsOut);
  };

  const storeNewTag = async (tagsIn) => {
    setShowAddTag(false);
    await tagsService.Add_Tags({
      name: '',
      tag: tagsIn,
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
    });
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('content-tags')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 mb-3 pt-3 mt-0 p-5">
            <div className="row">
              <label className="col-md-3 col-form-label">{t('tags')}</label>
              <div className="col-md-9"> 
                {!isLoading && (
                  <Select
                    style={{ width: '100%', margin: 5 }}
                    showSearch
                    loading={isLoading}
                    mode="tags"
                    allowClear
                    defaultValue={tags_}
                    onChange={onChangeTags}
                    filterOption={(input, option) => {
                      return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                    }}
                    options={isLoading ? [] : tags}
                  />
                )}
                {showAddTag && <Tag onSubmit={(tag) => storeNewTag(tag)} visible={showAddTag} onCancel={() => setShowAddTag(false)} service_name={service_name} clientName={clientName} setTranslations={setTranslations}></Tag>}
                <div className="text-right">
                  <Button onClick={() => setShowAddTag(true)} className="btn btn-secondary float-right rounded-pill mt-2 width-180 height-48">
                    {t('create-new-tag')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tags;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'

import PpvRules from './ppv';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/ppvrules`}><PpvRules /></Route>
    </Switch>
  )
}

export default Index

import { createContext } from 'react';
import { DeploymentWithPopulatedServices, PopulatedService } from '../services/deployments/deployment';

type ReportingContextType = {
  deployment: DeploymentWithPopulatedServices | undefined;
  service: PopulatedService | undefined;
};

export const ReportingContext = createContext<ReportingContextType>({
  deployment: undefined,
  service: undefined,
});

export const ReportingContextProvider = ReportingContext.Provider;

import { CoursesExport, CoursesImport, Courses } from '../../../../../interfaces/forms/areas/content/courses/courses';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Courses as Response_Course } from '../../../../../interfaces/responses/areas/content/courses/courses';
import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Courses_Service {
  Add_Course = async (data: Courses): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.COURSES.COURSES.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Courses = async (data: CoursesImport): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.COURSES.COURSES.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Courses = async (data: ICMS_Lists): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.COURSES.COURSES.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Course = async (data: IGetForm): Promise<Response_Course> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.COURSES.COURSES.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Course = async (data: Courses): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.COURSES.COURSES.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Course = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.COURSES.COURSES.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  AutoComplete_List_Courses = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.COURSES.COURSES.AUTOCOMPLETE_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Courses = async (data: CoursesImport): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.COURSES.COURSES.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          addcats: data.query_params.addcats,
          createcats: data.query_params.createcats,
          bundles: data.query_params.bundles,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Courses = async (data: CoursesExport): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.COURSES.COURSES.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  CreateThumbnailTracks = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.COURSES.COURSES.THUMBNAILS}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          url: data.query.url,
          type: data.query.type,
          _id: data.query._id
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Courses_Service();
